import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
/* import { useInternationalization } from "@progress/kendo-react-intl"; */
import "./cell-total.scss";

const CellTotal = ({ data: fragrances }) => {

  /* const intl = useInternationalization(); */

  const orderInfo = useSelector((state) => state.order.orderInfo);

  return (
    <td
      colSpan="1"
      className="c-order-total"
      role="gridcell"
      aria-colindex="2"
      aria-selected="false"
      data-grid-col-index="0"
    >
      {fragrances && fragrances?.rows.length > 0 && (
        <ul style={{ marginTop: 39 }}>
          {fragrances?.rows.map((fragrance, index) => {
            const totalValue = parseFloat(fragrance?.order?.subValue);
            /* const total = intl.formatNumber(fragrance?.order?.subValue,{ minimumFractionDigits: 2 }); fragrance?.order?.subValue */
            return (
              <li
                className={`l-mobile-flex u-flex-middle u-flex-end c-order-total__content ${
                  totalValue !== 0
                    ? "c-order-total__content--full"
                    : "c-order-total__content--empty"
                }`}
                key={index}
                style={{ height: 48 }}
              >
                {/* {`${total} ${orderInfo?.currency}`} */}
                {`${fragrance?.order?.subValue} ${orderInfo?.currency}`}
              </li>
            );
          })}
        </ul>
      )}
    </td>
  );
};

CellTotal.propTypes = {
  data: PropTypes.object,
};

export default CellTotal;
