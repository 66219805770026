import React from "react";
import PropTypes from "prop-types";
import { useInternationalization } from '@progress/kendo-react-intl';
import { formatDateByLanguage } from "utils";
import "./cell-date.scss";

const CellDateCreation = ({ date }) => {
 
  const intl = useInternationalization();

  const languageDateFormat = formatDateByLanguage(intl.locale);

  return (
    <td
      colSpan="1"
      className="c-list-orders__date"
      role="gridcell"
      aria-colindex="2"
      aria-selected="false"
      data-grid-col-index="0"
    >
      <strong>
      { intl.formatDate(
        new Date(date),
        languageDateFormat
      )}
      </strong>
    </td>
  );
};

CellDateCreation.propTypes = {
  date: PropTypes.string,
};

export default CellDateCreation;
