import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderFragranceDetails,
  getFragranceDetailsNotEditable,
} from "redux/features/catalogue/actions";
import { resetFragranceCode } from "redux/features/search";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import "./cell-action.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    addButtonLabel: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.addButtonLabel',
      defaultMessages.metislab.frontend.components.order.components.catalogue.addButtonLabel),
    detailButtonLabel: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.detailButtonLabel',
      defaultMessages.metislab.frontend.components.order.components.catalogue.detailButtonLabel),
    editButtonLabel: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.editButtonLabel',
      defaultMessages.metislab.frontend.components.order.components.catalogue.editButtonLabel),
      
  };
};

const CellAction = (props) => {
  const { addButtonLabel, detailButtonLabel, editButtonLabel } =
    useLocalizedMessages();

  const { data: fragrances, handleCart, idOrder, notEditable } = props;

  const dispatch = useDispatch();

  const loadingCheckoutOrder = useSelector(
    (state) => state.order.loadingCheckoutOrder
  );
  const loadingFragranceData = useSelector((state) => state.fragrance.loading);

  /**
   * searchProduct States
   */
  const fragranceCodeSearched = useSelector(
    (state) => state.searchProduct.fragranceCode
  );

  /**
   * Internal states
   */
  const [fragranceCode, setFragranceCode] = useState(null);

  /**
   * This hook used to call API when a user click on the button
   */
  useEffect(() => {
    if (fragranceCode && !fragranceCodeSearched) {
      if (!notEditable) {
        const args = {
          toast,
          id: fragranceCode,
          idOrder,
        };
        dispatch(getOrderFragranceDetails(args)).then(() => handleCart());
      } else {
        const args = {
          toast,
          fragranceCode,
          idOrder,
        };
        dispatch(getFragranceDetailsNotEditable(args)).then(() => handleCart());
      }
    }
  }, [
    dispatch,
    fragranceCode,
    fragranceCodeSearched,
    handleCart,
    idOrder,
    notEditable,
  ]);

  /**
   * This hook used to call API when a user land on the page after
   * a search
   */
  useEffect(() => {
    if (fragranceCodeSearched) {
      const isFound =
        fragrances?.rows.findIndex(
          (fragrance) => fragrance.fragrance_code === fragranceCodeSearched
        ) > -1;
      if (isFound) {
        dispatch(resetFragranceCode());
        const args = {
          toast,
          id: fragranceCodeSearched,
          idOrder,
        };
        dispatch(getOrderFragranceDetails(args)).then(() => handleCart());
      }
    }
  }, [dispatch, fragranceCodeSearched, fragrances, handleCart, idOrder]);

  const handleOrder = (code) => {
    setFragranceCode(code);
  };

  return (
    <>
      <td
        colSpan="1"
        className="c-order-action k-grid-content-sticky"
        role="gridcell"
        aria-colindex="3"
        aria-selected="false"
        data-grid-col-index="0"
        style={{ textOverflow: "unset", left: 0, right: 0 }}
      >
        {fragrances && fragrances?.rows.length > 0 && (
          <ul style={{ marginTop: 39 }}>
            {fragrances?.rows.map((fragrance, index) => {
              return fragrance?.order?.qty ? (
                <li
                  key={fragrance?.fragrance_code}
                  className="l-mobile-flex u-flex-middle u-flex-end"
                  style={{ height: 48 }}
                >
                  <Button
                    className="c-order-action__modify"
                    fillMode="outline"
                    icon={notEditable ? "preview" : "edit"}
                    dir="rtl"
                    disabled={loadingFragranceData || loadingCheckoutOrder}
                    style={{ color: "#610d11" }}
                    onClick={() => handleOrder(fragrance?.fragrance_code)}
                  >
                    {notEditable ? detailButtonLabel : editButtonLabel}
                  </Button>
                </li>
              ) : (
                <li
                  key={index}
                  className="l-mobile-flex u-flex-middle u-flex-end"
                  style={{ height: 48 }}
                >
                  <Button
                    fillMode="outline"
                    disabled={loadingFragranceData || loadingCheckoutOrder}
                    icon="plus"
                    dir="rtl"
                    onClick={() => handleOrder(fragrance?.fragrance_code)}
                  >
                    {addButtonLabel}
                  </Button>
                </li>
              );
            })}
          </ul>
        )}
      </td>
    </>
  );
};

CellAction.propTypes = {
  data: PropTypes.object,
  notEditable: PropTypes.bool,
};

export default CellAction;
