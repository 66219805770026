import React from "react";
import PropTypes from "prop-types";
import InfoArticle from "../infoArticle";

const Label = ({ data }) => {
  return (
    <div className="c-cart-label">
      <label className="l-mobile-flex u-flex-middle u-gap-8">
        {data.qty !== 0 && (
          <InfoArticle item={data} classIcon="k-icon k-i-info-circle" />
        )}
        <p className="k-my-0">{data?.article_title}</p>
        {data?.alert && (
          <InfoArticle
            item={data?.alert}
            classIcon="icon icon-alert"
            isAlert={true}
          />
        )}
      </label>
    </div>
  );
};

Label.propTypes = {
  data: PropTypes.object,
};

export default Label;
