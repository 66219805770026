import React from 'react';
import PropTypes from 'prop-types';

const CellCustomer = ({ name }) => {
    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="1" 
            aria-selected="false"
            data-grid-col-index="5"
        >
            { name || '-' }
        </td>
    )
}

CellCustomer.propTypes = {
    name: PropTypes.string,
}

export default CellCustomer;