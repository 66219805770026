import React from 'react';
import PropTypes from 'prop-types';

const CellAgent = ({ agentName }) => {
    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="1" 
            aria-selected="false"
            data-grid-col-index="5"
        >
            { agentName || '-' }
        </td>
    )
}

CellAgent.propTypes = {
    code: PropTypes.string,
    blocked: PropTypes.bool,
}

export default CellAgent;