import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipContentTemplate from "../tooltipContentTemplate";
import "./row.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    article: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.cellProduct.article',
      defaultMessages.metislab.frontend.components.order.components.catalogue.cellProduct.article),
    noArticle: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.cellProduct.noArticle',
      defaultMessages.metislab.frontend.components.order.components.catalogue.cellProduct.noArticle), 
  };
}

const RowProducts = ({ hasAlert, items }) => {
  const {
    article,
    noArticle
  } = useLocalizedMessages();
  
  return items?.qty ? (
    <li style={{ display: 'flex', alignItems: 'center', gap: 8}}>
      <Tooltip
        className="c-tooltip-box"
        anchorElement="target"
        content={(props) => <TooltipContentTemplate title={props.title} />}
      >
        <span
          className="c-order-product__content c-order-product__selected l-mobile-flex u-flex-middle"
          style={{ height: 48 }}
        >
          <strong title={JSON.stringify(items?.articles)}>
            {`${article} ${items?.qty}`}   
          </strong>
        </span>
      </Tooltip>
      {hasAlert === 1 && <span className="icon icon-alert" style={{ color: '#ed1c24'}}></span>}
    </li>
  ) : (
    <li
      className="c-order-product__content c-order-product__empty l-mobile-flex u-flex-middle"
      style={{ height: 48 }}
    >
      <span>{noArticle}</span>
    </li>
  );
};

RowProducts.propTypes = {
  hasAlert: PropTypes.number,
  items: PropTypes.object,
};

export default RowProducts;
