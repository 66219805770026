import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  validateLinkRecovery,
  changePasswordFromValidateLinkRecovery, 
} from "redux/features/user/actions";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import Loader from "components/loader";
import "./formPasswordRecovery.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    buttonLabel: toLanguageString(
      'metislab.frontend.components.passwordChange.components.buttonLabel',
      defaultMessages.metislab.frontend.components.passwordChange.components.buttonLabel),
    inputLabel: toLanguageString(
      'metislab.frontend.components.passwordChange.components.inputLabel',
      defaultMessages.metislab.frontend.components.passwordChange.components.inputLabel),
    inputPlaceholder: toLanguageString(
      'metislab.frontend.components.passwordChange.components.inputPlaceholder',
      defaultMessages.metislab.frontend.components.passwordChange.components.inputPlaceholder),  
    inputNewLabel: toLanguageString(
      'metislab.frontend.components.passwordChange.components.inputNewLabel',
      defaultMessages.metislab.frontend.components.passwordChange.components.inputNewLabel),
    inputNewPlaceholder: toLanguageString(
      'metislab.frontend.components.passwordChange.components.inputNewPlaceholder',
      defaultMessages.metislab.frontend.components.passwordChange.components.inputNewPlaceholder),
    passwordNotEqual: toLanguageString(
      'metislab.frontend.components.passwordChange.components.passwordNotEqual',
      defaultMessages.metislab.frontend.components.passwordChange.components.passwordNotEqual),
    title: toLanguageString(
      'metislab.frontend.components.passwordChange.components.title',
      defaultMessages.metislab.frontend.components.passwordChange.components.title),  
    checkingData: toLanguageString(
      'metislab.frontend.components.passwordChange.components.checkingData',
      defaultMessages.metislab.frontend.components.passwordChange.components.checkingData),        
    invalidData: toLanguageString(
      'metislab.frontend.components.passwordChange.components.invalidData',
      defaultMessages.metislab.frontend.components.passwordChange.components.invalidData),       
    goto: toLanguageString(
      'metislab.frontend.components.passwordRecovery.components.goto',
      defaultMessages.metislab.frontend.components.passwordRecovery.components.goto),         
  };
}

const FormPasswordChange = () => {
  const {
    buttonLabel,
    inputLabel,
    inputPlaceholder,
    inputNewLabel,
    inputNewPlaceholder,
    passwordNotEqual,
    title,
    checkingData,
    invalidData,
    goto,
  } = useLocalizedMessages();

  const initialValues = { 
    password: "", 
    passwordConfirmed: "" 
  };

  const initialVisibilityPassword = {
    password: false,
    passwordConfirmed: false,
  };

  const loginSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    passwordConfirmed: Yup.string()
      .required("Required")
      .test("isEqual", passwordNotEqual, (value, testContext) => {
        if (testContext.parent.password === value) return true;
        return false;
      }),
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const dataRef = useRef(null);

  const loadingValidateLinkRecovery = useSelector( (state) => state.user.loadingValidateLinkRecovery);
  const linkRecoveryIsValid = useSelector( (state) => state.user.linkRecoveryIsValid);
  const linkRecoveryResult = useSelector( (state) => state.user.linkRecoveryResult);
  const loadingChangePassword = useSelector( (state) => state.user.loadingChangePassword);


  const [isValidData, setIsValidData] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(initialVisibilityPassword);
  const { password, passwordConfirmed } = passwordVisible;

  useEffect( () => {
    const url= new URL(window.location.href);
    const parameterMap = new Map(url.searchParams);
    
    const username = parameterMap.get('username');
    const token = parameterMap.get('token');

    if (username && token) {
      dataRef.current = {username, token};
    } else {
      setIsValidData(false);
    }

    if (linkRecoveryIsValid === null && dataRef.current) {
      const args = {
        values: {
          username,
          token,
        },
        toast,
      }
      dispatch(validateLinkRecovery(args));
    }

  }, [dispatch, isValidData, linkRecoveryIsValid]);

  const togglePasswordVisibility = (e, input) => {
    e.preventDefault();
    input === "password"
      ? setPasswordVisible({ ...passwordVisible, password: !password })
      : setPasswordVisible({
          ...passwordVisible,
          passwordConfirmed: !passwordConfirmed,
        });
  };

  if (!isValidData || !linkRecoveryIsValid) {
    
    return (
      <div className="c-form c-form--login k-d-flex k-flex-column k-justify-content-center k-align-items-center">
        { loadingValidateLinkRecovery 
          ? <div className="k-d-flex k-align-items-center u-gap-8"
          >
              <Loader />
              <span>{checkingData}</span>
            </div>
          : <>
              <p 
                className="k-d-flex k-align-items-center u-gap-8 k-notification-error k-p-4"
                dangerouslySetInnerHTML={{ __html: isValidData ? linkRecoveryResult : invalidData }}
                style={{ lineHeight: 1, borderRadius: 8, }} 
              />
              <Link to="/" className="c-back">
                <svg
                  width={14}
                  height={12}
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.89611 5.33342H13.6727V6.66675H2.88033L6.61997 10.4064L5.67716 11.3492L0.335938 6.00797L5.67716 0.666748L6.61997 1.60956L2.89611 5.33342Z"
                    fill="#2A2A2A"
                  />
                </svg>
                {goto}
              </Link>
            </> 
        }
      </div>
    )
  }
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const args = {
          values: {
            token: dataRef.current.token,
            username: dataRef.current.username,
            password: values.password,
            password_confirmation: values.passwordConfirmed
          },
          toast,
          navigate,
        };
        dispatch(changePasswordFromValidateLinkRecovery(args));
      }}
      validationSchema={loginSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <form className="c-form c-form--login" onSubmit={handleSubmit}>
            <div className="c-fieldset">
              <header className="c-fieldset__header">
                <h1 className="c-fieldset__title t-title t-title--1">
                  {title}
                </h1>
              </header>
              <div className="c-fieldset__main">
                <div className="c-form-field">
                  <label htmlFor="password">{inputLabel}</label>
                  <Input
                    disabled={loadingChangePassword}
                    type={password ? "text" : "password"}
                    name="password"
                    placeholder={inputPlaceholder}
                    autoComplete="true"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.password && touched.password && (
                    <div className="input-feedback">{errors.password}</div>
                  )}
                  <Button
                    className="c-show-pw k-rounded-full"
                    icon="preview"
                    fillMode="flat"
                    size="small"
                    onClick={(e) => togglePasswordVisibility(e, "password")}
                  />
                  <PasswordStrengthBar
                    className="c-password-strenght"
                    password={values.password}
                  />
                </div>
                <div className="c-form-field">
                  <label htmlFor="password">{inputNewLabel}</label>
                  <Input
                    disabled={loadingChangePassword}
                    type={passwordConfirmed ? "text" : "password"}
                    name="passwordConfirmed"
                    placeholder={inputNewPlaceholder}
                    autoComplete="true"
                    value={values.passwordConfirmed}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.passwordConfirmed && touched.passwordConfirmed
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.passwordConfirmed && touched.passwordConfirmed && (
                    <div className="input-feedback">
                      {errors.passwordConfirmed}
                    </div>
                  )}
                  <Button
                    className="c-show-pw"
                    icon="preview"
                    fillMode="flat"
                    size="small"
                    onClick={(e) =>
                      togglePasswordVisibility(e, "passwordConfirmed")
                    }
                  />
                </div>
              </div>
              <Input
                type="hidden"
                name="username"
                value={dataRef.current.username}
              />
              <footer className="c-fieldset__footer">
                <Button
                  disabled={loadingChangePassword}
                  icon={loadingChangePassword ? null : "arrow-chevron-right"}
                  dir="rtl"
                  type="submit"
                >
                  {loadingChangePassword
                    ? <Loader />
                    : buttonLabel
                  }
                </Button>
              </footer>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormPasswordChange;
