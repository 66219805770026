import React from "react";
import PropTypes from "prop-types";

const TooltipTemplate = ({ item }) => {
  const itemParsed = JSON.parse(item);
  return (
    <ul className="c-tooltip__content">
      <li>
        <strong>Default Cliente:</strong>
        <span>{itemParsed}</span>
      </li>
    </ul>
  );
};

TooltipTemplate.propTypes = {
  item: PropTypes.string,
};

export default TooltipTemplate;
