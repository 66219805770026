import React from 'react';
import PropTypes from 'prop-types';

const CellDescription = ({ description, state }) => {

    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="1" 
            aria-selected="false"
            data-grid-col-index="0"
        >
            { ['approvato', 'non approvato'].includes(state.toLowerCase()) && <span className="k-icon k-i-lock"></span> }
            { description }
        </td>
    )
}

CellDescription.propTypes = {
    code: PropTypes.string,
    state: PropTypes.string,
}

export default CellDescription;