import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerNotes } from "redux/features/customer/actions";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from 'react-toastify';
import Loader from "components/loader";
import { Note, ImageNote, DocumentNote } from "./card";
import "./tabNotes.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    textAndImagesNotes: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.textAndImagesNotes",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.textAndImagesNotes
    ),
    documentsNotesLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.documentsNotes",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.documentsNotes
    ),
    emptyMediaNotes: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.emptyMediaNotes",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.emptyMediaNotes
    ),
    emptyDocumentsNotes: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.emptyDocumentsNotes",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.emptyDocumentsNotes
    ),
  };
};

const TabNotes = ({id}) => {

  const {
    textAndImagesNotes,
    documentsNotesLabel,
    emptyMediaNotes,
    emptyDocumentsNotes,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /**
   * User states
   */
  const authenticated = useSelector((state) => state.user.authenticated);
  const customerId = useSelector( (state) => state.user.customerId );

  /**
   * Customer states
   */
  const loading= useSelector((state) => state.customer.loadingCustomerNotes);
  const notes= useSelector((state) => state.customer.notes);
  
  const mediaAndTextNotes = notes && notes.filter(note =>  note.note_type !== 3);
  const documentsNotes = notes && notes.filter(note =>  note.note_type === 3);


  /**
   * Get the list of notes
   */
  useEffect(() => {
    if (authenticated) {
      const args = {
        id: id === undefined ? customerId : id,
        toast
      }
      dispatch(getCustomerNotes(args));
    }
    
  }, [authenticated, customerId, dispatch, id]);
  
  if (loading) {
    return <Loader className="c-loader--center c-loader--absolute" size="medium" />
  }

  return (
    <>
      <GridLayout className="c-notes l-desktop-flex u-gap-24">
        <GridLayoutItem className="c-note-item">
          <h3 className="c-note-item__title t-title t-title--3">
            {textAndImagesNotes}
          </h3>
          {mediaAndTextNotes && mediaAndTextNotes.length === 0 && (
            <p>{emptyMediaNotes}</p>
          )}
          {mediaAndTextNotes && mediaAndTextNotes.length > 0 && mediaAndTextNotes.map((note) => { 
            
            // CASE: note solo testuali
            if (note.note_type === 1) {
              return (
                <Note 
                  key={note.note_id}
                  id={note.note_id} 
                  customerId={id === undefined ? customerId : id}
                  text={note.text} 
                  type={note.note_type_description} 
                  updateAt={note.updated_at} 
                  username={note.user_full_name}
                />
              )
            }

            // CASE: note con file media (image, video)
            return (
              <ImageNote 
                key={note.note_id}
                files={note.files}
                id={note.note_id} 
                customerId={id === undefined ? customerId : id}
                text={note.text} 
                type={note.note_type_description} 
                updateAt={note.updated_at} 
                username={note.user_full_name}
              />
            )        
          })}
        </GridLayoutItem>
        <GridLayoutItem className="c-note-item">
          <h3 className="c-note-item__title t-title t-title--3">{documentsNotesLabel}</h3>
          {documentsNotes && documentsNotes.length === 0 && (
            <p>{emptyDocumentsNotes}</p>
          )}
          {documentsNotes && documentsNotes.length > 0 && documentsNotes.map((note) => {
            return (
              <DocumentNote 
                key={note.note_id}
                files={note.files}
                id={note.note_id} 
                customerId={id === undefined ? customerId : id}
                text={note.text} 
                type={note.note_type_description} 
                updateAt={note.updated_at} 
                username={note.user_full_name}
              />
            )
          })}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

TabNotes.propTypes = {};

export default TabNotes;
