import React from 'react';
import { Dialog } from "@progress/kendo-react-dialogs";
import FormNewOrder from './newOrder';
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    newOrderModalTitle: toLanguageString(
      'metislab.frontend.components.orders.components.header.newOrderModalTitle',
      defaultMessages.metislab.frontend.components.orders.components.header.newOrderModalTitle),
  };
}

const ModalOrder = ({ handleModal }) => {

  const {
    newOrderModalTitle,
  } = useLocalizedMessages();

  return (
    <Dialog 
      className="c-modal-testata"
      title={newOrderModalTitle}
      onClose={ handleModal } 
      width={ 566 }
    >
      <FormNewOrder />
    </Dialog>
  )
}

export default ModalOrder;