import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./cellDetails.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    detailsButtonLabel: toLanguageString(
      'metislab.frontend.components.orders.components.pending.cellDetails.detailsButtonLabel',
      defaultMessages.metislab.frontend.components.orders.components.pending.cellDetails.detailsButtonLabel),
  };
};

const CellDetails = ({ id }) => {
  const { detailsButtonLabel } = useLocalizedMessages();

  return (
    <td
      colSpan="1"
      className="c-list-orders__actions l-mobile-flex u-gap-8 k-grid-content-sticky"
      role="gridcell"
      aria-colindex="7"
      aria-selected="false"
      data-grid-col-index="6"
      style={{ textOverflow: "unset", left: 0, right: 0 }}
    >
      <Link
        className="k-button k-button-sm k-button-link"
        to={`/orders/pending/${id}`}
        style={{
          color: "#610d11",
          fontWeight: 400,
          fontSize: 13,
          lineHeight: 1,
          display: "flex",
          alignItems: "center",
          gap: 8,
          letterSpacing: 1,
          border: "1px solid #610d11",
          borderRadius: 0,
          padding: "11px 16px",
          boxShadow: "none",
          transition: "0.3s ease",
        }}
      >
        {detailsButtonLabel}
        <span className="k-icon k-i-preview"></span>
      </Link>
    </td>
  );
};

CellDetails.propTypes = {
  id: PropTypes.number,
  showActiveButton: PropTypes.bool,
  state: PropTypes.number,
};

export default CellDetails;
