import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomerNote } from "redux/features/customer/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CarouselMedia from "./carousel";
import { toast } from "react-toastify";
import Modal from "../../modal";
import "./card.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    userLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.userLabel",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.userLabel
    ),
    updatedAtLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.updatedAt",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.updatedAt
    ),
    emptyAttachmentFile: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.emptyAttachmentFile",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.emptyAttachmentFile
    ),
    confirmButtonLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.confirmButtonLabel",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.confirmButtonLabel
    ),
    deletingButtonMessage: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deletingButtonMessage",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deletingButtonMessage
    ),
    editNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.editNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.editNote
    ),
    deleteNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deleteNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deleteNote
    ),
    deleteNoteModalMessage: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deleteNoteModalMessage",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deleteNoteModalMessage
    ),
    deleteNoteModalTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deleteNoteModalTitle",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deleteNoteModalTitle
    ),
  };
};

const ImageNote = (props) => {
  const { customerId, files, id, text, type, updateAt, username } = props;

  const {
    userLabel,
    updatedAtLabel,
    emptyAttachmentFile,
    confirmButtonLabel,
    deletingButtonMessage,
    editNote,
    deleteNote,
    deleteNoteModalMessage,
    deleteNoteModalTitle,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /**
   * Customer states
   */
  const loadingDeleteCustomerNote = useSelector(
    (state) => state.customer.loadingDeleteCustomerNote
  );
  const panels = useSelector((state) => state.customer.customerDetails);

  const [carouselMediaUrl, setCarouselMediaUrl] = useState([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /**
   * handleCarousel is used to create the states carouselMediaUrl
   * and open the carousel's modal
   */
  const handleCarousel = () => {
    setCarouselMediaUrl(files);
    setShowCarousel((prevState) => !prevState);
  };

  /**
   * handleModal is used to handle the modal to add a note
   */
  const handleModal = () => {
    setShowModal(true);
  };

  /**
   * handleDeleteModal is used to handle the modal to confirm
   * the delete of a note
   */
  const handleDeleteModal = () => {
    if (!loadingDeleteCustomerNote) {
      setShowDeleteModal((prevState) => !prevState);
    }
  };

  /**
   * handleSubmitDelete is used to submit the request of delete
   */
  const handleSubmitDelete = useCallback(() => {
    const args = {
      customerId: customerId,
      noteId: id,
      toast,
    };

    dispatch(deleteCustomerNote(args)).then((res) => {
      setShowDeleteModal(false);
    });
  }, [dispatch, customerId, id]);

  const panelHiddenFields = panels?.find(
    (panel) => panel.name === "Hidden"
  )?.fields;
  const canEditNotes = panelHiddenFields?.find(
    (field) => field.name === "canEditNotes"
  );

  return (
    <div className="c-tile">
      <header className="c-tile__header l-mobile-flex u-flex-between u-flex-middle">
        <h2 className="c-tile__title t-title t-title--3">
          <span className="icon icon-image"></span>
          {type}
        </h2>
        <p className="c-tile__meta">
          <span className="k-pr-4">
            <strong>{userLabel}</strong>
            {username}
          </span>
          <span>
            <strong>{updatedAtLabel}</strong>
            {updateAt}
          </span>
        </p>
      </header>
      <p className="k-mb-4">{text}</p>
      {/* CASE: no file attached */}
      {files && files.length === 0 && <p>{emptyAttachmentFile}</p>}
      {/* CASE: file attached */}
      {files && files.length > 0 && (
        <div
          className="c-tile__previews l-mobile-flex u-flex-middle"
          style={{ gap: 8 }}
        >
          {files.map((file, index) => (
            <div key={`${file.file_id}-${index}`}>
              <Button className="" fillMode="flat" onClick={handleCarousel}>
                <img src={file.media_preview_url} alt="" />
              </Button>
            </div>
          ))}
        </div>
      )}
      {canEditNotes?.value && (
        <footer className="c-tile__actions u-align-right u-border">
          <Button
            className="c-tile__action"
            icon="edit"
            fillMode="link"
            dir="rtl"
            onClick={handleModal}
          >
            {editNote}
          </Button>
          <Button
            className="c-tile__action"
            icon="trash"
            fillMode="link"
            dir="rtl"
            onClick={handleDeleteModal}
          >
            {deleteNote}
          </Button>
        </footer>
      )}
      {showModal && (
        <Modal
          isUpdate
          content="image"
          customerId={customerId}
          files={files}
          noteId={id}
          text={text}
          onClose={() => setShowModal(false)}
        />
      )}
      {showDeleteModal && (
        <Dialog
          className="c-modal-delete-note"
          title={deleteNoteModalTitle}
          onClose={handleDeleteModal}
        >
          <p style={{ textAlign: "center" }}>{deleteNoteModalMessage}</p>
          <DialogActionsBar>
            <Button
              disabled={loadingDeleteCustomerNote}
              onClick={handleSubmitDelete}
            >
              {loadingDeleteCustomerNote
                ? deletingButtonMessage
                : confirmButtonLabel}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {showCarousel && (
        <Dialog
          className="c-modal-gallery"
          title=" "
          onClose={handleCarousel}
          width={566}
        >
          <CarouselMedia images={carouselMediaUrl} />
        </Dialog>
      )}
    </div>
  );
};

export default ImageNote;
