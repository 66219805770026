import React from "react";
import { useSelector } from "react-redux";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import "./address.scss";

const Address = ({ address }) => {
  const { 
    address: addressCustomer, 
    city, 
    province, 
    cap_code,
    ragionesociale
  } = address;

  const layoutColumnAddresses = useSelector((state) => state.customer.layoutColumnAddresses);

  return (
    <GridLayoutItem className="c-tile">
      <h3 className="c-tile__title t-title t-title--3">{ragionesociale || "-"}</h3>
      <ul className="c-tile__list">
        <li>
          <strong>
            {layoutColumnAddresses && layoutColumnAddresses[11].label}
          </strong>
          <span>{addressCustomer || "-"}</span>
        </li>
        <li>
          <strong>
            {layoutColumnAddresses && layoutColumnAddresses[13].label}
          </strong>
          <span>{city || "-"}</span>
        </li>
        <li className="l-tablet-flex">
          <p>
            <strong>
              {layoutColumnAddresses && layoutColumnAddresses[14].label}
            </strong>
            <span>{province || "-"}</span>
          </p>
          <p>
            <strong className="k-ml-2">
              {layoutColumnAddresses && layoutColumnAddresses[12].label}
            </strong>
            <span>{cap_code || "-"}</span>
          </p>
        </li>      
      </ul>
    </GridLayoutItem>
  );
};

export default Address;
