import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplate from "./tooltipTemplate";
import { useCustomLocalization } from "utils";
import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
      labelExpiredPayment: toLanguageString(
        'metislab.frontend.components.customer.components.details.tabs.tabPayments.labelExpiredPayment',
        defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.labelExpiredPayment),
  };
}

const CellOperationNumber = ({ operationNumber, isExpired }) => {

  const {
    labelExpiredPayment,
  } = useLocalizedMessages();

  const labelTooltip = labelExpiredPayment;

  return (
    <td
      colSpan="1"
      className={isExpired && "c-row-expired"}
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="0"
    >
      {operationNumber}
      {isExpired && (
        <Tooltip
          className="c-tooltip-box"
          anchorElement="target"
          position="right"
          content={(props) => <TooltipTemplate item={props.title} />}
        >
          <span title={labelTooltip} className="k-icon k-i-warning"></span>
        </Tooltip>
      )}
    </td>
  );
};

CellOperationNumber.propTypes = {
  operationNumber: PropTypes.string,
  isExpired: PropTypes.bool,
};

export default CellOperationNumber;
