import { createSlice } from "@reduxjs/toolkit";
import { getProductsSearched, } from "./actions";


const initialState =  { 
    articleCode: null,
    fragranceCode: null,
    keyword: "",
    loading: false,
    results: [],
    error: null,
};

const searchProductSlice = createSlice({
    name: "searchProduct",
    initialState,
    reducers: {
        resetArticleCode: (state) => {
            state.articleCode = null;
        },
        resetFragranceCode: (state) => {
            state.fragranceCode = null;
        },
        resetSearch: (state, action) => {
            state.keyword = "";
            state.results = [];
            state.articleCode = action.payload.article_code;
            state.fragranceCode = action.payload.fragrance_code;
        },
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        }, 
    },
    extraReducers: {
        /**
         * Get list of an order
         */
        [getProductsSearched.pending]: (state) => {
            state.loading = true;
        },
        [getProductsSearched.fulfilled]: (state, action) => {      
            state.loading = false;
            const { articles } = action.payload;
            state.results = articles;
        },
        [getProductsSearched.rejected]: (state, action) => {
            state.loading = false;
        },  
    },
});

export const { 
    resetArticleCode,
    resetFragranceCode,
    resetSearch,
    setKeyword,
} = searchProductSlice.actions;

export default searchProductSlice.reducer;