import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button } from "@progress/kendo-react-buttons";

const CellDetails = ({id}) => {

    const navigate = useNavigate();

    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="7" 
            aria-selected="false" 
            data-grid-col-index="6"
            style={{ textOverflow: 'unset'}}
        >
            <Button 
                icon="preview"
                fillMode="outline"
                dir='rtl'
                onClick={ () => navigate(`${id}`)}
            >
                {'Vai ai dettagli'}
            </Button>
        </td>
    )
}

CellDetails.propTypes = {
    id: PropTypes.number,
}

export default CellDetails;