import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarMenu } from "redux/features/user";
import { 
  setPagerState,
  setPagerStatePayments 
} from "redux/features/customer";
import { setPagerState as setPagerStateCatalogue } from "redux/features/catalogue";
import { 
  setPagerState as setPagerStateOrder,
  setPagerStateOrderPending,
  setPagerStateOrderProcessing
} from "redux/features/order";
import { 
  setPagerState as setPagerAdministrativeDownload,
  setPagerStateMarketing as setPagerMarketingDownload,
} from "redux/features/download";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { useLocation } from "react-router-dom";
import { useCurrentLocation } from "utils";
import Logo from "../logo";
import CustomersMenu from "./components/customers-menu";
import SearchProducts from "./components/search";
import UserMenu from "./components/user-menu";
import "./header.scss";

const Header = () => {

  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const currentLocation = useCurrentLocation(pathname);
  const isPagesCatalogue = currentLocation?.includes("catalog");
  const isPagesCustomer = currentLocation?.includes("customers");
  const isPagesCustomerDetails = currentLocation?.includes("customers") && !currentLocation?.endsWith("customers");
  const isPagesOrder = currentLocation?.includes("orders") && !currentLocation?.includes("pending") && !currentLocation?.includes("processing");
  const isPagesOrderPending = currentLocation?.includes("pending");
  const isPagesOrderProcessing = currentLocation?.includes("processing");
  const isPageSelectCompany = currentLocation?.includes("select-company");
  const isPageAdministrativeDownload = currentLocation?.includes("download");
  const isPageMarketingDownload = currentLocation?.includes("marketing");
  const isPrintPages = currentLocation?.includes("print");

  
  const authenticated = useSelector((state) => state.user.authenticated);

  const [openFormSearch, setOpenFormSearch] = useState(false);

  useEffect(() => {
    if( !isPagesCustomer ) {
      //Reset pageState customers
      dispatch( setPagerState({
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
      }));
    }

    if( !isPagesCustomerDetails ) {
      //Reset pageState customer's payments
      dispatch( setPagerStatePayments({
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
      }));
    }

    if( !isPagesCatalogue ) {
      //Reset pageState catalogue
      dispatch( setPagerStateCatalogue({
        page: null,
        perPage: null,
        class_code: null,
        fragrance_title: null,
      }));
    }

    if( !isPagesOrder ) {
      //Reset pageState orders
      dispatch( setPagerStateOrder({
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        filterFields: [],
        search: null,
        searchBy: null,
      }));
    }

    if( !isPagesOrderPending ) {
      //Reset pageState pending orders
      dispatch( setPagerStateOrderPending({
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
      }));     
    }

    if( !isPagesOrderProcessing ) {
      //Reset pageState pending orders
      dispatch( setPagerStateOrderProcessing({
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
      }));     
    }

    if( !isPageAdministrativeDownload ) {
      //Reset pageState documents administrative 
      dispatch( setPagerAdministrativeDownload({
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        filterFields: [],
        search: null,
        searchBy: null,
      }));     
    }

    if( !isPageMarketingDownload ) {
      //Reset pageState documents administrative 
      dispatch( setPagerMarketingDownload({
        search: null,
      }));     
    }
  
  }, 
  [
    dispatch, 
    isPagesCatalogue, 
    isPagesCustomer, 
    isPagesCustomerDetails,
    isPagesOrder, 
    isPagesOrderPending, 
    isPagesOrderProcessing,
    isPageAdministrativeDownload,
    isPageMarketingDownload
  ]);
  

  const handleSidebarMenu = () => {
    dispatch(setSidebarMenu());
  };

  const handleSearchForm = () => {
    setOpenFormSearch((prevState) => !prevState);
  };

  if (isPrintPages) {
    return null;
  }

  return (
    <AppBar
      className={`c-header ${
        authenticated && !isPageSelectCompany 
          ? "c-header--logged" 
          : ""
      }`}
    >
      <AppBarSection className="c-header__left">
        {authenticated && !isPageSelectCompany && (
          <AppBarSection>
            <button className="c-toggle u-mobile" onClick={handleSidebarMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </AppBarSection>
        )}
        <AppBarSection className="c-header__logo">
          <Logo authenticated={authenticated && !isPageSelectCompany} />
        </AppBarSection>
        {authenticated && !isPageSelectCompany && (
          <>
            <AppBarSpacer style={{ width: 32 }} />
            <AppBarSection
              className={`c-header__search c-form-field c-form-field--search ${
                openFormSearch ? "c-form-field--open" : ""
              }`}
            >
              <SearchProducts />
            </AppBarSection>
            <AppBarSection>
              <button
                className="c-toggle-search u-mobile"
                onClick={handleSearchForm}
              >
                <span className="k-icon k-i-search"></span>
              </button>
            </AppBarSection>
          </>
        )}
      </AppBarSection>
      {authenticated && !isPageSelectCompany && (
        <AppBarSection className="c-header__right">         
          <CustomersMenu />
          <UserMenu />
        </AppBarSection>
      )}
    </AppBar>
  );
};

export default Header;
