import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomerNote } from "redux/features/customer/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { toast } from "react-toastify";
import note from "assets/icons/note.svg";
import Modal from "../../modal";
import "./card.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    userLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.userLabel",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.userLabel
    ),
    updatedAtLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.updatedAt",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.updatedAt
    ),
    editNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.editNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.editNote
    ),
    deleteNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deleteNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deleteNote
    ),
    deleteNoteModalMessage: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deleteNoteModalMessage",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deleteNoteModalMessage
    ),
    deleteNoteModalTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deleteNoteModalTitle",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deleteNoteModalTitle
    ),
    confirmButtonLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.confirmButtonLabel",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.confirmButtonLabel
    ),
    deletingButtonMessage: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.deletingButtonMessage",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.deletingButtonMessage
    ),
  };
};

const Note = (props) => {
  const { customerId, id, text, type, updateAt, username } = props;

  const {
    userLabel,
    updatedAtLabel,
    editNote,
    deleteNote,
    deleteNoteModalMessage,
    deleteNoteModalTitle,
    confirmButtonLabel,
    deletingButtonMessage,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /**
   * Customer states
   */
  const loadingDeleteCustomerNote = useSelector(
    (state) => state.customer.loadingDeleteCustomerNote
  );
  const panels = useSelector((state) => state.customer.customerDetails);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /**
   * handleModal is used to open the modal to edit a note
   */
  const handleModal = () => {
    setShowModal(true);
  };

  /**
   * handleDeleteModal is used to handle the modal to confirm
   * the delete of a note
   */
  const handleDeleteModal = () => {
    if (!loadingDeleteCustomerNote) {
      setShowDeleteModal((prevState) => !prevState);
    }
  };

  /**
   * handleSubmitDelete is used to submit the request of delete
   */
  const handleSubmitDelete = useCallback(() => {
    const args = {
      customerId: customerId,
      noteId: id,
      toast,
    };

    dispatch(deleteCustomerNote(args)).then((res) => {
      setShowDeleteModal(false);
    });
  }, [dispatch, customerId, id]);

  const panelHiddenFields = panels?.find(
    (panel) => panel.name === "Hidden"
  )?.fields;
  const canEditNotes = panelHiddenFields?.find(
    (field) => field.name === "canEditNotes"
  );

  return (
    <div className="c-tile">
      <header className="c-tile__header l-mobile-flex u-flex-between u-flex-middle">
        <h2 className="c-tile__title t-title t-title--3">
          <img src={note} alt="note-icon" />
          {type}
        </h2>
        <p className="c-tile__meta">
          <span className="k-pr-4">
            <strong>{userLabel}</strong>
            {username}
          </span>
          <span>
            <strong>{updatedAtLabel}</strong>
            {updateAt}
          </span>
        </p>
      </header>
      <p>{text}</p>
      {canEditNotes?.value && (
        <footer className="c-tile__actions u-align-right u-border">
          <Button
            className="c-tile__action"
            icon="edit"
            fillMode="link"
            dir="rtl"
            onClick={handleModal}
          >
            {editNote}
          </Button>
          <Button
            className="c-tile__action"
            icon="trash"
            fillMode="link"
            dir="rtl"
            onClick={handleDeleteModal}
          >
            {deleteNote}
          </Button>
        </footer>
      )}
      {showModal && (
        <Modal
          isUpdate
          content="note"
          customerId={customerId}
          noteId={id}
          text={text}
          onClose={() => setShowModal(false)}
        />
      )}
      {showDeleteModal && (
        <Dialog
          className="c-modal-delete-note"
          title={deleteNoteModalTitle}
          onClose={handleDeleteModal}
        >
          <p style={{ textAlign: "center" }}>{deleteNoteModalMessage}</p>
          <DialogActionsBar>
            <Button
              disabled={loadingDeleteCustomerNote}
              onClick={handleSubmitDelete}
            >
              {loadingDeleteCustomerNote
                ? deletingButtonMessage
                : confirmButtonLabel}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default Note;
