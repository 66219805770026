import React from 'react';
import PropTypes from 'prop-types';
/* import { useInternationalization } from "@progress/kendo-react-intl"; */

const CellPrice = ({ price }) => {

/*     const intl = useInternationalization(); */
    
    const priceArticle = price?.value 
        ? price?.value
        : "-"

    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="5" 
            aria-selected="false"
            data-grid-col-index="0"
        >
            { priceArticle }
        </td>
    )
}

CellPrice.propTypes = {
    price: PropTypes.object,
}

export default CellPrice;