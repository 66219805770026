import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Skeleton } from "@progress/kendo-react-indicators";
import "./header.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    codeCustomerLabel: toLanguageString(
      "metislab.frontend.components.customer.components.header.codeCustomerLabel",
      defaultMessages.metislab.frontend.components.customer.components.header
        .codeCustomerLabel
    ),
    gotoBack: toLanguageString(
      "metislab.frontend.components.customer.components.header.gotoBack",
      defaultMessages.metislab.frontend.components.customer.components.header
        .gotoBack
    ),
  };
};

const HeaderCustomer = (props) => {
  const { codeCustomerLabel, gotoBack } = useLocalizedMessages();

  const { selected, buttons, handleModal } = props;

  /**
   * User states
   */
  const user = useSelector((state) => state.user.user);

  /**
   * Customer states
   */
  const customerHeader = useSelector((state) => state.customer.customerHeader);
  const loading = useSelector((state) => state.customer.loading);
  const panels = useSelector((state) => state.customer.customerDetails);

  const panelHiddenFields = panels?.find(
    (panel) => panel.name === "Hidden"
  )?.fields;
  const canEditNotes = panelHiddenFields?.find(
    (field) => field.name === "canEditNotes"
  );

  return (
    <header className="c-subheader k-mb-5">
      {user?.type !== "1" && (
        <Link className="c-subheader__link" to="/customers">
          <span className="k-icon k-i-arrow-left"></span>
          {gotoBack}
        </Link>
      )}
      <div className="l-tablet-flex u-flex-middle u-flex-between">
        <div className="l-desktop-big-flex u-flex-baseline">
          {loading === "pendingCustomerDetails" ? null : parseInt(
              customerHeader?.fido?.v
            ) ? (
            <span className="k-icon k-i-lock"></span>
          ) : null}
          <h1 className="c-subheader__title t-title t-title--2">
            {loading === "pendingCustomerDetails" ? (
              <Skeleton
                shape="text"
                style={{ width: 200, display: "inline-block" }}
              />
            ) : (
              customerHeader?.description?.v || ""
            )}
          </h1>
          <div className="c-subheader__text l-tablet-flex">
            <p>
              <strong>{codeCustomerLabel}</strong>
              <span className="k-pl-1">
                {loading === "pendingCustomerDetails" ? (
                  <Skeleton
                    shape="text"
                    style={{ width: 100, display: "inline-block" }}
                  />
                ) : (
                  customerHeader?.search_code?.v || ""
                )}
              </span>
            </p>
            <p>
              <strong>Company:</strong>
              <span className="k-pl-1">
                {loading === "pendingCustomerDetails" ? (
                  <Skeleton
                    shape="text"
                    style={{ width: 100, display: "inline-block" }}
                  />
                ) : (
                  customerHeader?.company_code?.v || ""
                )}
              </span>
            </p>
          </div>
        </div>
        {canEditNotes?.value && selected === 4 && (
          <div className="c-subheader__actions l-mobile-flex u-gap-8">
            {buttons.map((button, index) => {
              const { icon, text, type } = button;
              return (
                <Button
                  key={index}
                  icon={icon}
                  fillMode="outline"
                  dir="rtl"
                  onClick={() => handleModal(type)}
                >
                  {text}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </header>
  );
};

HeaderCustomer.propTypes = {};

export default HeaderCustomer;
