import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFragranceDetails } from "redux/features/catalogue/actions";
import { unsetOrderHeaderNotEditable } from "redux/features/order";
import { resetArticleCode, resetFragranceCode } from "redux/features/search";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import Carousel from "components/carousel";
import Cart from "components/cart";
import Error from "components/Error";
import Loader from "components/loader";
import ModalOrder from "./modal";
import NotFound from "components/notFound";
import Table from "./table";
import "./product.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    backButton: toLanguageString(
      "metislab.frontend.components.catalogue.components.product.backButton",
      defaultMessages.metislab.frontend.components.catalogue.components.product
        .backButton
    ),
    button: toLanguageString(
      "metislab.frontend.components.catalogue.components.product.button",
      defaultMessages.metislab.frontend.components.catalogue.components.product
        .button
    ),
    noDescription: toLanguageString(
      "metislab.frontend.components.catalogue.components.product.noDescription",
      defaultMessages.metislab.frontend.components.catalogue.components.product
        .noDescription
    ),
  };
};

const Product = ({ showOnlyData = false }) => {
  const { id } = useParams();

  const { backButton, button, noDescription } = useLocalizedMessages();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);
  const permissionsUser = useSelector((state) => state.user.permissions);
  const selectedCustomer = useSelector((state) => state.user.selectedCustomer);
  const selectedOrder = useSelector((state) => state.user.selectedOrder);

  /**
   * IndexWebCustomer states
   */
  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * Fragrance states
   */
  const errorFragranceDetails = useSelector(
    (state) => state.fragrance.errorFragranceDetails
  );
  const errorFragranceDetailsStatus = useSelector(
    (state) => state.fragrance.errorFragranceDetailsStatus
  );
  const loading = useSelector((state) => state.fragrance.loading);
  const fragranceDetails = useSelector(
    (state) => state.fragrance.fragranceDetails
  );

  /**
   * Order states
   */
  const orderHeaderNotEditable = useSelector(
    (state) => state.order.orderHeaderNotEditable
  );

  /**
   * searchProduct States
   */
  const articleCode = useSelector((state) => state.searchProduct.articleCode);

  const [showModal, setShowModal] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [code, setCode] = useState(null);

  const getDetails = useCallback(() => {
    if (selectedCompany && companyCustomers && !showOnlyData) {
      dispatch(getFragranceDetails({ toast, id }));
    }
  }, [companyCustomers, dispatch, id, selectedCompany, showOnlyData]);

  useEffect(() => getDetails(), [getDetails]);

  useEffect(() => {
    if (articleCode && !showCart) {
      setShowCart(true);
      setCode(articleCode);
      dispatch(resetArticleCode());
      dispatch(resetFragranceCode());
    }
  }, [articleCode, code, dispatch, showCart]);

  /**
   * handleCart handles the view of the cart on the right side
   */
  const handleCart = () => {
    setCode(null);
    setShowCart((prevState) => !prevState);
  };

  /**
   * handleOrder handles the view of modal window
   * It shows the modal to create a new order or edit one
   * or show the cart if an order is currently selected
   */
  const handleOrder = (e, fromCreationOrder = false) => {
    if (fromCreationOrder) {
      setShowCart((prevState) => !prevState);
      setShowModal((prevState) => !prevState);
    } else {
      if (selectedOrder?.id || !selectedCustomer?.code) {
        setShowCart((prevState) => !prevState);
      } else {
        setShowModal((prevState) => !prevState);
        orderHeaderNotEditable && dispatch(unsetOrderHeaderNotEditable());
      }
    }
  };

  if (loading || loadingUser || loadingIndexWebCustomer) {
    return <Loader className="c-loader--center" size="large" />;
  }

  if (errorFragranceDetails) {
    return errorFragranceDetailsStatus === 404 ? (
      <NotFound />
    ) : (
      <Error errorText={errorFragranceDetails} />
    );
  }

  return (
    <>
      {!showOnlyData && (
        <div>
          <Link
            className="c-subheader__link"
            to={`/catalogue${
              searchParams.get("tag") ? `?tag=${searchParams.get("tag")}` : ""
            }`}
          >
            <span className="k-icon k-i-arrow-left"></span>
            {backButton}
          </Link>
          {!fragranceDetails?.orderPermissions?.orderEdit &&
            fragranceDetails?.orderPermissions.orderDisabledMessage.length >
              0 && (
              <div
                className="c-disabled-message"
                dangerouslySetInnerHTML={{
                  __html:
                    fragranceDetails?.orderPermissions.orderDisabledMessage,
                }}
              />
            )}
        </div>
      )}
      <section className="c-good-container">
        <div
          className={`c-good l-tablet-flex ${showOnlyData ? "readOnly" : ""}`}
        >
          <Carousel
            actionOnMainImage
            images={fragranceDetails?.gallery}
            fragranceId={fragranceDetails?.fragrance?.fragrance_id}
          />
          <div className="c-good__content u-flex-1">
            {fragranceDetails?.fragrance?.class_title && (
              <strong className="c-good__lead">
                {fragranceDetails?.fragrance?.class_title}
              </strong>
            )}
            {fragranceDetails?.fragrance?.fragrance_title && (
              <h1 className="c-good__title t-title t-title--1">
                {fragranceDetails?.fragrance?.fragrance_title}
              </h1>
            )}
            <p className="c-good__text">
              {fragranceDetails?.fragrance?.fragrance_description ||
                noDescription}
            </p>
            {fragranceDetails?.orderPermissions?.orderEdit && (
              <>
                {!showOnlyData && (
                  <footer className="c-good__footer l-mobile-flex u-flex-middle u-flex-between">
                    <Button
                      disabled={
                        !permissionsUser?.orderEdit || !selectedCustomer?.code
                      }
                      className="c-good__button"
                      onClick={handleOrder}
                    >
                      {button}
                    </Button>
                  </footer>
                )}
              </>
            )}
          </div>
        </div>
      </section>
      <Table />
      {showCart && (
        <Cart
          articleCode={code}
          handleCart={handleCart}
          handleOrder={handleOrder}
        />
      )}
      {showModal && <ModalOrder handleOrder={handleOrder} />}
    </>
  );
};

export default Product;
