import React from "react";
import PropTypes from "prop-types";
import "./tooltip-content-template.scss";

const TooltipContentTemplate = ({ title }) => {
  const content = JSON.parse(title);
  return (
    <ul className="c-order-tooltip">
      {content.map((item, index) => {
        return (
          <li key={index}>
            {item?.total && <span>{`${item.total}x`}</span>}
            {item?.code && <span>{item.code}</span>}
            {item?.desc && <span>{item.desc}</span>}
          </li>
        );
      })}
    </ul>
  );
};

TooltipContentTemplate.propTypes = {
  title: PropTypes.string,
};

export default TooltipContentTemplate;
