import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const getProductsSearched = createAsyncThunk(
    "searchProduct/getProductsSearched",
    async ( {stringToSearch, toast}, { rejectWithValue } ) => {       
        try {
            const query = `/web/catalogue/search?search=${stringToSearch}`;          
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);