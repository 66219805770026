import React from "react";
import PropTypes from "prop-types";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    tooltipTemplateLabel: toLanguageString(
      'metislab.frontend.components.orders.components.pending.cellState.tooltipTemplateLabel',
      defaultMessages.metislab.frontend.components.orders.components.pending.cellState.tooltipTemplateLabel),
  };
}

const TooltipTemplate = ({ item }) => {
  const { tooltipTemplateLabel } = useLocalizedMessages();
  
  return (
    <ul className="c-tooltip__content">
      <li>
        <strong>{tooltipTemplateLabel}</strong>
        <span>{item}</span>
      </li>
    </ul>
  );
};

TooltipTemplate.propTypes = {
  item: PropTypes.string,
};

export default TooltipTemplate;
