import React from "react";
import { useSelector } from "react-redux";
import { GridLayout } from "@progress/kendo-react-layout";
import Address from "./address";
import Loader from "components/loader";
import "./tabAddresses.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    noAddressLabel: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabAddresses.noAddressLabel",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabAddresses.noAddressLabel
    ),
    emptyAddressResultsTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabAddresses.emptyAddressResultsTitle",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabAddresses.emptyAddressResultsTitle
    ),
  };
};

const TabAddresses = () => {
  const { 
    noAddressLabel,
    emptyAddressResultsTitle, 
  } = useLocalizedMessages();

  const loading = useSelector((state) => state.customer.loading);
  const customerAddresses = useSelector(
    (state) => state.customer.customerAddresses
  );

  if (loading) {
    return (
      <Loader className="c-loader--center c-loader--absolute" size="medium" />
    );
  }

  return (
    <GridLayout className="l-tablet-grid u-grid-3 u-gap-24">
      {customerAddresses?.length > 0 ? (
        customerAddresses.map((address, index) => {
          return <Address key={index} address={address} />;
        })
      ) : (
        <div className="c-box-research__no-results u-no-padding">
          <h2 className="c-empty-state__title l-mobile-flex">
            <span className="k-icon k-i-search"></span>
            <strong>{emptyAddressResultsTitle}</strong>
          </h2>
          <p>{noAddressLabel}</p>
        </div>
      )}
    </GridLayout>
  );
};

export default TabAddresses;
