import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPagerState,
  //setViewDocsAdministrative,
} from "redux/features/download";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DebounceInput } from "react-debounce-input";
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    initialValuesDropdown: toLanguageString(
      "metislab.frontend.components.download.components.header.initialValuesDropdown",
      defaultMessages.metislab.frontend.components.download.components.header
        .initialValuesDropdown
    ),
    placeholderInputSearch: toLanguageString(
      "metislab.frontend.components.download.components.header.placeholderInputSearch",
      defaultMessages.metislab.frontend.components.download.components.header
        .placeholderInputSearch
    ),
    resetFilterOrderActive: toLanguageString(
      "metislab.frontend.components.download.components.header.resetFilterOrderActive",
      defaultMessages.metislab.frontend.components.download.components.header
        .resetFilterOrderActive
    ),
    title: toLanguageString(
      "metislab.frontend.components.download.components.header.titleAdministrative",
      defaultMessages.metislab.frontend.components.download.components.header
        .titleAdministrative
    ),
  };
};

const HeaderDocsAdministrative = () => {
  const {
    initialValuesDropdown,
    placeholderInputSearch,
    resetFilterOrderActive,
    title,
  } = useLocalizedMessages();

  const INITIAL_VALUES_DROPDOWN = {
    propId: initialValuesDropdown,
    label: initialValuesDropdown,
  };

  const dispatch = useDispatch();

  const layoutColumn = useSelector((state) => state.download.layoutColumn);
  const pagerState = useSelector((state) => state.download.pagerState);
  /* const viewDocsAdministrative = useSelector(
    (state) => state.download.viewDocsAdministrative
  ); */

  /**
   * Internal states
   */
  const [filtersActive, setFiltersActive] = useState(null);
  const [keyword, setKeyword] = useState("");

  const dispatchFilters = useCallback(() => {
    let filterFields;

    /**
     * check if filtersActive is not null
     * is null only in first render otherwise is an array with length = 0
     */
    if (filtersActive) {
      const fieldsWithoutCustomerFields = pagerState.filterFields.filter(
        (field) => {
          return !field.includes("document_type_code");
        }
      );

      // Check if filter of customer is active
      if (fieldsWithoutCustomerFields.length > 0) {
        // Check if filter of doc type is active
        filtersActive.length > 0
          ? (filterFields = [
              ...fieldsWithoutCustomerFields,
              ["document_type_code", "IN", filtersActive],
            ])
          : (filterFields = [...fieldsWithoutCustomerFields]);
      } else {
        // Check if filter of doc type is active
        filtersActive.length > 0
          ? (filterFields = [["document_type_code", "IN", filtersActive]])
          : (filterFields = []);
      }
    }

    filtersActive &&
      dispatch(
        setPagerState({
          page: 1,
          filterFields,
        })
      );

    // eslint-disable-next-line
  }, [dispatch, filtersActive]);

  useEffect(() => {
    dispatchFilters();
  }, [dispatchFilters]);

  const docsTypeArray = layoutColumn?.filter(
    (row) => row.propId === "document_type_code"
  );

  const docTypes =
    docsTypeArray &&
    docsTypeArray?.length > 0 &&
    docsTypeArray[0]?.editor?.data?.rows;

  /**
   * onChangeKeyword is used to handle the string in the input
   * @param {*} e
   */
  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
    dispatch(
      setPagerState({
        page: 1,
        search: e.target.value,
      })
    );
  };

  const handleFilter = useCallback(
    (docType) => {
      if (filtersActive) {
        filtersActive && filtersActive.includes(docType)
          ? setFiltersActive((prevState) =>
              prevState.filter((filter) => filter !== docType)
            )
          : setFiltersActive((prevState) => [...prevState, docType]);
      } else {
        setFiltersActive([docType]);
      }
    },
    [filtersActive]
  );

  /* const handleViewGrid = (isGrid) => {
    dispatch(setViewDocsAdministrative(isGrid));
  }; */

  /**
   * onChangeType is used to handle the dropdown
   * @param {*} e
   */
  const onChangeType = (e) => {
    const value =
      e.value === INITIAL_VALUES_DROPDOWN
        ? null
        : { propId: e.value.propId, label: e.value.label };

    dispatch(
      setPagerState({
        searchBy: value,
      })
    );
  };

  const handleResetFilter = () => {
    dispatch(
      setPagerState({
        ...pagerState,
        search: null,
        searchBy: null,
      })
    );
    setKeyword("");
  };

  const filtersList = layoutColumn && layoutColumn.filter((fil) => !fil.hidden);

  return (
    <>
      <header className="c-subheader c-subheader--download">
        <div className="l-tablet-flex u-flex-between">
          <h1 className="c-subheader__title t-title t-title--2 k-pr-4">
            Download
          </h1>
          <ul className="l-tablet-flex k-align-items-center u-gap-16">
            {(pagerState?.search || pagerState?.searchBy) && (
              <li>
                <ResetFilter dispatchResetAction={handleResetFilter} />
              </li>
            )}
            <li className="c-filter">
              <DropDownList
                className="c-filter-container"
                name="searchByType"
                data={filtersList || []}
                fillMode="outline"
                textField="label"
                dataItemKey="propId"
                defaultItem={INITIAL_VALUES_DROPDOWN}
                onChange={onChangeType}
                value={pagerState.searchBy}
                popupSettings={{ popupClass: "c-filter-content", width: 180 }}
              />
            </li>
            <li className="c-form-field c-form-field--search">
              <DebounceInput
                className="k-input"
                name="searchByKeyword"
                placeholder={placeholderInputSearch}
                minLength={0}
                debounceTimeout={300}
                value={keyword}
                onChange={onChangeKeyword}
              />
              <span className="k-icon k-i-search"></span>
            </li>
          </ul>
        </div>

        <ul className="c-tags l-mobile-flex" style={{ gap: 8 }}>
          {docTypes &&
            docTypes.map((docType) => (
              <li key={docType.value} className="c-filter">
                <Button
                  className={`c-subheader__button ${
                    filtersActive?.includes(docType.value)
                      ? "c-subheader__button--selected"
                      : ""
                  } `}
                  icon={filtersActive?.includes(docType.value) ? "close" : null}
                  dir="rtl"
                  fillMode="outline"
                  onClick={() => handleFilter(docType.value)}
                >
                  {docType.label}
                </Button>
              </li>
            ))}
          {filtersActive && filtersActive?.length > 0 && (
            <li className="">
              <Button
                className={`c-subheader__button c-subheader__button--no-filter`}
                icon="close"
                dir=""
                fillMode="flat"
                onClick={() => setFiltersActive([])}
              >
                {resetFilterOrderActive}
              </Button>
            </li>
          )}
        </ul>

        <div className="c-subheader__meta l-mobile-flex u-flex-between u-flex-middle">
          <div className="c-subheader__meta-text l-desktop-flex k-align-items-end">
            <strong>{title}</strong>
          </div>
          {/* <div className="c-subheader__meta-buttons">
            <Button
              className={viewDocsAdministrative ? "active" : ""}
              dir="rtl"
              icon="group"
              fillMode="flat"
              onClick={() => handleViewGrid(true)}
            />
            <Button
              className={!viewDocsAdministrative ? "active" : ""}
              dir="rtl"
              icon="grid-layout"
              fillMode="flat"
              onClick={() => handleViewGrid(false)}
            />
          </div> */}
        </div>
      </header>
    </>
  );
};

export default HeaderDocsAdministrative;
