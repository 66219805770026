import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getFragranceDetails } from "redux/features/catalogue/actions";
import { activateOrder } from "redux/features/user/actions";
import { Button } from "@progress/kendo-react-buttons";
import Loader from "components/loader";
import { toast } from "react-toastify";
import { useCustomLocalization } from "utils";
import "./new-order.scss";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    activatedOrderSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.order.activatedOrderSuccess",
      defaultMessages.metislab.frontend.components.notification.components.order
        .activatedOrderSuccess
    ),
    buttonLabel: toLanguageString(
      "metislab.frontend.components.catalogue.components.modalOrder.buttonLabel",
      defaultMessages.metislab.frontend.components.catalogue.components
        .modalOrder.buttonLabel
    ),
    createdAt: toLanguageString(
      "metislab.frontend.components.catalogue.components.modalOrder.createdAt",
      defaultMessages.metislab.frontend.components.catalogue.components
        .modalOrder.createdAt
    ),
    finalAmount: toLanguageString(
      "metislab.frontend.components.catalogue.components.modalOrder.finalAmount",
      defaultMessages.metislab.frontend.components.catalogue.components
        .modalOrder.finalAmount
    ),
    idOrder: toLanguageString(
      "metislab.frontend.components.catalogue.components.modalOrder.idOrder",
      defaultMessages.metislab.frontend.components.catalogue.components
        .modalOrder.idOrder
    ),
  };
};

const FormOpenOrder = ({ handleOrder }) => {
  const {
    activatedOrderSuccess,
    buttonLabel,
    createdAt,
    finalAmount,
    idOrder,
  } = useLocalizedMessages();

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [idOrderInLoading, setIdOrderInLoading] = useState(null);

  const loadingCreateOrder = useSelector(
    (state) => state.user.loadingCreateOrder
  );

  const loadingNewOrderHeaderFields = useSelector(
    (state) => state.order.loadingNewOrderHeaderFields
  );
  const openOrders = useSelector((state) => state.order.openOrders);

  const handleOpenOrder = (idOrder) => {
    setIdOrderInLoading(idOrder);
    const args = {
      id: idOrder,
      navigate,
      redirect: false,
      toast,
      messageNotification: activatedOrderSuccess,
    };

    dispatch(activateOrder(args)).then(() =>
      dispatch(getFragranceDetails({ toast, id })).then(() => {
        setIdOrderInLoading(null);
        handleOrder();
      })
    );
  };
  
  return loadingNewOrderHeaderFields ? (
    <Loader className="c-loader--center c-loader--center-modal" />
  ) : (
    <div className="c-existing-orders">
      <ul className="c-existing-orders__list">
        {openOrders?.list.map((order) => {

          return (
            <li
              className="c-existing-orders__item"
              key={order?.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="c-existing-orders__details">
                <div className="c-existing-orders__meta">
                  <span className="c-existing-orders__id">
                    {idOrder}
                    <strong>{order?.IdSfa}</strong>
                  </span>
                  <span className="c-existing-orders__amount">
                    {finalAmount}
                    <strong>{order?.final_amount}</strong>
                  </span>
                </div>
                <div>
                  <span className="c-existing-orders__date">
                    {createdAt}
                    <span>{order?.creation_date}</span>
                  </span>
                </div>
                {order?.grid_tooltip?.length > 0 && (
                  <div>
                    <span>
                      {order.grid_tooltip}
                    </span>
                  </div>
                )}
              </div>
              <Button
                disabled={loadingCreateOrder}
                className="c-existing-orders__button"
                fillMode="outline"
                onClick={() => handleOpenOrder(order?.id)}
                style={{
                  flexBasis: 90,
                }}
              >
                {idOrderInLoading === order?.id ? <Loader /> : buttonLabel}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FormOpenOrder;
