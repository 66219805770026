import React, { useState } from "react";
import PropTypes from "prop-types";
import ArticleGallery from "../../articleGallery";

const CellPreviewGallery = ({ article_id, thumbnail }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <>
      <td
        colSpan="1"
        className="no-overflow"
        role="gridcell"
        aria-colindex="5"
        aria-selected="false"
        data-grid-col-index="0"
      >
        {thumbnail && (
          <button
            className="c-gallery-preview"
            onClick={() => handleClick(article_id)}
          >
            <img src={thumbnail} alt="gallery-preview" />
          </button>
        )}
      </td>
      {showModal && (
        <ArticleGallery articleId={article_id} handleGallery={handleClick} />
      )}
    </>
  );
};

CellPreviewGallery.propTypes = {
  article_id: PropTypes.number,
  thumbnail: PropTypes.string,
};

export default CellPreviewGallery;
