import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "redux/features/user";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import Header from "../header";
import Logout from "components/logout";
import "./userSettingsDetails.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    editButtonLabel: toLanguageString(
      "metislab.frontend.components.user.components.header.editButtonLabel",
      defaultMessages.metislab.frontend.components.user.components.header
        .editButtonLabel
    ),
    title: toLanguageString(
      "metislab.frontend.components.user.components.header.title",
      defaultMessages.metislab.frontend.components.user.components.header.title
    ),

    general: toLanguageString(
      "metislab.frontend.components.user.components.titleSection.general",
      defaultMessages.metislab.frontend.components.user.components.titleSection
        .general
    ),
    languageLabel: toLanguageString(
      "metislab.frontend.components.user.components.titleSection.language",
      defaultMessages.metislab.frontend.components.user.components.titleSection
        .language
    ),

    languageSelected: toLanguageString(
      "metislab.frontend.components.user.components.section.language.languageSelected",
      defaultMessages.metislab.frontend.components.user.components.section
        .language.languageSelected
    ),
    languageItalian: toLanguageString(
      "metislab.frontend.components.user.components.section.language.languageItalian",
      defaultMessages.metislab.frontend.components.user.components.section
        .language.languageItalian
    ),
    languageEnglish: toLanguageString(
      "metislab.frontend.components.user.components.section.language.languageEnglish",
      defaultMessages.metislab.frontend.components.user.components.section
        .language.languageEnglish
    ),

    nameLabel: toLanguageString(
      "metislab.frontend.components.user.components.section.details.nameLabel",
      defaultMessages.metislab.frontend.components.user.components.section
        .details.nameLabel
    ),
    surnameLabel: toLanguageString(
      "metislab.frontend.components.user.components.section.details.surnameLabel",
      defaultMessages.metislab.frontend.components.user.components.section
        .details.surnameLabel
    ),
  };
};

const UserSettingsDetails = ({ handleEdit }) => {
  const dispatch = useDispatch();

  const {
    editButtonLabel,
    title,

    general,
    languageLabel,
    languageSelected,
    languageEnglish,
    languageItalian,

    nameLabel,
    surnameLabel,
  } = useLocalizedMessages();

  const user = useSelector((state) => state.user.user);
  const language = useSelector((state) => state.user.language);
  const permissionsUser = useSelector((state) => state.user.permissions);

  const languages = permissionsUser?.labelView 
    ? [
        { id: "it-IT", name: languageItalian },
        { id: "en-US", name: languageEnglish },
        { id: "en-GB", name: "labels" },
      ]
    : [
      { id: "it-IT", name: languageItalian },
      { id: "en-US", name: languageEnglish },
    ];

  const localLanguage = JSON.parse(localStorage.getItem("lang"));

  const initialLanguage =
    language === "it-IT" || localLanguage === "it-IT"
      ? { id: "it-IT", name: languageItalian }
      : language === "en-US" || localLanguage === "en-US"
          ? { id: "en-US", name: languageEnglish }
          : { id: "en-GB", name: "labels" };

  return (
    <StackLayout orientation="vertical" className="c-user-setting">
      <Header className="c-subheader">
        <h1 className="c-user-setting__title t-title t-title--2 l-mobile-flex u-flex-middle">
          <span className="icon icon-user"></span>
          {title}
        </h1>
        <Button
          className="c-user-setting__button"
          fillMode="outline"
          onClick={handleEdit}
        >
          {editButtonLabel}
          <span className="k-icon k-i-pencil"></span>
        </Button>
      </Header>
      <section className="c-user-setting__content">
        <div className="c-user-setting__item">
          <h2 className="c-user-setting__heading t-title t-title--3">
            {general}
          </h2>
          <ul className="c-user-setting__list l-tablet-grid u-grid-3">
            <li>
              <strong>{nameLabel}</strong>
              <span>{user?.name}</span>
            </li>
            <li>
              <strong>{surnameLabel}</strong>
              <span>{user?.surname}</span>
            </li>
            <li>
              <strong>Email:</strong>
              <span>{user?.email}</span>
            </li>
          </ul>
        </div>
        <div className="c-user-setting__item">
          <h2 className="c-user-setting__heading t-title t-title--3">
            {languageLabel}
          </h2>
          <ul className="c-user-setting__list l-tablet-grid u-grid-3 ">
            <li className="c-form-field">
              <label htmlFor="language">{languageSelected}</label>
              <DropDownList
                name="language"
                data={languages}
                textField="name"
                dataItemKey="id"
                defaultValue={initialLanguage}
                onChange={(e) => {
                  dispatch(setLanguage(e.value.id));
                  window.location.reload();
                }}
              />
            </li>
          </ul>
        </div>
      </section>
      <footer className="c-user-setting__footer">
        <Logout fillMode="outline" icon="logout" label="logout" />
      </footer>
    </StackLayout>
  );
};

export default UserSettingsDetails;
