import React from "react";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import "./previewImage.scss";

const PreviewImage = (props) => {
  // console.log(props);

  const { onRemove } = props;

  const { getRawFile, name: filename, uid, validationErrors } = props.files[0];

  if (validationErrors) {
    return <span>{"Il tipo di immagine non è supportato"}</span>;
  }

  const src = URL.createObjectURL(getRawFile());

  return (
    <div className="c-upload__media l-mobile-flex u-flex-middle">
      <div className="c-upload__file">
        <figure
          className="c-upload__figure"
          style={{ maxWidth: 152, maxHeight: 152 }}
        >
          <img src={src} alt="" style={{ maxWidth: "100%", height: "auto" }} />
        </figure>
      </div>
      <p className="c-upload__label">
        {filename}
      </p>
      <Button
        className="c-upload__remove"
        icon="close"
        onClick={() => onRemove(uid)}
      />
    </div>
  );
};

PreviewImage.propTypes = {
  props: PropTypes.object,
};

export default PreviewImage;
