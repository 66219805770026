import React from 'react'; 
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Template from './Template';
import './logo.scss';

const Logo = ({ authenticated }) => {
  return (
    <Link 
      className={`c-logo ${authenticated ? 'c-logo--logged' : ''}`} 
      to={ authenticated ? "/dashboard" : "/"}
    >
      <Template />
    </Link>
  )
}


Logo.propTypes = {
  authenticated: PropTypes.bool,
}

export default Logo;