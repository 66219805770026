import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderHasPromoToSelect } from "redux/features/order";
import {
  getOrderPromo,
  setPromotionsOrder,
} from "redux/features/order/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { toast } from "react-toastify";
import Loader from "components/loader";
import Label from "./label";
/* import { useInternationalization } from "@progress/kendo-react-intl"; */
import { useCustomLocalization, formatNumber } from "utils";

import defaultMessages from "i18n/en.json";
import "./cart.scss";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    gotoBack: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.promo.gotoBack",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .promo.gotoBack
    ),
    savePromoButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.promo.savePromoButtonLabel",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .promo.savePromoButtonLabel
    ),
    savingPromoButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.promo.savingPromoButtonLabel",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .promo.savingPromoButtonLabel
    ),

    promoSelectionSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.promo.selectionSuccess",
      defaultMessages.metislab.frontend.components.notification.components.promo
        .selectionSuccess
    ),
  };
};

const Promo = () => {
  const {
    gotoBack,
    savePromoButtonLabel,
    savingPromoButtonLabel,
    promoSelectionSuccess,
  } = useLocalizedMessages();
  const dispatch = useDispatch();

  /* const intl = useInternationalization(); */

  const orderInfo = useSelector((state) => state.order.orderInfo);
  const loadingPromoOrder = useSelector(
    (state) => state.order.loadingPromoOrder
  );
  const loadingSetPromoOrder = useSelector(
    (state) => state.order.loadingSetPromoOrder
  );
  const orderFooterNotEditable = useSelector(
    (state) => state.order.orderFooterNotEditable
  );
  const orderHasPromoToSelect = useSelector(
    (state) => state.order.orderHasPromoToSelect
  );
  const orderPromos = useSelector((state) => state.order.orderPromos);

  const [promos, setPromos] = useState([]);

  useEffect(() => {
    if (orderHasPromoToSelect?.openCartPromo) {
      const args = {
        id: orderFooterNotEditable?.id,
        toast,
      };
      dispatch(getOrderPromo(args)).then((res) => {
        const groups = res.payload.article_list;
        setPromos(groups);
      });
    }
  }, [dispatch, orderFooterNotEditable, orderHasPromoToSelect]);

  const handlePromoCart = () => {
    dispatch(setOrderHasPromoToSelect());
  };

  const handleChange = (e) => {
    const [name, indexGroup] = e.target.element.name.split("-");
    const group = promos.map((promo) => {
      return {
        ...promo,
        groups: promo.groups.map((group, index) => {
          return group.type === name && index === parseInt(indexGroup)
            ? {
                ...group,
                options: group.options.map((option) => {
                  return {
                    ...option,
                    selected: option.id === e.value,
                  };
                }),
              }
            : group;
        }),
      };
    });
    setPromos(group);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const articlesPromoArrays = promos.map((promo) => {
      return promo.groups.map((group) => {
        return group.type === "choice"
          ? group.options.reduce((accumulator, option) => {
              if (option.selected) {
                return option.articles.map((article) => {
                  const {
                    promo_id,
                    article_id,
                    article_code,
                    qty,
                    discount,
                    type,
                  } = article;

                  accumulator = {
                    promo_id,
                    article_id,
                    article_code,
                    qty,
                    discount,
                    type,
                  };

                  return accumulator;
                });
              }
              return accumulator;
            }, {})
          : group.articles.map((article) => {
              const {
                promo_id,
                article_id,
                article_code,
                qty,
                discount,
                type,
              } = article;

              return {
                promo_id,
                article_id,
                article_code,
                qty,
                discount,
                type,
              };
            });
      });
    });

    // Build the array to send the articles to the API
    let articles = [];
    articlesPromoArrays.map((articlesArray) => {
      return articlesArray.map((article) => {
        const isArray = Array.isArray(article);

        return (
          isArray &&
          article.map((item) => {
            return articles.push(item);
          })
        );
      });
    });

    const args = {
      data: { articles },
      toast,
      id: orderFooterNotEditable?.id,
      messageNotification: promoSelectionSuccess,
    };
    dispatch(setPromotionsOrder(args));
  };

  return (
    <div className="c-cart c-cart--sidebar c-cart--promo c-overlay">
      <div className="showProductDetails">
        <section className="c-cart__main">
          <header className="c-cart__header">
            <div className="l-mobile-flex u-flex-middle u-flex-between">
              <Button
                className="c-cart__back"
                icon="arrow-left"
                fillMode="link"
                onClick={handlePromoCart}
              >
                {gotoBack}
              </Button>
              <Button
                className=""
                icon="close"
                fillMode="flat"
                onClick={handlePromoCart}
              />
            </div>
            <h2 className="c-cart__title t-title t-title--2">
              {loadingPromoOrder ? (
                <Skeleton style={{ width: 192 }} />
              ) : (
                orderPromos && orderPromos[0]?.label
              )}
            </h2>
            {loadingPromoOrder ? (
              <Skeleton style={{ width: 192 }} />
            ) : (
              orderPromos &&
              orderPromos[0]?.alert &&
              orderPromos[0]?.alert.length > 0 && (
                <p className="c-cart__alert">
                  <span className="icon icon-alert"></span>
                  {orderPromos[0]?.alert}
                </p>
              )
            )}
          </header>

          {loadingPromoOrder ? (
            <Loader
              size="medium"
              className="c-loader c-loader--center c-loader--absolute"
            />
          ) : (
            <form className="c-cart__content" onSubmit={handleSubmit}>
              {promos &&
                promos.map((promo) => {
                  return promo?.groups.map((group, indexGroup) => {
                    return (
                      <div
                        className="c-cart-element"
                        key={`${group?.type} ${indexGroup}`}
                      >
                        {group?.description && (
                          <p className="c-cart-element__label l-tablet-flex">
                            <strong>{group?.label}</strong>
                            <span>{group?.description}</span>
                          </p>
                        )}
                        {group?.type === "choice" ? (
                          <>
                            {group?.options?.length > 0 &&
                              group?.options?.map((option) => {
                                const articles = option?.articles?.map(
                                  (article) => article
                                );

                                return (
                                  <div
                                    key={option?.id}
                                    className={`c-cart-item l-desktop-flex u-flex-center${
                                      option?.default
                                        ? " c-cart-item--default"
                                        : ""
                                    }`}
                                  >
                                    <div className="c-cart-item__header l-mobile-flex u-flex-middle">
                                      <RadioButton
                                        name={`${group?.type}-${indexGroup}`}
                                        value={option?.id}
                                        checked={option?.selected}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div
                                      className="c-cart-item__group"
                                      style={{ flexGrow: 1 }}
                                    >
                                      {option?.default && (
                                        <span className="c-cart-item__label">
                                          {option?.defaultMessage}
                                        </span>
                                      )}
                                      {articles?.map((article) => (
                                        <div
                                          key={article.article_id}
                                          className="l-mobile-flex u-flex-middle u-flex-between"
                                        >
                                          <Label data={article} />
                                          <div className="c-cart__qnt l-mobile-flex u-flex-middle u-gap-8">
                                            {article?.selling &&
                                              article?.selling?.length > 0 && (
                                                <span className="c-cart-item__badge c-cart-item__badge--special">
                                                  Not selling
                                                </span>
                                              )}
                                            {article?.qty !==0 && (
                                              <>
                                                <strong>
                                                  <span>
                                                    {`${formatNumber(
                                                      article?.price?.value
                                                    )} ${article?.price?.currency}`}
                                                  </span>
                                                </strong>
                                                <Input
                                                  disabled={true}
                                                  value={article?.qty}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            {group?.articles?.length > 0 &&
                              group?.articles?.map((article, index) => (
                                <div
                                  key={index}
                                  className="c-cart-item c-cart-item--promo l-mobile-flex u-flex-middle u-flex-between"
                                >
                                  <Label data={article} />
                                  <div className="c-cart__qnt l-mobile-flex u-flex-middle u-gap-8">
                                    <span className="c-cart-item__badge">
                                      {article?.volume}
                                    </span>
                                    <strong>
                                      {/* <span>
                                        {intl.formatNumber(
                                          Number(article.price.value),
                                          "c"
                                        )}
                                      </span> */}
                                      <span>
                                        {`${formatNumber(
                                          article?.price?.value
                                        )} ${article?.price?.currency}`}
                                      </span>
                                    </strong>
                                    <Input
                                      value={article?.qty}
                                      name={group?.type}
                                      onFocus={(e) => e.target.select()}
                                    />
                                  </div>
                                </div>
                              ))}
                          </>
                        )}
                      </div>
                    );
                  });
                })}
              <footer className="c-cart__footer l-tablet-flex u-flex-middle u-flex-end">
                <Button
                  disabled={loadingSetPromoOrder || !orderInfo?.canEdit}
                  className="c-cart__button"
                  type="submit"
                >
                  {loadingSetPromoOrder
                    ? savingPromoButtonLabel
                    : savePromoButtonLabel}
                </Button>
              </footer>
            </form>
          )}
        </section>
      </div>
    </div>
  );
};

export default Promo;
