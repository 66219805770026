import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDocMarketing } from "redux/features/download/actions";
import { useSearchParams } from "react-router-dom";
import HeaderDocsMarketing from "./header";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import { toast } from "react-toastify";
import "./marketing.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    labelEmptyListOrder: toLanguageString(
      "metislab.frontend.components.download.components.emptyListOrder.label",
      defaultMessages.metislab.frontend.components.download.components
        .emptyListOrder.label
    ),
    labelStrongEmptyListOrder: toLanguageString(
      "metislab.frontend.components.download.components.emptyListOrder.labelStrong",
      defaultMessages.metislab.frontend.components.download.components
        .emptyListOrder.labelStrong
    ),
    downloadButtonLabel: toLanguageString(
      "metislab.frontend.components.download.components.table.downloadButtonLabel",
      defaultMessages.metislab.frontend.components.download.components.table
        .downloadButtonLabel
    ),
    publishedAt: toLanguageString(
      "metislab.frontend.components.download.components.list.publishedAt",
      defaultMessages.metislab.frontend.components.download.components.list
        .publishedAt
    ),
  };
};

const Marketing = () => {
  const dispatch = useDispatch();

  const {
    labelEmptyListOrder,
    labelStrongEmptyListOrder,
    downloadButtonLabel,
    publishedAt,
  } = useLocalizedMessages();

  const [searchParams] = useSearchParams();

  /**
   * Download states
   */
  const loadingMarketing = useSelector(
    (state) => state.download.loadingMarketing
  );
  /* const marketingFilterList = useSelector(
    (state) => state.download.marketingFilterList
  ); */
  const marketingList = useSelector((state) => state.download.marketingList);
  const pagerState = useSelector((state) => state.download.pagerStateMarketing);

  /**
   * IndexWebCustomer states
   */
  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);

  /**
   * Internal states
   */
  const [filtersActive, setFiltersActive] = useState(null);

  /**
   * getListDocs is a callback's hook
   * used to get the list of marketing's docs
   */
  const getListDocs = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      const args = {
        id: searchParams.get("id"),
        pager: pagerState,
        toast,
      };
      dispatch(getDocMarketing(args));
    }
  }, [companyCustomers, dispatch, pagerState, searchParams, selectedCompany]);

  useEffect(() => {
    getListDocs();
  }, [getListDocs]);

  useEffect(() => {
    if (!searchParams.get("id")) {
      return;
    }

    setFiltersActive(null);
  }, [searchParams]);

  const handleFiltersActive = useCallback((types) => {
    setFiltersActive(types);
  }, []);

  let listDocs = marketingList;

  if (filtersActive) {
    listDocs = marketingList
      .map((mark) => {
        return {
          ...mark,
          files: mark.files.filter((file) => {
            return filtersActive.every((filter) => file.tags.includes(filter));
          }),
        };
      })
      .filter((mark) => mark.files.length > 0);
  }

  return (
    <div className="">
      <HeaderDocsMarketing handleFiltersActive={handleFiltersActive} />
      {(loadingUser || loadingIndexWebCustomer || loadingMarketing) && (
        <Loader className="c-loader c-loader--center" size="large" />
      )}
      {!loadingUser &&
        !loadingIndexWebCustomer &&
        !loadingMarketing &&
        listDocs &&
        (listDocs?.length === 0 ? (
          <div className="c-empty-state-orders">
            <EmptySearch
              icon={pagerState.search ? "search" : null}
              strongText={labelStrongEmptyListOrder}
              text={labelEmptyListOrder}
            />
          </div>
        ) : (
          listDocs
            .filter((doc) => doc.files.length > 0)
            .map((docType) => {
              return (
                <div className="c-marketing" key={docType?.type_id}>
                  <h2 className="c-marketing__title">
                    {docType?.type_description}
                  </h2>
                  {docType?.files &&
                    (docType?.files.length === 0 ? (
                      <EmptySearch
                        strongText={labelStrongEmptyListOrder}
                        text={labelEmptyListOrder}
                      />
                    ) : (
                      <ul className="c-marketing__list">
                        {docType?.files.map((file) => {
                          return (
                              <li
                                  className="c-marketing-item l-tablet-flex"
                                  key={file.file_id}
                              >
                                <p className="c-marketing-item__title l-mobile-flex u-flex-middle u-gap-16">
                                  <span
                                      className={`c-extension icon-colored-default icon-colored-${file.file_extension}`}
                                  />
                                  <span className="c-marketing-item__name">
                                    {file.file_name}
                                  </span>
                                  { file.newFile && <span
                                      className="c-cart-item__badge c-cart-item__tag-new "
                                      key={`${file.file_id}-new`}
                                  >
                                          {file.newFileMessage}
                                        </span>
                                  }
                                  { file.updatedFile && <span
                                      className="c-cart-item__badge c-cart-item__tag-updated "
                                      key={`${file.file_id}-updated`}
                                  >
                                          {file.updatedFileMessage}
                                        </span>
                                  }

                                </p>

                                <div className="c-marketing-item__info l-mobile-flex u-flex-middle">
                                  {/*Tag rimossi , ma questo è il codice che li gestisce*/}
                                  {/*<ul className="c-marketing-item__badges l-mobile-flex u-flex-middle">*/}
                                  {/*  {*/}
                                  {/*  (*/}
                                  {/*      (file.showTags === undefined) ||*/}
                                  {/*      (file.showTags !== undefined && file.showTags)*/}
                                  {/*  )*/}
                                  {/*      && file.tags.map((tag) => (*/}
                                  {/*      <li*/}
                                  {/*          className="c-cart-item__badge c-cart-item__tag-promo"*/}
                                  {/*          key={`${file.file_id}-${tag}`}*/}
                                  {/*      >*/}
                                  {/*        {tag}*/}
                                  {/*      </li>*/}
                                  {/*  ))}*/}
                                  {/*</ul>*/}
                                  <span className="c-marketing-item__extension">
                                  {file.file_extension}
                                  </span>
                                  <span className="c-marketing-item__dimension">
                                  {file.file_dimension}
                                </span>
                                  <span className="c-marketing-item__publish l-mobile-flex u-gap-8">
                                  {publishedAt}
                                    <strong>{file.created_at}</strong>
                                </span>
                                  <a
                                      className="k-button k-button-sm k-button-link c-marketing-item__button"
                                      href={file.download_url}
                                      target="_blank"
                                      rel="noreferrer"
                                  >
                                    {downloadButtonLabel}
                                    <span
                                        role="presentation"
                                        className="k-icon k-i-download"
                                    ></span>
                                  </a>
                                </div>
                              </li>
                          );
                        })}
                      </ul>
                    ))}
                </div>
              );
            })
        ))}
    </div>
  );
};

export default Marketing;
