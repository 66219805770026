import React from 'react';
import PropTypes from 'prop-types';

const CellCode = ({ code, fido }) => {
    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="1" 
            aria-selected="false"
            data-grid-col-index="0"
        >
            { parseInt(fido) 
                ? <span className="k-icon k-i-lock"></span> 
                : null
            }
            <span style={{ verticalAlign: 'text-top'}}>{ code }</span>
        </td>
    )
}

CellCode.propTypes = {
    code: PropTypes.string,
    blocked: PropTypes.bool,
}

export default CellCode;