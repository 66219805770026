import { createSlice } from "@reduxjs/toolkit";
import { 
    activateOrder,
    approveOrder,
    confirmOrder,
    rejectOrder,
    deleteOrder,
    exportExcelOrder,
    exportExcelOrderTemplate,
    importExcelOrder,
    checkAuth,
    createOrderHeader,
    deactivateOrder,
    forgotPassword,
    changePasswordFromLoggedUser,
    validateLinkRecovery,
    changePasswordFromValidateLinkRecovery,
    getLoggedUser,
    login,
    logout,
    setCustomerRequest,
    unSetCustomerRequest,
    getPublicRoutes,
} from "./actions";

const language = JSON.parse(localStorage.getItem('lang'))
    ? JSON.parse(localStorage.getItem('lang'))
    : null;

const token = localStorage.getItem('usrtkn')
    ? localStorage.getItem('usrtkn')
    : null;

const initialState =  {
    authenticated: false,

    company: null,
    companyChannel: null,
    companies: [],

    customerId: null,

    exportExcelFile: null,
    exportExcelFileTemplate: null,
    importExcelFileResult: null,

    error: false,
    errorOnConfirmOrder: false,
    errorOnApproveOrder: false,

    language,

    loading: false,
    loadingApproveOrder: false,
    loadingConfirmOrder: false,
    loadingCreateOrder: false,
    loadingRejectOrder: false,
    loadingDeleteOrder: false,
    loadingExportExcelFile: false,
    loadingExportExcelFileTemplate: false,
    loadingImportExcelFile: false,
    loadingUnsetCustomer: false,    
    loadingRecoveryData: false,
    loadingChangePassword: false,
    loadingValidateLinkRecovery: false,
    linkRecoveryIsValid: null,
    linkRecoveryResult: null,

    menu: [],
    routes: [], 

    permissions: null,
    comboOrderTags: null,

    selectedCustomer: null,

    selectedOrder: null,

    sidebarMenu: false,

    token,
    
    user: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetImportExcelFileResult: (state, action) => {
            state.importExcelFileResult = null;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setCompanyChannel: (state, action) => {
            state.companyChannel = action.payload;
        },
        setLanguage: (state, action) => {
            localStorage.setItem('lang', JSON.stringify(action.payload));
            state.language = action.payload;
        },
        setNavigationMenu: (state, action) => {
            state.menu = action.payload;
        },
        setSidebarMenu: (state) => {
            state.sidebarMenu = !state.sidebarMenu;
        },
        setTotalOrder: (state, action) => {
            state.selectedOrder = { 
                ...state.selectedOrder, 
                final_amount: action.payload.final_amount,
                perc_discount: action.payload.perc_discount,
                total_discount: action.payload.total_discount,
                total_value: action.payload.total_value,
            };
        },
    },
    extraReducers: {
        /**
         * Check authentication reducers
         */
        [checkAuth.pending]: (state) => {
            state.loadingRecoveryData = true;
        },
        [checkAuth.fulfilled]: (state, action) => {
            const { authenticated } = action.payload;
            state.authenticated = authenticated;
        },
        [checkAuth.rejected]: (state, action) => {
            state.loadingRecoveryData = false;
        }, 
        /**
         * forgot Password reducers
         */
        [forgotPassword.pending]: (state) => {
            state.loading = true;
        },
        [forgotPassword.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [forgotPassword.rejected]: (state, action) => {
            state.loading = false;
        },        
        /**
         * change Password
         */
        [changePasswordFromLoggedUser.pending]: (state) => {
            state.loadingChangePassword = true;
        },
        [changePasswordFromLoggedUser.fulfilled]: (state) => {
            state.loadingChangePassword = false;
        },
        [changePasswordFromLoggedUser.rejected]: (state) => {
            state.loadingChangePassword = false;
        }, 
        /**
         * validation of the token and mail passed 
         * in the link recovery
         */
        [validateLinkRecovery.pending]: (state) => {
            state.loadingValidateLinkRecovery = true;
        },
        [validateLinkRecovery.fulfilled]: (state, action) => {
            state.loadingValidateLinkRecovery = false;
            const { aMessage, bResult } = action.payload;
            state.linkRecoveryIsValid = bResult;
            if (aMessage !== undefined) {
                state.linkRecoveryResult = aMessage[0].sMessageText;
            }
        },
        [validateLinkRecovery.rejected]: (state) => {
            state.loadingValidateLinkRecovery = false;
        },  
        /**
         * change Password
         */
        [changePasswordFromValidateLinkRecovery.pending]: (state) => {
            state.loadingChangePassword = true;
        },
        [changePasswordFromValidateLinkRecovery.fulfilled]: (state) => {
            state.loadingChangePassword = false;
        },
        [changePasswordFromValidateLinkRecovery.rejected]: (state) => {
            state.loadingChangePassword = false;
        },       
        /**
         * Get user logged reducers
         */
        [getLoggedUser.pending]: (state) => {
            state.loadingRecoveryData = true;
        },
        [getLoggedUser.fulfilled]: (state, action) => {   
            const { 
                user, 
                selectedCompany,
                selectedCompanyChannel, 
                selectedOrder 
            } = action.payload;
            const { 
                companies, 
                customer_id,
                menu, 
                permissions,
                combo_order_tags,
                routes,
                selectedCustomerData, 
                ...userData
            } = user;
            state.loadingRecoveryData = false;
            state.company = selectedCompany;       
            state.companyChannel = selectedCompanyChannel 
                ? companies.find( comp => comp?.key === selectedCompanyChannel)
                : selectedCompanyChannel;
            state.companies = companies;
            state.selectedCustomer = selectedCustomerData;
            state.selectedOrder = selectedOrder 
                ? selectedOrder
                : null;
            state.user = userData;
            state.routes = routes;
            state.menu = menu;
            state.customerId = customer_id;
            state.permissions = permissions;
            state.comboOrderTags = combo_order_tags;
        },
        [getLoggedUser.rejected]: (state, action) => {
            state.loadingRecoveryData = false;
        },
        /**
         * Login reducers
         */
        [login.pending]: (state) => {
            state.loading = true;
        },
        [login.fulfilled]: (state, action) => {  
            const { 
                selectedCompany,
                selectedCompanyChannel,
                token, 
                user,             
            } = action.payload;
            const { 
                companies, 
                customer_id,
                menu, 
                permissions,
                combo_order_tags,
                routes,       
                selectedCustomerData, 
                ...userData
            } = user;
            localStorage.setItem('usrtkn', token);
            if (sessionStorage.getItem('URL_NOTES') && companies.length === 1) {
                state.company = null;       
                state.companyChannel = null; 
            } else {
                state.company = selectedCompany;       
                state.companyChannel = selectedCompanyChannel 
                    ? companies.find( comp => comp?.key === selectedCompanyChannel)
                    : selectedCompanyChannel;
            }
            state.loading = false;           
            state.token = token;
            state.companies = companies;
            state.selectedCustomer = selectedCustomerData;
            state.user = userData;
            state.authenticated = true;
            state.routes = routes;
            state.menu = menu;
            state.customerId = customer_id;
            state.permissions = permissions;
            state.comboOrderTags = combo_order_tags;
        },
        [login.rejected]: (state, action) => {
            state.loading = false;
        },
        /**
         * Logout reducers
         */
        [logout.pending]: (state) => {
            state.loadingRecoveryData = true;
        },
        [logout.fulfilled]: (state) => {
            localStorage.removeItem('usrtkn');
            state.loadingRecoveryData = false;           
            state.authenticated = false;
            state.company = null;
            state.companies = [];
            state.token = null;
            state.user = null;
            state.language = null;
        },
        [logout.rejected]: (state, action) => {
            state.loadingRecoveryData = false;
        },
        /**
         * Set a customer
         */
        [setCustomerRequest.pending]: (state) => {
            state.loading = 'pendingSetCustomer';
        },
        [setCustomerRequest.fulfilled]: (state, action) => {
            const { description, search_code, selectedCustomer } = action.payload;
            state.loading = false;        
            state.selectedCustomer = { 
                description, 
                search_code,
                code: selectedCustomer
            };
        },
        [setCustomerRequest.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Set a customer
         */
        [createOrderHeader.pending]: (state) => {
            state.loadingCreateOrder = true;
        },
        [createOrderHeader.fulfilled]: (state, action) => {
            const { selectedOrder } = action.payload;
            state.loadingCreateOrder = false;        
            state.selectedOrder = selectedOrder;
        },
        [createOrderHeader.rejected]: (state, action) => {
            state.loadingCreateOrder = false;
        },
        /**
         * Activate an order
         */
        [activateOrder.pending]: (state) => {
            state.loadingCreateOrder = true;
        },
        [activateOrder.fulfilled]: (state, action) => {
            const { 
                selectedOrder,
                description, 
                search_code, 
                selectedCustomer
            } = action.payload;
            state.loadingCreateOrder = false;  
            state.selectedOrder = selectedOrder;
            if (selectedCustomer !== state.selectedCustomer?.code ) {
                state.selectedCustomer = { 
                    description, 
                    search_code,
                    code: selectedCustomer
                };
            }
        },
        [activateOrder.rejected]: (state, action) => {
            state.loadingCreateOrder = false;
        }, 
        /**
         * Deactivate an order
         */
        [deactivateOrder.pending]: (state) => {
            state.loadingCreateOrder = true;
        },
        [deactivateOrder.fulfilled]: (state, action) => {
            const { selectedOrder } = action.payload;
            state.loadingCreateOrder = false;  
            state.selectedOrder = selectedOrder;
        },
        [deactivateOrder.rejected]: (state, action) => {
            state.loadingCreateOrder = false;
        }, 
        /**
         * Unset customer 
         */
        [unSetCustomerRequest.pending]: (state) => {
            state.loading = 'pendingSetCustomer';
        },
        [unSetCustomerRequest.fulfilled]: (state, action) => {
            state.loading = false;  
            state.selectedCustomer = null;
        },
        [unSetCustomerRequest.rejected]: (state, action) => {
            state.loading = false;  
        }, 
        /**
         * Confirm order
         */
        [confirmOrder.pending]: (state) => {
            state.loadingConfirmOrder = true;
        },
        [confirmOrder.fulfilled]: (state, action) => {
            state.loadingConfirmOrder = false;  
            if (!action.payload?.bResult) {
                const { sMessageText } = action.payload?.aMessage[0];
                state.errorOnConfirmOrder = sMessageText;
            }
        },
        [confirmOrder.rejected]: (state, action) => {
            state.loadingConfirmOrder = false;  
        },
        /**
         * Approve order
         */
        [approveOrder.pending]: (state) => {
            state.loadingApproveOrder = true;
        },
        [approveOrder.fulfilled]: (state, action) => {
            state.loadingApproveOrder = false;  
            if (!action.payload?.bResult) {
                const { sMessageText } = action.payload?.aMessage[0];
                state.errorOnApproveOrder = sMessageText;
            }
        },
        [approveOrder.rejected]: (state, action) => {
            state.loadingApproveOrder = false;  
        },
         /**
         * Reject order
         */
        [rejectOrder.pending]: (state) => {
            state.loadingRejectOrder = true;
        },
        [rejectOrder.fulfilled]: (state, action) => {
            state.loadingRejectOrder = false;  
        },
        [rejectOrder.rejected]: (state, action) => {
            state.loadingRejectOrder = false;  
        },
        /**
         * Delete order
         */
        [deleteOrder.pending]: (state) => {
            state.loadingDeleteOrder = true;
        },
        [deleteOrder.fulfilled]: (state, action) => {
            state.loadingDeleteOrder = false;  
        },
        [deleteOrder.rejected]: (state, action) => {
            state.loadingDeleteOrder = false;  
        },
        /**
         * Export file of an order
         */
        [exportExcelOrder.pending]: (state) => {
            state.loadingExportExcelFile = true;
        },
        [exportExcelOrder.fulfilled]: (state, action) => {
            state.loadingExportExcelFile = false;  
            state.exportExcelFile = action.payload;  
        },
        [exportExcelOrder.rejected]: (state, action) => {
            state.loadingExportExcelFile = false;  
        },
        /**
         * Export template file for an order
         */
        [exportExcelOrderTemplate.pending]: (state) => {
            state.loadingExportExcelFileTemplate = true;
        },
        [exportExcelOrderTemplate.fulfilled]: (state, action) => {
            state.loadingExportExcelFileTemplate = false;  
            const { name, url }  = action.payload;  
            state.exportExcelFileTemplate = { name, url };
        },
        [exportExcelOrderTemplate.rejected]: (state, action) => {
            state.loadingExportExcelFileTemplate = false;  
        },
        /**
         * Import file to an order
         */
        [importExcelOrder.pending]: (state) => {
            state.loadingImportExcelFile = true;
        },
        [importExcelOrder.fulfilled]: (state, action) => {
            state.loadingImportExcelFile = false;  
            const { bResult } = action.payload;
            const { sMessageText } = action.payload.aMessage[0];
            state.importExcelFileResult = {
                bResult,
                sMessageText
            };
        },
        [importExcelOrder.rejected]: (state, action) => {
            state.loadingImportExcelFile = false;  
        },
        /**
         * Get Public Routes
         */
        [getPublicRoutes.pending]: (state) => {
            state.loadingRecoveryData = true;
        },
        [getPublicRoutes.fulfilled]: (state, action) => {   
            state.loadingRecoveryData = false;
            const { routes } = action.payload;
            state.routes = [routes];
        },
        [getPublicRoutes.rejected]: (state, action) => {
            state.loadingRecoveryData = false;
        },
        
    },
});
  
export const { 
    resetImportExcelFileResult,
    setCompany, 
    setCompanyChannel,
    setLanguage,
    setNavigationMenu,
    setSidebarMenu,
    setTotalOrder, 
} = userSlice.actions;
export default userSlice.reducer;