import React from 'react';
import PropTypes from 'prop-types';
import ProductItemSearch from '../product';

const ProductsList = ({products}) => {
  return (
    <ul style={{ width: '100%' }}>
        {products.map( (product, index) => {
            return (
                <ProductItemSearch 
                    key={`${product.article_id}-${index}`} 
                    item={product}
                />
            )
        })}
    </ul>
  )
}

ProductsList.propTypes = {
    products: PropTypes.array
}

export default ProductsList;