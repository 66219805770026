import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetConcurrentAccessOrderUsersIsChanged,
  setOrderHasPromoToSelect,
  unsetOrderFooterNotEditable,
} from "redux/features/order";
import { resetImportExcelFileResult } from "redux/features/user";
import {
  deactivateOrder,
  confirmOrder,
  approveOrder,
  rejectOrder,
  deleteOrder,
  exportExcelOrder,
  // exportExcelOrderTemplate,
  importExcelOrder,
} from "redux/features/user/actions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetMatchesRoutes } from "utils";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Skeleton } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplate from "./tooltipTemplate";
import "./order-active.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";
import Loader from "components/loader";
import MediaUpload from "components/mediaUpload";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    approveOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.approve",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .approve
    ),
    approveMessageSave: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.approveMessageSave",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .approveMessageSave
    ),
    approveTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.approveTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .approveTitle
    ),
    approvingOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.approving",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .approving
    ),
    approvedOrderButton: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.approvedOrderButton",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .approvedOrderButton
    ),
    approvedOrderTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.approvedOrderTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .approvedOrderTitle
    ),

    buttonPromoLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.buttonPromoLabel",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .buttonPromoLabel
    ),
    buttonPromoSelectedLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.buttonPromoSelectedLabel",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .buttonPromoSelectedLabel
    ),

    confirm: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.confirm",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .confirm
    ),
    confirmMessageSave: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.confirmMessageSave",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .confirmMessageSave
    ),
    confirmTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.confirmTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .confirmTitle
    ),
    confirmingSave: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.confirmingSave",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .confirmingSave
    ),

    currentOrder: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.currentOrder",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .currentOrder
    ),

    deleteOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.delete",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .delete
    ),
    deleteMessage: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.deleteMessage",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .deleteMessage
    ),
    deleteOrderText: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.deleteText",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .deleteText
    ),
    deleteTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.deleteTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .deleteTitle
    ),
    deleteButton: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.deleteButton",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .deleteButton
    ),
    deletingButton: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.deletingButton",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .deletingButton
    ),

    discount: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.discount",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .discount
    ),

    editOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.edit",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .edit
    ),
    editOrderText: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.editText",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .editText
    ),

    errorConfirmOrderModalButton: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.errorConfirmOrderModalButton",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .errorConfirmOrderModalButton
    ),
    errorConfirmOrderModalTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.errorConfirmOrderModalTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .errorConfirmOrderModalTitle
    ),

    exportFile: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.export",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .export
    ),
    exportButtonLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.exportButtonLabel",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .exportButtonLabel
    ),
    exportModalTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.exportModalTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .exportModalTitle
    ),
    exportFileText: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.exportText",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .exportText
    ),

    goto: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.goto",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .goto
    ),
    gotoCheckout: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.gotoCheckout",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .gotoCheckout
    ),

    importFile: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.import",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .import
    ),
    importButtonLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.importButtonLabel",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .importButtonLabel
    ),
    importingButtonLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.importingButtonLabel",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .importingButtonLabel
    ),
    importModalTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.importModalTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .importModalTitle
    ),
    importModalTemplateTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.importModalTemplateTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .importModalTemplateTitle
    ),
    importFileText: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.importText",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .importText
    ),

    rejectOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.rejectOrder",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .rejectOrder
    ),
    rejectPlaceholder: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.rejectPlaceholder",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .rejectPlaceholder
    ),
    rejectText: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.rejectText",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .rejectText
    ),
    rejectTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.rejectTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .rejectTitle
    ),

    save: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.save",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .save
    ),
    saveConfirmMessage: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.saveConfirmMessage",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .saveConfirmMessage
    ),
    saveConfirmSave: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.saveConfirmSave",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .saveConfirmSave
    ),
    saveConfirmTitle: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.saveConfirmTitle",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .saveConfirmTitle
    ),
    saveConfirmingSave: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.saveConfirmingSave",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .saveConfirmingSave
    ),

    submitOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.submitOrder",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .submitOrder
    ),

    total: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.total",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .total
    ),

    saveOrderSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.order.saveOrderSuccess",
      defaultMessages.metislab.frontend.components.notification.components.order
        .saveOrderSuccess
    ),
    confirmOrderSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.order.confirmOrderSuccess",
      defaultMessages.metislab.frontend.components.notification.components.order
        .confirmOrderSuccess
    ),
    rejectOrderSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.order.rejectOrderSuccess",
      defaultMessages.metislab.frontend.components.notification.components.order
        .rejectOrderSuccess
    ),
    deleteOrderSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.order.deleteOrderSuccess",
      defaultMessages.metislab.frontend.components.notification.components.order
        .deleteOrderSuccess
    ),
  };
};

const FooterOrderActive = () => {
  const {
    approveOrderLabel,
    approveMessageSave,
    approveTitle,
    approvingOrderLabel,
    approvedOrderButton,
    approvedOrderTitle,

    buttonPromoLabel,
    buttonPromoSelectedLabel,

    confirm,
    confirmMessageSave,
    confirmTitle,
    confirmingSave,

    deleteOrderLabel,
    deleteMessage,
    deleteOrderText,
    deleteTitle,
    deleteButton,
    deletingButton,

    editOrderLabel,
    editOrderText,

    errorConfirmOrderModalButton,
    errorConfirmOrderModalTitle,

    exportFile,
    exportButtonLabel,
    exportModalTitle,
    exportFileText,

    importFile,
    importButtonLabel,
    importingButtonLabel,
    importModalTitle,
    // importModalTemplateTitle,
    importFileText,

    discount,

    goto,
    gotoCheckout,

    rejectOrderLabel,
    rejectPlaceholder,
    rejectText,
    rejectTitle,

    save,
    saveConfirmMessage,
    saveConfirmSave,
    saveConfirmTitle,
    saveConfirmingSave,

    submitOrderLabel,

    total,

    saveOrderSuccess,
    confirmOrderSuccess,
    rejectOrderSuccess,
    deleteOrderSuccess,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  /**
   * User states
   */
  const errorOnApproveOrder = useSelector(
    (state) => state.user.errorOnApproveOrder
  );
  const errorOnConfirmOrder = useSelector(
    (state) => state.user.errorOnConfirmOrder
  );

  const exportExcelFile = useSelector((state) => state.user.exportExcelFile);
  // const exportExcelFileTemplate = useSelector(
  //   (state) => state.user.exportExcelFileTemplate
  // );
  const importExcelFileResult = useSelector(
    (state) => state.user.importExcelFileResult
  );
  const loadingApproveOrder = useSelector(
    (state) => state.user.loadingApproveOrder
  );
  const loadingConfirmOrder = useSelector(
    (state) => state.user.loadingConfirmOrder
  );
  const loadingCreateOrder = useSelector(
    (state) => state.user.loadingCreateOrder
  );
  const loadingRejectOrder = useSelector(
    (state) => state.user.loadingRejectOrder
  );
  const loadingDeleteOrder = useSelector(
    (state) => state.user.loadingDeleteOrder
  );
  const loadingExportExcelFile = useSelector(
    (state) => state.user.loadingExportExcelFile
  );
  // const loadingExportExcelFileTemplate = useSelector(
  //   (state) => state.user.loadingExportExcelFileTemplate
  // );
  const loadingImportExcelFile = useSelector(
    (state) => state.user.loadingImportExcelFile
  );
  const selectedOrder = useSelector((state) => state.user.selectedOrder);

  /**
   * IndexWebCustomer states
   */
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );

  /**
   * Order states
   */
  const orderFooterNotEditable = useSelector(
    (state) => state.order.orderFooterNotEditable
  );
  const loading = useSelector((state) => state.order.loading);
  const loadingPromoOrder = useSelector(
    (state) => state.order.loadingPromoOrder
  );
  const orderHasPromoToSelect = useSelector(
    (state) => state.order.orderHasPromoToSelect
  );
  const orderActions = useSelector((state) => state.order.orderActions);
  const orderInfo = useSelector((state) => state.order.orderInfo);

  const concurrentAccessOrderTotalUsers = useSelector(
    (state) => state.order.concurrentAccessOrderTotalUsers
  );
  const concurrentAccessOrderUsersInEdit = useSelector(
    (state) => state.order.concurrentAccessOrderUsersInEdit
  );
  const concurrentAccessOrderUsersInView = useSelector(
    (state) => state.order.concurrentAccessOrderUsersInView
  );
  const concurrentAccessOrderUsersIsChanged = useSelector(
    (state) => state.order.concurrentAccessOrderUsersIsChanged
  );

  /**
   * These are the controls to check the current location
   * It used to handle the views of the footer
   */
  const routesOrderCatalogue = [
    { path: "/orders/:id/edit" },
    { path: "/orders/pending/:id/edit" },
  ];

  const routesOrderDetails = [
    { path: "/orders/:id" },
    { path: "/orders/pending/:id" },
    { path: "/orders/processing/:id" },
  ];

  const matchesmatchesCatalogue = useGetMatchesRoutes(routesOrderCatalogue);
  const isCatalogueOrderPage = matchesmatchesCatalogue ? true : false;

  const matchesmatchesDetails = useGetMatchesRoutes(routesOrderDetails);
  const isDetailsOrderPages =
    matchesmatchesDetails &&
    !matchesmatchesDetails[0].pathname?.endsWith("pending") &&
    !matchesmatchesDetails[0].pathname?.endsWith("processing")
      ? true
      : false;

  const isEditPendingOrderPages =
    matchesmatchesDetails &&
    matchesmatchesDetails[0].route.path === "/orders/pending/:id";

  const isDetailsAndActiveOrderPages =
    matchesmatchesCatalogue ||
    (matchesmatchesDetails &&
      !matchesmatchesDetails[0].pathname?.endsWith("pending") &&
      !matchesmatchesDetails[0].pathname?.endsWith("processing"))
      ? true
      : false;

  /**
   * Internal states of the component
   */
  const [showModal, setShowModal] = useState(false);
  const [rejectReasons, setRejectReasons] = useState("");
  const [showModalConfirmOrder, setShowModalConfirmOrder] = useState(false);
  const [showModalApproveOrder, setShowModalApproveOrder] = useState(false);
  const [showModalOrderApproved, setShowModalOrderApproved] = useState(false);
  const [showModalRejectOrder, setShowModalRejectOrder] = useState(false);
  const [showModalDeleteOrder, setShowModalDeleteOrder] = useState(false);
  const [showDownloadExcelOrder, setShowDownloadExcelOrder] = useState(false);
  const [showImportExcelOrder, setShowImportExcelOrder] = useState(false);

  const [showModalErrorOnApproveOrder, setShowModalErrorOnApproveOrder] =
    useState(false);
  const [showModalErrorOnConfirmOrder, setShowModalErrorOnConfirmOrder] =
    useState(false);

  const [fileExcelToImport, setFileExcelToImport] = useState(null);

  useEffect(() => {
    return () => {
      if (isDetailsAndActiveOrderPages) {
        dispatch(unsetOrderFooterNotEditable());
      }
    };
  }, [dispatch, isDetailsAndActiveOrderPages]);

  useEffect(() => {
    if (importExcelFileResult?.bResult) {
      setFileExcelToImport(null);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [importExcelFileResult]);

  /**
   * handleModal handles the modal to save the order
   */
  const handleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  /**
   * handleModalConfirmOrder handles the modal to confirm the order
   */
  const handleModalConfirmOrder = () => {
    setShowModalConfirmOrder((prevState) => !prevState);
  };

  /**
   * handleModalApproveOrder handles the modal to approve the order
   */
  const handleModalApproveOrder = () => {
    setShowModalApproveOrder((prevState) => !prevState);
  };

  /** handleModalDeleteOrder
   * handleModalRejectOrder handles the modal to reject the order
   */
  const handleModalRejectOrder = () => {
    setShowModalRejectOrder((prevState) => !prevState);
    setRejectReasons("");
  };

  /**
   * handleModalRejectOrder handles the modal to reject the order
   */
  const handleModalDeleteOrder = () => {
    setShowModalDeleteOrder((prevState) => !prevState);
  };

  /**
   * handleModalDownloadExcelOrder handles the modal to download a file excel for the order
   */
  const handleModalDownloadExcelOrder = (isOpenAction = true) => {
    setShowDownloadExcelOrder((prevState) => !prevState);
    if (isOpenAction) {
      const args = {
        idOrder: orderInfo?.id,
        toast,
      };
      dispatch(exportExcelOrder(args));
    }
  };

  /**
   * handleModalImportExcelOrder handles the modal to import a file excel for the order
   */
  const handleModalImportExcelOrder = () => {
    setShowImportExcelOrder((prevState) => !prevState);
    setFileExcelToImport(null);
    if (!showImportExcelOrder) {
      // const args = {
      //   idOrder: isActiveOrderPage
      //     ? selectedOrder?.id
      //     : orderFooterNotEditable?.id,
      //   toast,
      // };
      // dispatch(exportExcelOrderTemplate(args));
    }
    dispatch(resetImportExcelFileResult());
  };

  const handleModalErrorOrder = () => {
    window.location.reload();
  };

  /**
   * unsetOrder make a call to the API to unset an anctive order
   */
  const unsetOrder = () => {
    const args = {
      navigate,
      toast,
      messageNotification: saveOrderSuccess,
    };
    dispatch(deactivateOrder(args)).then(() =>
      setShowModal((prevState) => !prevState)
    );
  };

  /**
   * confirmOrderAction is used to dispatch the confirm of an order
   */
  const confirmOrderAction = () => {
    const args = {
      id: orderFooterNotEditable?.id,
      toast,
      navigate,
      notification: false,
      messageNotification: confirmOrderSuccess,
      redirect: false,
    };

    if (orderFooterNotEditable?.id === selectedOrder?.id) {
      dispatch(confirmOrder(args)).then((res) => {
        setShowModalConfirmOrder(false);
        if (res?.payload?.bResult) {
          dispatch(deactivateOrder(args)).then(() => navigate("/orders"));
        } else {
          setShowModalErrorOnConfirmOrder((prevState) => !prevState);
        }
      });
    } else {
      dispatch(confirmOrder(args)).then((res) => {
        setShowModalConfirmOrder(false);
        if (res?.payload?.bResult) {
          navigate("/orders");
        } else {
          setShowModalErrorOnConfirmOrder((prevState) => !prevState);
        }
      });
    }
  };

  /**
   * approveOrderAction is used to dispatch the approvation of an order
   */
  const approveOrderAction = () => {
    const args = {
      id: orderFooterNotEditable?.id,
      toast,
    };

    dispatch(approveOrder(args)).then((res) => {
      console.log(res?.payload);
      setShowModalApproveOrder((prevState) => !prevState);

      if (res?.payload?.bResult) {
        setShowModalOrderApproved((prevState) => !prevState);
      } else {
        setShowModalErrorOnApproveOrder((prevState) => !prevState);
      }
    });
  };

  /**
   * approveOrderAction is used to dispatch the approvation of an order
   */
  const deleteOrderAction = () => {
    const args = {
      id: orderFooterNotEditable?.id,
      notification: false,
      redirect: false,
      toast,
      messageNotification: deleteOrderSuccess,
    };

    if (orderFooterNotEditable?.id === selectedOrder?.id) {
      dispatch(deleteOrder(args)).then(() => {
        dispatch(deactivateOrder(args)).then(() => {
          const urlRedirect = orderInfo?.canApprove
            ? "/orders/pending"
            : "/orders";
          navigate(urlRedirect);
          handleModalDeleteOrder();
        });
      });
    } else {
      dispatch(deleteOrder(args)).then(() => {
        const urlRedirect = orderInfo?.canApprove
          ? "/orders/pending"
          : "/orders";
        navigate(urlRedirect);
        handleModalDeleteOrder();
      });
    }
  };

  /**
   * rejectOrderAction is used to dispatch the reject of an order
   */
  const rejectOrderAction = () => {
    const args = {
      id: orderFooterNotEditable?.id,
      data: rejectReasons,
      navigate,
      toast,
      messageNotification: rejectOrderSuccess,
    };

    if (orderFooterNotEditable?.id === selectedOrder?.id) {
      dispatch(deactivateOrder(args)).then(() => {
        dispatch(rejectOrder(args)).then(() => {
          setRejectReasons("");
          setShowModalRejectOrder(false);
        });
      });
    } else {
      dispatch(rejectOrder(args)).then(() => {
        setRejectReasons("");
        setShowModalRejectOrder(false);
      });
    }
  };

  const handleAddFile = (e) => {
    if (!e.affectedFiles[0].hasOwnProperty("validationErrors")) {
      dispatch(resetImportExcelFileResult());
      setFileExcelToImport(e.affectedFiles[0]);
    }
  };

  const handleRemoveFile = (e) => {
    setFileExcelToImport(null);
  };

  const importFileOrderAction = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("orderExcel", fileExcelToImport.getRawFile());

    const args = {
      data: formData,
      idOrder: orderInfo?.id,
      toast,
    };

    dispatch(resetImportExcelFileResult());
    dispatch(importExcelOrder(args));
  };

  /**
   * handleEditOrder is used to handle the editing of an order
   */
  const handleEditOrder = () => {
    isEditPendingOrderPages
      ? navigate(`/orders/pending/${orderFooterNotEditable?.id}/edit`)
      : navigate(`/orders/${orderFooterNotEditable?.id}/edit`);
  };

  /**
   * handlePromo is used to handle the cart of the promo
   */
  const handlePromo = () => {
    dispatch(setOrderHasPromoToSelect());
  };

  /**
   * handleOrderApproved is used to handle state and redirect
   * after an approvation of an order
   */
  const handleOrderApproved = () => {
    const args = {
      id: orderFooterNotEditable?.id,
      notification: false,
      redirect: false,
      toast,
    };

    if (orderFooterNotEditable?.id === selectedOrder?.id) {
      dispatch(deactivateOrder(args)).then(() => {
        setShowModalOrderApproved((prevState) => !prevState);
        navigate("/orders/pending");
      });
    } else {
      setShowModalOrderApproved((prevState) => !prevState);
      navigate("/orders/pending");
    }
  };

  /**
   * handleRejectReasons is used to handle the text in the textArea
   * for the reasons of a reject order.
   */
  const handleRejectReasons = (e) => {
    setRejectReasons(e.value);
  };

  /**
   * handleConcurrentAccessOrderUsersIsChanged is used to reset status changed
   */
  const handleConcurrentAccessOrderUsersIsChanged = () => {
    if (concurrentAccessOrderUsersIsChanged) {
      dispatch(resetConcurrentAccessOrderUsersIsChanged());
    }
  };

  /**
   * If not present an order selected
   * and is not page of details or page for edit
   * doesn't show the footer
   */
  if (!selectedOrder && !isDetailsAndActiveOrderPages) {
    return null;
  }

  return (
    <>
      <div
        className={`c-footer l-desktop-medium-flex u-flex-middle u-flex-between ${
          isDetailsOrderPages || isCatalogueOrderPage
            ? "c-footer--details-order"
            : ""
        }`}
      >
        <div
          className="c-footer__meta-act"
          style={{ display: "flex", alignItems: "center", gap: 16 }}
        >
          {!loading && concurrentAccessOrderTotalUsers > 0 && (
            <div
              className={
                concurrentAccessOrderUsersIsChanged
                  ? "c-animation-notification c-animation-notification--animated"
                  : "c-animation-notification"
              }
            >
              <Tooltip
                className="c-tooltip-box"
                anchorElement="target"
                content={(props) => <TooltipTemplate item={props.title} />}
                onOpen={handleConcurrentAccessOrderUsersIsChanged}
              >
                <span
                  className={`s-state`}
                  title={JSON.stringify(
                    concurrentAccessOrderUsersInEdit.concat(
                      concurrentAccessOrderUsersInView
                    )
                  )}
                  style={{ display: "flex", alignItems: "center", gap: 8 }}
                ></span>
              </Tooltip>
              <span className="k-icon k-i-user"></span>
              <span>{concurrentAccessOrderTotalUsers}</span>
            </div>
          )}

          {orderActions?.canEdit && (
            <div className="l-tablet-flex u-flex-middle">
              {!loading && isDetailsOrderPages && orderHasPromoToSelect && (
                <div
                  className={`c-footer__selection l-mobile-flex u-flex-middle ${
                    orderHasPromoToSelect?.selected
                      ? "c-footer__selection--success"
                      : ""
                  } `}
                >
                  <span
                    className={`c-footer__selection-alert ${
                      orderHasPromoToSelect?.selected
                        ? "c-footer__selection-alert--success"
                        : ""
                    }`}
                  >
                    {orderHasPromoToSelect?.alert}
                  </span>
                  <Button
                    className={`c-footer__selection-button ${
                      orderHasPromoToSelect?.selected
                        ? "c-footer__selection-button--success"
                        : ""
                    }`}
                    disabled={loadingPromoOrder}
                    fillMode="outline"
                    onClick={handlePromo}
                  >
                    {orderHasPromoToSelect?.selected
                      ? buttonPromoSelectedLabel
                      : buttonPromoLabel}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={`c-footer__content l-desktop-flex u-flex-middle ${
            !orderInfo?.canConfirm && !orderInfo?.canApprove ? "k-pb-0" : ""
          }`}
        >
          {!loading && (
            <ul className="c-footer__actions c-footer__actions--inline l-mobile-flex u-flex-middle">
              {isDetailsAndActiveOrderPages && orderInfo?.canDownloadExcel && (
                <li>
                  <Button
                    className="c-footer__button"
                    fillMode="flat"
                    icon="excel"
                    onClick={handleModalDownloadExcelOrder}
                  >
                    {exportFile}
                    <span className="c-footer__button-label-full u-tablet">
                      {exportFileText}
                    </span>
                  </Button>
                </li>
              )}
              {isDetailsAndActiveOrderPages && orderInfo?.canUploadExcel && (
                <li>
                  <Button
                    className="c-footer__button"
                    fillMode="flat"
                    icon="csv"
                    onClick={handleModalImportExcelOrder}
                  >
                    {importFile}
                    <span className="c-footer__button-label-full u-tablet">
                      {importFileText}
                    </span>
                  </Button>
                </li>
              )}
              {!isCatalogueOrderPage && orderActions?.canDelete && (
                <li>
                  <Button
                    className="c-footer__button"
                    fillMode="flat"
                    icon="close"
                    onClick={handleModalDeleteOrder}
                  >
                    {deleteOrderLabel}
                    <span className="c-footer__button-label-full u-tablet">
                      {deleteOrderText}
                    </span>
                  </Button>
                </li>
              )}
              {!isCatalogueOrderPage && orderActions?.canApprove && (
                <li>
                  <Button
                    className="c-footer__button"
                    fillMode="flat"
                    icon="track-changes-reject"
                    onClick={handleModalRejectOrder}
                  >
                    {rejectOrderLabel}
                    <span className="c-footer__button-label-full u-tablet">
                      {rejectText}
                    </span>
                  </Button>
                </li>
              )}
              {!isCatalogueOrderPage && orderActions?.canEdit && (
                <li>
                  <Button
                    className="c-footer__button"
                    fillMode="flat"
                    icon="pencil"
                    onClick={handleEditOrder}
                  >
                    {editOrderLabel}
                    <span className="c-footer__button-label-full u-tablet">
                      {editOrderText}
                    </span>
                  </Button>
                </li>
              )}
            </ul>
          )}
          <div className="c-footer__layout l-mobile-flex u-flex-middle">
            {orderFooterNotEditable?.perc_discount && (
              <div className="c-footer__sale">
                <span>{discount}</span>
                <strong className="c-percentage">
                  {loading ? (
                    <Skeleton />
                  ) : (
                    orderFooterNotEditable?.perc_discount
                  )}
                </strong>
                <strong>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    orderFooterNotEditable?.total_discount
                  )}
                </strong>
              </div>
            )}
            {!isDetailsOrderPages &&
              !isCatalogueOrderPage &&
              selectedOrder?.perc_discount && (
                <div className="c-footer__sale">
                  <span>{discount}</span>
                  <strong className="c-percentage">
                    {loading || loadingIndexWebCustomer ? (
                      <Skeleton />
                    ) : (
                      selectedOrder?.perc_discount
                    )}
                  </strong>
                  <strong>
                    {loading || loadingIndexWebCustomer ? (
                      <Skeleton />
                    ) : (
                      selectedOrder?.total_discount
                    )}
                  </strong>
                </div>
              )}
            <div className="c-footer__total">
              <span>{total}</span>
              <strong>
                {loading || loadingIndexWebCustomer ? (
                  <Skeleton />
                ) : orderFooterNotEditable ? (
                  `${orderFooterNotEditable?.total_value}`
                ) : (
                  selectedOrder?.total_value
                )}
              </strong>
            </div>

            {/* Showed only in editing order */}
            {!loading && isCatalogueOrderPage && orderInfo?.id && (
              <div className="c-footer__act l-mobile-flex u-gap-16">
                {selectedOrder && selectedOrder?.id === orderInfo?.id && (
                  <Button fillMode="outline" onClick={handleModal}>
                    {save}
                  </Button>
                )}
                <Link
                  className="k-button k-button-md"
                  to={
                    orderInfo?.canConfirm
                      ? `/orders/${orderInfo?.id}`
                      : `/orders/pending/${orderInfo?.id}`
                  }
                >
                  {gotoCheckout}
                </Link>
              </div>
            )}

            {/* show everywhere except in editing order or detail's order */}
            {!loading &&
              !loadingIndexWebCustomer &&
              !isDetailsOrderPages &&
              !isCatalogueOrderPage && (
                <Link
                  className="c-footer__order"
                  to={`/orders/${selectedOrder?.id}/edit`}
                >
                  {goto}
                </Link>
              )}

            {/* Showed only in details's order and canCorfirm is true */}
            {!loading && isDetailsOrderPages && orderActions?.canConfirm && (
              <div className="c-footer__act l-mobile-flex u-gap-16">
                <Button
                  disabled={
                    orderHasPromoToSelect && !orderHasPromoToSelect?.selected
                  }
                  fillMode="solid"
                  icon="check"
                  dir="rtl"
                  onClick={handleModalConfirmOrder}
                >
                  {submitOrderLabel}
                </Button>
              </div>
            )}

            {/* Showed only in details's order and canApprove is true */}
            {!loading && isDetailsOrderPages && orderActions?.canApprove && (
              <div className="c-footer__act l-mobile-flex u-gap-16">
                <Button
                  disabled={
                    orderHasPromoToSelect && !orderHasPromoToSelect?.selected
                  }
                  fillMode="solid"
                  icon="check"
                  dir="rtl"
                  onClick={handleModalApproveOrder}
                >
                  {approveOrderLabel}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Modal to save order and unset it */}
      {showModal && (
        <Dialog title={saveConfirmTitle} onClose={handleModal} width={566}>
          {saveConfirmMessage}
          <DialogActionsBar>
            <Button
              disabled={loadingCreateOrder}
              type="submit"
              onClick={unsetOrder}
            >
              {loadingCreateOrder ? saveConfirmingSave : saveConfirmSave}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Modal to confirm order and unset it */}
      {showModalConfirmOrder && (
        <Dialog
          title={confirmTitle}
          onClose={handleModalConfirmOrder}
          width={566}
        >
          {confirmMessageSave}
          <DialogActionsBar>
            <Button disabled={loadingConfirmOrder} onClick={confirmOrderAction}>
              {loadingConfirmOrder ? confirmingSave : confirm}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Modal to approve order */}
      {showModalApproveOrder && (
        <Dialog
          title={approveTitle}
          onClose={handleModalApproveOrder}
          width={566}
        >
          {approveMessageSave}
          <DialogActionsBar>
            <Button disabled={loadingApproveOrder} onClick={approveOrderAction}>
              {loadingApproveOrder ? approvingOrderLabel : approveOrderLabel}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Modal for order approved */}
      {showModalOrderApproved && (
        <Dialog
          className="c-modal-approved"
          title={approvedOrderTitle}
          onClose={handleOrderApproved}
          width={364}
        >
          <div className="c-modal-content c-modal-content--approved">
            <svg
              width={80}
              height={80}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2081_455753)">
                <circle
                  cx={40}
                  cy={40}
                  r="39.5"
                  fill="#D8E8D4"
                  stroke="#D8E8D4"
                />
                <path
                  d="M59.131 31.7096L36.5926 52.5162C36.0099 53.0457 35.2387 53.3384 34.4387 53.3336C33.6505 53.3365 32.892 53.0434 32.3233 52.5162L21.0541 42.1129C20.7415 41.8494 20.4873 41.5277 20.3068 41.1669C20.1262 40.8061 20.023 40.4138 20.0034 40.0136C19.9838 39.6133 20.0482 39.2134 20.1927 38.8377C20.3372 38.4621 20.5588 38.1186 20.8442 37.8278C21.1297 37.5371 21.473 37.3051 21.8536 37.1457C22.2342 36.9864 22.6443 36.9031 23.0591 36.9008C23.4739 36.8985 23.8849 36.9772 24.2674 37.1322C24.6499 37.2872 24.996 37.5154 25.2849 37.8029L34.4387 46.237L54.9003 27.3996C55.4985 26.8954 56.2754 26.6341 57.0685 26.6703C57.8616 26.7066 58.6094 27.0376 59.1558 27.5941C59.7021 28.1506 60.0046 28.8897 60 29.6566C59.9953 30.4236 59.684 31.1592 59.131 31.7096Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2081_455753">
                  <rect width={80} height={80} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <footer className="c-modal-footer u-align-center">
            <DialogActionsBar>
              <Button onClick={handleOrderApproved}>
                {approvedOrderButton}
              </Button>
            </DialogActionsBar>
          </footer>
        </Dialog>
      )}
      {/* Modal to reject order */}
      {showModalRejectOrder && (
        <Dialog
          title={rejectTitle}
          onClose={handleModalRejectOrder}
          width={566}
        >
          <div className="c-form-field c-form-field--textarea">
            <label htmlFor="newNote"></label>
            <TextArea
              name="rejectOrderReasons"
              placeholder={rejectPlaceholder}
              rows={7}
              value={rejectReasons}
              onChange={handleRejectReasons}
            />
          </div>
          <DialogActionsBar>
            <Button
              disabled={rejectReasons.length === 0 || loadingRejectOrder}
              onClick={rejectOrderAction}
            >
              {loadingRejectOrder ? confirmingSave : confirm}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Modal to delete order */}
      {showModalDeleteOrder && (
        <Dialog
          className="c-modal-remove-order"
          title={deleteTitle}
          onClose={handleModalDeleteOrder}
          width={520}
        >
          {deleteMessage}
          <DialogActionsBar>
            <Button disabled={loadingDeleteOrder} onClick={deleteOrderAction}>
              {loadingDeleteOrder ? deletingButton : deleteButton}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Modal to export excel */}
      {showDownloadExcelOrder && (
        <Dialog
          className="c-modal-export-order"
          title={exportModalTitle}
          onClose={() => handleModalDownloadExcelOrder(false)}
          width={520}
        >
          {loadingExportExcelFile ? (
            <Loader className="c-loader" />
          ) : (
            <>
              <p className="c-export-order-text">
                <span className="icon icon-excel-table"></span>
                <span>{exportExcelFile?.name}</span>
              </p>
              <DialogActionsBar>
                <a
                  className="k-button k-button-md"
                  href={exportExcelFile?.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleModalDownloadExcelOrder(false)}
                >
                  <span className="k-icon k-i-download"></span>
                  <span>{exportButtonLabel}</span>
                </a>
              </DialogActionsBar>
              <div
                className="c-modal-message"
                dangerouslySetInnerHTML={{
                  __html: exportExcelFile?.modalMessage,
                }}
              />
            </>
          )}
        </Dialog>
      )}
      {/* Modal to import excel */}
      {showImportExcelOrder && (
        <Dialog
          className="c-modal-import-order"
          title={importModalTitle}
          onClose={handleModalImportExcelOrder}
          width={520}
        >
          <form onSubmit={importFileOrderAction}>
            <MediaUpload
              autoUpload={false}
              batch={false}
              multiple={false}
              allowedExtensions={[".xls", ".xlsx"]}
              showActionButtons={false}
              listItemUI="previewDocument"
              withCredentials={true}
              onAdd={handleAddFile}
              onRemove={handleRemoveFile}
            />
            {importExcelFileResult && (
              <div
                className={
                  importExcelFileResult?.bResult
                    ? "c-import-result c-import-result--valid"
                    : "c-import-result c-import-result--failed"
                }
              >
                {importExcelFileResult?.bResult ? (
                  <span className="k-icon k-i-tick"></span>
                ) : (
                  <span className="k-icon k-i-close"></span>
                )}

                <p
                  className="c-alert-error"
                  dangerouslySetInnerHTML={{
                    __html: importExcelFileResult?.sMessageText,
                  }}
                />
              </div>
            )}
            <Button
              disabled={
                fileExcelToImport && !loadingImportExcelFile ? false : true
              }
              type="submit"
              icon="upload"
            >
              {loadingImportExcelFile
                ? importingButtonLabel
                : importButtonLabel}
            </Button>
          </form>
        </Dialog>
      )}
      {showModalErrorOnConfirmOrder && (
        <Dialog
          className="c-modal-import-order"
          title={errorConfirmOrderModalTitle}
          onClose={handleModalErrorOrder}
          width={520}
        >
          <div
            className="c-modal-import-error"
            dangerouslySetInnerHTML={{ __html: errorOnConfirmOrder }}
          />
          <Button onClick={handleModalErrorOrder}>
            {errorConfirmOrderModalButton}
          </Button>
        </Dialog>
      )}
      {showModalErrorOnApproveOrder && (
        <Dialog
          className="c-modal-import-order"
          title={errorConfirmOrderModalTitle}
          onClose={handleModalErrorOrder}
          width={520}
        >
          <div
            className="c-modal-import-error"
            dangerouslySetInnerHTML={{ __html: errorOnApproveOrder }}
          />
          <Button onClick={handleModalErrorOrder}>
            {errorConfirmOrderModalButton}
          </Button>
        </Dialog>
      )}
    </>
  );
};

export default FooterOrderActive;
