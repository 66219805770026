import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFragrances,
  getFragrancesFilters,
} from "redux/features/catalogue/actions";
import { toast } from "react-toastify";
import { setPagerState } from "redux/features/catalogue";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "./header.scss";

const HeaderProducts = () => {
  const dispatch = useDispatch();

  const authenticated = useSelector((state) => state.user.authenticated);

  const filters = useSelector((state) => state.fragrance.filters);
  const pagerState = useSelector((state) => state.fragrance.pagerState);

  /**
   * getListOfFilter is a callback's hook
   * used to get the list of fragrances
   */
  const getListOfFilter = useCallback(() => {
    if (authenticated && !filters) {
      dispatch(getFragrancesFilters({ toast }));
    }
  }, [authenticated, dispatch, filters]);

  useEffect(() => {
    getListOfFilter();
  }, [getListOfFilter]);

  /**
   * Convert the object of filters into array of filters
   */
  const arrayOfFilters =
    filters && Object.keys(filters).map((key) => filters[key]);

  const handleFilters = useCallback(
    (e) => {
      const newValueToSearch = dispatch(
        setPagerState({
          page: 1,
          [e.target.name]: e.value.key
            ? { key: e.value.key, label: e.value.label }
            : null,
        })
      ).payload;
      const pager = { ...pagerState, ...newValueToSearch };
      dispatch(getFragrances({ toast, pager }));
    },
    [dispatch, pagerState]
  );

  return (
    <header className="c-subheader l-tablet-flex k-align-items-center k-justify-content-between u-bg-beige">
      <h1 className="c-subheader__title t-title t-title--2 k-m-0">Nuovi arrivi</h1>

      <ul
        className="c-subheader__filter c-subheader__filter--catalog l-tablet-flex k-align-items-center"
        style={{ listStyle: "none" }}
      >
        {arrayOfFilters &&
          arrayOfFilters.map((filter) => {
            const options = Object.entries(filter.options).map(
              ([_key, _value]) => {
                return { key: _key, label: _value };
              }
            );

            const DEFAULT_VALUE = {
              key: null,
              label: filter.label,
            };

            return (
              <li key={filter.field} className="c-filter">
                <DropDownList
                  className="c-filter-container"
                  name={filter.field}
                  data={options}
                  fillMode="outline"
                  textField="label"
                  dataItemKey="key"
                  onChange={handleFilters}
                  popupSettings={{
                    popupClass: "c-filter-content",
                    width: 180,
                  }}
                  defaultItem={DEFAULT_VALUE}
                  value={pagerState[filter.field]}
                />
              </li>
            );
          })}
      </ul>
    </header>
  );
};

export default React.memo(HeaderProducts);
