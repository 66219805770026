import React from 'react';
import {
    Administrative,
    Catalog,
    Contacts,
    Customer,
    Customers,
    Home,
    Login,
    Marketing,
    NewArrivals,
    Orders,
    OrdersCatalog,
    OrdersPending,
    OrdersProcessing,
    PasswordChange,
    PasswordRecovery,
    PrintOrder,
    Product,
    Prospects,
    ProspectsDetails,
    ProspectsNew,
    SelectCompany,
    SpecialProducts,
    User
  } from 'pages';

const getElement = (element) => {
    let component;
    switch (element) {
        // Public Routes
        case 'Login':
            component = <Login />
            break;
        case 'passwordchange':
            component = <PasswordChange />
            break;
        case 'passwordrecovery':
            component = <PasswordRecovery />
            break; 
            
        // Private Routes
        case 'selectcompany':
            component = <SelectCompany />
            break;  
        case 'catalog':
            component = <Catalog />
            break;
        case 'product':
            component = <Product />
            break;
        case 'newarrivals':
            component = <NewArrivals />
            break;
        case 'product_new_arrivals':
            component = <Product />
            break;  
        case 'specialproducts':
            component = <SpecialProducts />
            break;  
        case 'customers':
            component = <Customers />
            break;
        case 'customer':
            component = <Customer />
            break; 
        case 'prospects':
            component = <Prospects />
            break;  
        case 'prospectsdetails':
            component = <ProspectsDetails />
            break;
        case 'prospectsnew':
            component = <ProspectsNew />
            break;
        case 'orders':
            component = <Orders />
            break;  
        case 'orderscatalog':
            // View order details
            component = <OrdersCatalog notEditable={true}  />
            break; 
        case 'order_edit':
            component = <OrdersCatalog />
            break; 
        case 'orderspending':
            component = <OrdersPending />
            break;
        case 'orderscatalog_pending':
            // View pending order details
            component = <OrdersCatalog notEditable={true} isPendingOrder={true} />
            break; 
        case 'order_pending_edit':
            component = <OrdersCatalog isPendingOrder={true}/>
            break;  
        case 'ordersprocessing':
            component = <OrdersProcessing />
            break; 
        case 'orderscatalog_processing':
            // View processing order details
            component = <OrdersCatalog notEditable={true} isProcessingOrder={true} />
            break;                                         
        case 'administrative':
            component = <Administrative />
            break;  
        case 'marketing':
            component = <Marketing />
            break;
        case 'home':
            component = <Home />
            break;
        case 'contacts':
            component = <Contacts />
            break;
        case 'user':
            component = <User />
            break;  
        case 'print_order':
            component = <PrintOrder />
            break;                                      
        default:
            break;
    }
    return component;
}

export default getElement;