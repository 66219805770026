import React, { useMemo } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplateMultipleItems from "./tooltip.Template";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    multiplierErrorLabel: toLanguageString(
      "metislab.frontend.components.cart.multiplierErrorLabel",
      defaultMessages.metislab.frontend.components.cart.multiplierErrorLabel
    ),
    multiplierNotAvailableLabel: toLanguageString(
      "metislab.frontend.components.cart.multiplierNotAvailableLabel",
      defaultMessages.metislab.frontend.components.cart
        .multiplierNotAvailableLabel
    ),
    multiplierSuccessLabel: toLanguageString(
      "metislab.frontend.components.cart.multiplierSuccessLabel",
      defaultMessages.metislab.frontend.components.cart.multiplierSuccessLabel
    ),
    multiplierWarningLabel: toLanguageString(
      "metislab.frontend.components.cart.multiplierWarningLabel",
      defaultMessages.metislab.frontend.components.cart.multiplierWarningLabel
    ),
  };
};

const MultipleItems = ({ currentQty, shippingMethod, qty }) => {
  const {
    multiplierErrorLabel,
    multiplierNotAvailableLabel,
    multiplierSuccessLabel,
    multiplierWarningLabel,
  } = useLocalizedMessages();

  /**
   * pointerEvents is an hook used to handle the behaviour of
   * the tooltip
   */
  const pointerEvents = useMemo(() => {
    if (qty === null) {
      return "none";
    }

    if (qty !== 0 && !currentQty) {
      return "none";
    }

    return "auto";
  }, [currentQty, qty]);

  /**
   * status is an hook to handle the variations of CSS class
   */
  const statusCssClassVariation = useMemo(() => {
    if (qty === 0) {
      return "error";
    }

    if (!currentQty || !qty) {
      return "disabled";
    }

    if (currentQty % qty !== 0) {
      return "warning";
    }

    return "success";
  }, [currentQty, qty]);

  /**
   * status is an hook to handle the variations of CSS class
   */
  const tooltipMessage = useMemo(() => {
    if (qty === null) {
      return "";
    }

    if (qty === 0) {
      return `${multiplierErrorLabel} ${shippingMethod}`;
    }

    if (currentQty % qty !== 0) {
      return `${multiplierWarningLabel} ${qty}`;
    }

    return `${multiplierSuccessLabel} ${qty}`;
  }, [
    currentQty,
    qty,
    multiplierErrorLabel,
    multiplierWarningLabel,
    multiplierSuccessLabel,
    shippingMethod,
  ]);

  return (
    <Tooltip
      className="c-tooltip-box"
      anchorElement="target"
      content={(props) => <TooltipTemplateMultipleItems msg={props.title} />}
    >
      <div
        className="c-status-tooltip"
        title={tooltipMessage}
        style={{
          gap: 4,
          pointerEvents,
        }}
      ></div>
      <div
        className={`l-mobile-flex u-flex-middle u-flex-center c-status c-status--${statusCssClassVariation}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00001 1L14.5 4.11259V11.8874L8 15L1.5 11.8874V4.1126L8.00001 1ZM3.11804 4.40855L8 6.74633L9.78404 5.89202L5.00751 3.50375L3.11804 4.40855ZM12.882 4.40855L11.4441 5.09707L6.6676 2.7088L8.00001 2.07076L12.882 4.40855ZM2.5 5.18336V11.2955L7.5 13.6898V7.57766L2.5 5.18336ZM8.5 7.57766V13.6898L13.5 11.2955V5.18336L11.75 6.02136V8.25L10.25 9V6.73966L8.5 7.57766Z"
            fill="#A7A7A7"
          />
        </svg>
        {qty === null && <span>{multiplierNotAvailableLabel}</span>}
        {qty === 0 && (
          <span className="icon icon-alert" style={{ color: "red" }} />
        )}
        {qty !== null && qty > 0 && <span>{qty}</span>}
      </div>
    </Tooltip>
  );
};

export default MultipleItems;
