import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { 
  getCustomerAddresses,
  getCustomerContacts, 
  getCustomerDetails,
} from "redux/features/customer/actions";
import { toast } from 'react-toastify';
import CustomerDetails from "./components/details";

const Customer = () => {

  const { id } = useParams();

  const authenticated = useSelector( (state) => state.user.authenticated );
  const customerId = useSelector( (state) => state.user.customerId );

  const dispatch = useDispatch();

  /**
   * isFirstRender is a ref's hook 
   * used to run once the call to the endpoints
   */
  const isFirstRender = useRef(true);

  const getDetails = useCallback( () => {
    if (authenticated  && isFirstRender.current) {
      const args = {
        id: id === undefined ? customerId : id,
        toast
      }
      dispatch(getCustomerDetails(args))
        .then( () => dispatch(getCustomerAddresses({ toast })) )
        .then( () => dispatch(getCustomerContacts({ toast })) );
      isFirstRender.current = false;
    }
  }, [authenticated, customerId, dispatch, id])

  useEffect(() => getDetails(), [getDetails]);


  return <CustomerDetails id={ id }/>
}

export default Customer;