import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplate from "./tooltipTemplate";
import "./cell-id-order.scss";

const CellIDOrder = ({ code, gridTooltip }) => {
  return (
    <td
      colSpan="1"
      className="c-list-orders__id-order"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="5"
      style={{}}
    >
      {gridTooltip?.length > 0 && (
        <Tooltip
          className="c-tooltip-box u-width-fit"
          anchorElement="target"
          content={(props) => <TooltipTemplate item={props.title} />}
        >
          <span title={gridTooltip} className=" k-icon k-i-info"></span>
        </Tooltip>
      )}
      <span>{code}</span>
    </td>
  );
};

CellIDOrder.propTypes = {
  code: PropTypes.string,
  gridTooltip: PropTypes.string,
};

export default CellIDOrder;
