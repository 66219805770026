import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "redux/features/user";
import "./App.scss";
import "./components/typography/typography.scss";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./routes";
import BroadcastChannelComponent from "components/BroadcastChannel";
import { setupLocalization, I18NProvider } from './i18n';

function App() {

  const dispatch = useDispatch();

  const language = useSelector( (state) => state.user.language);

  const [labels, setLabels] = useState(null);
  
  const localLanguage = JSON.parse(localStorage.getItem('lang'));
  
  const getLabels = useCallback( async() => {
    const urlEn = '/i18n/en.json';
    const urlIt = '/i18n/it.json';

    const responses = await Promise.all([fetch(urlEn), fetch(urlIt)]);

    const labelsEn = await responses[0].json();
    const labelsIt = await responses[1].json();

    setLabels({labelsEn, labelsIt});

  }, [])

  useEffect( () => {
    if (!labels) {    
     getLabels();
    } else {
      setupLocalization(labels.labelsEn, labels.labelsIt);  
    }
    
  }, [getLabels, labels]);
  
  
  useEffect(() => {
    if (!language && !localLanguage) {
      window.navigator.language.includes('it')
        ? dispatch(setLanguage('it-IT'))
        : dispatch(setLanguage('en-US'))
    }
    
  }, [dispatch, language, localLanguage]);
  
  return (
    <I18NProvider lang={language || localLanguage} >
      <BroadcastChannelComponent>
        <BrowserRouter>
          <ToastContainer
            autoClose={2000}
            closeButton={false}
            hideProgressBar={true}
            icon={false}
            limit={1}
            position="bottom-right"
          />
          <AppRoutes />
        </BrowserRouter>
      </BroadcastChannelComponent>
    </I18NProvider>
  );
}

export default App;
