import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAuth, getLoggedUser, logout, getPublicRoutes } from 'redux/features/user/actions';
import { toast } from "react-toastify";

export const useLoadingRecoveryData = () => {
  
  const authenticated = useSelector( (state) => state.user.authenticated);
  const loadingRecoveryData = useSelector( (state) => state.user.loadingRecoveryData);
  const routes = useSelector( (state) => state.user.routes);
  const token = useSelector( (state) => state.user.token);
    
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  /**
   * isFirstRender is a ref's hook 
   * used to run once the call to the endpoints
   */
  const isFirstRender = useRef(true);
  
  useEffect(() => {

    if (!authenticated && !token && routes.length === 0) {
      dispatch(getPublicRoutes({ toast }));
    }

    if ( !authenticated && token ) {
      dispatch( checkAuth() )
        .then( ({payload}) => {
          if (!payload?.authenticated) {
            dispatch(logout({ navigate }));
          }
        });
    }
    
    if ( isFirstRender.current && authenticated ) {
      dispatch( getLoggedUser() )
        .then( () => {       
          
          /**
           * It used to navigate and open the notes of the order
          */
          const url= new URL(window.location.href);

          if (url?.href.includes('order')) {
            const parameterMap = new Map(url.searchParams);        
            const showNotes = parameterMap.get('showNotes');
  
            navigate(location.pathname, { state: {showNotes: !!showNotes}});         
          }
        });
      isFirstRender.current = false;
    }
    
  // eslint-disable-next-line
  }, [authenticated, dispatch, navigate, token]);

    return [authenticated, loadingRecoveryData];
}