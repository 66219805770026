import React from "react";
import { useSelector } from "react-redux";
import { GridLayout } from "@progress/kendo-react-layout";
import Contact from "./contact";
import Loader from "components/loader";
import "./tabContacts.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    noContactLabel: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.noContactLabel',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.noContactLabel),
  };
}

const TabContacts = () => {

  const {
    noContactLabel,
  } = useLocalizedMessages();

  const loading = useSelector((state) => state.customer.loading);
  const customerContacts = useSelector((state) => state.customer.customerContacts);

  if (loading) {
    return (
      <Loader className="c-loader--center c-loader--absolute" size="medium" />
    );
  }

  return (
    <GridLayout className="l-tablet-grid u-grid-3 u-gap-24">
      {customerContacts?.length > 0 ? (
        customerContacts.map((contact, index) => {
          return <Contact key={index} contact={contact} />;
        })
      ) : (
        <p>{noContactLabel}</p>
      )}
    </GridLayout>
  );
};

export default TabContacts;
