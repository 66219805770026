import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditOrderHeaderFields,
  setHeaderCurrentOrder,
} from "redux/features/order/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Formik } from "formik";
import FormElement from "./FormElement";
import Loader from "components/loader";
import { toast } from "react-toastify";
import "./new-order.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    saveHeaderChanges: toLanguageString(
      'metislab.frontend.components.order.components.header.saveHeaderChanges',
      defaultMessages.metislab.frontend.components.order.components.header.saveHeaderChanges),  
    saveHeaderChangesInProgress: toLanguageString(
      'metislab.frontend.components.order.components.header.saveHeaderChangesInProgress',
      defaultMessages.metislab.frontend.components.order.components.header.saveHeaderChangesInProgress),  
      
    editOrderHeaderSuccess: toLanguageString(
      'metislab.frontend.components.notification.components.order.editOrderHeaderSuccess',
      defaultMessages.metislab.frontend.components.notification.components.order.editOrderHeaderSuccess),  
  
  };
}

const FormEditHeaderOrder = ({handleModal}) => {
  const {
    saveHeaderChanges,
    saveHeaderChangesInProgress,
    editOrderHeaderSuccess,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /**
   * Order states
   */
  const loadingNewOrderHeaderFields = useSelector((state) => state.order.loadingNewOrderHeaderFields);
  const orderHeaderActive = useSelector((state) => state.order.orderHeaderActive);
  const loadingEditHeaderOrder = useSelector((state) => state.order.loadingEditHeaderOrder);
  const orderInfo = useSelector((state) => state.order.orderInfo);

  /**
   * User states
   */
  const company = useSelector((state) => state.user.company);
  const selectedCustomer = useSelector((state) => state.user.selectedCustomer);
  
  useEffect(() => {
    const args = {
      id: orderInfo?.id,
      toast
    }
      dispatch(getEditOrderHeaderFields(args));
  }, [dispatch, orderInfo]);
  
  /**
   * INITIAL_FIELD_VALUES are the initial values of the input's elements
   * present in the form for the creation of a new order's header
   */
  const INITIAL_FIELD_VALUES =
  orderHeaderActive &&
    orderHeaderActive.reduce((accumulator, currentValue) => {
      const { 
        attribute,
        component,
        data,
        defaulValue,
        value, 
        visible 
      } = currentValue;
      
      if (visible) {
        if (component === 'select-field') {

          // If a value exists, select it
          if (value?.length > 0) {
            const selectValue = data.rows.filter( row => row.value === value)[0];
            accumulator[attribute] = selectValue;
          } else {

            // If a value doesn't exists but defaulValue exists, select it
            if (defaulValue?.length > 0) {
              const selectValue = data.rows.filter( row => row.value === defaulValue)[0];
              accumulator[attribute] = selectValue;
            } else {
              accumulator[attribute] = value;
            }
          }        
        } else {
          accumulator[attribute] = value;
        }
      }
      return accumulator;
    }, {});

  return loadingNewOrderHeaderFields ? (
    <Loader className="c-loader--center c-loader--center-modal" />
  ) : (
    <Formik
      initialValues={INITIAL_FIELD_VALUES}
      onSubmit={(values) => {
        const { delivery_date, customer_order_date, ...other_values } = values;

        // Date conversion for customer order date
        let customer_order_date_iso_string;

        if (customer_order_date) {
          const date = new Date(customer_order_date);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          customer_order_date_iso_string = `${month}/${day}/${year}`
        } else {
          customer_order_date_iso_string = ""
        }

        // Date conversion for customer delivery date
        let delivery_date_iso_string;

        if (delivery_date) {
          const date = new Date(delivery_date);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          delivery_date_iso_string = `${month}/${day}/${year}`
        } else {
          delivery_date_iso_string = ""
        }


        const data = {
          company: company?.key,
          customer: selectedCustomer?.code || orderInfo?.customer_code,
          customer_order_date: customer_order_date_iso_string,
          delivery_date: delivery_date_iso_string,
          ...other_values,
        };
        
        const order = { order: data };

          const args = {
            toast,
            id: orderInfo?.id,
            data: order,
            messageNotification: editOrderHeaderSuccess
          };
          dispatch( setHeaderCurrentOrder(args))
            .then( () => {
              handleModal();
              window.location.reload();
            });
      }}
    >
      {(props) => {
        const { values, handleBlur, handleSubmit, setValues } = props;

        const handleOnChange = (e) => {
          const fieldName = e.target.name;
          if (fieldName === 'delivery_date') {
            const daySelected = new Date(e.value);

            // Check if the day selected is a disable day
            const { disabledDays } = orderHeaderActive.find( field => field.attribute === fieldName);
            const disabledDaysConverted = disabledDays.map( day => {
              const dayToConvert = new Intl.DateTimeFormat('en-US').format(new Date(day));
              return dayToConvert;
            })
            const daySelectedConverted = new Intl.DateTimeFormat('en-US').format(daySelected);
            const isDisabledDay = disabledDaysConverted.includes(daySelectedConverted);

            if( !isDisabledDay && (daySelected.getDay() !== 0 && daySelected.getDay() !== 6)) {
              setValues({...values, [fieldName]: e.value})
            }
          } else {
            setValues({...values, [fieldName]: e.value})
          }      
        }

        return (
          <form onSubmit={handleSubmit}>
            {orderHeaderActive &&
              orderHeaderActive.map(
                (field) =>
                  field?.visible &&
                  field?.attribute !== "note" && (
                    <FormElement
                      key={field?.attribute}
                      field={field}
                      required={field.mandatory ?? false}
                      value={values}
                      onBlur={handleBlur}
                      onChange={handleOnChange}
                    />
                  )
              )}
            <Button
              disabled={loadingEditHeaderOrder}
              className="c-new-order-modal__button"
              icon={loadingEditHeaderOrder ? null : "tick"}
              dir="rtl"
              type="submit"
            >
              {loadingEditHeaderOrder
                ? saveHeaderChangesInProgress
                : saveHeaderChanges
              }
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormEditHeaderOrder;
