import React from "react";
import PropTypes from "prop-types";
import {
  Calendar,
  CalendarCell,
  DatePicker,
} from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplate from "../../accordion/tooltipTemplate";
import { useCheckIsMobile, formatDateByLanguage } from "utils";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    changeDefaultValue: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.changeDefaultValue",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.changeDefaultValue
    ),
    customerOrderNumberPlaceholder: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.customerOrderNumberPlaceholder",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.customerOrderNumberPlaceholder
    ),
    datePickerPlaceholderCustomerOrder: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.datePickerPlaceholderCustomerOrder",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.datePickerPlaceholderCustomerOrder
    ),
    datePickerPlaceholderDeliveryDate: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.datePickerPlaceholderDeliveryDate",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.datePickerPlaceholderDeliveryDate
    ),
    datePickerPlaceholderDeliveryDateLabel: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.datePickerPlaceholderDeliveryDateLabel",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.datePickerPlaceholderDeliveryDateLabel
    ),
    datePickerPlaceholderDeliveryDateLabelStrong: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.datePickerPlaceholderDeliveryDateLabelStrong",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.datePickerPlaceholderDeliveryDateLabelStrong
    ),
    discountPlaceholder: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.discountPlaceholder",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.discountPlaceholder
    ),
    dropDownListLabelDeliveryAddress: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.dropDownListLabelDeliveryAddress",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.dropDownListLabelDeliveryAddress
    ),
    dropDownListLabelDeliveryMode: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.dropDownListLabelDeliveryMode",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.dropDownListLabelDeliveryMode
    ),
    dropDownListLabelDeliveryType: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.dropDownListLabelDeliveryType",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.dropDownListLabelDeliveryType
    ),
    dropDownListLabelPayMode: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.dropDownListLabelPayMode",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.dropDownListLabelPayMode
    ),
    messageInputRequired: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.messageInputRequired",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.messageInputRequired
    ),
    messageSelectRequired: toLanguageString(
      "metislab.frontend.components.order.components.header.modalOrderHeader.messageSelectRequired",
      defaultMessages.metislab.frontend.components.order.components.header
        .modalOrderHeader.messageSelectRequired
    ),
  };
};

const FormElement = (props) => {
  const {
    changeDefaultValue,
    customerOrderNumberPlaceholder,
    datePickerPlaceholderCustomerOrder,
    datePickerPlaceholderDeliveryDate,
    datePickerPlaceholderDeliveryDateLabel,
    datePickerPlaceholderDeliveryDateLabelStrong,
    discountPlaceholder,
    dropDownListLabelDeliveryAddress,
    dropDownListLabelDeliveryMode,
    dropDownListLabelDeliveryType,
    dropDownListLabelPayMode,
    messageInputRequired,
    messageSelectRequired,
  } = useLocalizedMessages();

  const { field, value, onBlur, onChange , required} = props;

  const intl = useInternationalization();

  const PLACEHOLDER_INPUTS = {
    customer_order_number: customerOrderNumberPlaceholder,
    discount: discountPlaceholder,
  };

  const DEFAULT_VALUES_DROPDOWN = {
    delivery_address: {
      label: dropDownListLabelDeliveryAddress,
      value: null,
    },
    delivery_mode: {
      label: dropDownListLabelDeliveryMode,
      value: null,
    },
    delivery_type: {
      label: dropDownListLabelDeliveryType,
      value: null,
    },
    pay_mode: {
      label: dropDownListLabelPayMode,
      value: null,
    },
  };

  const isMobile = useCheckIsMobile();

  const customCell = (props) => {
    const dateOfCell = new Date(Date.parse(props?.value)).toDateString();

    const datesDisabled = field?.disabledDays.map((day) => {
      return new Date(Date.parse(day)).toDateString();
    });

    const className =
      props.isWeekend || datesDisabled.includes(dateOfCell)
        ? "c-day--disable"
        : "";

    return <CalendarCell {...props} className={`c-day ${className}`} />;
  };

  const calendar = (props) => <Calendar {...props} cell={customCell} />;

  let element;

  const isDisabled = !field.hasOwnProperty("editable")
    ? false
    : field?.editable
    ? false
    : true;

  switch (field?.component) {
    case "date-field":
      const defaultDate = value[field?.attribute]
        ? new Date(value[field?.attribute])
        : null;
      element = (
        <DatePicker
          disabled={isDisabled}
          format={formatDateByLanguage(intl.locale)}
          name={field?.attribute}
          min={
            field.startDate !== undefined
              ? new Date(field.startDate)
              : new Date(null)
          }
          required={required}
          placeholder={
            field?.attribute === "customer_order_date"
              ? datePickerPlaceholderCustomerOrder
              : datePickerPlaceholderDeliveryDate
          }
          onChange={onChange}
          value={defaultDate}
          calendar={
            field?.attribute === "customer_order_date" ? null : calendar
          }
          validationMessage={messageInputRequired}
        />
      );
      break;
    case "select-field":
      const values = field?.data?.rows;
      element = (
        <DropDownList
          disabled={isDisabled}
          name={field?.attribute}
          data={values}
          textField="label"
          dataItemKey="value"
          required={required}
          label={ value[field?.attribute]? '': DEFAULT_VALUES_DROPDOWN[field?.attribute].label}
          defaultValue={
            value[field?.attribute]
          }
          onChange={onChange}
          validationMessage={messageSelectRequired}
        />
      );
      break;
    case "checkbox-field":
      element = (
        <Checkbox
          className="u-reverse"
          name={field?.attribute}
          required={required}
          defaultChecked={field?.defaulValue}
          label={field?.name}
          onChange={onChange}
        />
      );
      break;
    default:
      element = (
        <Input
          required={required}
          disabled={isDisabled}
          maxLength={field?.maxLength || 524288}
          name={field?.attribute}
          placeholder={PLACEHOLDER_INPUTS[field?.attribute]}
          value={value[field?.attribute] || ""}
          onBlur={onBlur}
          onChange={onChange}
          validationMessage={messageInputRequired}
        />
      );
      break;
  }
  return (
    <div className="c-form-field">
      <div className="l-mobile-flex u-flex-between u-flex-middle">
        {field?.component !== "checkbox-field" && (
          <label
            htmlFor={field?.attribute}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {field?.name}
            {field?.defaulValue?.length > 0 &&
              field?.value !== field?.defaulValue && (
                <Tooltip
                  className="c-tooltip-box"
                  anchorElement="target"
                  position={isMobile ? "left" : "right"}
                  content={(props) => <TooltipTemplate item={props.title} />}
                  style={{ zIndex: 20000 }}
                >
                  <span
                    title={JSON.stringify(field?.defaulValue)}
                    className=" k-icon k-i-warning"
                  ></span>
                </Tooltip>
              )}
          </label>
        )}
        {field?.defaulValue?.length > 0 &&
          field?.value !== field?.defaulValue && (
            <span className="c-form-field__status">({changeDefaultValue})</span>
          )}
      </div>
      {element}
      {field?.attribute === "delivery_date" && (
        <p className="c-text-alert l-mobile-flex">
          <span className="k-icon k-i-warning"></span>
          <span>
            <strong>{datePickerPlaceholderDeliveryDateLabelStrong}</strong>
            {datePickerPlaceholderDeliveryDateLabel}
          </span>
        </p>
      )}
    </div>
  );
};

FormElement.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default FormElement;
