import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordFromLoggedUser } from "redux/features/user/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { StackLayout } from "@progress/kendo-react-layout";
import PasswordStrengthBar from "react-password-strength-bar";
import Header from "../header";
import "./userSettingsEdit.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    cancelButtonLabel: toLanguageString(
      'metislab.frontend.components.user.components.header.cancelButtonLabel',
      defaultMessages.metislab.frontend.components.user.components.header.cancelButtonLabel),
    passwordNotEqual: toLanguageString(
      'metislab.frontend.components.passwordChange.components.passwordNotEqual',
      defaultMessages.metislab.frontend.components.passwordChange.components.passwordNotEqual),
    saveButtonLabel: toLanguageString(
      'metislab.frontend.components.user.components.header.saveButtonLabel',
      defaultMessages.metislab.frontend.components.user.components.header.saveButtonLabel), 
    savingButtonLabel: toLanguageString(
      'metislab.frontend.components.user.components.header.savingButtonLabel',
      defaultMessages.metislab.frontend.components.user.components.header.savingButtonLabel),     
    title: toLanguageString(
      'metislab.frontend.components.user.components.header.title',
      defaultMessages.metislab.frontend.components.user.components.header.title),
  
    accessInfo: toLanguageString(
      'metislab.frontend.components.user.components.titleSection.accessInfo',
      defaultMessages.metislab.frontend.components.user.components.titleSection.accessInfo),
    
    currentPasswordLabel: toLanguageString(
      'metislab.frontend.components.user.components.section.password.currentPasswordLabel',
      defaultMessages.metislab.frontend.components.user.components.section.password.currentPasswordLabel),
    newPasswordLabel: toLanguageString(
      'metislab.frontend.components.user.components.section.password.newPasswordLabel',
      defaultMessages.metislab.frontend.components.user.components.section.password.newPasswordLabel),   
    confirmNewPasswordLabel: toLanguageString(
      'metislab.frontend.components.user.components.section.password.confirmNewPasswordLabel',
      defaultMessages.metislab.frontend.components.user.components.section.password.confirmNewPasswordLabel),       
    currentPasswordPlaceholder: toLanguageString(
      'metislab.frontend.components.user.components.section.password.currentPasswordPlaceholder',
      defaultMessages.metislab.frontend.components.user.components.section.password.currentPasswordPlaceholder),
    newPasswordPlaceholder: toLanguageString(
      'metislab.frontend.components.user.components.section.password.newPasswordPlaceholder',
      defaultMessages.metislab.frontend.components.user.components.section.password.newPasswordPlaceholder),   
    confirmNewPasswordPlaceholder: toLanguageString(
      'metislab.frontend.components.user.components.section.password.confirmNewPasswordPlaceholder',
      defaultMessages.metislab.frontend.components.user.components.section.password.confirmNewPasswordPlaceholder),       
    changePasswordSuccess: toLanguageString(
      'metislab.frontend.components.user.components.section.password.changePasswordSuccess',
      defaultMessages.metislab.frontend.components.user.components.section.password.changePasswordSuccess),     
    requiredPasswordMessage: toLanguageString(
      'metislab.frontend.components.user.components.section.password.requiredPasswordMessage',
      defaultMessages.metislab.frontend.components.user.components.section.password.requiredPasswordMessage),   
    requiredNewPasswordMessage: toLanguageString(
      'metislab.frontend.components.user.components.section.password.requiredNewPasswordMessage',
      defaultMessages.metislab.frontend.components.user.components.section.password.requiredNewPasswordMessage),       
    requiredConfirmNewPasswordMessage: toLanguageString(
      'metislab.frontend.components.user.components.section.password.requiredConfirmNewPasswordMessage',
      defaultMessages.metislab.frontend.components.user.components.section.password.requiredConfirmNewPasswordMessage),       
    
       
  };
}

const UserSettingsEdit = ({ handleEdit }) => {

  const {
    cancelButtonLabel,
    passwordNotEqual,
    saveButtonLabel,
    savingButtonLabel,
    title,

    accessInfo,
    currentPasswordLabel,
    newPasswordLabel,
    confirmNewPasswordLabel,
    currentPasswordPlaceholder,
    newPasswordPlaceholder,
    confirmNewPasswordPlaceholder,
    changePasswordSuccess,

    requiredPasswordMessage,
    requiredNewPasswordMessage,
    requiredConfirmNewPasswordMessage,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const initialVisibilityPassword = {
    password: false,
    newPassword: false,
    checkNewPassword: false,
  };
  
  const userValidationSchema = Yup.object().shape({
    password: Yup.string().required(requiredPasswordMessage).nullable(),
    newPassword: Yup.string().required(requiredNewPasswordMessage).nullable(),
    checkNewPassword: Yup.string().required(requiredConfirmNewPasswordMessage).nullable().test("isEqual", passwordNotEqual, (value, testContext) => {
      return testContext.parent.newPassword === value
    }),
  });

  const initialValues = {
    password: "",
    newPassword: "",
    checkNewPassword: "",
  }
  
  const loadingChangePassword = useSelector((state) => state.user.loadingChangePassword);

  const [passwordVisible, setPasswordVisible] = useState(initialVisibilityPassword);
  const { password, newPassword, checkNewPassword } = passwordVisible;

  const togglePasswordVisibility = (e, input) => {
    e.preventDefault();
    input === "password"
      ? setPasswordVisible({ ...passwordVisible, password: !password })
      : input === "newPassword"
        ? setPasswordVisible({ ...passwordVisible, newPassword: !newPassword })
        : setPasswordVisible({ ...passwordVisible, checkNewPassword: !checkNewPassword });
  };
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const args = {
          data: {
            current_password: values?.password,
            password: values?.newPassword,
            password_confirmation: values?.checkNewPassword,
          },
          toast,
          messageNotification: changePasswordSuccess
      }
        dispatch(changePasswordFromLoggedUser(args))
          .then( (res) => {
            !res?.meta?.hasOwnProperty('rejectedWithValue') && handleEdit();
          });
      }}
      validationSchema={userValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        
        return (
          <form
            className="c-user-setting c-user-setting--edit"
            onSubmit={handleSubmit}
          >
            <Header className="c-subheader l-tablet-flex u-flex-between u-flex-middle">
              <h1 className="c-user-setting__title t-title t-title--2 l-mobile-flex u-flex-middle">
                <span className="icon icon-user"></span>
                {title}
              </h1>
              <div className="l-tablet-flex u-gap-16">
                <Button
                  className="c-user-setting__button"
                  fillMode="outline"
                  onClick={handleEdit}
                >
                  {cancelButtonLabel}
                  <span className="k-icon k-i-close"></span>
                </Button>
                <Button
                  className="c-user-setting__button"
                  fillMode="solid"
                  type="submit"
                  disabled={
                    loadingChangePassword 
                    || values?.password.length === 0 || values?.newPassword.length === 0 || values?.checkNewPassword.length === 0
                    || values?.newPassword !== values?.checkNewPassword
                  }
                >
                  {loadingChangePassword
                    ? savingButtonLabel
                    : saveButtonLabel
                  }
                  <span className="k-icon k-i-tick"></span>
                </Button>
              </div>
            </Header>
            <StackLayout
              className="c-user-setting__content"
              orientation="vertical"
            >
              <div className="c-user-setting__item">
                <h2 className="c-user-setting__heading t-title t-title--3">
                  {accessInfo}
                </h2>
                <ul className="c-user-setting__list l-tablet-grid u-grid-3 k-mt-4">
                  <li className="c-form-field">
                    <label htmlFor="password">{currentPasswordLabel}</label>
                    <Input
                      type={password ? "text" : "password"}
                      name={"password"}
                      autoComplete="true"
                      placeholder={currentPasswordPlaceholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.password}
                      className={
                        errors.password && touched.password
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <Button
                      icon="preview"
                      className="c-show-pw"
                      fillMode="flat"
                      size="small"
                      onClick={(e) => togglePasswordVisibility(e, "password")}
                    />
                    {errors.password && touched.password && (
                      <div className="input-feedback">{errors.password}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="newPassword">{newPasswordLabel}</label>
                    <Input
                      type={newPassword ? "text" : "password"}
                      name={"newPassword"}
                      autoComplete="true"
                      placeholder={newPasswordPlaceholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.newPassword}
                      className={
                        errors.newPassword && touched.newPassword
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <Button
                      className="c-show-pw"
                      icon="preview"
                      fillMode="flat"
                      size="small"
                      onClick={(e) =>
                        togglePasswordVisibility(e, "newPassword")
                      }
                    />
                    {values.newPassword && (
                      <PasswordStrengthBar password={values.newPassword} />
                    )}
                    {errors.newPassword && touched.newPassword && (
                      <div className="input-feedback">{errors.newPassword}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="newPassword">{confirmNewPasswordLabel}</label>
                    <Input
                      type={checkNewPassword ? "text" : "password"}
                      name={"checkNewPassword"}
                      autoComplete="true"
                      placeholder={confirmNewPasswordPlaceholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.checkNewPassword}
                      className={
                        errors.checkNewPassword && touched.checkNewPassword
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    <Button
                      className="c-show-pw"
                      icon="preview"
                      fillMode="flat"
                      size="small"
                      onClick={(e) =>
                        togglePasswordVisibility(e, "checkNewPassword")
                      }
                    />
                    {values.checkNewPassword && (
                      <PasswordStrengthBar password={values.checkNewPassword} />
                    )}
                    {errors.checkNewPassword && touched.checkNewPassword && (
                      <div className="input-feedback">{errors.checkNewPassword}</div>
                    )}
                  </li>
                </ul>
              </div>
            </StackLayout>
          </form>
        );
      }}
    </Formik>
  );
};

export default UserSettingsEdit;
