import React from "react";
import PropTypes from "prop-types";
import { Upload } from "@progress/kendo-react-upload";
import PreviewDocument from "./previewDocument";
import PreviewImage from "./previewImage";
import "./upload.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    loadFileLabel: toLanguageString(
      "upload.loadFileLabel",
      defaultMessages.upload.loadFileLabel
    ),
  };
};

const MediaUpload = (props) => {
  const { loadFileLabel } = useLocalizedMessages();

  const {
    autoUpload = false,
    batch = false,
    multiple = false,
    allowedExtensions = [],
    maxFileSize,
    showActionButtons = false,
    actionsLayout = "start",
    listItemUI = "previewDocument",
    withCredentials = false,
    onAdd,
    onRemove,
  } = props;

  return (
    <Upload
      autoUpload={autoUpload}
      batch={batch}
      multiple={multiple}
      restrictions={{
        allowedExtensions,
        maxFileSize: maxFileSize,
      }}
      defaultFiles={[]}
      selectMessageUI={() => `${loadFileLabel}`}
      showActionButtons={showActionButtons}
      actionsLayout={actionsLayout}
      listItemUI={
        listItemUI === "previewDocument" ? PreviewDocument : PreviewImage
      }
      withCredentials={withCredentials}
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
};

MediaUpload.propTypes = {
  autoUpload: PropTypes.bool,
  batch: PropTypes.bool,
  multiple: PropTypes.bool,
  allowedExtensions: PropTypes.array,
  showActionButtons: PropTypes.bool,
  actionsLayout: PropTypes.oneOf(["start", "center", "end", "stretched"]),
  listItemUI: PropTypes.string,
  withCredentials: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

export default MediaUpload;
