import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastOrders } from "redux/features/home/actions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Skeleton } from "@progress/kendo-react-indicators";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";

import { formatDateTimeByLanguage } from "utils";
import EmptySearch from "components/emptySearch";
import "./last-orders.scss";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    amountLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.amountLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.amountLabel
    ),
    customerLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.customerLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.customerLabel
    ),
    detailsLinkLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.detailsLinkLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.detailsLinkLabel
    ),
    dateLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.dateLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.dateLabel
    ),
    linkLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.linkLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.linkLabel
    ),
    noOrders: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.noOrders",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.noOrders
    ),
    orderNumberLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.orderNumberLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.orderNumberLabel
    ),
    title: toLanguageString(
        "metislab.frontend.components.homepage.components.lastOrders.title",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastOrders.title
    ),
  };
};

const LastOrders = () => {
  const {
    amountLabel,
    customerLabel,
    detailsLinkLabel,
    dateLabel,
    linkLabel,
    noOrders,
    orderNumberLabel,
    title,
  } = useLocalizedMessages();

  const intl = useInternationalization();

  const dispatch = useDispatch();

  /**
   * IndexWebCustomer states
   */
  const loadingCompany = useSelector((state) => state.indexWebCustomer.loading);
  const selectedCompany = useSelector(
      (state) => state.indexWebCustomer.selectedCompany
  );
  const companyCustomers = useSelector(
      (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
      (state) => state.indexWebCustomer.loading
  );

  /**
   * Homepage states
   */
  const orderView = useSelector((state) => state.home.orderPermissions.orderView);
  const lastOrders = useSelector((state) => state.home.lastOrders);
  const loadingLastOrder = useSelector((state) => state.home.loadingLastOrder);

  /**
   * User states
   */
  const loadingSetCustomer = useSelector((state) => state.user.loading);

  /**
   * getLastOrderAndDocuments is a callback's hook
   * used to get the list of the last orders and documents
   */
  const getLastOrdersList = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      dispatch(getLastOrders({ toast }));
    }
  }, [companyCustomers, dispatch, selectedCompany]);

  useEffect(() => {
    getLastOrdersList();
  }, [getLastOrdersList]);

  return (
      orderView  ? (<div className="c-table-list-container">
        <header className="c-table-list-container__header l-tablet-flex u-flex-middle u-flex-between">
          <h2 className="t-title t-title--2">{title}</h2>
          <Link
              className="c-table-list-container__button l-mobile-flex u-flex-middle"
              to="/orders"
          >
            {linkLabel}
            <span className="k-icon k-i-arrow-right k-ml-2"></span>
          </Link>
        </header>
        {loadingCompany ||
        loadingLastOrder ||
        loadingIndexWebCustomer ||
        loadingSetCustomer === "pendingSetCustomer" ? (
            <ul className="c-table-list u-gap-16">
              {[0, 1, 2, 3].map((order, index) => (
                  <li
                      className="c-table-list__item"
                      key={order.id || `order-${index}`}
                  >
              <span>
                <Skeleton style={{ width: "80%" }} />
              </span>
                    <span className="">
                <Skeleton style={{ width: "90%" }} />
              </span>
                    <span className="">
                <Skeleton style={{ width: "90%" }} />
              </span>
                    <span className="c-table-list__item-total">
                <Skeleton style={{ width: "80%" }} />
              </span>
                    <Skeleton className="c-table-list__item-button" />
                  </li>
              ))}
            </ul>
        ) : lastOrders.length === 0 ? (
            <EmptySearch strongText={noOrders} text={null} />
        ) : (
            <ul className="c-table-list u-gap-16">
              {lastOrders.map((order, index) => (
                  <li
                      className="c-table-list__item"
                      key={order.id || `order-${index}`}
                  >
              <span>
                {customerLabel}
                <strong>{order.customer_description}</strong>
              </span>
                    <span className="">
                {orderNumberLabel}
                      <strong>{order.code}</strong>
              </span>
                    <span className="">
                {dateLabel}{" "}
                      <strong>
                  {formatDateTimeByLanguage(
                      intl.locale,
                      order.creation_date,
                      true
                  )}
                </strong>
              </span>
                    <span className="c-table-list__item-total">
                {amountLabel}
                      <strong>{order.final_amount}</strong>
              </span>
                    <Link
                        className="c-table-list__item-button"
                        to={`/orders/${order.id}`}
                    >
                      {detailsLinkLabel}
                    </Link>
                  </li>
              ))}
            </ul>
        )}
      </div>) : <></>
  );
};

export default LastOrders;
