import React from "react";
import PropTypes from "prop-types";
import InfoArticle from "../../../../../components/cart/infoArticle";
import "./cellDescription.scss";

const CellDescription = ({ full_description, out_of_stock_message, is_out_of_stock }) => {

    return (
        <td
            colSpan="1"
            className=""
            role="gridcell"
            aria-colindex="5"
            aria-selected="false"
            data-grid-col-index="0"
        >
             <span className="c-table-item__description">
                 {full_description}
                 {is_out_of_stock && <InfoArticle
                     item={out_of_stock_message}
                     classIcon="c-cart-item__alert icon icon-alert"
                     isAlert={true}
                 />}
             </span>
        </td>
    );
};

CellDescription.propTypes = {
    full_description: PropTypes.string,
    out_of_stock_message: PropTypes.string,
    is_out_of_stock: PropTypes.bool,
};

export default CellDescription;
