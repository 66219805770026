import React from "react";
import PropTypes from "prop-types";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";

import { formatDateTimeByLanguage } from "utils";
import "./cell-date.scss";
import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    at: toLanguageString(
      'metislab.frontend.components.orders.components.pending.cellDateCreation.at',
      defaultMessages.metislab.frontend.components.orders.components.pending.cellDateCreation.at),
  };
}

const CellDateCreation = (props) => {

  const { 
    date, 
    isDateDelivery = false
  } = props;

  const { at } = useLocalizedMessages();
  const intl = useInternationalization();

  const dateConverted = formatDateTimeByLanguage(intl.locale, date, true);
  const timeConverted = formatDateTimeByLanguage(intl.locale, date, false, true);

  return (
    <td
      colSpan="1"
      className="c-list-orders__date"
      role="gridcell"
      aria-colindex="2"
      aria-selected="false"
      data-grid-col-index="0"
    >
      {date 
      ? <>
          <strong>{dateConverted}</strong>
          {!isDateDelivery && <span>{`${at} ${timeConverted}`}</span>}
        </>
      : <span>{'-'}</span>
      }
    </td>
  );
};

CellDateCreation.propTypes = {
  date: PropTypes.string,
  isDateDelivery: PropTypes.bool
};

export default CellDateCreation;
