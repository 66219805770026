import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import "./header.scss";

const HeaderProspects = () => {
  const INITIAL_VALUES_DROPDOWN = {
    propId: "Filtra per",
    label: "Filtra per",
  };

  const DATA_DUMMIES = [
    {
      propId: "Ragione Sociale",
      label: "Ragione Sociale",
    },
    {
      propId: "Email",
      label: "Email",
    },
    {
      propId: "Telefono",
      label: "Telefono",
    },
    {
      propId: "Comune",
      label: "Comune",
    },
    {
      propId: "Stato",
      label: "Stato",
    },
  ];

  /**
   * User states
   */
  const permissionsUser = useSelector((state) => state.user.permissions);

  /**
   * onChangeKeyword is used to handle the input
   * @param {*} e
   */
  const onChangeKeyword = (e) => {
    console.log(e.value);
  };

  /**
   * onChangeType is used to handle the dropdown
   * @param {*} e
   */
  const onChangeType = (e) => {
    console.log(e.value);
  };

  return (
    <header className="c-subheader c-subheader--prospect l-desktop-flex k-align-items-center k-justify-content-between">
      <h1 className="c-subheader__title t-title t-title--2 k-m-0">
        Elenco prospect
      </h1>
      <div className="c-subheader__content l-tablet-flex">
        <ul
          className="c-subheader__filter l-tablet-flex k-align-items-center"
          style={{ listStyle: "none" }}
        >
          <li className="c-filter">
            <DropDownList
              className="c-filter-container"
              name="searchByType"
              data={DATA_DUMMIES}
              fillMode="outline"
              textField="label"
              dataItemKey="propId"
              defaultItem={INITIAL_VALUES_DROPDOWN}
              onChange={onChangeType}
              value={null}
              popupSettings={{ popupClass: "c-filter-content", width: 180 }}
            />
          </li>
          <li className="c-form-field c-form-field--search k-pos-relative">
            <Input
              name="searchByKeyword"
              placeholder="Cerca prospect"
              onChange={onChangeKeyword}
            />
            <span className="k-icon k-i-search"></span>
          </li>
        </ul>
        {permissionsUser?.prospectEdit && (
          <Link
            to="/prospects/add"
            className="c-new-prospect k-button k-button-md"
          >
            Nuovo prospect
            <span className="k-icon k-i-add"></span>
          </Link>
        )}
      </div>
    </header>
  );
};

export default HeaderProspects;
