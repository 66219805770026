import React from "react";
import PropTypes from "prop-types";
import "./page-error.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    fallback: toLanguageString(
      "metislab.frontend.components.errorAPI.components.fallback",
      defaultMessages.metislab.frontend.components.errorAPI.components.fallback
    ),
  };
};

const Error = ({ errorText }) => {
  const { fallback } = useLocalizedMessages();

  return (
    <section className="c-page-error">
      <div className="c-page-error__content">
        <span className="icon icon-alert"></span>
        <p>{errorText || fallback}</p>
      </div>
    </section>
  );
};
Error.propTypes = {
  errorText: PropTypes.string,
};

export default Error;
