import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";

export const getOrderToPrint = createAsyncThunk(
    "print/Order",
    async ( {id, toast}, { rejectWithValue } ) => {       
        try {
            const query = `/sfas/order/${id}/printData`;          
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            const { status } = err?.response;
            return rejectWithValue({sMessageText, status});
        }
    }
);