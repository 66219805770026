import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFragrances } from "redux/features/catalogue/actions";
import { setPagerState } from "redux/features/catalogue";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import CellProduct from "./cellProduct";
import HeaderProducts from "./header";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import "./catalog.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    labelEmptyListProduct: toLanguageString(
      "metislab.frontend.components.catalogue.components.emptyListProduct.label",
      defaultMessages.metislab.frontend.components.catalogue.components
        .emptyListProduct.label
    ),
    labelStrongEmptyListProduct: toLanguageString(
      "metislab.frontend.components.catalogue.components.emptyListProduct.labelStrong",
      defaultMessages.metislab.frontend.components.catalogue.components
        .emptyListProduct.labelStrong
    ),
    labelEmptySearch: toLanguageString(
      "metislab.frontend.components.catalogue.components.emptySearch.label",
      defaultMessages.metislab.frontend.components.catalogue.components.emptySearch
        .label
    ),
    labelStrongEmptySearch: toLanguageString(
      "metislab.frontend.components.catalogue.components.emptySearch.labelStrong",
      defaultMessages.metislab.frontend.components.catalogue.components.emptySearch
        .labelStrong
    ),
  };
};

const Catalog = () => {

  const {
    labelEmptyListProduct,
    labelStrongEmptyListProduct,
    labelEmptySearch,
    labelStrongEmptySearch,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);

  /**
   * IndexWebCustomer states
   */
  const companyCustomers = useSelector((state) => state.indexWebCustomer.indexCustomers);
  const loadingIndexWebCustomer = useSelector((state) => state.indexWebCustomer.loading);
  const selectedCompany = useSelector((state) => state.indexWebCustomer.selectedCompany);

  /**
   * Fragrance states
   */
  const fragrances = useSelector((state) => state.fragrance.fragrances);
  const loading = useSelector((state) => state.fragrance.loading);
  const pagerState = useSelector((state) => state.fragrance.pagerState);
  const pagination = useSelector((state) => state.fragrance.pagination);
  const pageSizes = useSelector((state) => state.fragrance.pageSizes);

  /**
   * getListOfFragrances is a callback's hook
   * used to get the list of fragrances
   */
  const getListOfFragrances = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      const args = { 
        toast, 
        pager: pagerState,
        tag: searchParams.get('tag'),
      };
      dispatch(getFragrances(args));
    }
  }, [companyCustomers, dispatch, pagerState, searchParams, selectedCompany]);

  useEffect(() => {
    getListOfFragrances();
  }, [getListOfFragrances]);

  /**
   * pageChange is the function to handle the change between pages
   * @param {object} e - It's an event object
   */
  const pageChange = (e) => {
    const { skip, take } = e.page;
    dispatch(
      setPagerState({
        page: skip / take + 1,
        perPage: take,
      })
    );
  };

  const cellProduct = ({ dataItem: product }) => {
    return <CellProduct data={product} />;
  };
  
  return (
    <>
      <HeaderProducts />

      {(loading || loadingUser || loadingIndexWebCustomer) ? (
        <Loader className="c-loader--center" size="large" />
      ) : fragrances && fragrances?.length === 0 ? (
        <EmptySearch 
          icon={pagerState.class_code ? "search" : null}
          strongText={
            pagerState.class_code
              ? labelStrongEmptySearch
              : labelStrongEmptyListProduct
          }
          text={pagerState.class_code ? labelEmptySearch : labelEmptyListProduct}
        />
      ) : (
        <Grid
          className="c-table-gragrances"
          data={fragrances}
          pageable={{ buttonCount: 5, info: false, pageSizes }}
          onPageChange={pageChange}
          skip={(pagerState?.page - 1) * pagerState?.perPage}
          take={pagination?.size}
          total={pagination?.total}
        >
          <Column cell={cellProduct} headerClassName="k-hidden" />
        </Grid>
      )}
    </>
  );
};

export default Catalog;
