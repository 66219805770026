import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Animation } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import Logout from "components/logout";
import "./userMenu.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    userSettings: toLanguageString(
      'metislab.frontend.components.header.components.userMenu.userSettings',
      defaultMessages.metislab.frontend.components.header.components.userMenu.userSettings),   
  };
}

const UserMenu = () => {

  const {
    userSettings,
  } = useLocalizedMessages();
  
  const loading = useSelector( (state) => state.user.loading);
  // const username = useSelector( (state) => state.user.user);

  /**
   * refMenu is a ref's hook used to get a DOM
   * access on the element
   */
  const refMenu = useRef();

  const [open, setOpen] = useState(false);

  /**
   * handleClickOutside is a useCallback hook to close
   * the modal when an user clicks outside it
   */
  const handleClickOutside = useCallback( (e) => {
    if ( open && !refMenu.current.contains(e.target)) {
      setOpen( (prevState) => !prevState);
    }
  }, [open]);
  
  useEffect(() => {
    
    open && document.addEventListener( 'click', handleClickOutside );

    return () => {
      document.removeEventListener( 'click', handleClickOutside );
    }
  }, [open, handleClickOutside])

  return (
    <section className="c-user-menu" ref={ refMenu }>
      <Button
        className="c-user-menu__button"
        icon="user"
        fillMode="link"
        onClick={() => setOpen(!open)}
        style={{ color: "white" }}
        disabled={ (loading && loading !== 'pendingSetCustomer' ) }
      >     
        { !loading && <span className={`k-icon k-i-chevron-${ open ? "up" : "down" }`}></span> }
      </Button>
      <Animation
        appear={true}
        enter={true}
        exit={true}
        transitionName="slide"
        transitionEnterDuration={300}
        transitionExitDuration={300}
        className="c-user-menu__menu"
      >
        {open && (
          <ul className="c-user-menu__list">
            <li className="c-user-menu__list-item">
              <Link to="user-settings" onClick={() => setOpen(!open)}>
                {userSettings}
              </Link>
            </li>
            <li className="c-user-menu__list-item">
              <Logout fillmode="link" label="logout" />
            </li>
          </ul>
        )}
      </Animation>
    </section>
  );
};

export default UserMenu;
