import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useCurrentLocation } from "utils";
import { AppBar, AppBarSection } from "@progress/kendo-react-layout";
import FooterOrderActive from "./components/orderActive";
import FooterUnauthenticated from "./components/unauthenticated";
import "./footer.scss";

const Footer = () => {
  const { pathname } = useLocation();
  const currentLocation = useCurrentLocation(pathname);
  const isPrintPages = currentLocation?.includes("print");

  const authenticated = useSelector((state) => state.user.authenticated);
  const loadingCreateOrder = useSelector(
    (state) => state.user.loadingCreateOrder
  );
  const selectedOrder = useSelector((state) => state.user.selectedOrder);
  const orderFooterNotEditable = useSelector(
    (state) => state.order.orderFooterNotEditable
  );

  const foo = authenticated && !selectedOrder && !orderFooterNotEditable;

  if (isPrintPages) {
    return null;
  }

  return (
    <AppBar
      className={`k-appbar--footer k-block${foo ? " u-disabled" : ""}`}
      position="bottom"
      positionMode="fixed"
    >
      <AppBarSection className="" style={{ display: "block" }}>
        {authenticated ? (
          loadingCreateOrder ? null : (
            <FooterOrderActive />
          )
        ) : (
          <FooterUnauthenticated />
        )}
      </AppBarSection>
    </AppBar>
  );
};

export default Footer;
