import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { downloadCustomerPayment } from 'redux/features/customer/actions';
import { Button } from "@progress/kendo-react-buttons";
import { toast } from "react-toastify";
import Loader from "components/loader";
import { useCustomLocalization } from "utils";
import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {
        downloadButtonLabel: toLanguageString(
            'metislab.frontend.components.customer.components.details.tabs.tabPayments.downloadButtonLabel',
            defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.downloadButtonLabel),
    };
}

const CellDownload = ({ idDocs, isExpired }) => {

    const {
        downloadButtonLabel,
    } = useLocalizedMessages();

    const dispatch = useDispatch();

    const loadingDownloadDoc = useSelector((state) => state.customer.loadingDownloadDoc);

    const [isDownloading, setIsDownloading] = useState(false);

    const handleClick = (id) => {
        const args = {
          id, 
          toast,
        }; 
        setIsDownloading(true);
        dispatch(downloadCustomerPayment(args))
          .then( () => setIsDownloading(false));
      }

    return (
        <td 
            colSpan="1" 
            className={ isExpired && "c-row-expired"}
            role="gridcell" 
            aria-colindex="9"
            aria-selected="false"
            data-grid-col-index="8"
        >
            {idDocs && (
                <Button
                    className=""
                    fillMode="outline"
                    onClick={() => handleClick(idDocs)}
                >
                    {downloadButtonLabel}
                    {(loadingDownloadDoc && isDownloading) 
                        ? <Loader size="small" className="k-ml-2" />
                        : <span role="presentation" className="k-icon k-i-download"></span>
                    }
                </Button>
            )}
        </td>
    )
}

CellDownload.propTypes = {
    idDocs: PropTypes.number,
    isExpired: PropTypes.bool,
}

export default CellDownload;