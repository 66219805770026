import React from "react";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";
import "./order-details.scss";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    orderHeaderPrintListProducts: toLanguageString(
      "metislab.frontend.components.order.components.header.orderHeaderPrintListProducts",
      defaultMessages.metislab.frontend.components.order.components.header
        .orderHeaderPrintListProducts
    ),
    subAmount: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.cellFragrance.subAmount",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .cellFragrance.subAmount
    ),
  };
};

const OrderDetails = (props) => {
  const { order } = props;

  const { orderHeaderPrintListProducts, subAmount } = useLocalizedMessages();

  return (
    <section className="c-print__orderdetails">
      <h2 className="c-print__title">{orderHeaderPrintListProducts}</h2>
      {order?.length > 0 &&
        order?.map((typeOfFragrance) => {
          return (
            <div key={typeOfFragrance?.label} className="c-print__products">
              <h4 className="c-print__label">
                <span style={{ fontWeight: "normal" }}>
                  <strong>{`${typeOfFragrance?.label} - `}</strong>
                  {subAmount}
                  <strong>{` ${typeOfFragrance?.subValue}`}</strong>
                </span>
              </h4>
              {typeOfFragrance?.rows?.map((fragranceFamily, index) => {
                return (
                  <section
                    className="c-print__item"
                    key={`${fragranceFamily?.fragrance_title}-${index}`}
                  >
                    <div className="c-print__cat-heading l-mobile-flex u-flex-between u-flex-middle">
                      <h3 className="c-print__cat-title">
                        {fragranceFamily?.fragrance_title}
                      </h3>
                      <strong>{fragranceFamily?.order?.subValue}</strong>
                    </div>
                    {fragranceFamily?.order?.articles?.length > 0 && (
                      <div className="c-print__list">
                        {fragranceFamily?.order?.articles?.map(
                          (article, indexArticle) => {
                            return (
                              <div
                                className="c-print__list-element"
                                key={`${article?.code}-${indexArticle}`}
                              >
                                <div>
                                  <span>{`${article?.total} x ${article?.desc}`}</span>
                                  {article?.selling?.length > 0 && (
                                    <span className="c-badge c-badge--special">
                                      {article?.selling}
                                    </span>
                                  )}
                                </div>
                                <div className="c-print__numbers l-mobile-flex u-flex-middle">
                                  {article?.discount && (
                                    <span className="c-print__discount">
                                      {article?.discount}
                                    </span>
                                  )}
                                  <strong>{article?.final_price}</strong>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </section>
                );
              })}
            </div>
          );
        })}
    </section>
  );
};

export default OrderDetails;
