import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLastDocuments } from "redux/features/home/actions";
import { downloadAdministrative } from "redux/features/download/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Skeleton } from "@progress/kendo-react-indicators";
import {
  useInternationalization,
} from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";
import { formatDateTimeByLanguage } from "utils";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import "./last-documents.scss";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    dateLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastDocuments.dateLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastDocuments.dateLabel
    ),
    downloadLinkLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastDocuments.downloadLinkLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastDocuments.downloadLinkLabel
    ),
    linkLabel: toLanguageString(
        "metislab.frontend.components.homepage.components.lastDocuments.linkLabel",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastDocuments.linkLabel
    ),
    noDocuments: toLanguageString(
        "metislab.frontend.components.homepage.components.lastDocuments.noDocuments",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastDocuments.noDocuments
    ),
    title: toLanguageString(
        "metislab.frontend.components.homepage.components.lastDocuments.title",
        defaultMessages.metislab.frontend.components.homepage.components
            .lastDocuments.title
    ),
  };
};

const LastDocuments = () => {
  const { dateLabel, downloadLinkLabel, linkLabel, noDocuments, title } =
      useLocalizedMessages();

  const intl = useInternationalization();

  const dispatch = useDispatch();

  /**
   * IndexWebCustomer states
   */
  const loadingCompany = useSelector((state) => state.indexWebCustomer.loading);
  const selectedCompany = useSelector((state) => state.indexWebCustomer.selectedCompany);
  const companyCustomers = useSelector((state) => state.indexWebCustomer.indexCustomers);
  const loadingIndexWebCustomer = useSelector((state) => state.indexWebCustomer.loading);
  /**
   * Homepage states
   */
  const documentDownload = useSelector((state) => state.home.documentPermissions.documentDownload);
  const lastDocuments = useSelector((state) => state.home.lastDocuments);
  const loadingLastDocuments = useSelector(
      (state) => state.home.loadingLastDocuments
  );

  /**
   * Download states
   */
  const loadingDownloadDoc = useSelector((state) => state.download.loadingDownloadDoc);

  /**
   * User states
   */
  const loadingSetCustomer = useSelector((state) => state.user.loading);



  const [isDownloading, setIsDownloading] = useState(false);

  /**
   * getLastOrderAndDocuments is a callback's hook
   * used to get the list of the last orders and documents
   */
  const getLastDocumentsList = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      dispatch(getLastDocuments({ toast }));
    }
  }, [companyCustomers, dispatch, selectedCompany]);

  useEffect(() => {
    getLastDocumentsList();
  }, [getLastDocumentsList]);

  const handleClick = (id) => {
    const args = {
      id,
      toast,
    };
    setIsDownloading(id);
    dispatch(downloadAdministrative(args))
        .then( () => setIsDownloading(false));
  }

  return (
      documentDownload ?
            (<div className="c-tiles-container">
              <header className="c-tiles-container__header l-tablet-flex u-flex-middle u-flex-between">
                <h2 className="t-title t-title--2">{title} </h2>
                <Link
                    className="c-tiles-container__button l-mobile-flex u-flex-middle"
                    to="/download"
                >
                  {linkLabel}
                  <span className="k-icon k-i-arrow-right k-ml-2"></span>
                </Link>
              </header>
              {loadingCompany || loadingLastDocuments || loadingIndexWebCustomer || loadingSetCustomer === 'pendingSetCustomer' ? (
                  <ul className="c-tiles l-mobile-flex u-gap-16">
                    {[0, 1, 2, 3].map((doc, index) => (
                        <li
                            className="c-tile c-tile--small u-flex-1"
                            key={doc.id || `order-${index}`}
                        >
                          <Skeleton style={{width: "50%", height: 32}}/>
                          <Skeleton style={{width: "40%"}}/>
                          <Skeleton/>
                          <Skeleton className="c-tile__button"/>
                        </li>
                    ))}
                  </ul>
              ) : lastDocuments.length === 0 ? (
                  <EmptySearch strongText={noDocuments}/>
              ) : (
                  <ul className="c-tiles l-mobile-flex u-gap-16">
                    {lastDocuments.map((doc, index) => (
                        <li
                            className="c-tile c-tile--small u-flex-1"
                            key={doc.id || `order-${index}`}
                        >
                          <strong
                              className="c-tile__name">{`${doc.document_type_description} N. ${doc.document_number}`}</strong>
                          <p>
                            {dateLabel}
                            <strong>
                              {formatDateTimeByLanguage(
                                  intl.locale,
                                  doc.document_date,
                                  true
                              )}
                            </strong>
                          </p>
                          <p>
                            <strong>{doc.customer_name}</strong>
                          </p>
                          <Button
                              disabled={
                                  loadingDownloadDoc && !isDownloading
                              }
                              className="c-tile__button"
                              fillMode="outline"
                              onClick={() => handleClick(doc.document_id)}
                          >
                            {downloadLinkLabel}
                            {(loadingDownloadDoc && isDownloading === doc.document_id)
                                ? <Loader size="small" className="k-ml-2"/>
                                : <span role="presentation" className="k-icon k-i-download"></span>
                            }
                          </Button>
                        </li>
                    ))}
                  </ul>
              )}
            </div>)
            : <></>

  );
};

export default LastDocuments;
