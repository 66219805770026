import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const getLastOrders = createAsyncThunk(
    "homepage/getLastOrders",
    async ( {toast}, { rejectWithValue } ) => {       
        try {
            const query = "/lastordersrows";          
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getLastDocuments = createAsyncThunk(
    "homepage/getLastDocuments",
    async ( {toast}, { rejectWithValue } ) => {       
        try {
            const query = "/lastdocumentsrows";          
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getCarousel = createAsyncThunk(
    "homepage/getCarousel",
    async ( {toast}, { rejectWithValue } ) => {       
        try {
            const query = "/carouselinfo";          
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);