import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FormPasswordChange from './components/form-password-recovery';

const PasswordChange = () => {
  const authenticated = useSelector((state) => state.user.authenticated);
 
  return authenticated
    ? <Navigate to="/dashboard" /> 
    : <FormPasswordChange />
}

export default PasswordChange;