import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { StackLayout } from "@progress/kendo-react-layout";
import { useInternationalization } from "@progress/kendo-react-intl";
import { formatDateTimeByLanguage } from "utils";
import Loader from "components/loader";
import "./tabDetails.scss";

const TabCustomer = () => {

  const intl = useInternationalization();

  const customerDetails = useSelector((state) => state.customer.customerDetails);
  const loading = useSelector((state) => state.customer.loading);

  if (loading) {
    return (
      <Loader className="c-loader--center c-loader--absolute" size="medium" />
    );
  }

  return (
    <StackLayout orientation="vertical" className="">
      {customerDetails &&
        customerDetails?.length > 0 &&
        customerDetails.map((customer) => {
          return (
            <Fragment key={customer?.name}>
              {customer?.name.toLowerCase() !== "hidden" && (
                <div className="c-tile c-tile--big u-transparent">
                  <h2 className="c-tile__title t-title t-title--3">
                    {customer?.name}
                  </h2>
                  <ul className="c-tile__list l-tablet-grid u-grid-3">
                    {customer?.fields.length &&
                      customer?.fields.map((field) => {
                        return (
                          <Fragment key={field?.name}>
                            {field?.visible && (
                              <li>
                                <strong className=" k-mr-1">{`${field?.name}:`}</strong>
                                <span>
                                  {field?.component === "date-field"
                                    ? formatDateTimeByLanguage(intl.locale, field?.value, true)
                                    : field?.value || "-"}
                                </span>
                              </li>
                            )}
                          </Fragment>
                        );
                      })}
                  </ul>
                </div>
              )}
            </Fragment>
          );
        })}
    </StackLayout>
  );
};

export default React.memo(TabCustomer);
