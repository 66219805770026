import React from "react";
import PropTypes from "prop-types";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Formik } from "formik";
import * as Yup from "yup";
import { StackLayout } from "@progress/kendo-react-layout";
import HeaderProspectsAdd from "./header";
import "./prospects-new.scss";

const ProspectsNew = ({ isEditView, data }) => {
  const prospectValidationSchema = Yup.object().shape({
    description: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Inserire una email valida"),

    // GENERALS
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    comune: Yup.string().nullable(),
    cap: Yup.number().typeError("Inserire un numero").nullable(),
    province: Yup.string().nullable(),
    country: Yup.string().nullable(),

    // FATTURAZIONE
    codfiscale: Yup.string().nullable(),
    pIVA: Yup.number().typeError("Inserire un numero").nullable(),
    discount: Yup.number().typeError("Inserire un numero").nullable(),
  });

  const initialValues = {
    description: isEditView ? data.info.description : "",
    email: isEditView ? data.info.email : "",

    // GENERALS
    address: isEditView ? data.info.address : null,
    city: isEditView ? data.info.city : null,
    comune: isEditView ? data.info.comune : null,
    cap: isEditView ? data.info.cap : null,
    province: isEditView ? data.info.province : null,
    country: isEditView ? data.info.country : null,

    // FATTURAZIONE
    codfiscale: isEditView ? data.info.codfiscale : "",
    pIVA: isEditView ? data.info.pIVA : "",
    delivery_methods: isEditView
      ? data.info.delivery_methods
      : "Seleziona la modalità di consegna",
    typology: isEditView ? data.info.typology : "Seleziona la tipologia",
    signboard: isEditView ? data.info.signboard : "Seleziona l'insegna",
    discount: isEditView ? data.info.discount : null,

    // INFO
    notes: isEditView ? data.info.notes : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);
        setSubmitting(false);
      }}
      validationSchema={prospectValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <form
            className="c-user-setting c-user-setting--edit"
            onSubmit={handleSubmit}
          >
            {!isEditView && <HeaderProspectsAdd />}
            <StackLayout
              className="c-user-setting__content"
              orientation="vertical"
            >
              <div className="c-user-setting__item">
                <ul className="c-user-setting__list l-tablet-grid u-grid-3 ">
                  <li className="c-form-field">
                    <label htmlFor="description">Ragione sociale</label>
                    <Input
                      name="description"
                      placeholder="Inserisci la ragione sociale"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.description && touched.description
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.description && touched.description && (
                      <div className="input-feedback">{errors.description}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="email">Email</label>
                    <Input
                      name="email"
                      placeholder="Inserisci l'indirizzo email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.email && touched.email
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.email && touched.email && (
                      <div className="input-feedback">{errors.email}</div>
                    )}
                  </li>
                </ul>
              </div>
              <div className="c-user-setting__item">
                <h2 className="c-user-setting__heading t-title t-title--3">
                  Generali
                </h2>
                <ul className="c-user-setting__list l-tablet-grid u-grid-3 ">
                  <li className="c-form-field">
                    <label htmlFor="address">Indirizzo</label>
                    <Input
                      name="address"
                      placeholder="Inserisci l'indirizzo"
                      value={values.address || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.address && touched.address
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.address && touched.address && (
                      <div className="input-feedback">{errors.address}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="city">Frazione</label>
                    <Input
                      name="city"
                      placeholder="Inserisci il nome della frazione"
                      value={values.city || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.city && touched.city
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.city && touched.city && (
                      <div className="input-feedback">{errors.city}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="comune">Comune</label>
                    <Input
                      name="comune"
                      placeholder="Inserisci il nome del comune"
                      value={values.comune || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.comune && touched.comune
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.comune && touched.comune && (
                      <div className="input-feedback">{errors.comune}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="cap">CAP</label>
                    <Input
                      name="cap"
                      placeholder="Inserisci il CAP"
                      value={values.cap || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.cap && touched.cap
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.cap && touched.cap && (
                      <div className="input-feedback">{errors.cap}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="province">Provincia</label>
                    <DropDownList
                      name="province"
                      data={["BO", "FE", "MO", "PR"]}
                      defaultItem="Seleziona la sigla della provincia"
                      value={
                        values.province || "Seleziona la sigla della provincia"
                      }
                      onChange={handleChange}
                    />
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="country">Nazione</label>
                    <DropDownList
                      name="country"
                      data={["EN", "FR", "IT", "US"]}
                      defaultItem="Seleziona il codice nazione"
                      value={values.country || "Seleziona il codice nazione"}
                      onChange={handleChange}
                    />
                  </li>
                </ul>
              </div>
              <div className="c-user-setting__item">
                <h2 className="c-user-setting__heading t-title t-title--3">
                  Fatturazione
                </h2>
                <ul className="c-user-setting__list l-tablet-grid u-grid-3 ">
                  <li className="c-form-field">
                    <label htmlFor="codfiscale">Codice fiscale</label>
                    <Input
                      name="codfiscale"
                      placeholder="Inserisci il codice fiscale"
                      value={values.codfiscale || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.codfiscale && touched.codfiscale
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.codfiscale && touched.codfiscale && (
                      <div className="input-feedback">{errors.codfiscale}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="pIVA">Partita IVA</label>
                    <Input
                      name="pIVA"
                      placeholder="Inserisci la partita IVA"
                      value={values.pIVA || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.pIVA && touched.pIVA
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.pIVA && touched.pIVA && (
                      <div className="input-feedback">{errors.pIVA}</div>
                    )}
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="delivery_methods">
                      Modalità di consegna
                    </label>
                    <DropDownList
                      name="delivery_methods"
                      data={[
                        "Vettore 1",
                        "Vettore 2",
                        "Vettore 3",
                        "Vettore 4",
                      ]}
                      defaultItem="Seleziona la modalità di consegna"
                      value={values.delivery_methods}
                      onChange={handleChange}
                    />
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="typology">Tipologia</label>
                    <DropDownList
                      name="typology"
                      data={[
                        "Tipologia 1",
                        "Tipologia 2",
                        "Tipologia 3",
                        "Tipologia 4",
                      ]}
                      defaultItem="Seleziona la tipologia"
                      value={values.typology}
                      onChange={handleChange}
                    />
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="signboard">Insegna</label>
                    <DropDownList
                      name="signboard"
                      data={[
                        "Insegna 1",
                        "Insegna 2",
                        "Insegna 3",
                        "Insegna 4",
                      ]}
                      defaultItem="Seleziona l'insegna"
                      value={values.signboard}
                      onChange={handleChange}
                    />
                  </li>
                  <li className="c-form-field">
                    <label htmlFor="discount">
                      Sconto percentuale su listino
                    </label>
                    <Input
                      name="discount"
                      placeholder="Inserisci lo sconto % sul listino"
                      value={values.discount || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.discount && touched.discount
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.discount && touched.discount && (
                      <div className="input-feedback">{errors.discount}</div>
                    )}
                  </li>
                </ul>
              </div>
              <div className="c-user-setting__item">
                <h2 className="c-user-setting__heading t-title t-title--3">
                  Altre informazioni
                </h2>
                <ul className="c-user-setting__list l-tablet-grid u-grid-3">
                  <li
                    className="c-form-field"
                    style={{ gridArea: "1 / 1 / span 1 / span 2" }}
                  >
                    <label className="">Informazioni aggiuntive</label>
                    <TextArea
                      name="notes"
                      placeholder="Inserire informazioni aggiuntive"
                      rows={10}
                      defaultValue={values.notes}
                      onChange={handleChange}
                    />
                  </li>
                </ul>
              </div>
            </StackLayout>
          </form>
        );
      }}
    </Formik>
  );
};

ProspectsNew.propTypes = {
  isEditView: PropTypes.bool,
  data: PropTypes.object,
};

ProspectsNew.defaultProps = {
  isEditView: false,
};

export default ProspectsNew;
