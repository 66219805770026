import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveOrderNote } from "redux/features/order/actions";
import { Button } from "@progress/kendo-react-buttons";
import { TextArea } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import "./cart-notes.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    addNoteButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.addNoteButtonLabel",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.addNoteButtonLabel
    ),
    addNotePlaceholder: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.addNotePlaceholder",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.addNotePlaceholder
    ),
    saveNoteButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.saveNoteButtonLabel",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.saveNoteButtonLabel
    ),
    savingNoteButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.esavingNoteButtonLabelmpty",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.savingNoteButtonLabel
    ),
    undoAddNewNote: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.undoAddNewNote",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.undoAddNewNote
    ),

    newNoteSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.order.newNoteSuccess",
      defaultMessages.metislab.frontend.components.notification.components.order
        .newNoteSuccess
    ),
  };
};

const MAX_NOTES_LENGTH = 1200;

const NewNote = (props) => {
  const { handleModal, handleModalCreationNote, idOrder, showNewNoteForm } =
    props;

  const {
    addNoteButtonLabel,
    addNotePlaceholder,
    saveNoteButtonLabel,
    savingNoteButtonLabel,
    undoAddNewNote,
    newNoteSuccess,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const loadingSaveNote = useSelector((state) => state.order.loadingSaveNote);
  const orderInfo = useSelector((state) => state.order.orderInfo);

  const [textNote, setTextNote] = useState("");

  /**
   * handleNotesCart is used to handle the visualization
   * of the cart contains the notes of an order
   */
  const handleNotesCart = () => {
    handleModal();
  };

  /**
   * handleNewNoteCart is used to handle the visualization
   * of the cart to create a note for an order
   */
  const handleNewNoteCart = () => {
    setTextNote("");
    handleModalCreationNote();
  };

  /**
   * handletextNote is used to handle the state
   * of the textarea
   */
  const handletextNote = (e) => {
    if (e.value.length <= MAX_NOTES_LENGTH) {
      setTextNote(e.value);
    }
  };

  /**
   * handleSubmit is used to handle the submit
   * of the form
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (textNote.length > 0) {
      const note = {
        company: orderInfo?.company_code,
        customer: orderInfo?.customer_code,
        note: textNote,
      };
      const args = {
        id: idOrder,
        data: note,
        toast,
        messageNotification: newNoteSuccess,
      };
      dispatch(saveOrderNote(args)).then(() => handleNewNoteCart());
    }
  };

  return (
    <div
      className="c-new-note"
      style={{
        position: "absolute",
        inset: 0,
        zIndex: 101,
        transition: "0.3s ease-out",
        transform: showNewNoteForm ? "translateX(0)" : "translateX(100%)",
      }}
    >
      <section
        style={{
          backgroundColor: "#fff",
          height: "100%",
        }}
      >
        <header className="c-new-note__header">
          <div className="l-mobile-flex u-flex-middle u-flex-end">
            <Button
              className=""
              icon="close"
              fillMode="flat"
              onClick={handleNotesCart}
            />
          </div>
          <h2 className="t-title t-title--2">{addNoteButtonLabel}</h2>
        </header>
        <form className="c-new-note__form" onSubmit={handleSubmit}>
          <div>
            <TextArea
              disabled={loadingSaveNote}
              name="textNote"
              placeholder={addNotePlaceholder}
              rows={7}
              value={textNote}
              onChange={handletextNote}
            />
            <span
              className={`c-new-note-count ${
                textNote.length < MAX_NOTES_LENGTH ? "" : "u-error"
              }`}
            >
              {`${textNote.length}/${MAX_NOTES_LENGTH}`}
            </span>
          </div>
          <footer className="c-new-note__footer l-mobile-flex u-flex-middle u-flex-between">
            <Button
              className=""
              icon="close"
              fillMode="flat"
              onClick={handleNewNoteCart}
            >
              {undoAddNewNote}
            </Button>
            <Button
              disabled={loadingSaveNote || textNote.length === 0}
              className=""
              icon={loadingSaveNote ? null : "tick"}
              type="submit"
            >
              {loadingSaveNote ? savingNoteButtonLabel : saveNoteButtonLabel}
            </Button>
          </footer>
        </form>
      </section>
    </div>
  );
};

export default NewNote;
