import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deactivedOrderSync } from "redux/features/order";
import { getNewOrderHeaderFields } from "redux/features/order/actions";
import {
  createOrderHeader,
  deactivateOrder,
} from "redux/features/user/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Formik } from "formik";
import FormElement from "./FormElement";
import Loader from "components/loader";
import { toast } from "react-toastify";
import "./new-order.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    newOrderModalButtonLabel: toLanguageString(
      'metislab.frontend.components.orders.components.header.newOrderModalButtonLabel',
      defaultMessages.metislab.frontend.components.orders.components.header.newOrderModalButtonLabel),
    newOrderModalButtonLabelInProgress: toLanguageString(
      'metislab.frontend.components.orders.components.header.newOrderModalButtonLabelInProgress',
      defaultMessages.metislab.frontend.components.orders.components.header.newOrderModalButtonLabelInProgress),
    
    newOrderSuccess: toLanguageString(
      'metislab.frontend.components.notification.components.order.newOrderSuccess',
      defaultMessages.metislab.frontend.components.notification.components.order.newOrderSuccess),  
  };
}

const FormNewOrder = () => {

  const {
    newOrderModalButtonLabel,
    newOrderModalButtonLabelInProgress,
    newOrderSuccess,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loadingNewOrderHeaderFields = useSelector(
    (state) => state.order.loadingNewOrderHeaderFields
  );
  const newOrderHeaderFields = useSelector(
    (state) => state.order.newOrderHeader
  );

  const company = useSelector((state) => state.user.company);
  const loadingCreateOrder = useSelector(
    (state) => state.user.loadingCreateOrder
  );
  const selectedCustomer = useSelector((state) => state.user.selectedCustomer);
  const selectedOrder = useSelector((state) => state.user.selectedOrder);

  useEffect(() => {
    dispatch(getNewOrderHeaderFields({ toast }));
  }, [dispatch]);

  /**
   * INITIAL_FIELD_VALUES are the initial values of the input's elements
   * present in the form for the creation of a new order's header
   */
  const INITIAL_FIELD_VALUES =
    newOrderHeaderFields &&
    newOrderHeaderFields.reduce((accumulator, currentValue) => {
      const { 
        attribute,
        component,
        data,
        defaulValue,
        value, 
        visible 
      } = currentValue;
      
      if (visible) {
        if (component === 'select-field') {

          // If a value exists, select it
          if (value.length > 0) {
            const selectValue = data.rows.filter( row => row.value === value)[0];
            accumulator[attribute] = selectValue;
          } else {

            // If a value doesn't exists but defaulValue exists, select it
            if (defaulValue.length > 0) {
              const selectValue = data.rows.filter( row => row.value === defaulValue)[0];
              accumulator[attribute] = selectValue;
            } else {
              accumulator[attribute] = value;
            }
          }        
        } else {
          accumulator[attribute] = value;
        }
      }
      return accumulator;
    }, {});

  return loadingNewOrderHeaderFields ? (
    <Loader className="c-loader--center c-loader--center-modal" />
  ) : (
    <Formik
      initialValues={INITIAL_FIELD_VALUES}
      onSubmit={(values) => {
        const { delivery_date, customer_order_date, ...other_values } = values;
        
        // Date conversion for customer order date
        let customer_order_date_iso_string;

        if (customer_order_date) {
          const date = new Date(customer_order_date);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          customer_order_date_iso_string = `${month}/${day}/${year}`
        } else {        
          customer_order_date_iso_string = ""
        }

        // Date conversion for customer delivery date
        let delivery_date_iso_string;

        if (delivery_date) {
          const date = new Date(delivery_date);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          delivery_date_iso_string = `${month}/${day}/${year}`
        } else {
          delivery_date_iso_string = ""
        }

        const data = {
          company: company?.key,
          customer: selectedCustomer?.code,
          customer_order_date: customer_order_date_iso_string,
          delivery_date: delivery_date_iso_string,
          ...other_values,
        };
        
        const order = { order: data };

        if (selectedOrder) {
          const args = {
            navigate,
            toast,
            notification: false,
          };
          dispatch(deactivateOrder(args)).then(() => {
            dispatch(deactivedOrderSync());
            const argsHeader = { 
              data: order, 
              toast, 
              navigate,
              messageNotification: newOrderSuccess
            }
            dispatch(createOrderHeader(argsHeader));
          });
        } else {
          const argsHeader = { 
            data: order, 
            toast, 
            navigate,
            messageNotification: newOrderSuccess 
          }
          dispatch(createOrderHeader(argsHeader));
        }
      }}
    >
      {(props) => {
        const { values, handleBlur, handleSubmit, setValues } = props;

        const handleOnChange = (e) => {
          const fieldName = e.target.name;
          if (fieldName === 'delivery_date') {
            const daySelected = new Date(e.value);

            // Check if the day selected is a disable day
            const { disabledDays } = newOrderHeaderFields.find( field => field.attribute === fieldName);
            const disabledDaysConverted = disabledDays.map( day => {
              const dayToConvert = new Intl.DateTimeFormat('en-US').format(new Date(day));
              return dayToConvert;
            })
            const daySelectedConverted = new Intl.DateTimeFormat('en-US').format(daySelected);
            const isDisabledDay = disabledDaysConverted.includes(daySelectedConverted);

            if( !isDisabledDay && (daySelected.getDay() !== 0 && daySelected.getDay() !== 6)) {
              setValues({...values, [fieldName]: e.value})
            }
          } else {
            setValues({...values, [fieldName]: e.value})
          }      
        }

        return (
          <form onSubmit={handleSubmit}>
            {newOrderHeaderFields &&
              newOrderHeaderFields.map(
                (field) =>
                  field?.visible &&
                  field?.attribute !== "note" && (
                    <FormElement
                      key={field?.attribute}
                      field={field}
                      value={values}
                      required={field.mandatory ?? false}
                      onBlur={handleBlur}
                      onChange={handleOnChange}
                    />
                  )
              )}
            <Button
              disabled={loadingCreateOrder}
              className="c-new-order-modal__button"
              icon={loadingCreateOrder ? null : "add"}
              dir="rtl"
              type="submit"
            >
              {loadingCreateOrder
                ? newOrderModalButtonLabelInProgress
                : newOrderModalButtonLabel
              }
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormNewOrder;
