import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPagerState } from "redux/features/order";
import { Button } from "@progress/kendo-react-buttons";
import {DropDownList, MultiSelect} from "@progress/kendo-react-dropdowns";
import { DebounceInput } from "react-debounce-input";
import ModalOrder from "../modal";
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {
        newOrderButtonLabel: toLanguageString(
            "metislab.frontend.components.orders.components.header.newOrderButtonLabel",
            defaultMessages.metislab.frontend.components.orders.components.header
                .newOrderButtonLabel
        ),
        initialValuesDropdown: toLanguageString(
            "metislab.frontend.components.orders.components.header.initialValuesDropdown",
            defaultMessages.metislab.frontend.components.orders.components.header
                .initialValuesDropdown
        ),
        placeholderInputSearch: toLanguageString(
            "metislab.frontend.components.orders.components.header.placeholderInputSearch",
            defaultMessages.metislab.frontend.components.orders.components.header
                .placeholderInputSearch
        ),
        resetFilterOrderActive: toLanguageString(
            "metislab.frontend.components.download.components.header.resetFilterOrderActive",
            defaultMessages.metislab.frontend.components.download.components.header
                .resetFilterOrderActive
        ),
        title: toLanguageString(
            "metislab.frontend.components.orders.components.header.title",
            defaultMessages.metislab.frontend.components.orders.components.header
                .title
        ),
    };
};

const HeaderOrderListing = () => {
    const {
        initialValuesDropdown,
        newOrderButtonLabel,
        placeholderInputSearch,
        title,
        resetFilterOrderActive,
    } = useLocalizedMessages();

    const dispatch = useDispatch();

    const INITIAL_VALUES_DROPDOWN = {
        label: initialValuesDropdown,
        value: null,
    };

    /**
     * User states
     */
    const permissionsUser = useSelector((state) => state.user.permissions);
    const selectedCustomer = useSelector((state) => state.user.selectedCustomer);

    /**
     * Order states
     */
    const layoutColumn = useSelector((state) => state.order.layoutColumn);
    const orderPermissions = useSelector((state) => state.order.orderPermissions);
    const pagerState = useSelector((state) => state.order.pagerState);
    const comboOrderTags = useSelector((state) => state.user.comboOrderTags);

    /**
     * Internal states
     */
    const [showModal, setShowModal] = useState(false);
    const [filtersActive, setFiltersActive] = useState(null);
    const [filtersOrderTagActive, setFiltersOrderTagActive] = useState(null);
    const [keyword, setKeyword] = useState("");

    /**
     permesso per poter vedere il tag associato ad un ordine.
     */
    const bCanViewOrderTag = permissionsUser.orderTagsView ?? false;


    useEffect(() => {
        let filterFields;

        let filterFields_State = [];
        let filterFields_OrderTag = [];
        /**
         * check if filtersActive is not null
         * is null only in first render otherwise is an array with length = 0
         */

        // const fieldsWithoutCustomerFields = pagerState.filterFields.filter(
        //     (field) => {
        //         return !field.includes("state","orderTag");
        //     }
        // );

        if (filtersActive)          filterFields_State = filtersActive.length ? ["state", "IN", filtersActive] : []

        if (filtersOrderTagActive)  filterFields_OrderTag = filtersOrderTagActive.length ?  ["tag", "IN", filtersOrderTagActive] : []

        filterFields = [filterFields_State,filterFields_OrderTag]

        if (filtersActive || filtersOrderTagActive ) {
            dispatch(
                setPagerState({
                    page: 1,
                    filterFields,
                })
            );
        }
        // eslint-disable-next-line
    }, [dispatch, filtersActive,filtersOrderTagActive]);

    // useEffect(() => {
    //     dispatchFilters();
    // }, [dispatchFilters]);

    useEffect(() => {
        if (pagerState.filterFields  && pagerState.filterFields.length > 0) {
            if(pagerState.filterFields.find(el => el[0] === 'state')) {
                setFiltersActive(pagerState.filterFields.find(el => el[0] === 'state')[2])
            }else{
                setFiltersActive(null);
            }
            if(pagerState.filterFields.find(el => el[0] === 'tag')) {
                setFiltersOrderTagActive(pagerState.filterFields.find(el => el[0] === 'tag')[2])
            }else{
                setFiltersOrderTagActive(null);
            }

        } else {
            setFiltersActive(null);
            setFiltersOrderTagActive(null);
        }

        if (pagerState?.search) {
            setKeyword(pagerState?.search);
        } else {
            setKeyword("");
        }
    }, [pagerState.filterFields, pagerState?.search]);

    const statesArray =
        layoutColumn && layoutColumn.filter((row) => row.propId === "state");

    const states =
        statesArray &&
        statesArray?.length > 0 &&
        statesArray[0]?.editor?.data?.rows;

    const orderTags =
        bCanViewOrderTag &&
        comboOrderTags.filter(el => el.key !== '') ;

    /**
     * handleModal handle the modal to create a new order
     */
    const handleModal = () => {
        setShowModal((prevState) => !prevState);
    };

    /**
     * onChangeKeyword is used to handle the string in the input
     * @param {*} e
     */
    const onChangeKeyword = (e) => {
        setKeyword(e.target.value);
        dispatch(
            setPagerState({
                page: 1,
                search: e.target.value,
            })
        );
    };

    /**
     * onChangeType is used to handle the dropdown
     * @param {*} e
     */
    const onChangeType = (e) => {
        const value =
            e.value === INITIAL_VALUES_DROPDOWN
                ? null
                : { propId: e.value.propId, label: e.value.label };

        dispatch(
            setPagerState({
                searchBy: value,
            })
        );
    };

    /**
     * handleFilter is used to handle the order's state filter
     */
    const handleFilter = useCallback(
        (docType) => {
            if (filtersActive) {
                filtersActive && filtersActive.includes(docType)
                    ? setFiltersActive((prevState) =>
                        prevState.filter((filter) => filter !== docType)
                    )
                    : setFiltersActive((prevState) => [...prevState, docType]);
            } else {
                setFiltersActive([docType]);
            }
        },
        [filtersActive]
    );

    /**
     * handleOrderTagFilter is used to handle the order's tag filter
     */
    const handleOrderTagFilter = useCallback(
        (docType) => {
            if (filtersOrderTagActive) {
                filtersOrderTagActive && filtersOrderTagActive.includes(docType)
                    ? setFiltersOrderTagActive((prevState) =>
                        prevState.filter((filter) => filter !== docType)
                    )
                    : setFiltersOrderTagActive((prevState) => [...prevState, docType]);
            } else {
                setFiltersOrderTagActive([docType]);
            }
        },
        [filtersOrderTagActive]
    );

    /**
     * handleFilter is used to reset the dropdown filter and the input field
     */
    const handleResetFilter = () => {
        dispatch(
            setPagerState({
                ...pagerState,
                search: null,
                searchBy: null,
            })
        );
        setKeyword("");
    };

    const dropDownListArray =
        layoutColumn &&
        layoutColumn.filter(
            (row) =>
                row.propId === "search_code" ||
                row.propId === "customer_description" ||
                row.propId === "code"
        );


    return (
        <>
            <header className="c-subheader c-subheader--listing-orders k-align-items-center k-justify-content-between">
                <div className="c-subheader__main u-flex-between">
                    <h1 className="c-subheader__title t-title t-title--2 k-m-0">
                        {title}
                    </h1>
                    <div className="l-desktop-flex">
                        <ul
                            className="c-subheader__filter l-mobile-flex k-align-items-center"
                            style={{ listStyle: "none" }}
                        >
                            {(pagerState?.search || pagerState?.searchBy) && (
                                <li>
                                    <ResetFilter dispatchResetAction={handleResetFilter} />
                                </li>
                            )}
                            <li className="c-filter">
                                <DropDownList
                                    className="c-filter-container"
                                    name="searchByKey"
                                    data={dropDownListArray || []}
                                    fillMode="outline"
                                    textField="label"
                                    dataItemKey="propId"
                                    defaultItem={INITIAL_VALUES_DROPDOWN}
                                    onChange={onChangeType}
                                    value={pagerState.searchBy}
                                    popupSettings={{ popupClass: "c-filter-content", width: 180 }}
                                />
                            </li>
                            <li className="c-form-field c-form-field--search">
                                <DebounceInput
                                    className="k-input"
                                    name="searchByKeyword"
                                    placeholder={placeholderInputSearch}
                                    minLength={0}
                                    debounceTimeout={300}
                                    value={keyword}
                                    onChange={onChangeKeyword}
                                />
                                <span className="k-icon k-i-search"></span>
                            </li>
                            {orderPermissions?.orderEdit && (
                                <li className="c-form-field k-pos-relative">
                                    <Button
                                        disabled={
                                            !selectedCustomer ||
                                            selectedCustomer?.code === null ||
                                            !permissionsUser?.orderEdit
                                        }
                                        icon="plus"
                                        fillMode="solid"
                                        dir="rtl"
                                        onClick={handleModal}
                                    >
                                        {newOrderButtonLabel}
                                    </Button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <ul className="c-tags l-mobile-flex" style={{ width: "100%", gap: 8 }}>
                    {states &&
                        states.map((state) => {
                            return (
                                <li key={state.value} className="c-filter">
                                    <Button
                                        className={`c-subheader__button ${
                                            filtersActive?.includes(state.value)
                                                ? "c-subheader__button--selected"
                                                : ""
                                        } `}
                                        icon={filtersActive?.includes(state.value) ? "close" : null}
                                        dir="rtl"
                                        fillMode="outline"
                                        onClick={() => handleFilter(state.value)}
                                    >
                                        {state.label}
                                    </Button>
                                </li>
                            );
                        })}
                    {orderTags &&
                        orderTags.filter((orderTag)=>(orderTag.key !== 'no-tag')).map((orderTag) => {
                            return (
                                <li  className="c-filter " key={'li-'+orderTag.key}>

                                    <Button
                                        key={'btn-'+orderTag.key}
                                        className={`c-subheader__button ${
                                            filtersOrderTagActive?.includes(orderTag.key)
                                                ? "c-subheader__button--selected"
                                                : ""
                                        } ` + ' c-icon-filter c-background-'+orderTag.type+'__'+orderTag.label }

                                        ///TODO: mettere icona da server
                                        // icon={filtersOrderTagActive?.includes(orderTag.value) ? "close" : null}
                                        dir="rtl"
                                        fillMode="outline"
                                        onClick={() =>
                                            handleOrderTagFilter(orderTag.key)}
                                    >
                                        {/*{orderTag.label}*/}
                                    </Button>
                                </li>
                            );
                         })
                    }
                    {
                        ((filtersActive && filtersActive?.length > 0) ||
                        (filtersOrderTagActive && filtersOrderTagActive?.length > 0)) &&
                    (
                        <li className="">
                            <Button
                                className={`c-subheader__button c-subheader__button--no-filter`}
                                icon="close"
                                dir=""
                                fillMode="flat"
                                onClick={() => {
                                    setFiltersActive([]);
                                    setFiltersOrderTagActive([]);
                                }}
                            >
                                {resetFilterOrderActive}
                            </Button>
                        </li>
                    )}
                </ul>
            </header>
            {!orderPermissions?.orderEdit &&
                orderPermissions?.orderDisabledMessage.length > 0 && (
                    <div
                        className="c-disabled-message"
                        dangerouslySetInnerHTML={{
                            __html: orderPermissions?.orderDisabledMessage,
                        }}
                    />
                )}
            {showModal && <ModalOrder handleModal={handleModal} />}
        </>
    );
};

export default HeaderOrderListing;
