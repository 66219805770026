import React from "react";
import PropTypes from "prop-types";
import "./emptySearch.scss";

const EmptySearch = ({ icon = "search", strongText, text }) => {
  return (
    <div className="c-empty-state">
      <div className="c-empty-state__content">
        <h2 className="c-empty-state__title">
          {icon && <span className={`k-icon k-i-${icon}`}></span>}
          {strongText && <strong>{strongText}</strong>}
        </h2>
        {text && <p>{text}</p>}
      </div>
    </div>
  );
};

EmptySearch.propTypes = {
  icon: PropTypes.string,
  strongText: PropTypes.string,
  text: PropTypes.string,
};

export default EmptySearch;
