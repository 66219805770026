import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    label: toLanguageString(
      "metislab.frontend.components.catalogue.components.cellProduct.label",
      defaultMessages.metislab.frontend.components.catalogue.components
        .cellProduct.label
    ),
  };
};

const CellProduct = ({ data: product }) => {
  const { label } = useLocalizedMessages();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  return (
    <td
      colSpan="1"
      className="c-product"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
    >
      <img
        src={product.preview_file_name || "img/placeholder-medium.jpg"}
        alt=""
      />
      {product.class_title && (
        <strong className="c-product__lead">{product.class_title}</strong>
      )}
      {product.fragrance_title && (
        <h3 className="c-product__title t-title t-title--3">
          {product.fragrance_title}
        </h3>
      )}
      {product.fragrance_short_description && (
        <p className="c-product__text">{product.fragrance_short_description}</p>
      )}
      {product.tag_promo && (
        <span className="c-product__meta">{product.tag_promo}</span>
      )}
      <Button
        icon="arrow-right"
        dir="rtl"
        onClick={
          () => navigate(`${product.fragrance_code}${searchParams.get('tag') 
            ? `?tag=${searchParams.get('tag')}`
            : ''}`
          )
        }
      >
        {label}
      </Button>
    </td>
  );
};

CellProduct.propTypes = {
  product: PropTypes.object,
};

export default CellProduct;
