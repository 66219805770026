import React from "react";
import PropTypes from "prop-types";
import "./cell-state.scss";

const CellState = ({ state, stateLabel }) => {
  
  return (
    <td
      colSpan="1"
      className="c-state-order"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="5"
    >
      <span className={`s-${state}`}>{stateLabel || "-"}</span>
    </td>
  );
};

CellState.propTypes = {
  state: PropTypes.number,
  stateLabel: PropTypes.string,
};

export default CellState;
