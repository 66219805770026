import { createSlice } from "@reduxjs/toolkit";
import { filter, groupBy, sortBy } from 'lodash';
import { 
    indexWebCustomer,
    setCompanyRequest,
} from "./actions";


const initialState =  {
    agents: null,
    areaManager: null,
    indexCustomers: null,
    loading: false,
    selectedCompany: null,
    selectedCustomer: null,
    error: null,
};

const indexWebCustomerSlice = createSlice({
    name: "indexWebCustomer",
    initialState,
    reducers: {},
    extraReducers: {
        /**
         * Get paginated list of customers 
         */
        [indexWebCustomer.pending]: (state) => {
            state.loading = true;
            state.areaManager = null;
        },
        [indexWebCustomer.fulfilled]: (state, action) => {
            state.loading = false;
            const { 
                company, 
                res: { 
                    table : { rows } 
                }             
            } = action.payload;
            // state.selectedCompany = company;      
            let filterIndexCustomer = filter( rows, ['classe_budget', company] );
            state.indexCustomers = filterIndexCustomer;
            const areaManager = groupBy( 
                sortBy( 
                    filterIndexCustomer.map( 
                        ({area_manager_code, area_manager_description}) => {
                            return {area_manager_code, area_manager_description};
                        }
                    ), ['area_manager_description']
                ), 'area_manager_description'
            );
            state.areaManager = Object.entries(areaManager).map( manager => {
                return(manager[1][1]);
            })
            const agents =  groupBy( 
                sortBy( 
                    filterIndexCustomer.map( 
                        ({ agent_description, search_code, customer_description, IdSfa }) => {
                            return {
                                agent_description, 
                                search_code, 
                                customer_description, 
                                IdSfa
                            };
                        }
                    ), ['agent_description']
                ), 'agent_description'
            );
            state.agents = Object.entries(agents);
        },
        [indexWebCustomer.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Set a company
         */
        [setCompanyRequest.pending]: (state) => {
            state.loading = true;
            state.indexCustomers = null;
        },
        [setCompanyRequest.fulfilled]: (state, action) => {
            const { selectedCompanyChannel } = action.payload;
            state.loading = false;        
            state.selectedCompany = selectedCompanyChannel;
        },
        [setCompanyRequest.rejected]: (state, action) => {
            state.loading = false;
        },       
    },
});

export default indexWebCustomerSlice.reducer;