import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = () => {
  const token = localStorage.getItem('usrtkn') 
    ? localStorage.getItem('usrtkn') 
    : null;

  if (!token) {

    return (
      <Navigate 
          to="/" 
          replace={true} 
        />
    )
  }
  
  return <Outlet/>;
}
export default ProtectedRoutes;