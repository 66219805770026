import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPagerStatePayments as setPagerState } from "redux/features/customer";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ExportFileExcel from "./exportFileExcel";
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    initialValuesDropdown: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabPayments.initialValuesDropdown',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.initialValuesDropdown),
  };
}

const HeaderCustomerPayments = ({id}) => {
  
  const {
    initialValuesDropdown,
  } = useLocalizedMessages();

  const INITIAL_VALUES_DROPDOWN = {
    label: initialValuesDropdown,
    value: null,
  };

  const dispatch = useDispatch();

  const layoutColumn = useSelector((state) => state.customer.layoutColumnPayments);
  const pagerState = useSelector((state) => state.customer.pagerStatePayments);
  
  /**
   * onChangeType is used to handle the dropdown
   * @param {*} e
   */
  const onChangeType = (e) => {
    const value = e.value === INITIAL_VALUES_DROPDOWN
        ? null
        : { label: e.value.label, value: e.value.value };

    dispatch( setPagerState({
        search: value,
        searchBy: 'document_type',
      })
    );
  };

  const handleResetFilter = () => {
    dispatch(
      setPagerState({
        ...pagerState,
        search: null,
        searchBy: null,
      })
    );
  }

  const documentTypes = layoutColumn && layoutColumn.filter( column => column.propId === 'document_type')[0].editor.data.rows;

  return (
    <header className="c-subheader l-desktop-flex k-align-items-center k-justify-content-end">
      <ul
        className="c-subheader__filter l-tablet-flex k-align-items-center"
        style={{ listStyle: "none" }}
      >
        <li>
          <ExportFileExcel id={id} />
        </li>
        {(pagerState?.search || pagerState?.searchBy) && (
          <li>
            <ResetFilter
              dispatchResetAction={handleResetFilter}
            />
          </li>
        )}
        <li className="c-filter">
          <DropDownList
            className="c-filter-container"
            name="searchByType"
            data={documentTypes || []}
            fillMode="outline"
            textField="label"
            dataItemKey="value"
            defaultItem={INITIAL_VALUES_DROPDOWN}
            onChange={onChangeType}
            value={pagerState.search}
            popupSettings={{ popupClass: "c-filter-content", width: 180 }}
          />
        </li>
      </ul>
    </header>
  );
};

export default HeaderCustomerPayments;
