import React from "react";
import PropTypes from "prop-types";
import "./cell-approver.scss";

const CellApprover = ({ name, type }) => {
  return (
    <td
      colSpan="1"
      className="c-list-orders__name"
      role="gridcell"
      aria-colindex="0"
      aria-selected="false"
      data-grid-col-index="0"
    >
      <strong>{type}</strong>
      <span>{name}</span>
    </td>
  );
};

CellApprover.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
};

export default CellApprover;
