import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import FormEditHeaderOrder from "./viewOrderHeader";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    orderHeaderAccordionTitle: toLanguageString(
      'metislab.frontend.components.order.components.header.orderHeaderAccordionTitle',
      defaultMessages.metislab.frontend.components.order.components.header.orderHeaderAccordionTitle),   
  };
}

const ModalOrder = (props) => {
  const {
    orderHeaderAccordionTitle,
  } = useLocalizedMessages();

  const { handleModal } = props;

  return (
    <Dialog
      className="c-modal-testata"
      title={orderHeaderAccordionTitle}
      onClose={handleModal}
      width={566}
    >
      <FormEditHeaderOrder {...props} />
    </Dialog>
  );
};

export default ModalOrder;
