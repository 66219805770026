import React from "react";
import PropTypes from "prop-types";
import "./cell-total.scss";

const CellTotal = ({ total }) => {

  return (
    <td
      colSpan="1"
      className="c-state-total"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="5"
    >
      {total}
    </td>
  );
};

CellTotal.propTypes = {
  total: PropTypes.string,
};

export default CellTotal;
