import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const indexWebCustomer = createAsyncThunk(
    "indexWebCustomer/getIndexWebCustomer",
    async ( { toast }, { getState, rejectWithValue } ) => {       
        try {
            const { user: { companyChannel } } = getState();
            const response = await http.get("/r/web/customers/rows");
            return {
                res: response.data, 
                company: companyChannel?.key
            };
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const setCompanyRequest = createAsyncThunk(
    "indexWebCustomer/setCompanyRequest",
    async ( { toast, company }, { rejectWithValue } ) => {       
        try {   
            const response = await http.get(`/customer/setCompany/${company?.key}`);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);