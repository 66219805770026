import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const getFragrances = createAsyncThunk(
    "catalogue/getFragrances",
    async ( { toast, pager, tag }, { rejectWithValue } ) => {       
        try {
            let query = "/web/catalogue/fragrances?";
            if (tag) {
                query += `tag=${encodeURIComponent(tag)}`;
            }
            if ( pager ) {
                pager.page && (query += `${tag ? '&' : ''}page=${pager.page}`);
                pager.perPage && (query += `&perPage=${pager.perPage}`);
                pager.class_code && (query += `&class_code=${pager.class_code.key}`);
                pager.fragrance_title && (query += `&fragrance_title=${pager.fragrance_title.key}`);
            }
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getFragrancesFilters = createAsyncThunk(
    "catalogue/getFragrancesFilters",
    async ( {tag, toast}, { rejectWithValue } ) => {       
        try {
            let query = "/web/catalogue/filters";
            if (tag) {
                query += `?tag=${encodeURIComponent(tag)}`;
            }
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getFragranceDetails = createAsyncThunk(
    "catalogue/getFragranceDetails",
    async ( { toast, id }, { rejectWithValue } ) => {       
        try {
            let query = `/web/catalogue/fragrances/${id}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            const { status } = err?.response;
            return rejectWithValue({sMessageText, status});
        }
    }
);

export const getFragranceDetailsNotEditable = createAsyncThunk(
    "catalogue/getFragranceDetailsNotEditable",
    async ( { toast, fragranceCode, idOrder }, { rejectWithValue } ) => {       
        try {
            let query = `/sfas/order/rows/${idOrder}/${fragranceCode}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getOrderFragranceDetails = createAsyncThunk(
    "catalogue/getOrderFragranceDetails",
    async ( { toast, id, idOrder }, { rejectWithValue } ) => {       
        try {
            let query = `/sfas/order/${idOrder}/fragrance/${id}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const downloadExcelCatalogue = createAsyncThunk(
    "catalogue/downloadExcelCatalogue",
    async ( { toast }, { rejectWithValue } ) => {       
        try {    
            const query = '/web/catalogue/downloadExcel';
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getArticleGallery = createAsyncThunk(
    "catalogue/getArticleGallery",
    async ( { articleId, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/web/catalogue/photoGallery/${ articleId }`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);