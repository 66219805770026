import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFragrancesFilters } from "redux/features/catalogue/actions";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import { Skeleton } from "@progress/kendo-react-indicators";
import OrderHeaderAccordion from "./accordion";
import ModalOrder from "./modal";
import Notes from "../notes";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";
import { formatDateByLanguage } from "utils";
import "./order-catalogue-header.scss";
import defaultMessages from "i18n/en.json";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {saveOrderNote, saveOrderTag} from "../../../../redux/features/order/actions";

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {
        addUserNote: toLanguageString(
            "metislab.frontend.components.order.components.header.addUserNote",
            defaultMessages.metislab.frontend.components.order.components.header
                .addUserNote
        ),
        addOrderTag: toLanguageString(
            "metislab.frontend.components.order.components.header.addOrderTag",
            defaultMessages.metislab.frontend.components.order.components.header
                .addOrderTag
        ),
        addOrderTagSuccess: toLanguageString(
            "metislab.frontend.components.order.components.header.addOrderTagSuccess",
            defaultMessages.metislab.frontend.components.order.components.header
                .addOrderTagSuccess
        ),
        alertOrderLabel: toLanguageString(
            "metislab.frontend.components.order.components.header.alertOrderLabel",
            defaultMessages.metislab.frontend.components.order.components.header
                .alertOrderLabel
        ),
        codeCustomerLabel: toLanguageString(
            "metislab.frontend.components.order.components.header.codeCustomerLabel",
            defaultMessages.metislab.frontend.components.order.components.header
                .codeCustomerLabel
        ),
        descriptionCustomerLabel: toLanguageString(
            "metislab.frontend.components.order.components.header.descriptionCustomerLabel",
            defaultMessages.metislab.frontend.components.order.components.header
                .descriptionCustomerLabel
        ),
        editOrderHeader: toLanguageString(
            "metislab.frontend.components.order.components.header.editOrderHeader",
            defaultMessages.metislab.frontend.components.order.components.header
                .editOrderHeader
        ),
        gotoBack: toLanguageString(
            "metislab.frontend.components.order.components.header.gotoBack",
            defaultMessages.metislab.frontend.components.order.components.header
                .gotoBack
        ),
        gotoBackPending: toLanguageString(
            "metislab.frontend.components.order.components.header.gotoBackPending",
            defaultMessages.metislab.frontend.components.order.components.header
                .gotoBackPending
        ),
        gotoBackProcessing: toLanguageString(
            "metislab.frontend.components.order.components.header.gotoBackProcessing",
            defaultMessages.metislab.frontend.components.order.components.header
                .gotoBackProcessing
        ),
        orderHeaderAccordionClosed: toLanguageString(
            "metislab.frontend.components.order.components.header.orderHeaderAccordionClosed",
            defaultMessages.metislab.frontend.components.order.components.header
                .orderHeaderAccordionClosed
        ),
        orderHeaderAccordionOpened: toLanguageString(
            "metislab.frontend.components.order.components.header.orderHeaderAccordionOpened",
            defaultMessages.metislab.frontend.components.order.components.header
                .orderHeaderAccordionOpened
        ),
        orderHeaderAccordionTitle: toLanguageString(
            "metislab.frontend.components.order.components.header.orderHeaderAccordionTitle",
            defaultMessages.metislab.frontend.components.order.components.header
                .orderHeaderAccordionTitle
        ),
        printButtonLabel: toLanguageString(
            "metislab.frontend.components.order.components.header.printButtonLabel",
            defaultMessages.metislab.frontend.components.order.components.header
                .printButtonLabel
        ),
        resetFilterOrderActive: toLanguageString(
            "metislab.frontend.components.order.components.header.resetFilterOrderActive",
            defaultMessages.metislab.frontend.components.order.components.header
                .resetFilterOrderActive
        ),
        viewOrderHeader: toLanguageString(
            "metislab.frontend.components.order.components.header.viewOrderHeader",
            defaultMessages.metislab.frontend.components.order.components.header
                .viewOrderHeader
        ),
        viewOrderNotes: toLanguageString(
            "metislab.frontend.components.order.components.header.viewOrderNotes",
            defaultMessages.metislab.frontend.components.order.components.header
                .viewOrderNotes
        ),
    };
};

const HeaderOrderCatalogue = (props) => {
    const {
        filter,
        filtersActive,
        isPendingOrder,
        isProcessingOrder,
        notEditable,
        filterTag,
        filtersTagActive,
    } = props;

    const {
        addUserNote,
        addOrderTag,
        addOrderTagSuccess,
        alertOrderLabel,
        codeCustomerLabel,
        descriptionCustomerLabel,
        editOrderHeader,
        gotoBack,
        gotoBackPending,
        gotoBackProcessing,
        orderHeaderAccordionClosed,
        orderHeaderAccordionOpened,
        orderHeaderAccordionTitle,
        printButtonLabel,
        resetFilterOrderActive,
        viewOrderHeader,
        viewOrderNotes,
    } = useLocalizedMessages();

    const intl = useInternationalization();

    const location = useLocation();

    const dispatch = useDispatch();

    const headerRefs = useRef();
    const categoriesRefs = useRef();

    /**
     * Fragrance states
     */
    const filters = useSelector((state) => state.fragrance.filters);

    /**
     * Order states
     */
    const loading = useSelector((state) => state.order.loading);
    const orderHeaderNotEditable = useSelector(
        (state) => state.order.orderHeaderNotEditable
    );
    const orderActions = useSelector((state) => state.order.orderActions);
    const orderAlerts = useSelector((state) => state.order.orderAlerts);
    const orderInfo = useSelector((state) => state.order.orderInfo);
    const orderHeader = useSelector((state) => state.order);


    const permissionsUser = useSelector((state) => state.user.permissions);
    const comboOrderTags = useSelector((state) => state.user.comboOrderTags);

    const orderCountNotes = useSelector(
        (state) => state.order.notesCountNotEditable
    );

    /**
     permesso per poter editare il tag associato ad un ordine.
     */
    const bCanEditOrderTag = permissionsUser.orderTagsEdit ?? false; //TODO: mettere permessi giusti


    const tags = useSelector((state) => state.order.tagPromo);
    /**
     * indexWebCustomer states
     */
    const selectedCompany = useSelector(
        (state) => state.indexWebCustomer.selectedCompany
    );

    /**
     * SearchProduct states
     */
    const articleCode = useSelector((state) => state.searchProduct.articleCode);

    const languageDateFormat = formatDateByLanguage(intl.locale);

    const [openHeaderAccordion, setOpenHeaderAccordion] = useState([
        "orderHeader",
    ]);

    const [openModalViewOrderHeader, setOpenModalViewOrderHeader] =
        useState(false);
    const [openModalViewNotes, setOpenModalViewNotes] = useState(
        location.state?.showNotes || false
    );

    const [orderTagDropDown, setOrderTagDropDown] = useState(  null)

    /**
     * handleFilter is a callback hook to handle
     * the behaviour of the filter's buttons clicked
     */
    const handleFilter = useCallback(
        (option) => {
            filter(option);
        },
        [filter]
    );

    /**
     * handleFilterTag is a callback hook to handle
     * the behaviour of the filter's tags buttons clicked
     */
    const handleFilterTag = useCallback(
        (tag) => {
            filterTag(tag);
        },
        [filterTag]
    );

    /**
     * getListOfFilter is a callback's hook
     * used to get the list of fragrances shows in the
     * header of catalogue's order
     */
    const getListOfFilter = useCallback(() => {
        if (!notEditable && selectedCompany) {
            dispatch(getFragrancesFilters({ toast }));
        }
    }, [dispatch, notEditable, selectedCompany]);

    /**
     * Hooks to get list of filter
     */
    useEffect(() => {
        getListOfFilter();
    }, [getListOfFilter]);

    /**
     * Hooks to handle filter
     */
    useEffect(() => {
        if (
            !notEditable &&
            articleCode &&
            (filtersActive.length > 0 || filtersTagActive.length > 0)
        ) {
            handleFilter(null);
            handleFilterTag(null);
        }
    }, [
        articleCode,
        filtersActive,
        filtersTagActive,
        handleFilter,
        handleFilterTag,
        notEditable,
    ]);

    useEffect(() => {
        if(orderInfo){
            if(comboOrderTags.find(tag => tag.key === orderInfo.tag)){
                setOrderTagDropDown(comboOrderTags.find(tag => tag.key === orderInfo.tag))
            }else{
                setOrderTagDropDown(comboOrderTags.find(tag=>tag.key ==="") ?? null)
            }
        }
    }, [orderInfo]);

    useEffect(() => {
        if (filters) {
            const el = categoriesRefs.current;
            const header = headerRefs.current;
            const observer = new IntersectionObserver(
                ([e]) => el?.classList?.toggle("u-sticky", e.intersectionRatio < 1),
                {
                    rootMargin: "0px 0px 0px 0px",
                    threshold: [1],
                }
            );
            observer.observe(header);
        }
    }, [filters]);

    /**
     * Convert the object of filters into array of filters
     */
    const arrayOfFilters =
        filters &&
        Object.keys(filters)
            .map((key) => filters[key])
            .filter((obj) => obj.field === "class_code");

    const accordionHeader = [
        {
            id: "orderHeader",
            title: " ",
            content: (
                <OrderHeaderAccordion headerOrder={orderHeaderNotEditable || []} />
            ),
        },
    ];
    const components = PanelBarUtils.mapItemsToComponents(accordionHeader);

    const handleOrderHeaderAccordion = () => {
        openHeaderAccordion.length > 0
            ? setOpenHeaderAccordion([])
            : setOpenHeaderAccordion(["orderHeader"]);
    };

    /**
     * handleModalOrderHeader is used to handle the visualization
     * of the modal to edit the header of an order
     */
    const handleModalOrderHeader = () => {
        setOpenModalViewOrderHeader((prevState) => !prevState);
    };

    /**
     * handleNotes is used to handle the visualization
     * of the modal to view the notes of an order
     */
    const handleNotes = () => {
        location.state = {};
        setOpenModalViewNotes((prevState) => !prevState);
    };

    /**
     * handleOrderTag is used to handle the visualization
     * of the modal to view the tag of an order
     */
    const handleOrderTag = (event) => {
        if (event.key !== null && orderInfo?.id) {
            setOrderTagDropDown(event);
            const tag = event.key;
            const args = {
                id: orderInfo?.id,
                data: tag,
                toast,
                messageNotification:  addOrderTagSuccess,
            };
            dispatch(saveOrderTag(args));
        }
    };

    const statusOrder = orderHeaderNotEditable?.filter(
        (field) => field.attribute === "status_field"
    );
    const statusOrderLabel = statusOrder && statusOrder[0]?.value;

    const createGoToBackLink = useMemo(() => {
        if (isProcessingOrder) {
            return "/orders/processing";
        }
        if (isPendingOrder) {
            return "/orders/pending";
        }
        return "/orders";
    }, [isProcessingOrder, isPendingOrder]);

    const createGoToBackLinkLabel = useMemo(() => {
        if (isProcessingOrder) {
            return gotoBackProcessing;
        }
        if (isPendingOrder) {
            return gotoBackPending;
        }
        return gotoBack;
    }, [
        gotoBack,
        gotoBackPending,
        gotoBackProcessing,
        isProcessingOrder,
        isPendingOrder,
    ]);

    const ddrDownItemRender = (li, itemProps) => {

        const itemChildren = (
            <span
                className={itemProps.dataItem.type ? 'c-dropdown-order-tag-item c-background-type-'+itemProps.dataItem.type+'__'+itemProps.dataItem.label : ''}
            >
        {itemProps.dataItem.type == '' ? itemProps.dataItem.label : ''}
      </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };
    const ddrDownValueRender = (element, value) => {
        if (!value) {
            return element;
        }
        const children = [
            <span className={value.type ? 'c-dropdown-order-tag-value c-background-type-'+value.type+'__'+value.label : ''} >&nbsp;
                {value.type == '' ? element.props.children : ''}
            </span>,
        ];
        return React.cloneElement(
            element,
            {
                ...element.props,
            },
            children
        );
    };


    return (
        <>
            <header
                className="c-subheader c-subheader--catalogue k-mb-5"
                ref={headerRefs}
            >
                <Link className="c-subheader__link" to={createGoToBackLink}>
                    <span className="k-icon k-i-arrow-left"></span>
                    {createGoToBackLinkLabel}
                </Link>
                {notEditable &&
                    !orderActions?.canEdit &&
                    orderActions?.orderDisabledMessage.length > 0 && (
                        <div
                            className="c-disabled-message c-disabled-message-order-detail"
                            dangerouslySetInnerHTML={{
                                __html: orderActions.orderDisabledMessage,
                            }}
                        />
                    )}
                <div className="l-tablet-flex u-flex-start u-flex-between">
                    <div className="c-subheader__main">
                        <h1 className="c-subheader__title t-title t-title--2">
                            {loading ? (
                                <Skeleton />
                            ) : (
                                <>
                                    {orderInfo?.code}
                                    <span className="c-subheader__date">
                    {` del ${intl.formatDate(
                        new Date(orderInfo?.creation_date),
                        languageDateFormat
                    )}`}
                  </span>
                                    {notEditable && (
                                        <span
                                            className={`c-subheader__status s-${orderInfo?.state}`}
                                        >
                      {loading ? <Skeleton /> : statusOrderLabel}
                    </span>
                                    )}
                                </>
                            )}
                        </h1>
                        <div className="l-tablet-flex u-flex-start" style={{ gap: 8 }}>
                            <p className="c-subheader__text l-tablet-flex k-ml-0">
                                <strong>{codeCustomerLabel}</strong>
                                <span className="k-pl-1">
                  {loading ? <Skeleton /> : orderInfo?.search_code}
                </span>
                            </p>
                            <p className="c-subheader__text l-tablet-flex k-ml-0">
                                <strong>{descriptionCustomerLabel}</strong>
                                <span className="k-pl-1">
                  {loading ? (
                      <Skeleton />
                  ) : (
                      <>
                          {orderInfo?.customer?.fido === "1" && (
                              <span className="k-icon k-i-lock"></span>
                          )}
                          {orderInfo?.customer_description}
                      </>
                  )}
                </span>
                            </p>
                        </div>
                    </div>
                    <ul className="c-subheader__actions l-mobile-flex u-flex-middle ">

                        {(bCanEditOrderTag && comboOrderTags ) && <li className={'handle-order-tag'}>
                            <DropDownList
                                className="c-filter-container c-order-tag-dropodown"
                                name="searchByKey"
                                data={comboOrderTags
                                // [
                                //     {key: 'no-tag',  label: 'Nessun Tag'},
                                //     {key: 'tag-t-1', type: 'triangle',   label: '1'},
                                //     {key: 'tag-t-2', type: 'triangle',   label: '2'},
                                //     {key: 'tag-t-3', type: 'triangle',   label: '3'},
                                //     {key: 'tag-s-1', type: 'square',     label: '1'},
                                //     {key: 'tag-s-2', type: 'square',     label: '2'},
                                //     {key: 'tag-s-3', type: 'square',     label: '3'},
                                // ]
                                }
                                // defaultItem={ comboOrderTags.find(tag=>tag.key ==="") ??  null}

                                fillMode="outline"
                                textField="label"
                                dataItemKey="key"
                                itemRender={ddrDownItemRender}
                                valueRender={ddrDownValueRender}
                                value={orderTagDropDown}
                                onChange={(e)=>handleOrderTag(e.target.value)}

                                popupSettings={{ popupClass: "c-filter-content c-order-tag-content", width:120 }}
                            />
                        </li>}
                        {!notEditable && (
                            <>
                                {loading ? (
                                    <Skeleton />
                                ) : (
                                    <li>
                                        <Button
                                            className="c-headerboard__link u-pl-0"
                                            icon="preview"
                                            fillMode="flat"
                                            style={{
                                                textTransform: "none",
                                            }}
                                            onClick={handleModalOrderHeader}
                                        >
                                            {viewOrderHeader}
                                        </Button>
                                    </li>
                                )}
                            </>
                        )}
                        {loading ? (
                            <Skeleton />
                        ) : (
                            <>
                                {notEditable && (
                                    <li>
                                        <Link
                                            className="c-headerboard__link c-headerboard__link--small c-link-print"
                                            target="_blank"
                                            to={`/print/${orderInfo?.id}`}
                                        >
                                            <span className="k-icon k-i-print"></span>
                                            <span className="k-button-text">{printButtonLabel}</span>
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    {orderCountNotes !== 0 || !orderInfo?.canEdit ? (
                                        <Button className="" icon="file" onClick={handleNotes}>
                                            {`${viewOrderNotes} ${orderCountNotes}`}
                                        </Button>
                                    ) : (
                                        <Button
                                            className=""
                                            icon="add"
                                            fillMode="solid"
                                            onClick={handleNotes}
                                            style={{ color: "white" }}
                                        >
                                            {addUserNote}
                                        </Button>
                                    )}
                                </li>
                            </>
                        )}
                    </ul>
                </div>
                {notEditable && orderAlerts.length > 0 && (
                    <div className="c-order-alerts">
                        <strong>
                            <span className="icon icon-alert"></span>
                            {alertOrderLabel}
                        </strong>
                        <ul className="c-order-alerts__list">
                            {orderAlerts.map((alert, index) => (
                                <li
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: alert }}
                                ></li>
                            ))}
                        </ul>
                    </div>
                )}
                {/* Edit 5/12 spostare il messaggio tra vai ad elenco ordini e titolo ordine*/}
                {/*{notEditable &&*/}
                {/*  !orderActions?.canEdit &&*/}
                {/*  orderActions?.orderDisabledMessage.length > 0 && (*/}
                {/*    <div*/}
                {/*      className="c-disabled-message"*/}
                {/*      dangerouslySetInnerHTML={{*/}
                {/*        __html: orderActions.orderDisabledMessage,*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  )}*/}
                {notEditable && (
                    <div className="c-headerboard">
                        <div className="c-headerboard__top l-tablet-flex u-flex-middle u-flex-between">
                            <div className="c-headerboard__top-container l-tablet-flex u-flex-middle">
                                <h3 className="c-headerboard__title">
                                    {orderHeaderAccordionTitle}
                                </h3>
                                {orderActions?.canEdit && (
                                    <Button
                                        className="c-headerboard__link c-headerboard__link--edit"
                                        icon="pencil"
                                        fillMode="flat"
                                        onClick={handleModalOrderHeader}
                                        style={{
                                            color: "#610d11",
                                            textTransform: "none",
                                        }}
                                    >
                                        {editOrderHeader}
                                    </Button>
                                )}
                            </div>
                            <Button
                                className="c-headerboard__link c-headerboard__link--open"
                                icon={
                                    openHeaderAccordion.length > 0 ? "chevron-up" : "chevron-down"
                                }
                                dir="rtl"
                                fillMode="flat"
                                onClick={handleOrderHeaderAccordion}
                                style={{
                                    color: "#610d11",
                                    textTransform: "none",
                                }}
                            >
                                {openHeaderAccordion.length > 0
                                    ? orderHeaderAccordionClosed
                                    : orderHeaderAccordionOpened}
                            </Button>
                        </div>
                        <PanelBar
                            isControlled={true}
                            expanded={openHeaderAccordion}
                            children={components}
                        />
                    </div>
                )}
            </header>
            {!notEditable && (
                <>
                    <ul
                        className="c-tags"
                        ref={categoriesRefs}
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 8,
                        }}
                    >
                        {arrayOfFilters &&
                            arrayOfFilters.map((filter) => {
                                const options = Object.entries(filter.options).map(
                                    ([_key, _value]) => {
                                        return { key: _key, label: _value };
                                    }
                                );

                                return options.map((option) => (
                                    <li key={option.key} className="c-filter">
                                        <Button
                                            className={`c-subheader__button ${
                                                filtersActive.includes(option.label)
                                                    ? "c-subheader__button--selected"
                                                    : ""
                                            } `}
                                            icon={
                                                filtersActive.includes(option.label) ? "close" : null
                                            }
                                            dir="rtl"
                                            fillMode="outline"
                                            onClick={() => handleFilter(option.label)}
                                        >
                                            {option.label}
                                        </Button>
                                    </li>
                                ));
                            })}
                        {tags.map((tag) => {
                            return (
                                <li key={tag} className="c-filter c-filter--tags">
                                    <Button
                                        className={`c-subheader__button ${
                                            filtersTagActive.includes(tag)
                                                ? "c-subheader__button--selected"
                                                : ""
                                        } `}
                                        icon={filtersTagActive.includes(tag) ? "close" : null}
                                        fillMode="outline"
                                        onClick={() => handleFilterTag(tag)}
                                    >
                                        {tag}
                                    </Button>
                                </li>
                            );
                        })}
                        {(filtersActive.length > 0 || filtersTagActive.length > 0) && (
                            <Button
                                className="c-subheader__button c-close-filter"
                                icon="close"
                                dir="rtl"
                                fillMode="flat"
                                style={{ color: "#610d11" }}
                                onClick={() => {
                                    handleFilter(null);
                                    handleFilterTag(null);
                                }}
                            >
                                {resetFilterOrderActive}
                            </Button>
                        )}
                    </ul>
                </>
            )}
            {openModalViewOrderHeader && (
                <ModalOrder
                    handleModal={handleModalOrderHeader}
                    notEditable={notEditable}
                />
            )}
            {openModalViewNotes && (
                <Notes handleModal={handleNotes} notEditable={notEditable} />
            )}
        </>
    );
};

export default HeaderOrderCatalogue;
