import React from "react";
import PropTypes from "prop-types";

const TooltipTemplate = ({ item }) => {
  
  return (
    <div dangerouslySetInnerHTML={{__html: item}} />
  );
};

TooltipTemplate.propTypes = {
  item: PropTypes.string,
};

export default TooltipTemplate;
