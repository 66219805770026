import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FormPasswordChange from './components/form-password-recovery';

const Login = () => {
  const { authenticated } = useSelector((state) => ({ ...state.user }));

  return authenticated
    ? <Navigate to="/dashboard" /> 
    : <FormPasswordChange />
}

export default Login;