import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import "./header.scss";

const HeaderProspectsAdd = () => {
  return (
    <header className="c-subheader c-subheader--new-prospect">
      <Link className="c-subheader__link" to="/prospects">
        <span className="k-icon k-i-arrow-left"></span>
        Torna all’elenco dei Prospect
      </Link>
      <div className="l-tablet-flex u-flex-middle u-flex-between">
        <div className="l-desktop-big-flex u-flex-baseline">
          <h1 className="c-subheader__title t-title t-title--2">
            Inserisci nuovo Prospect
          </h1>
          <div className="c-subheader__text l-tablet-flex">
            <span className="c-prospect-state">da approvare</span>
          </div>
        </div>
        <div
          className="c-subheader__buttons l-tablet-flex u-flex-middle"
          style={{ gap: 16 }}
        >
          <Link
            className="k-button k-button-md k-button-solid k-button-solid-base k-button-outline k-rounded-md"
            to="/prospects"
          >
            Annulla
            <span className="k-icon k-i-x"></span>
          </Link>
          <Button icon="tick" fillMode="solid" dir="rtl" type="submit">
            Salva Prospect
          </Button>
        </div>
      </div>
    </header>
  );
};

export default HeaderProspectsAdd;
