import React from 'react';
import PropTypes from 'prop-types';
import { useInternationalization } from '@progress/kendo-react-intl';
import { formatDateByLanguage } from "utils";

const CellDate = ({ date, isExpired }) => {

    const intl = useInternationalization();
    const languageDateFormat = formatDateByLanguage(intl.locale);

    return (
        <td 
            colSpan="1" 
            className={ isExpired && "c-row-expired"}
            role="gridcell" 
            aria-colindex="9"
            aria-selected="false"
            data-grid-col-index="8"
        >
            { intl.formatDate(
                new Date(date),
                languageDateFormat
            )} 
        </td>
    )
}

CellDate.propTypes = {
    date: PropTypes.string,
    isExpired: PropTypes.bool,
}

export default CellDate;