import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const getOrders = createAsyncThunk(
    "order/getOrders",
    async ( { pager, toast }, { rejectWithValue } ) => {       
        try {
            let query = "/r/sfas/orders?";     
            if ( pager ) {
                pager.page && (query += `page=${pager.page}`);
                pager.perPage && (query += `&perPage=${pager.perPage}`);
                pager.orderBy && (query += `&orderBy=${pager.orderBy}`);
                pager.orderByDirection && (query += `&orderByDirection=${pager.orderByDirection}`);
                pager.search && (query += `&search=${encodeURIComponent(pager.search)}`);   
                pager.searchBy && (
                    query += `&searchBy=${
                        pager.searchBy.propId === 'search_code'
                            ? 'customer_code'
                            : pager.searchBy.propId
                    }`
                );
                if (pager.filterFields.length > 0) {
                    const encodedFields = encodeURIComponent(JSON.stringify(pager.filterFields));
                    query += `&filterFields=${encodedFields}`;
                } 
            }     
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(sMessageText);
        }
    }
);

export const getOrder = createAsyncThunk(
    "order/getOrder",
    async ( {id, toast}, { rejectWithValue } ) => {       
        try {
            let query = `/web/catalogue/order/${id}`;          
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            const { status } = err?.response;
            return rejectWithValue({sMessageText, status});
        }
    }
);

export const setOrderFragrance = createAsyncThunk(
    "order/setOrderFragrance",
    async ({ toast, fragrance_code, id_order, data, messageNotification }, { rejectWithValue }) => {       
        try {         
            let query = `/sfas/order/rows/${id_order}/${fragrance_code}`;     
            const response = await http.put(query, data);
            toast.success(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );           
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getNewOrderHeaderFields = createAsyncThunk(
    "order/getNewOrderHeaderFields",
    async ({ toast }, { rejectWithValue }) => {       
        try {         
            const query = '/sfas/order/create';     
            const response = await http.get(query);     
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getOrderNotEditable = createAsyncThunk(
    "order/getOrderNotEditable",
    async ({ toast, id }, { rejectWithValue }) => {       
        try {         
            let query = `/sfas/order/${id}`;     
            const response = await http.get(query); 
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            const { status } = err?.response;
            return rejectWithValue({sMessageText, status});
        }
    }
);

export const getEditOrderHeaderFields = createAsyncThunk(
    "order/getEditOrderHeaderFields",
    async ({ id, toast }, { rejectWithValue }) => {       
        try {         
            const query = `/sfas/order/edit/${id}`;   
            const response = await http.get(query);     
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const setHeaderCurrentOrder = createAsyncThunk(
    "order/setHeaderCurrentOrder",
    async ({ toast, id, data, messageNotification }, { rejectWithValue }) => {       
        try {         
            let query = `/sfas/order/update/${id}`;     
            const response = await http.put(query, data);
            toast.success(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );           
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getOrdersPending = createAsyncThunk(
    "order/getOrdersPending",
    async ( { pager, toast }, { rejectWithValue } ) => {       
        try {
            let query = "/r/sfas/orders/approving?";     
            if ( pager ) {
                pager.page && (query += `page=${pager.page}`);
                pager.perPage && (query += `&perPage=${pager.perPage}`);
                pager.orderBy && (query += `&orderBy=${pager.orderBy}`);
                pager.orderByDirection && (query += `&orderByDirection=${pager.orderByDirection}`);
                pager.search && (query += `&search=${encodeURIComponent(pager.search)}`);   
                pager.searchBy && (
                    query += `&searchBy=${
                        pager.searchBy.propId === 'search_code'
                            ? 'customer_code'
                            : pager.searchBy.propId
                    }`
                );            }     
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(sMessageText);
        }
    }
);

export const getOrderPromo = createAsyncThunk(
    "order/getOrderPromo",
    async ( {id, toast}, { rejectWithValue } ) => {       
        try {
            let query = `/sfas/order/rows/${id}/promo`;   
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const setPromotionsOrder = createAsyncThunk(
    "order/setPromotionsOrder",
    async ({ toast, id, data, messageNotification }, { rejectWithValue }) => {       
        try {         
            let query = `/sfas/order/rows/${id}/promo`;     
            const response = await http.put(query, data);    
            toast.success(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );      
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getOrderNotes = createAsyncThunk(
    "order/getOrderNotes",
    async ( {id, toast}, { rejectWithValue } ) => {       
        try {
            let query = `/sfas/order/${id}/notes`;   
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const saveOrderNote = createAsyncThunk(
    "order/saveOrderNote",
    async ( {id, data, toast, messageNotification}, { rejectWithValue } ) => {
        try {
            let query = `/sfas/order/${id}/notes`;
            const response = await http.post(query, { note: data });
            toast.success(
                <Notification
                    message={messageNotification}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);
export const saveOrderTag = createAsyncThunk(
    "order/saveOrderTag",
    async ( {id, data, toast, messageNotification},
            { rejectWithValue } ) => {
        try {
            let query = `/sfas/order/${id}/orderTag`;
            const response = await http.post(query, { tag: data });
            toast.success(
                <Notification
                    message={messageNotification}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getDocumentsOrder = createAsyncThunk(
    "order/getDocumentsOrder",
    async ( { id_order, toast }, { rejectWithValue } ) => {       
        try {
            const query = `/r/xtr/administrativeDocuments/?order=${id_order}`;     
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(sMessageText);
        }
    }
);

export const getOrdersProcessing = createAsyncThunk(
    "order/getOrdersProcessing",
    async ( { pager, toast }, { rejectWithValue } ) => {       
        try {
            let query = "/r/sfas/orders/delivering?";     
            if ( pager ) {
                pager.page && (query += `page=${pager.page}`);
                pager.perPage && (query += `&perPage=${pager.perPage}`);
                pager.orderBy && (query += `&orderBy=${pager.orderBy}`);
                pager.orderByDirection && (query += `&orderByDirection=${pager.orderByDirection}`);
                pager.search && (query += `&search=${encodeURIComponent(pager.search)}`);   
                pager.searchBy && (
                    query += `&searchBy=${
                        pager.searchBy.propId === 'search_code'
                            ? 'customer_code'
                            : pager.searchBy.propId
                    }`
                );            }     
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            toast.error(
                <Notification 
                    message={sMessageText} 
                    type="error"
                />
            );
            return rejectWithValue(sMessageText);
        }
    }
);

export const getConcurrentOrderAccess = createAsyncThunk(
    "order/getConcurrentOrderAccess",
    async ( {idOrder, data}, { rejectWithValue } ) => {       
        try {
            let query = `/sfas/order/${idOrder}/concurrentUsers`;   
            const response = await http.post(query, { edit: data }); 
            return response.data;
        } catch (err) {
            // const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            return rejectWithValue(err.message);
        }
    }
);
