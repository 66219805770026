import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import { load, loadMessages } from '@progress/kendo-react-intl';

// required data (must be always loaded)
import likelySubTags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

// locale-dependent data
import itNumbers from 'cldr-numbers-full/main/it/numbers.json';
import itCurrency from 'cldr-numbers-full/main/it/currencies.json';
import itTimeZoneNames from 'cldr-dates-full/main/it/timeZoneNames.json';
import itCaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itDateFields from 'cldr-dates-full/main/it/dateFields.json';

// localized messages (catalogs)
// import enMessages from './en.json';
// import itMessages from './it.json';

export const setupLocalization = (labelsEn, labelsIt) => {

    load(
        likelySubTags,
        currencyData,
        weekData,

        itNumbers,
        itCurrency,
        itTimeZoneNames,
        itCaGregorian,
        itDateFields,

        // add here other CLDR objects
    );

    // Each message collection must be explicitly loaded for each variant of any accepted language tag.
    loadMessages(labelsEn, 'en');
    loadMessages(labelsEn, 'en-US');

    loadMessages(labelsIt, 'it');
    loadMessages(labelsIt, 'it-IT');
    loadMessages(labelsIt, 'it-CH');
    // add here other message catalogs
};

export const I18NProvider = ({ children, lang }) => {
    return (
        <LocalizationProvider language={lang}>
            <IntlProvider locale={lang}>
                {children}
            </IntlProvider>
        </LocalizationProvider>
    );
};