import { createSlice } from "@reduxjs/toolkit";
import { 
    getEditOrderHeaderFields,
    getNewOrderHeaderFields,
    getOrder,
    getOrderNotEditable,
    getOrderNotes,
    getOrderPromo,
    getOrders,
    getOrdersPending,
    getOrdersProcessing,
    saveOrderNote,
    setOrderFragrance,
    setHeaderCurrentOrder,
    setPromotionsOrder,
    getDocumentsOrder,
    getConcurrentOrderAccess,
} from "./actions";
import { keyBy, merge } from 'lodash';

const initialState =  { 

    concurrentAccessOrderTotalUsers: 0,
    concurrentAccessOrderUsersInEdit: [],
    concurrentAccessOrderUsersInView: [],
    concurrentAccessOrderUsersIsChanged: false,

    documentsOrder: null,

    error: null,

    errorOrderInEditing: false,
    errorOrderInEditingStatus: null,
    
    errorNotEditable: false,
    errorNotEditableStatus: null,

    filters: null,
    layoutColumn: null,

    loading: false,
    loadingPending: false,
    loadingProcessing: false,
    loadingConcurrentAccessOrder: false,
    loadingCheckoutOrder: false,
    loadingEditHeaderOrder: false,
    loadingPromoOrder: false,
    loadingSetOrderFragrance: false,
    loadingNewOrderHeaderFields: false,
    loadingSaveNote: false,
    loadingSetPromoOrder: false,
    loadingDocumentsOrder: false,

    currentOrderHeader: null,
    currentOrder: null,

    orderActions: null,
    orderAlerts: [],
    orderHasPromoToSelect: null,
    orderPromos: null,

    notesCountNotEditable: null,

    newOrderHeader: null,
    orderHeaderActive: null,

    orderHeaderNotEditable: null,
    orderFooterNotEditable: null,

    orderList: null,
    orderListNotEditable: null,
    orderInfo: null,
    orderNotes: [],

    orderPermissions: null,

    orders: null,
    openOrders: null,
    
    pagerState: {
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        filterFields: [],
        search: null,
        searchBy: null,
    },
    pagerStateOrderPending: {
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
    },
    pagerStateOrderProcessing: {
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
    },
    pageSizes: null,
    pagination: null,

    tagPromo: [],
    tagOrder: null,
};

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        deactivedOrderSync: (state) => {
            state.orderList = null;
        },
        resetConcurrentAccessOrderUsersIsChanged: (state) => {
            state.concurrentAccessOrderUsersIsChanged = false;
        },
        resetErrors: (state, action) => {
            state.errorNotEditable = null;
        },
        setOrderHasPromoToSelect: (state) => {
            state.orderHasPromoToSelect = {
                ...state.orderHasPromoToSelect,
                openCartPromo: !state.orderHasPromoToSelect.openCartPromo
            };
        },
        setPagerState: (state, action) => {
            state.pagerState = { ...state.pagerState, ...action.payload };
        },
        setPagerStateOrderPending: (state, action) => {
            state.pagerStateOrderPending = { ...state.pagerStateOrderPending, ...action.payload };
        },
        setPagerStateOrderProcessing: (state, action) => {
            state.pagerStateOrderProcessing = { ...state.pagerStateOrderProcessing, ...action.payload };
        },
        setTotalOrder: (state, action) => {
            state.orderFooterNotEditable = action.payload;
        },
        unsetOrderHeaderNotEditable: (state) => {
            state.orderHeaderNotEditable = null;
        },
        unsetOrderFooterNotEditable: (state) => {
            state.orderFooterNotEditable = null;
            state.orderActions = null;
            state.orderInfo = null;
            state.orderAlerts = [];
            state.orderHasPromoToSelect = null;
            state.orderHeaderNotEditable = null;
            state.orderPromos = null;
            state.concurrentAccessOrderTotalUsers = 0;
            state.concurrentAccessOrderUsersInEdit = [];
            state.concurrentAccessOrderUsersInView = [];
            state.concurrentAccessOrderUsersIsChanged = false;
        },
    },
    extraReducers: {
        /**
         * Get paginated list of orders 
         */
        [getOrders.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [getOrders.fulfilled]: (state, action) => {
            const {
                grid: {
                    columns,
                    layout: { children },
                    pageSizes
                },
                orderPermissions,
                table: {
                    page,
                    pages,
                    rows,
                    size,
                    total,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });
            state.loading = false;
            state.orders = rows;
            state.pageSizes = pageSizes;
            state.layoutColumn = arrayMergeColumnsAndChildren;   
            state.orderPermissions = orderPermissions;     
            state.pagination = {
                page,
                pages,
                size,
                total,
            };
        },
        [getOrders.rejected]: (state, action) => {
            state.loading = false;
            state.orders = null;
            state.error = action.payload;
        },         
        /**
         * Get list of an active order
         */
        [getOrder.pending]: (state) => {
            state.loading = true;
            state.errorNotEditable = false;
            state.errorOrderInEditing = false;
            state.errorNotEditableStatus = null;
            state.errorOrderInEditingStatus = null;
        },
        [getOrder.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                footer,
                notes_count,
                order, 
                orderInfo, 
                selectedOrder,
                tag_promo,
                tag_order
            } = action.payload;
            state.tagPromo = tag_promo;
            state.tagOrder = tag_order;
            state.currentOrder = selectedOrder;
            state.orderFooterNotEditable = footer;
            state.notesCountNotEditable = notes_count;
            state.orderList = order;    
            state.orderInfo = orderInfo;                      
        },
        [getOrder.rejected]: (state, action) => {
            state.errorOrderInEditing = action.payload?.sMessageText;
            state.errorOrderInEditingStatus = action.payload?.status;
            state.loading = false;
        },  
        /**
         * Set a fragrance in an order
         */
        [setOrderFragrance.pending]: (state) => {
            state.loadingSetOrderFragrance = true;
        },
        [setOrderFragrance.fulfilled]: (state, action) => {      
            state.loadingSetOrderFragrance = false;
            const { order } = action.payload;
            state.orderList = order;                          
        },
        [setOrderFragrance.rejected]: (state, action) => {
            state.loadingSetOrderFragrance = false;
        },  
        /**
         * Create the header of a new order 
         */
        [getNewOrderHeaderFields.pending]: (state) => {
            state.loadingNewOrderHeaderFields = true;
        },
        [getNewOrderHeaderFields.fulfilled]: (state, action) => {      
            state.loadingNewOrderHeaderFields = false;
            const { 
                panels: { fields },
                openOrder,
            } = action.payload;
            state.openOrders = openOrder;
            state.newOrderHeader = fields;
        },
        [getNewOrderHeaderFields.rejected]: (state, action) => {
            state.loadingNewOrderHeaderFields = false;
        },              
        /**
         * Get list of an order not editable (mode only view)
         */
        [getOrderNotEditable.pending]: (state) => {
            state.loading = true;
            state.errorNotEditable = false;
            state.errorOrderInEditing = false;
            state.errorNotEditableStatus = null;
            state.errorOrderInEditingStatus = null;
        },
        [getOrderNotEditable.fulfilled]: (state, action) => {  
            state.loading = false;
            const { 
                actions, 
                alerts,
                footer,
                header, 
                notes_count,
                order,
                orderInfo,  
            } = action.payload;
            state.notesCountNotEditable = notes_count;
            state.orderActions = actions;
            state.orderAlerts = alerts;
            state.orderHeaderNotEditable = header;
            state.orderFooterNotEditable = footer;
            state.orderInfo = orderInfo;
            state.orderListNotEditable = order;  
            if (action.payload.hasOwnProperty('promoSelection')) {
                const { alert, selected } = action.payload.promoSelection; 
                state.orderHasPromoToSelect = {
                    alert , 
                    openCartPromo: !selected,
                    selected: selected,
                };
            } else {
                state.orderHasPromoToSelect = null;
            }                   
        },
        [getOrderNotEditable.rejected]: (state, action) => {
            state.errorNotEditable = action.payload?.sMessageText;
            state.errorNotEditableStatus = action.payload?.status;
            state.loading = false;
        },   
        /**
         * Get the header of the current active order 
         */
        [getEditOrderHeaderFields.pending]: (state) => {
            state.loadingNewOrderHeaderFields = true;
        },
        [getEditOrderHeaderFields.fulfilled]: (state, action) => {      
            state.loadingNewOrderHeaderFields = false;
            const { 
                panels: { fields } 
            } = action.payload;
            state.orderHeaderActive = fields;
        },
        [getEditOrderHeaderFields.rejected]: (state, action) => {
            state.loadingNewOrderHeaderFields = false;
        },
        /**
         * Set the header of the current active order 
         */
        [setHeaderCurrentOrder.pending]: (state) => {
            state.loadingEditHeaderOrder = true;
        },
        [setHeaderCurrentOrder.fulfilled]: (state, action) => {      
            state.loadingEditHeaderOrder = false;
            const { header } = action.payload;
            state.orderHeaderNotEditable = header;
        },
        [setHeaderCurrentOrder.rejected]: (state, action) => {
            state.loadingEditHeaderOrder = false;
        },
        /**
         * Get paginated list of pending's orders 
         */
        [getOrdersPending.pending]: (state, action) => {
            state.loadingPending = true;
            state.error = null;
        },
        [getOrdersPending.fulfilled]: (state, action) => {
            const {
                grid: {
                    columns,
                    layout: { children },
                    pageSizes
                },
                table: {
                    page,
                    pages,
                    rows,
                    size,
                    total,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });
            state.loadingPending = false;
            state.orders = rows;
            state.pageSizes = pageSizes;
            state.layoutColumn = arrayMergeColumnsAndChildren;        
            state.pagination = {
                page,
                pages,
                size,
                total,
            };
        },
        [getOrdersPending.rejected]: (state, action) => {
            state.loadingPending = false;
            state.orders = null;
            state.error = action.payload;
        },           
        /**
         * get promo of an order
         */
        [getOrderPromo.pending]: (state) => {
            state.loadingPromoOrder = true;
        },
        [getOrderPromo.fulfilled]: (state, action) => {
            state.loadingPromoOrder = false; 
            const { article_list } = action.payload;
            state.orderPromos = article_list;
        },
        [getOrderPromo.rejected]: (state, action) => {
            state.loadingPromoOrder = false;  
        },  
        /**
         * set promo of an order
         */
        [setPromotionsOrder.pending]: (state) => {
            state.loadingSetPromoOrder = true;
        },
        [setPromotionsOrder.fulfilled]: (state, action) => {
            state.loadingSetPromoOrder = false; 
            const {
                actions,
                alerts,
                footer,
                header,
                notes_count,
                promoSelection: {
                    alert,
                    selected
                },
                order,
                orderInfo,
            } = action.payload;
            state.notesCountNotEditable = notes_count;
            state.orderActions = actions;
            state.orderAlerts = alerts;
            state.orderHeaderNotEditable = header;
            state.orderFooterNotEditable = footer;
            state.orderInfo = orderInfo;
            state.orderListNotEditable = order;
            state.orderHasPromoToSelect = {
                alert , 
                openCartPromo: !selected,
                selected: selected,
            };
            state.orderListNotEditable = order;
        },
        [setPromotionsOrder.rejected]: (state, action) => {
            state.loadingSetPromoOrder = false;  
        },
        /**
         * get notes of an order 
         */
        [getOrderNotes.pending]: (state) => {
            state.loadingGetNotesOrder = true;
        },
        [getOrderNotes.fulfilled]: (state, action) => {
            state.loadingGetNotesOrder = false; 
            const { notes } = action.payload;
            state.orderNotes = notes;            
        },
        [getOrderNotes.rejected]: (state, action) => {
            state.loadingGetNotesOrder = false;  
        },
        /**
         * save note for an order 
         */
        [saveOrderNote.pending]: (state) => {
            state.loadingSaveNote = true;
        },
        [saveOrderNote.fulfilled]: (state, action) => {
            state.loadingSaveNote = false; 
            const { notes } = action.payload;
            state.orderNotes = notes;  
            state.notesCountNotEditable += 1;
        },
        [saveOrderNote.rejected]: (state) => {
            state.loadingSaveNote = false;  
        },
        /**
         * get the documents of an order 
         */
        [getDocumentsOrder.pending]: (state) => {
            state.loadingDocumentsOrder = true;
        },
        [getDocumentsOrder.fulfilled]: (state, action) => {
            state.loadingDocumentsOrder = false; 
            const { table: {rows} } = action.payload;
            state.documentsOrder = rows;
        },
        [getDocumentsOrder.rejected]: (state) => {
            state.loadingDocumentsOrder = false;  
        },      
        /**
         * Get paginated list of pending's orders 
         */
        [getOrdersProcessing.pending]: (state, action) => {
            state.loadingProcessing = true;
            state.error = null;
        },
        [getOrdersProcessing.fulfilled]: (state, action) => {
            const {
                grid: {
                    columns,
                    layout: { children },
                    pageSizes
                },
                table: {
                    page,
                    pages,
                    rows,
                    size,
                    total,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });
            state.loadingProcessing = false;
            state.orders = rows;
            state.pageSizes = pageSizes;
            state.layoutColumn = arrayMergeColumnsAndChildren;        
            state.pagination = {
                page,
                pages,
                size,
                total,
            };
        },
        [getOrdersProcessing.rejected]: (state, action) => {
            state.loadingProcessing = false;
            state.orders = null;
            state.error = action.payload;
        },           
        /**
         * Create the header of a new order 
         */
        [getConcurrentOrderAccess.pending]: (state) => {
            state.loadingConcurrentAccessOrder = true;
        },
        [getConcurrentOrderAccess.fulfilled]: (state, action) => {      
            state.loadingConcurrentAccessOrder = false;            
            const { total_users, view, edit } = action.payload;

            // Check if total_users has changed
            if (total_users !== state.concurrentAccessOrderTotalUsers) {
                state.concurrentAccessOrderUsersIsChanged = true;
            } else {

                // Check if total_users has the same but the users in view are changed
                for (let index = 0; index < view.length; index++) {
                    if (view[index].username !== state.concurrentAccessOrderUsersInView[index].username) {
                        state.concurrentAccessOrderUsersIsChanged = true;
                    }
                }

                // Check if total_users has the same but the users in view are changed
                for (let index = 0; index < edit.length; index++) {
                    if (edit[index].username !== state.concurrentAccessOrderUsersInEdit[index].username) {
                        state.concurrentAccessOrderUsersIsChanged = true;
                    }
                }
            }

            state.concurrentAccessOrderTotalUsers = total_users;
            state.concurrentAccessOrderUsersInEdit = edit;
            state.concurrentAccessOrderUsersInView = view;
        },
        [getConcurrentOrderAccess.rejected]: (state) => {
            state.loadingConcurrentAccessOrder = false;
        },  
    },
});

export const { 
    deactivedOrderSync,
    resetConcurrentAccessOrderUsersIsChanged,
    resetErrors,
    setOrderHasPromoToSelect,
    setPagerState,
    setPagerStateOrderPending,
    setPagerStateOrderProcessing,
    setTotalOrder,
    unsetOrderHeaderNotEditable,
    unsetOrderFooterNotEditable,
} = orderSlice.actions;
export default orderSlice.reducer;
