import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderNotes } from "redux/features/order/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";
import { formatDateTimeByLanguage } from "utils";
import NewNote from "./newNote";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import Notification from "components/notification";
import "./cart-notes.scss";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    addNoteButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.addNoteButtonLabel",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.addNoteButtonLabel
    ),
    copyLinkButtonLabel: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.copyLinkButtonLabel",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.copyLinkButtonLabel
    ),
    empty: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.empty",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.empty
    ),
    title: toLanguageString(
      "metislab.frontend.components.order.components.catalogue.notes.title",
      defaultMessages.metislab.frontend.components.order.components.catalogue
        .notes.title
    ),

    copyLinkNotes: toLanguageString(
      "metislab.frontend.components.notification.components.order.copyLinkNotes",
      defaultMessages.metislab.frontend.components.notification.components.order
        .copyLinkNotes
    ),
  };
};

const Notes = ({ handleModal, notEditable }) => {
  const {
    addNoteButtonLabel,
    copyLinkButtonLabel,
    empty,
    title,
    copyLinkNotes,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const intl = useInternationalization();

  const refInputUrlToCopy = useRef();

  /**
   * IndexWebCustomer states
   */
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * Order states loading
   */
  const loadingOrder = useSelector((state) => state.order.loading);
  const loadingGetNotesOrder = useSelector(
    (state) => state.order.loadingGetNotesOrder
  );
  const orderInfo = useSelector((state) => state.order.orderInfo);
  const orderNotes = useSelector((state) => state.order.orderNotes);

  /**
   * Internal states
   */
  const [openNewNoteForm, setOpenNewNoteForm] = useState(false);

  const getNotes = useCallback(() => {
    if (selectedCompany && orderInfo) {
      const id = orderInfo?.id;

      const args = {
        id,
        toast,
      };
      dispatch(getOrderNotes(args)).then(() => {
        sessionStorage.removeItem("SHOW_NOTES");
        sessionStorage.removeItem("URL_NOTES");
        sessionStorage.removeItem("CHANNEL");
      });
    }
  }, [dispatch, orderInfo, selectedCompany]);

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  const handleNotesCart = () => {
    handleModal();
  };

  const handleCreationNewNote = () => {
    setOpenNewNoteForm((prevState) => !prevState);
  };

  /**
   * handleCopyUrl used to handle the copy of the url of the desire order
   */
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      refInputUrlToCopy.current.value +
        `?showNotes=true&companyChannel=${selectedCompany}`
    );
    toast.success(<Notification message={copyLinkNotes} type="success" />);
  };

  return (
    <div className="c-cart c-cart--sidebar c-cart--notes c-overlay">
      <section className="c-cart__main">
        <header className="c-cart__header">
          <div className="l-mobile-flex u-flex-middle u-flex-end">
            <Button
              className=""
              icon="close"
              fillMode="flat"
              onClick={handleNotesCart}
            />
          </div>
          <h2 className="c-cart__title t-title t-title--2">{title}</h2>
        </header>
        <div className="c-cart__content">
          {loadingOrder || loadingGetNotesOrder ? (
            <Loader
              className="c-loader--center c-loader--absolute"
              size="medium"
            />
          ) : orderNotes.length === 0 ? (
            <EmptySearch text={empty} />
          ) : (
            <ul className="c-list-notes">
              {orderNotes.map((orderNote, indexOrdeNote) => {
                if (orderNote?.log === 2) {
                  return (
                    <li
                      className="c-note-item"
                      key={`${orderNote?.id}-${indexOrdeNote}`}
                      style={{
                        background: "#F9F6F3",
                        padding: 16,
                        marginBottom: 8,
                      }}
                    >
                      <header className="c-note-item__header l-mobile-flex u-flex-middle u-gap-8">
                        {orderNote?.log === 0 ? (
                          <span className="k-icon k-i-file"></span>
                        ) : (
                          <span
                            className="k-icon k-i-file-config
                          "
                          ></span>
                        )}
                        <strong>{orderNote?.username}</strong>
                        <strong className="c-note-item__date">
                          {formatDateTimeByLanguage(
                            intl.locale,
                            orderNote?.created_at
                          ).replace(",", " - ")}
                        </strong>
                      </header>
                      <section className="c-note-item__text">
                        {orderNote?.operations.map(
                          (operation, indexOperation) => (
                            <div
                              className="c-note-item__element"
                              key={`detail-${indexOperation}`}
                            >
                              <strong>
                                {operation?.type === 1 && (
                                  <span className="k-icon k-i-plus"></span>
                                )}
                                {operation?.type === 2 && (
                                  <span className="k-icon k-i-minus"></span>
                                )}
                                {(operation?.type === 3 ||
                                  operation?.type === 4) && (
                                  <span className="k-icon k-i-pencil"></span>
                                )}
                                {operation?.type_description}
                              </strong>
                              <ul>
                                {operation?.details.map((detail, index) => (
                                  <li key={`detail-${index}`}>
                                    <span>{detail?.description} </span>
                                    {operation?.type === 1 && (
                                      <strong
                                        dangerouslySetInnerHTML={{
                                          __html: detail?.new_value,
                                        }}
                                      />
                                    )}
                                    {operation?.type === 2 && (
                                      <strong
                                        dangerouslySetInnerHTML={{
                                          __html: detail?.old_value,
                                        }}
                                      />
                                    )}
                                    {operation?.type === 3 && (
                                      <>
                                        <strong
                                          dangerouslySetInnerHTML={{
                                            __html: detail?.old_value,
                                          }}
                                        />
                                        &nbsp;
                                        <strong
                                          dangerouslySetInnerHTML={{
                                            __html: detail?.new_value,
                                          }}
                                        />
                                      </>
                                    )}
                                    {operation?.type === 4 && (
                                      <strong
                                        dangerouslySetInnerHTML={{
                                          __html: detail?.new_value,
                                        }}
                                      />
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )
                        )}
                      </section>
                    </li>
                  );
                }

                return (
                  <li
                    className="c-note-item"
                    key={`${orderNote?.id}-${indexOrdeNote}`}
                    style={{
                      background: "#F9F6F3",
                      padding: 16,
                      marginBottom: 8,
                    }}
                  >
                    <header className="c-note-item__header l-mobile-flex u-flex-middle u-gap-8">
                      {orderNote?.log === 0 ? (
                        <span className="k-icon k-i-file"></span>
                      ) : (
                        <span className="k-icon k-i-file-config"></span>
                      )}
                      <strong>{orderNote?.username}</strong>
                      <strong className="c-note-item__date">
                        {formatDateTimeByLanguage(
                          intl.locale,
                          orderNote?.created_at
                        ).replace(",", " - ")}
                      </strong>
                    </header>
                    <section
                      className="c-note-item__text"
                      dangerouslySetInnerHTML={{ __html: orderNote?.note }}
                    ></section>
                  </li>
                );
              })}
            </ul>
          )}
          <footer
            className={`c-cart__footer l-tablet-flex u-flex-middle ${
              notEditable ? "u-flex-between" : "u-flex-end"
            }`}
          >
            {notEditable && (
              <Button
                className="c-cart__button c-cart__button--link k-ml-0"
                icon="copy"
                fillMode="flat"
                onClick={handleCopyUrl}
                style={{
                  color: "#610d11",
                  textTransform: "none",
                }}
              >
                {copyLinkButtonLabel}
              </Button>
            )}
            <Input
              type="hidden"
              ref={refInputUrlToCopy}
              value={window.location.href}
            />
            <Button
              disabled={!orderInfo?.canEdit}
              className="c-cart__button"
              icon="add"
              onClick={handleCreationNewNote}
            >
              {addNoteButtonLabel}
            </Button>
          </footer>
        </div>
        <NewNote
          handleModal={handleNotesCart}
          handleModalCreationNote={handleCreationNewNote}
          idOrder={orderInfo?.id}
          showNewNoteForm={openNewNoteForm}
        />
      </section>
    </div>
  );
};

export default Notes;
