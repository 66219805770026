import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocMarketingTypes } from "redux/features/download/actions";
import { setPagerStateMarketing as setPagerState } from "redux/features/download";
import { useSearchParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { DebounceInput } from "react-debounce-input";
import { Skeleton } from "@progress/kendo-react-indicators";
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { toast } from "react-toastify";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    placeholderInputSearch: toLanguageString(
      "metislab.frontend.components.download.components.header.placeholderInputSearch",
      defaultMessages.metislab.frontend.components.download.components.header
        .placeholderInputSearch
    ),
    resetFilterOrderActive: toLanguageString(
      "metislab.frontend.components.download.components.header.resetFilterOrderActive",
      defaultMessages.metislab.frontend.components.download.components.header
        .resetFilterOrderActive
    ),
  };
};

const HeaderDocsMarketing = ({ handleFiltersActive }) => {
  const { placeholderInputSearch, resetFilterOrderActive } =
    useLocalizedMessages();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  /**
   * IndexWebCustomer states
   */
  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * Download States
   */
  const loadingMarketing = useSelector(
    (state) => state.download.loadingMarketingTypes
  );
  const marketingFilterList = useSelector(
    (state) => state.download.marketingFilterList
  );
  const pagerState = useSelector((state) => state.download.pagerStateMarketing);

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);

  /**
   * Internal states
   */
  const [filtersActive, setFiltersActive] = useState(null);
  const [keyword, setKeyword] = useState("");

  const getListDocsAdministrative = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      const args = { 
        id: searchParams.get("id"),
        toast,
      }
      dispatch(getDocMarketingTypes(args));
    }
  }, [companyCustomers, dispatch, searchParams, selectedCompany]);

  useEffect(() => {
    getListDocsAdministrative();
  }, [getListDocsAdministrative]);

  useEffect(() => {
    if (!searchParams.get("id")) {
      return;
    }

    setFiltersActive(null);
    handleResetFilter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchParams]);

  /**
   * This hook is used to send the array of active filters
   * to the parent
   */
  useEffect(() => {
    handleFiltersActive(filtersActive);
  }, [filtersActive, handleFiltersActive]);

  /**
   * onChangeKeyword is used to handle the string in the input
   * @param {*} e
   */
  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
    dispatch(
      setPagerState({
        page: 1,
        search: e.target.value,
      })
    );
  };

  /**
   * handleFilter is used to handle the filters active
   * of the types of documents
   */
  const handleFilter = useCallback(
    (docTypeValue) => {
      if (filtersActive) {
        if (filtersActive && filtersActive.includes(docTypeValue)) {
          if (filtersActive.length === 1) {
            setFiltersActive(null);
          } else {
            setFiltersActive((prevState) =>
              prevState.filter((filter) => filter !== docTypeValue)
            );
          }
        } else {
          setFiltersActive((prevState) => [...prevState, docTypeValue]);
        }
      } else {
        setFiltersActive([docTypeValue]);
      }
    },
    [filtersActive]
  );

  const handleResetFilter = () => {
    dispatch(
      setPagerState({
        ...pagerState,
        search: null,
      })
    );
    setKeyword("");
  };

  return (
    <>
      <header className="c-subheader c-subheader--download">
        <div className="l-tablet-flex u-flex-start u-flex-between">
          <h1 className="c-subheader__title t-title t-title--2  k-pr-4">
            Download
          </h1>
          <ul className="l-tablet-flex k-align-items-center u-gap-16">
            {pagerState?.search && (
              <li>
                <ResetFilter dispatchResetAction={handleResetFilter} />
              </li>
            )}
            <li className="c-form-field c-form-field--search">
              <DebounceInput
                autoComplete="off"
                className="k-input"
                name="searchByKeyword"
                placeholder={placeholderInputSearch}
                minLength={0}
                debounceTimeout={300}
                value={keyword}
                onChange={onChangeKeyword}
              />
              <span className="k-icon k-i-search"></span>
            </li>
          </ul>
        </div>
        <ul className="c-tags l-mobile-flex" style={{ gap: 8 }}>
          {(loadingUser || loadingMarketing || loadingIndexWebCustomer) &&
            [0, 1, 2].map((index) => (
              <div key={index} className="c-subheader__button">
                <Skeleton
                  style={{
                    width: 120,
                    height: 42,
                    transform: "scale(1)",
                  }}
                />
              </div>
            ))}
          {!loadingUser &&
            !loadingMarketing &&
            !loadingIndexWebCustomer &&
            marketingFilterList &&
            marketingFilterList.map((docType, index) => (
              <li key={`${docType?.type_value}-${index}`} className="c-filter">
                <Button
                  className={`c-subheader__button ${
                    filtersActive?.includes(docType.type_value)
                      ? "c-subheader__button--selected"
                      : ""
                  } `}
                  icon={
                    filtersActive?.includes(docType.type_value) ? "close" : null
                  }
                  dir="rtl"
                  fillMode="outline"
                  onClick={() => handleFilter(docType.type_value)}
                >
                  {docType.type_description}
                </Button>
              </li>
            ))}
          {filtersActive && filtersActive?.length > 0 && (
            <li className="">
              <Button
                className={`c-subheader__button c-subheader__button--no-filter`}
                icon="close"
                dir=""
                fillMode="flat"
                onClick={() => setFiltersActive(null)}
              >
                {resetFilterOrderActive}
              </Button>
            </li>
          )}
        </ul>
      </header>
    </>
  );
};

export default HeaderDocsMarketing;
