import React from "react";
import PropTypes from "prop-types";

const CellCode = ({ article_code, available_from, tag_promo }) => {
  const tags = tag_promo || [];

  return (
    <td
      colSpan="1"
      className=""
      role="gridcell"
      aria-colindex="5"
      aria-selected="false"
      data-grid-col-index="0"
    >
      <span>{article_code}</span>
      <div className="c-cart-item__tags">
        {tags.map((tag) => {
          return (
            <span
              key={tag}
              className="c-cart-item__badge c-cart-item__tag-promo"
            >
              {tag}
            </span>
          );
        })}
      </div>
      {available_from && available_from?.length > 0 && (
        <span className="c-cart-item__badge c-cart-item__available-from">
          {available_from}
        </span>
      )}
    </td>
  );
};

CellCode.propTypes = {
  article_code: PropTypes.string,
  available_from: PropTypes.string,
  tag_promo: PropTypes.array,
};

export default CellCode;
