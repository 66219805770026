import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPagerState } from "redux/features/customer";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DebounceInput } from 'react-debounce-input';
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    initialValuesDropdown: toLanguageString(
      'metislab.frontend.components.customers.components.header.initialValuesDropdown',
      defaultMessages.metislab.frontend.components.customers.components.header.initialValuesDropdown),
    placeholderInputSearch: toLanguageString(
      'metislab.frontend.components.customers.components.header.placeholderInputSearch',
      defaultMessages.metislab.frontend.components.customers.components.header.placeholderInputSearch),
    title: toLanguageString(
      'metislab.frontend.components.customers.components.header.title',
      defaultMessages.metislab.frontend.components.customers.components.header.title),
  };
}


const HeaderCustomers = (props) => {

  const {
    initialValuesDropdown,
    placeholderInputSearch,
    title 
  } = useLocalizedMessages();

  const INITIAL_VALUES_DROPDOWN = {
    propId: initialValuesDropdown,
    label: initialValuesDropdown,
  };

  const { filterByType } = props;

  const dispatch = useDispatch();

  const pagerState = useSelector((state) => state.customer.pagerState);

  const [keyword, setKeyword] = useState(pagerState?.search || "");

  /**
   * onChangeType is used to handle the dropdown
   * @param {*} e
   */
  const onChangeType = (e) => {
    const value =
      e.value === INITIAL_VALUES_DROPDOWN
        ? null
        : { propId: e.value.propId, label: e.value.label };

    dispatch( setPagerState({
        searchBy: value,
      })
    );
  };

  /**
   * onChangeKeyword is used to handle the string in the input
   * @param {*} e
   */
  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
    dispatch(
      setPagerState({
        page: 1,
        search: e.target.value,
      })
    )
  };

  const handleResetFilter = () => {
    dispatch(
      setPagerState({
        ...pagerState,
        search: null,
        searchBy: null,
      })
    );
    setKeyword("");
  }

  const filtersList = filterByType && filterByType.filter((fil) => !fil.hidden);
  
  return (
    <header className="c-subheader l-desktop-flex k-align-items-center k-justify-content-between">
      <h1 className="c-subheader__title t-title t-title--2 k-m-0">
        {title}
      </h1>
      <div className="l-desktop-flex">
        <ul
          className="c-subheader__filter l-tablet-flex k-align-items-center"
          style={{ listStyle: "none" }}
        >
          {(pagerState?.search || pagerState?.searchBy) && (
            <li>
              <ResetFilter
                dispatchResetAction={handleResetFilter}
              />
            </li>
          )}
          <li className="c-filter">
            <DropDownList
              className="c-filter-container"
              name="searchByType"
              data={filtersList || []}
              fillMode="outline"
              textField="label"
              dataItemKey="propId"
              defaultItem={INITIAL_VALUES_DROPDOWN}
              onChange={onChangeType}
              value={pagerState.searchBy}
              popupSettings={{ popupClass: "c-filter-content", width: 180 }}
            />
          </li>
          <li className="c-form-field c-form-field--search k-pos-relative">
            <DebounceInput
              className="k-input"
              name="searchByKeyword"
              placeholder={placeholderInputSearch}
              minLength={0}
              debounceTimeout={300}
              value={keyword}
              onChange={onChangeKeyword}
            />
            <span className="k-icon k-i-search"></span>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default React.memo(HeaderCustomers);
