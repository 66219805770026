import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const checkAuth = createAsyncThunk(
    "user/checkAuth",
    async ( _, { rejectWithValue } ) => {       
        try {
            const response = await http.get('/auth/check');
            if(response?.data?.hasOwnProperty('redirect') && typeof response?.data?.redirect == "string") {
                console.log("response user/checkAuth",response)
                window.location.href = response?.data?.redirect;
            }
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    "user/forgotPassword",
    async ( { toast, values, messageNotification }, { rejectWithValue } ) => {   
        try {
            const response = await http.post('/auth/forgotPassword', values);
            toast.success(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const changePasswordFromLoggedUser = createAsyncThunk(
    "user/changePasswordFromLoggedUser",
    async ({ data, toast, messageNotification }, { rejectWithValue }) => {       
        try {
            const query = '/auth/user/password';
            const response = await http.put(query, data);
            toast.success(
                <Notification 
                    message={messageNotification} 
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[1];
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const validateLinkRecovery = createAsyncThunk(
    "user/validateLinkRecovery",
    async ( { toast, values }, { rejectWithValue } ) => {   
        try {
            const query = '/auth/validateLinkRecovery';
            const response = await http.post(query, values);
            return response.data;
        } catch (err) {
            toast.error(
                <Notification 
                    message={err.message} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const changePasswordFromValidateLinkRecovery = createAsyncThunk(
    "user/changePasswordFromValidateLinkRecovery",
    async ( { toast, values, navigate }, { rejectWithValue } ) => {   
        try {
            const query = '/auth/applyLinkRecovery';
            const response = await http.post(query, values);
            navigate("/", { state: {username: values?.username}});
            if (response?.data?.hasOwnProperty('aMessage')) {
                const { sMessageText } = response?.data?.aMessage[0];
                toast.success(
                    <Notification 
                        message={sMessageText} 
                        type="success"
                    />
                );
            }
            return response.data;
        } catch (err) {
            toast.error(
                <Notification 
                    message={err.message} 
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getLoggedUser = createAsyncThunk(
    "user/getLoggedUser",
    async ( _, { rejectWithValue } ) => {       
        try {
            const response = await http.get('/auth/user');
            if(response?.data?.hasOwnProperty('redirect') && typeof response?.data?.redirect == "string") {
                window.location.href = response?.data?.redirect;
                console.log("response user/getLoggedUser",response)
            }
            return response.data;
        } catch (err) {
           
            return rejectWithValue(err.message);
        }
    }
);

export const login = createAsyncThunk(
    "user/login",
    async ({ toast, data }, { rejectWithValue }) => {       
        try {
            const response = await http.post('/auth/login', data);

            if(response?.data?.hasOwnProperty('redirect') && typeof response?.data?.redirect == "string") {
                window.location.href = response?.data?.redirect;
                console.log("user/login response",response)
            }
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const logout = createAsyncThunk(
    "user/logout",
    async ({ navigate }, { rejectWithValue }) => {       
        try {
            const response = await http.post('/auth/logout');
            navigate("/");
            return response.data;
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.message);
        }
    }
);

export const setCustomerRequest = createAsyncThunk(
    "user/setCustomer",
    async ( { idsfa, toast }, { rejectWithValue } ) => {       
        try {         
            const response = await http.get(`/customer/setCustomer/${idsfa}`);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const unSetCustomerRequest = createAsyncThunk(
    "user/unSetCustomer",
    async ( { toast }, { rejectWithValue } ) => {       
        try {      
            const query = '/customer/unsetCustomer';
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);


export const createOrderHeader = createAsyncThunk(
    "user/createOrderHeader",
    async ({ data, toast, navigate, redirect = true, messageNotification }, { rejectWithValue }) => {       
        try {
            let query = '/sfas/order/store';
            const response = await http.post(query, data);
            toast.success(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );
            redirect && navigate(`/orders/${response.data.selectedOrder.id}/edit`);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

// These are the thunks to handle the calls to API's order
export const activateOrder = createAsyncThunk(
    "user/activateOrder",
    async ( { id, navigate, redirect = true, toast, messageNotification}, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/active/${id}`;
            const response = await http.get(query);
            toast.info(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );
            redirect && navigate(`/orders/${id}/edit`);  
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deactivateOrder = createAsyncThunk(
    "user/deactivateOrder",
    async ( { navigate, toast, redirect = true, notification = true, messageNotification }, { rejectWithValue } ) => {       
        try {    
            const query = '/sfas/order/unsetActive';
            const response = await http.get(query);
            if (notification) {
                toast.info(
                    <Notification 
                        message={messageNotification}
                        type="success"
                    />
                );
            }
            redirect && navigate("/orders");  
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const confirmOrder = createAsyncThunk(
    "user/confirmOrder",
    async ( { id, toast, messageNotification }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/confirm/${id}`;
            const response = await http.post(query);
            if (response.data?.bResult) {
                toast.info(
                    <Notification 
                        message={messageNotification}
                        type="success"
                    />
                );
            }
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const approveOrder = createAsyncThunk(
    "user/approveOrder",
    async ( { id, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/approve/${id}`;
            const response = await http.post(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const rejectOrder = createAsyncThunk(
    "user/rejectOrder",
    async ( { id, data, navigate, toast, messageNotification }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/reject/${id}`;
            const message = {
                message: data
            };
            const response = await http.post(query, message);
            navigate("/orders/pending");
            toast.info(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deleteOrder = createAsyncThunk(
    "user/deleteOrder",
    async ( { id, toast, messageNotification }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/${id}`;
            const response = await http.delete(query);
            toast.info(
                <Notification 
                    message={messageNotification}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);


export const exportExcelOrder = createAsyncThunk(
    "user/exportExcelOrder",
    async ( { idOrder, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/downloadExcel/${idOrder}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const exportExcelOrderTemplate = createAsyncThunk(
    "user/exportExcelOrderTemplate",
    async ( { idOrder, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/downloadExcelTemplate/${idOrder}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const importExcelOrder = createAsyncThunk(
    "user/importExcelOrder",
    async ( { data, idOrder, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/sfas/order/uploadExcel/${idOrder}`;
            const response = await http.post(query, data);         
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getPublicRoutes = createAsyncThunk(
    "user/getPublicRoutes",
    async ( { toast}, { rejectWithValue } ) => {       
        try {    
            const query = '/publicroutes';
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);