import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
/* import { useInternationalization } from "@progress/kendo-react-intl"; */
import { useCustomLocalization } from "utils";

import "./cell-fragrance.scss";
import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    noArticle: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.cellFragrance.noArticle',
      defaultMessages.metislab.frontend.components.order.components.catalogue.cellFragrance.noArticle),
    subAmount: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.cellFragrance.subAmount',
      defaultMessages.metislab.frontend.components.order.components.catalogue.cellFragrance.subAmount),
  
  };
}

const CellFragrance = ({ data }) => {

  const {
    noArticle,
    subAmount
  } = useLocalizedMessages();

  const orderInfo = useSelector((state) => state.order.orderInfo);
 
  return (
    <td
      colSpan="1"
      className="c-order-fragrances"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="0"
    >
      <div className="c-order-fragrances__header l-mobile-flex u-flex-middle">
        <div>
          <strong>{`${data?.label}`} - </strong> {subAmount}
          <strong>{`${data?.subValue} ${orderInfo?.currency}`}</strong>
        </div>
      </div>

      {data?.rows && data?.rows.length > 0 ? (
        <ul>
          {data?.rows.map((fragrance, index) => (
            <li
              key={fragrance?.fragrance_code}
              className="c-order-fragrances__product l-mobile-flex u-flex-middle"
            >
              <figure>
                <img
                  src={
                    fragrance?.preview_file_name ||
                    "/img/placeholder-medium.jpg"
                  }
                  alt="placeholder fragrance"
                  width={44}
                  height={48}
                />
              </figure>
              <span>{fragrance?.fragrance_title}</span>
              {(fragrance?.lvl2_tag && fragrance?.lvl2_tag.length > 0) && <strong>{fragrance?.lvl2_tag}</strong>}
              {fragrance?.tag_promo && fragrance?.tag_promo.length > 0 && (
                fragrance?.tag_promo.map( (tag) => (
                  <strong 
                    key={tag}
                    style={{ 
                      background: '#d1a170',
                      color: '#ffffff'
                    }}
                  >
                    {tag}
                  </strong>
                ))
              )}
            </li>
          ))}
        </ul>
      ) : (
        <span>{noArticle}</span>
      )}
    </td>
  );
};

CellFragrance.propTypes = {
  data: PropTypes.object,
};

export default CellFragrance;
