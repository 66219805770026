import React from 'react';
import { Loader as Spinner } from "@progress/kendo-react-indicators";
import './loader.scss';

const Loader = ({ className='', size='small' }) => {

  return (
    <Spinner 
      className={`c-loader ${className}`}
      size={size} 
      type='infinite-spinner'
    />
  )
}

export default Loader;