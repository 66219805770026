import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { activateOrder } from "redux/features/user/actions";
import { getDocumentsOrder } from "redux/features/order/actions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import "./cellDetails.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    activeOrderButtonLabel: toLanguageString(
      'metislab.frontend.components.orders.components.listing.cellDetails.activeOrderButtonLabel',
      defaultMessages.metislab.frontend.components.orders.components.listing.cellDetails.activeOrderButtonLabel),
    activedOrderButtonLabel: toLanguageString(
      'metislab.frontend.components.orders.components.listing.cellDetails.activedOrderButtonLabel',
      defaultMessages.metislab.frontend.components.orders.components.listing.cellDetails.activedOrderButtonLabel),
    detailsButtonLabel: toLanguageString(
      'metislab.frontend.components.orders.components.listing.cellDetails.detailsButtonLabel',
      defaultMessages.metislab.frontend.components.orders.components.listing.cellDetails.detailsButtonLabel),
    documentsButtonLabel: toLanguageString(
      'metislab.frontend.components.orders.components.listing.cellDetails.documentsButtonLabel',
      defaultMessages.metislab.frontend.components.orders.components.listing.cellDetails.documentsButtonLabel),       
    activatedOrderSuccess: toLanguageString(
      'metislab.frontend.components.notification.components.order.activatedOrderSuccess',
      defaultMessages.metislab.frontend.components.notification.components.order.activatedOrderSuccess),  
  };
};

const CellDetails = (props) => {
  const { handleModal, id, idOrder, canConfirm, showActiveButton } = props;

  const {
    activeOrderButtonLabel,
    activedOrderButtonLabel,
    detailsButtonLabel,
    documentsButtonLabel,
    activatedOrderSuccess,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loadingCreateOrder = useSelector(
    (state) => state.user.loadingCreateOrder
  );

  const activeOrder = (idOrder) => {
    const args = {
      id: idOrder,
      navigate,
      toast,
      messageNotification: activatedOrderSuccess,
    };
    dispatch(activateOrder(args));
  };

  const handleDocuments = (idOrder) => {
    const args = {
      id_order: idOrder,
      toast
    };
    handleModal(); 
    dispatch(getDocumentsOrder(args));
  };

  return (
    <td
      colSpan="1"
      className="c-list-orders__actions l-tablet-flex u-gap-8 k-grid-content-sticky"
      role="gridcell"
      aria-colindex="7"
      aria-selected="false"
      data-grid-col-index="6"
      style={{ textOverflow: "unset", left: 0, right: 0 }}
    >
      <Link
        className="k-button k-button-sm k-button-link"
        to={loadingCreateOrder ? null : `/orders/${id}`}
        style={{
          color: "#610d11",
          fontWeight: 400,
          fontSize: 13,
          lineHeight: 1,
          display: "flex",
          alignItems: "center",
          gap: 8,
          letterSpacing: 1,
          border: "1px solid #610d11",
          borderRadius: 0,
          padding: "11px 16px",
          boxShadow: "none",
          transition: "0.3s ease",
        }}
      >
        {detailsButtonLabel}
        <span className="k-icon k-i-preview"></span>
      </Link>
      {canConfirm &&
        (showActiveButton ? (
          <Button
            disabled={loadingCreateOrder}
            icon="play"
            fillMode="outline"
            dir="rtl"
            onClick={() => activeOrder(id)}
          >
            {activeOrderButtonLabel}
          </Button>
        ) : (
          <Button disabled={true} icon="tick" fillMode="outline" dir="rtl">
            {activedOrderButtonLabel}
          </Button>
        ))}
      {!canConfirm && (
        <Button
          disabled={loadingCreateOrder}
          icon="arrow-right"
          fillMode="outline"
          dir="rtl"
          onClick={() => handleDocuments(idOrder)}
        >
          {documentsButtonLabel}
        </Button>
      )}
    </td>
  );
};

CellDetails.propTypes = {
  handleModal: PropTypes.func,
  id: PropTypes.number,
  idOrder: PropTypes.string,
  showActiveButton: PropTypes.bool,
  canConfirm: PropTypes.bool,
};

export default CellDetails;
