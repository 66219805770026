import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPagerState as setPagerStateCatalogue } from "redux/features/catalogue";
import { setSidebarMenu } from "redux/features/user";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { useCurrentLocation, useCheckIsMobile } from "utils";
import {
  Drawer,
  DrawerContent,
  PanelBar,
  PanelBarItem,
} from "@progress/kendo-react-layout";
import { Skeleton } from "@progress/kendo-react-indicators";
import Logout from "components/logout";

import "./navSidebar.scss";

const NavSidebar = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();
  const currentLocation = useCurrentLocation(pathname);
  const isPageSelectCompany = currentLocation?.includes("select-company");
  const isPrintPages = currentLocation?.includes("print");

  const isMobile = useCheckIsMobile();

  /**
   * User states
   */
  const authenticated = useSelector((state) => state.user.authenticated);
  const menu = useSelector((state) => state.user.menu);
  const sidebarMenu = useSelector((state) => state.user.sidebarMenu);

  /**
   * IndexWebCustomer states
   */
  const loadingSetCompany = useSelector((state) => state.indexWebCustomer.loading);
  
  const handleSidebarMenu = (element, path) => {
    if (isMobile) {
      dispatch(setSidebarMenu());
    }

    if (element.toLowerCase() === 'catalog' && !path.includes(searchParams.get('tag')) ) {
      //Reset pageState catalogue
      dispatch( setPagerStateCatalogue({
        page: null,
        perPage: null,
        class_code: null,
        fragrance_title: null,
      }));
    }
  };
  
  if (!authenticated || isPageSelectCompany || isPrintPages) return null;

  if (loadingSetCompany) {
    return (
      <div className="k-pos-fixed k-drawer-container">
        <div className="k-drawer-content">
          <ul className="k-panelbar">
            <li className="k-panelbar-item k-panelbar-header k-level-0">
              <span className="k-link"> 
                <Skeleton style={{ width: '80%' }} />
              </span>
            </li>
            <li className="k-panelbar-item k-panelbar-header k-level-0">
              <span className="k-link"> 
                <Skeleton style={{ width: '80%' }} />
              </span>            
            </li>
            <li className="k-panelbar-item k-panelbar-header k-level-0">
              <span className="k-link"> 
                <Skeleton style={{ width: '80%' }} />
              </span>            
            </li>
            <li className="k-panelbar-item k-panelbar-header k-level-0">
              <span className="k-link"> 
                <Skeleton style={{ width: '80%' }} />
              </span>            
            </li>
          </ul>
        </div>
      </div>
    )
  }
  
  return (
    <Drawer
      className="k-pos-fixed"
      expanded={!isMobile || sidebarMenu}
      position="start"
      mode="push"
      mini={false}
      style={{ width: 208 }}
    >
      <DrawerContent>
        <PanelBar expandMode="single">
          {menu.length > 0 && menu.map( (section, index) => {     
            return (
              <PanelBarItem
                className={section.items[0].mobile ? "c-user-account u-mobile" : "" }
                key={index}
                expanded={false} 
                title={
                  <div className="k-d-flex k-align-items-center k-justify-content-between">
                    <NavLink 
                      to={section.items[0].path}
                      onClick={() => {
                        // if(section.items[0].target && section.items[0].target === '_blank') {
                        //   window.open(section.items[0].path);
                        // }else {
                          handleSidebarMenu(section.items[0].element, section.items[0].path)
                        // }
                      }}
                    >
                      {section.showAccordion 
                        ? section.label 
                        : section.items[0].label
                      }
                    </NavLink>
                    {section.items[0].mobile && (
                      <Logout 
                        fillMode="flat"
                        icon="logout" 
                        label="logout"
                      />
                    )}
                  </div>
                }
              >
                { section.showAccordion && section.items.map( (item, index) => {   
                  if (!item.isVisible) {
                    return null;
                  }

                  return (
                    <NavLink
                        className={({isActive}) => {

                          if(item.target !== '_blank') {
                            // Check if the element is a catalog's nav item and the querystring id is contained in the path
                            if (item.element.toLowerCase() === 'marketing' && !!searchParams.get('id')) {
                              return (isActive && item.path.includes(searchParams.get('id'))) ? "active" : "";
                            }

                            // Check if the element is not a catalog's nav item
                            if (item.element.toLowerCase() !== 'catalog') {
                              return (isActive) ? "active" : "";
                            }

                            // Check if the element is a catalog's nav item but the location has not a tag url search param
                            if (item.element.toLowerCase() === 'catalog' && !searchParams.get('tag')) {
                              return (isActive && !item.path.includes('tag')) ? "active" : "";
                            }

                            return (item.path.includes(searchParams.get('tag'))) ? "active" : "";
                          }else {
                            return '';
                          }
                        }}
                        key={`${item.element}-${index}`}
                      to={item.target === '_blank' ? '' : item.path}
                      // target={item.target}
                      end={item.element === 'orders'}
                      onClick={(e) => {

                        if(item.target && item.target === '_blank') {
                          e.preventDefault()
                          window.open(item.path);
                        }else {
                          handleSidebarMenu(item.element, item.path)
                        }
                      }}
                    >
                      {item.label}
                    </NavLink>
                  )
                })}
              </PanelBarItem>
            )
          })}
        </PanelBar>
      </DrawerContent>
    </Drawer>
  );
};

export default NavSidebar;
