import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrderToPrint } from "redux/features/print/actions";
import { toast } from "react-toastify";
import Error from "components/Error";
import Loader from "components/loader";
import NotFound from "components/notFound";
import Template from "components/logo/Template";
import { Button } from "@progress/kendo-react-buttons";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";
import OrderDetails from "./orderDetails";
import OrderFooter from "./orderFooter";
import OrderHeader from "./orderHeader";
import "./print-order.scss";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    orderHeaderAccordionTitle: toLanguageString(
      "metislab.frontend.components.order.components.header.orderHeaderAccordionTitle",
      defaultMessages.metislab.frontend.components.order.components.header
        .orderHeaderAccordionTitle
    ),
    orderHeaderPrint: toLanguageString(
      "metislab.frontend.components.order.components.header.orderHeaderPrint",
      defaultMessages.metislab.frontend.components.order.components.header
        .orderHeaderPrint
    ),
    orderHeaderPrintClose: toLanguageString(
      "metislab.frontend.components.order.components.header.orderHeaderPrintClose",
      defaultMessages.metislab.frontend.components.order.components.header
        .orderHeaderPrintClose
    ),
  };
};

const PrintOrder = () => {
  const { orderHeaderAccordionTitle, orderHeaderPrint, orderHeaderPrintClose } =
    useLocalizedMessages();

  const dispatch = useDispatch();

  const params = useParams();

  /**
   * Redux states - print
   */
  const error = useSelector((state) => state.print.error);
  const errorStatus = useSelector((state) => state.print.errorStatus);

  const footer = useSelector((state) => state.print.footer);
  const loading = useSelector((state) => state.print.loading);
  const header = useSelector((state) => state.print.header);
  const order = useSelector((state) => state.print.order);
  const orderInfo = useSelector((state) => state.print.orderInfo);

  const getOrder = useCallback(() => {
    const args = {
      id: params?.id,
      toast,
    };

    dispatch(getOrderToPrint(args));
  }, [dispatch, params?.id]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);
  
  useEffect(() => { 
    if (orderInfo) {
      document.title = orderInfo?.page_title;
    }
  }, [orderInfo])

  // Remove the element used to show the state of an order
  const orderHeader = header?.filter(
    (info) =>
      info?.attribute !== "status_field" && info?.attribute !== "discount"
  );

  const statusOrder = header?.find(
    (info) => info?.attribute === "status_field"
  );

  if (loading) {
    return <Loader className="c-loader c-loader--center" size="large" />;
  }

  if (error) {
    if (errorStatus === 404) {
      return (
        <>
          <NotFound />
          <Button
            className=""
            icon="close"
            fillMode="outline"
            onClick={() => window.close()}
            style={{ color: "white" }}
          >
            {orderHeaderPrintClose}
          </Button>
        </>
      );
    }

    return (
      <>
        <Error errorText={error} />
        <Button
          className=""
          icon="close"
          fillMode="outline"
          onClick={() => window.close()}
          style={{ color: "white" }}
        >
          {orderHeaderPrintClose}
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="c-print">
        <div className="c-print__header">
          <Template />
        </div>
        <OrderHeader
          className="c-print__meta"
          code={orderInfo?.code}
          creationDate={orderInfo?.creation_date}
          customerDescription={orderInfo?.customer_description}
          fido={orderInfo?.customer?.fido}
          searchCode={orderInfo?.search_code}
          status={statusOrder?.value}
          statusCode={orderInfo?.state}
        />
        <section className="c-print__main">
          <h2 className="c-print__title">{orderHeaderAccordionTitle}</h2>
          <ul className="c-print__list">
            {orderHeader?.map((field) => (
              <li key={field.attribute}>
                <strong>{field.name}</strong>
                <span>{field.value}</span>
              </li>
            ))}
          </ul>
        </section>
        <OrderDetails order={order} />
        <OrderFooter
          percDiscount={footer?.perc_discount}
          totalDiscount={footer?.total_discount}
          totalValue={footer?.total_value}
        />
      </div>
      <footer className="c-print__buttons l-mobile-flex u-flex-end u-gap-16">
        <Button
          className=""
          icon="printer"
          fillMode="solid"
          onClick={() => window.print()}
          style={{ color: "white" }}
        >
          {orderHeaderPrint}
        </Button>
        <Button
          className=""
          icon="close"
          fillMode="outline"
          onClick={() => window.close()}
          style={{ color: "white" }}
        >
          {orderHeaderPrintClose}
        </Button>
      </footer>
    </>
  );
};

export default PrintOrder;
