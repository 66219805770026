import React from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";
import { formatDateByLanguage } from "utils";
import defaultMessages from "i18n/en.json";
import "./order-header.scss";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    codeCustomerLabel: toLanguageString(
      "metislab.frontend.components.order.components.header.codeCustomerLabel",
      defaultMessages.metislab.frontend.components.order.components.header
        .codeCustomerLabel
    ),
    descriptionCustomerLabel: toLanguageString(
      "metislab.frontend.components.order.components.header.descriptionCustomerLabel",
      defaultMessages.metislab.frontend.components.order.components.header
        .descriptionCustomerLabel
    ),
  };
};

const OrderHeader = (props) => {
  const {
    code,
    creationDate,
    customerDescription,
    fido,
    searchCode,
    status,
    statusCode,
  } = props;

  const intl = useInternationalization();
  const languageDateFormat = formatDateByLanguage(intl.locale);

  const { codeCustomerLabel, descriptionCustomerLabel } =
    useLocalizedMessages();

  const currentDate = new Date().toLocaleString(intl.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <section className="c-print__heading">
      <h1 className="c-print__main-title l-tablet-flex">
        <strong>{code}</strong>
        <span className="c-print__date">
          {` del ${intl.formatDate(
            new Date(creationDate),
            languageDateFormat
          )}`}
        </span>
        <span className={`s-${statusCode} c-print__status`}>{status}</span>
        <small className="c-print__current-date">{currentDate}</small>
      </h1>
      <ul className="c-print__details l-tablet-flex">
        <li>
          <strong>{codeCustomerLabel}</strong>
          <span className="k-pl-1">{searchCode}</span>
        </li>
        <li>
          <strong>{descriptionCustomerLabel}</strong>
          <span className="k-pl-1">
            {fido === "1" && <span className="k-icon k-i-lock"></span>}
            {customerDescription}
          </span>
        </li>
      </ul>
    </section>
  );
};

export default OrderHeader;
