import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { downloadAdministrative } from "redux/features/download/actions";
import { Button } from "@progress/kendo-react-buttons";
import { toast } from "react-toastify";
import Loader from "components/loader";
import "./cellDetails.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    downloadButtonLabel: toLanguageString(
      'metislab.frontend.components.download.components.table.downloadButtonLabel',
      defaultMessages.metislab.frontend.components.download.components.table.downloadButtonLabel),
  };
};

const CellDetails = ({ idDocs }) => {
  const { downloadButtonLabel } = useLocalizedMessages();

  const dispatch = useDispatch();

  const loadingDownloadDoc = useSelector((state) => state.download.loadingDownloadDoc);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleClick = (id) => {
    const args = {
      id, 
      toast,
    }; 
    setIsDownloading(true);
    dispatch(downloadAdministrative(args))
      .then( () => setIsDownloading(false));
  }

  return (
    <td
      colSpan="1"
      className="c-download-grid__actions k-grid-content-sticky"
      role="gridcell"
      aria-colindex="7"
      aria-selected="false"
      data-grid-col-index="6"
      style={{ textOverflow: "unset", left: 0, right: 0 }}
    >
      <Button
        disabled={
          loadingDownloadDoc && !isDownloading
        }
        className=""
        fillMode="outline"
        onClick={() => handleClick(idDocs)}
      >
        {downloadButtonLabel}
        {(loadingDownloadDoc && isDownloading) 
          ? <Loader size="small" className="k-ml-2" />
          : <span role="presentation" className="k-icon k-i-download"></span>
        }
      </Button>
    </td>
  );
};

CellDetails.propTypes = {
  idDocs: PropTypes.number,
  state: PropTypes.number,
};

export default CellDetails;
