import { createSlice } from "@reduxjs/toolkit";
import { 
    getFragrances,
    getFragrancesFilters,
    getFragranceDetails,
    getFragranceDetailsNotEditable,
    getOrderFragranceDetails,
    downloadExcelCatalogue,
    getArticleGallery,
} from "./actions";
import { keyBy, merge } from 'lodash';

const initialState =  { 

    articleGallery: [],

    filters: null,

    fragranceDetails: null,
    fragrances: null,
    fragranceTableRows: null,

    layoutColumn: null,

    loading: false,
    loadingDownloadExcelCatalogue: false,
    loadingFilters: false,
    loadingGallery: false,

    pageSizes: null,
    pagination: null,
    pagerState: {
        page: null,
        perPage: null,
        class_code: null,
        fragrance_title: null,
    },
    pagerStateFragrance: {
        orderBy: null,
        orderByDirection: null,
    },

    error: null,
    errorFragranceDetails: false,
    errorFragranceDetailsStatus: null,

    downloadExcelCatalogueFile: null,
};

const fragranceSlice = createSlice({
    name: "catalogue",
    initialState,
    reducers: {
        setArticleList: (state, action) => {
            state.fragranceDetails = { ...state.fragranceDetails, article_list: action.payload};
        },
        setPagerState: (state, action) => {
            state.pagerState = { ...state.pagerState, ...action.payload };
        },
        setPagerStateFragrance: (state, action) => {
            state.pagerStateFragrance = { ...state.pagerStateFragrance, ...action.payload };
        }
    },
    extraReducers: {
        /**
         * Get list of fragrances
         */
        [getFragrances.pending]: (state) => {
            state.loading = true;
        },
        [getFragrances.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                fragrances, 
                pageSizes,
                page,
                pages,
                size,
                total,
            } = action.payload;
            state.pageSizes = pageSizes;        
            state.pagination = {
                page,
                pages,
                size,
                total,
            };
            state.fragrances = fragrances;
        },
        [getFragrances.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Get filters of fragrances
         */
        [getFragrancesFilters.pending]: (state) => {
            state.loadingFilters = true;
        },
        [getFragrancesFilters.fulfilled]: (state, action) => {      
            state.loadingFilters = false;
            const { filters } = action.payload;
            state.filters = filters;
        },
        [getFragrancesFilters.rejected]: (state, action) => {
            state.loadingFilters = false;
        }, 
        /**
         * Get details of a fragrance
         */
        [getFragranceDetails.pending]: (state) => {
            state.loading = true;
            state.errorFragranceDetails = false;
            state.errorFragranceDetailsStatus = null;
        },
        [getFragranceDetails.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                article_list, 
                fragrance, 
                gallery, 
                orderPermissions,
                grid: {
                    columns,
                    layout: { children },
                }, 
                table: {
                    rows,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });

            state.fragranceDetails = { 
                article_list, 
                fragrance, 
                gallery,
                orderPermissions,
            };
            state.fragranceTableRows = rows;
            state.layoutColumn = arrayMergeColumnsAndChildren;    
        },
        [getFragranceDetails.rejected]: (state, action) => {
            state.loading = false;
            state.errorFragranceDetails = action.payload?.sMessageText;
            state.errorFragranceDetailsStatus = action.payload?.status;
        }, 
        /**
         * Get details of a fragrance not editable
         */
        [getFragranceDetailsNotEditable.pending]: (state) => {
            state.loading = true;
        },
        [getFragranceDetailsNotEditable.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                article_list, 
                fragrance, 
                gallery, 
                grid: {
                    columns,
                    layout: { children },
                }, 
                table: {
                    rows,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });

            state.fragranceDetails = { 
                article_list, 
                fragrance, 
                gallery,
            };
            state.fragranceTableRows = rows;
            state.layoutColumn = arrayMergeColumnsAndChildren;    
        },
        [getFragranceDetailsNotEditable.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Get details of a fragrance
         */
        [getOrderFragranceDetails.pending]: (state) => {
            state.loading = true;
        },
        [getOrderFragranceDetails.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                article_list, 
                fragrance, 
                gallery, 
                grid: {
                    columns,
                    layout: { children },
                }, 
                table: {
                    rows,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });

            state.fragranceDetails = { 
                article_list, 
                fragrance, 
                gallery,
            };
            state.fragranceTableRows = rows;
            state.layoutColumn = arrayMergeColumnsAndChildren;    
        },
        [getOrderFragranceDetails.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Download file excel of the catalogue
         */
        [downloadExcelCatalogue.pending]: (state) => {
            state.loadingDownloadExcelCatalogue = true;
        },
        [downloadExcelCatalogue.fulfilled]: (state, action) => {
            state.loadingDownloadExcelCatalogue = false;  
            state.downloadExcelCatalogueFile = action.payload;  
        },
        [downloadExcelCatalogue.rejected]: (state, action) => {
            state.loadingDownloadExcelCatalogue = false;  
        },
        /**
         * Get the gallery of an article of a fragrance
         */
        [getArticleGallery.pending]: (state) => {
            state.loadingGallery = true;
        },
        [getArticleGallery.fulfilled]: (state, action) => {
            state.loadingGallery = false;  
            const { images } = action.payload;
            state.articleGallery = images;
        },
        [getArticleGallery.rejected]: (state, action) => {
            state.loadingGallery = false;  
        },
    },
});

export const { 
    setArticleList,
    setPagerState,
    setPagerStateFragrance,
} = fragranceSlice.actions;
export default fragranceSlice.reducer;