import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import HeaderProspects from "./header";
import CellDescription from "./cellDescription";
import CellDetails from "./cellDetails";
import CellState from "./cellState";
import "./prospects-listing.scss";

import DATA_DUMMIES from "../data";

const Orders = () => {
  /**
   * Render the cell in column description
   * @param {object} data
   * @returns {CellDescription} component
   */
  const cellDescription = ({ dataItem }) => {
    const { description, state } = dataItem;
    return <CellDescription description={description} state={state} />;
  };

  /**
   * Render the cell in column description
   * @param {object} data
   * @returns {CellDescription} component
   */
  const cellDetails = ({ dataItem }) => {
    const { id } = dataItem;
    return <CellDetails id={id} />;
  };

  /**
   * Render the cell in column description
   * @param {object} data
   * @returns {CellDescription} component
   */
  const cellState = ({ dataItem }) => {
    const { state } = dataItem;
    return <CellState state={state} />;
  };

  return (
    <>
      <HeaderProspects />
      <Grid className="c-table-prospects" data={DATA_DUMMIES}>
        {/* Column Description */}
        <Column
          field="description"
          title="Ragione sociale"
          cell={cellDescription}
        />
        {/* Column Email */}
        <Column field="email" title="Email" />
        {/* Column Phone */}
        <Column field="phone" title="Telefono" />
        {/* Column Province */}
        <Column field="province" title="Comune" />
        {/* Column State */}
        <Column field="state" title="Stato" cell={cellState} />
        {/* Column Details */}
        <Column field="id" title=" " cell={cellDetails} />
      </Grid>
    </>
  );
};

export default Orders;
