import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from "react-router-dom";
import getElement from './getElement';
import Layout from "components/layout";
import NotFound from 'components/notFound';
import ProtectedRoutes from 'components/protectedRoutes';

const AppRoutes = () => {
  const routes = useSelector( (state) => state.user.routes);

  const privateRoutes = routes?.find(({label}) => label.toLowerCase() === 'private');
  const publicRoutes = routes?.find(({label}) => label.toLowerCase() === 'public');
  
  return (
    <Layout>
      { routes.length > 0 && (
        <Routes>
          { publicRoutes !== undefined && publicRoutes.items.map( route => {
            return (
              <Route 
                key={route.element} 
                path={`/${route.path}`}
                element={getElement(route.element)} 
              />
            )
          })}     
          <Route element={<ProtectedRoutes />}>
            { privateRoutes !== undefined && privateRoutes.items.map( route => {
              return (
                <Route 
                  key={route.element} 
                  path={`/${route.path}`}
                  element={getElement(route.element)} 
                />
              )
            })}
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
        )}
    </Layout>

  )
}

export default AppRoutes;