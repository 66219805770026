import React from "react";
import "./page-not-found.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    message: toLanguageString(
      "metislab.frontend.components.notFound.components.message",
      defaultMessages.metislab.frontend.components.notFound.components.message
    ),
  };
};

const PageNotFound = () => {
  const { message } = useLocalizedMessages();

  return (
    <section className="c-not-found">
      <div className="c-not-found__content">
        <p>{message}</p>
        <h1 className="c-not-found__title">404</h1>
      </div>
    </section>
  );
};

export default PageNotFound;
