import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTotalOrder } from "redux/features/user";
import { setArticleList } from "redux/features/catalogue";
import { setTotalOrder as setTotalOrderInfo } from "redux/features/order";
import { setOrderFragrance } from "redux/features/order/actions";
import { toast } from "react-toastify";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import Product from "pages/catalog/product";
import InfoArticle from "./infoArticle";
import MultipleItems from "./multipleItems";
import {useCheckIsMobile, useGetMatchesRoutes} from "utils";
import "./cart.scss";
import { useCustomLocalization, formatNumber } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    showProduct: toLanguageString(
      "metislab.frontend.components.cart.showProduct",
      defaultMessages.metislab.frontend.components.cart.showProduct
    ),
    hideProduct: toLanguageString(
      "metislab.frontend.components.cart.hideProduct",
      defaultMessages.metislab.frontend.components.cart.hideProduct
    ),
    orderSubTotal: toLanguageString(
      "metislab.frontend.components.cart.orderSubTotal",
      defaultMessages.metislab.frontend.components.cart.orderSubTotal
    ),
    add: toLanguageString(
      "metislab.frontend.components.cart.add",
      defaultMessages.metislab.frontend.components.cart.add
    ),
    adding: toLanguageString(
      "metislab.frontend.components.cart.adding",
      defaultMessages.metislab.frontend.components.cart.adding
    ),

    editOrderSuccess: toLanguageString(
      "metislab.frontend.components.notification.components.cart.editOrderSuccess",
      defaultMessages.metislab.frontend.components.notification.components.cart
        .editOrderSuccess
    ),

    totalOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.total",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .total
    ),
  };
};

const Cart = (props) => {
  const { articleCode, handleCart, handleOrder, notEditable } = props;

  const {
    showProduct,
    hideProduct,
    orderSubTotal,
    add,
    adding,
    editOrderSuccess,
    totalOrderLabel,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /* const intl = useInternationalization(); */

  /**
   * User States
   */
  const selectedCustomer = useSelector((state) => state.user.selectedCustomer);
  const selectedOrder = useSelector((state) => state.user.selectedOrder);

  /**
   * fragrance States
   */
  const fragranceDetails = useSelector(
    (state) => state.fragrance.fragranceDetails
  );

  /**
   * order States
   */
  const loadingSetOrderFragrance = useSelector(
    (state) => state.order.loadingSetOrderFragrance
  );
  const orderInfo = useSelector((state) => state.order.orderInfo);
  const alertRef = useRef();
  const formEl = useRef();
  const initialSubTotal = useRef();

  const routesOrder = [
    { path: "/orders/:id" },
    { path: "/orders/:id/edit" },
    { path: "/orders/pending/:id" },
    { path: "/orders/pending/:id/edit" },
  ];
  const matches = useGetMatchesRoutes(routesOrder);
  const isCatalogueOrderPages = matches ? true : false;

  /**
   * Internal states
   */
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [productOrder, setProductOrder] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [total, setTotal] = useState(0);
  const [localTotalOrder, setLocalTotalOrder] = useState(
    orderInfo ? parseFloat(orderInfo?.base_amount) : selectedOrder?.final_amount
  );
  const [isChanged, setIsChanged] = useState(false);

  const getDetails = useCallback(() => {
    setProductOrder(fragranceDetails?.article_list);
  }, [fragranceDetails]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  /**
   * calculateTotal calculates the total of the order of the
   * single product
   */
  const calculateTotal = useCallback(() => {
    const totalsArray = productOrder.map((articleGroup) => {
      return articleGroup?.groups.map((group) => {
        return group.articles.reduce((accumulator, currentValue) => {
          !currency && setCurrency(() => currentValue?.price?.currency);
          return currentValue?.discount !== null
            ? Number(
                parseFloat(
                  accumulator +
                    currentValue?.qty *
                      parseFloat(
                        currentValue?.price?.value -
                          currentValue?.price?.value *
                            (currentValue?.discount / 100)
                      )
                ).toFixed(2)
              )
            : Number(
                parseFloat(
                  accumulator +
                    currentValue?.qty * parseFloat(currentValue?.price?.value)
                ).toFixed(2)
              );
        }, 0);
      });
    });

    /**
     * totalsTmp is an array with partials totals divided by type
     */
    const totalsTmp = totalsArray.map((tot) => {
      return Number(
        tot.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      ).toFixed(2);
    });

    /**
     * total is the sum of the partials totals
     */
    const total = Number(
      totalsTmp.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue),
        0
      )
    ).toFixed(2);

    return total;
  }, [productOrder, currency]);

  useEffect(() => {
    if (productOrder) {
      // totalCalculated is the total of the articles of a fragrance
      const totalCalculated = calculateTotal();

      setTotal(() => totalCalculated);
      if (isChanged) {
        const newTotal = orderInfo
          ? parseFloat(
              parseFloat(orderInfo?.base_amount) -
                initialSubTotal.current +
                parseFloat(totalCalculated)
            ).toFixed(2)
          : parseFloat(
              selectedOrder?.final_amount -
                initialSubTotal.current +
                parseFloat(totalCalculated)
            ).toFixed(2);
        setLocalTotalOrder(() => newTotal);
      } else {
        initialSubTotal.current = parseFloat(totalCalculated);
      }
    }
  }, [calculateTotal, isChanged, orderInfo, productOrder, selectedOrder]);

  /**
   * handleChangeQty handles the value of the quantity of the
   * various articles
   *
   * @param {*} e - event object
   * @param {*} group - group of the article
   * @param {*} article_code - code of the article
   * @returns null
   */
  const handleChangeQty = (e, group_type, article_code) => {
    /**
     * This condition check if e.value is not a number or its length is zero.
     * Length = 0 it's used to delete the content of an input's element with
     * back button
     */
    if (!isNaN(parseInt(e.value)) || e.value.length === 0) {
      const productOrderUpdated = productOrder.map((articleGroup) => {
        return {
          ...articleGroup,
          groups: articleGroup?.groups?.map((group) => {
            return {
              ...group,
              articles: group.articles.map((article) => {
                return article?.type === group_type &&
                  article?.article_code === article_code
                  ? {
                      ...article,
                      qty:
                        typeof e.value === "string"
                          ? e.value.length > 0
                            ? parseInt(e.value)
                            : 0
                          : e.value,
                    }
                  : article;
              }),
            };
          }),
        };
      });
      setProductOrder(() => productOrderUpdated);
      setIsChanged(true);
    }
    return;
  };

  /**
   * handleChangeDiscount handles the value of the discount of the
   * various articles
   *
   * @param {*} e
   * @param {*} group
   * @param {*} article_code
   * @returns
   */
  const handleChangeDiscount = (e, group_type, article_code) => {
    if (!isNaN(e.value)) {
      const productOrderUpdated = productOrder.map((articleGroup) => {
        return {
          ...articleGroup,
          groups: articleGroup?.groups?.map((group) => {
            return {
              ...group,
              articles: group.articles.map((article) => {
                return article?.type === group_type &&
                  article?.article_code === article_code
                  ? {
                      ...article,
                      discount:
                        e.value.length > 0
                          ? parseFloat(e.value) <= 100
                            ? parseFloat(e.value)
                            : 100
                          : 0,
                    }
                  : article;
              }),
            };
          }),
        };
      });
      setProductOrder(() => productOrderUpdated);
      setIsChanged(true);
    }
    return;
  };

  /**
   * handleProductDetails handles the state open/close of
   * the product details's tab
   */
  const handleProductDetails = () => {
    setShowProductDetails((prevState) => !prevState);
  };

  /**
   * handleSubmit handles the submit of the form
   * @param {*} e - event object
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const productOrderUpdatedArray = [].concat(
      ...productOrder.map((articleGroup) => {
        return articleGroup?.groups.map((group) => {
          return group.articles.map((article) => {
            return article;
          });
        });
      })
    );
    const articlesArray = [].concat(
      ...productOrderUpdatedArray.map((articlesGroup) => {
        return articlesGroup;
      })
    );
    const id_order = orderInfo?.id ? orderInfo?.id : selectedOrder?.id;
    const fragrance_code = fragranceDetails?.fragrance?.fragrance_code;
    const articles = { articles: articlesArray };

    dispatch(
      setOrderFragrance({
        toast,
        fragrance_code,
        id_order,
        data: articles,
        messageNotification: editOrderSuccess,
      })
    ).then((res) => {
      const orderDetails = res?.payload.selectedOrder;
      dispatch(setArticleList(productOrder));
      if (selectedOrder && orderDetails?.id === selectedOrder?.id) {
        dispatch(setTotalOrder(orderDetails));
      }
      dispatch(setTotalOrderInfo(orderDetails));
      handleCart();
    });
  };

  /**
   *
   */
  const handleClose = (e) => {
    isChanged
      ? window.confirm(
          "Ci sono delle modifiche non salvate. Vuoi uscire senza salvare?"
        ) && handleCart()
      : handleCart();
  };

  const handleSearch = () => {
    handleCart();
    handleOrder();
  };

  const isMobile = useCheckIsMobile();

  return (
    <>
      <style>
        {`
          .c-cart__content {
            height: calc(100vh - 86px - ${alertRef?.current?.offsetHeight}px )
          } 
          @media screen and (min-width: 1024px) {
            .c-cart__content {
              height: calc(100vh - 174px - ${alertRef?.current?.offsetHeight}px )
            } 
          }
        `}
      </style>
      <div
        className={`c-cart c-cart--sidebar c-overlay ${
          notEditable ? "c-cart--not-editable" : ""
        }`}
      >
        <div
          className={`showProductDetails ${
            showProductDetails ? "showProductDetails--open" : ""
          }`}
        >
          {!notEditable && (
            <section
              className={`c-product-detail u-desktop ${
                showProductDetails ? "c-product-detail--open" : ""
              }`}
            >
              <Product showOnlyData={true} />
            </section>
          )}
          <section className="c-cart__main">
            <header className="c-cart__header">
              <strong className="c-cart__lead">
                {notEditable ? "" : fragranceDetails?.fragrance?.class_title}
              </strong>

              <h2 className="c-cart__title t-title t-title--2">
                {fragranceDetails?.fragrance?.fragrance_title}
              </h2>

              {!notEditable && (
                <Button
                  className="c-cart__show u-desktop"
                  icon="arrow-left"
                  fillMode="link"
                  onClick={handleProductDetails}
                >
                  <span className="u-text-close">{showProduct}</span>
                  <span className="u-text-open">{hideProduct}</span>
                </Button>
              )}

              <Button
                className="c-cart__close"
                icon="close"
                fillMode="flat"
                onClick={handleClose}
              />
            </header>
            {fragranceDetails?.fragrance?.alert && (
              <div className="c-cart__alert" ref={alertRef}>
                <span className="icon icon-alert"></span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: fragranceDetails?.fragrance?.alert,
                  }}
                ></div>
              </div>
            )}
            <form
              autoComplete="off"
              className="c-cart__content"
              onSubmit={handleSubmit}
              ref={formEl}
            >
              {productOrder &&
                productOrder.map((article, index) => {
                  return !article?.showAccordion ? (
                    <div key={index}>
                      {notEditable && (
                        <h2 className="c-cart__notehead">{article.label}</h2>
                      )}
                      {article?.groups.length > 0 && (
                        <>
                          {article?.groups.map((group) => {
                            return (
                              <div
                                key={group.label}
                                className={`c-cart-element ${
                                  notEditable && group?.bDiscount
                                    ? "c-cart-element--discounted"
                                    : ""
                                }`}
                              >
                                <strong>{group.label}</strong>
                                {group?.articles?.length > 0 &&
                                  group?.articles.map((item) => {
                                    // Generate array for the select
                                    const range = (start, stop, step) =>
                                      Array.from(
                                        { length: (stop - start) / step + 1 },
                                        (_, i) => start + i * step
                                      );

                                    const tags = item?.tag_promo || [];

                                    return (
                                      <div
                                        className={`c-cart-item ${
                                          item?.available_from &&
                                          item?.available_from?.length > 0
                                            ? "c-cart-item--available-from"
                                            : ""
                                        }`}
                                        key={item?.article_id}
                                        style={{
                                          background:
                                            articleCode === item.article_code &&
                                            "#D8E8D4",
                                        }}
                                      >
                                        <div className="c-cart-item__main l-desktop-flex u-flex-between">
                                          <div className="c-cart-item__unit">
                                            <span className="c-cart-item__code">
                                              {item?.article_title}
                                              {/*{item?.out_of_stock && (*/}
                                              {/*    <InfoArticle*/}
                                              {/*        item={item?.out_of_stock_message}*/}
                                              {/*        classIcon="c-cart-item__alert icon icon-alert"*/}
                                              {/*        isAlert={true}*/}
                                              {/*    />*/}
                                              {/*)}*/}
                                              {(item?.alert && item?.out_of_stock)?
                                                  <InfoArticle
                                                      item={item}
                                                      newLineInAlert={true}
                                                      classIcon="c-cart-item__alert icon icon-alert"
                                                      isAlert={true}
                                                  />
                                                  :
                                                  ( (item?.alert || item?.out_of_stock) && <InfoArticle
                                                      item={item?.alert ? item?.alert : item?.out_of_stock_message}
                                                      classIcon="c-cart-item__alert icon icon-alert"
                                                      isAlert={true}
                                                  />)
                                              }

                                            </span>

                                            <span className={'c-info-tooltip'}>
                                            <InfoArticle
                                                item={item}
                                                classIcon="c-cart-item__info"
                                            />
                                            </span>


                                          </div>
                                          <div className="l-mobile-flex u-flex-middle">
                                            <div className="c-cart-item__meta">
                                              {item?.volume && (
                                                <span className="c-cart-item__badge">
                                                  {item?.volume}
                                                </span>
                                              )}
                                            </div>
                                            {item?.price?.value !== null && (
                                              <div className="c-cart-item__price l-mobile-flex u-flex-middle">
                                                <strong>
                                                  <span>{`${formatNumber(
                                                    item?.price?.value
                                                  )} ${currency}`}</span>
                                                </strong>
                                                <div className="l-mobile-flex">
                                                  <div>
                                                    {/* maxQty is used to discriminate if the field rendered
                                                      is a select or a an input */}
                                                    {item?.maxQty ? (
                                                      <DropDownList
                                                        className="c-cart-price__select"
                                                        popupSettings={{
                                                          className:
                                                            "c-cart-price__select-box",
                                                        }}
                                                        disabled={
                                                          loadingSetOrderFragrance ||
                                                          notEditable ||
                                                          !article?.editable ||
                                                          item?.locked
                                                        }
                                                        name={
                                                          item?.article_code
                                                        }
                                                        data={range(
                                                          0,
                                                          item?.maxQty,
                                                          item?.multiply
                                                        )}
                                                        value={
                                                          item?.qty ||
                                                          range(
                                                            0,
                                                            item?.maxQty,
                                                            item?.multiply
                                                          )[0]
                                                        }
                                                        onChange={(e) =>
                                                          handleChangeQty(
                                                            e,
                                                            item?.type,
                                                            item?.article_code
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <Input
                                                        disabled={
                                                          loadingSetOrderFragrance ||
                                                          notEditable ||
                                                          !article?.editable ||
                                                          item?.locked ||
                                                          (item?.out_of_stock && !item?.qty)
                                                        }
                                                        name={
                                                          item?.article_code
                                                        }
                                                        value={item?.qty || ""}
                                                        onChange={(e) =>
                                                          handleChangeQty(
                                                            e,
                                                            item?.type,
                                                            item?.article_code
                                                          )
                                                        }
                                                        onFocus={(e) =>
                                                          e.target.select()
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  {item?.discount !== null && (
                                                    <div className="c-cart-item__percentage">
                                                      <Input
                                                        disabled={
                                                          loadingSetOrderFragrance ||
                                                          notEditable ||
                                                          !article?.editable ||
                                                          item?.locked
                                                        }
                                                        name={
                                                          item?.article_code
                                                        }
                                                        value={item?.discount}
                                                        onChange={(e) =>
                                                          handleChangeDiscount(
                                                            e,
                                                            item?.type,
                                                            item?.article_code
                                                          )
                                                        }
                                                        onFocus={(e) =>
                                                          e.target.select()
                                                        }
                                                      />
                                                      <span>%</span>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="c-cart-item__extra">
                                          <div className="c-cart-item__badges">
                                            {item?.selling &&
                                              item?.selling?.length > 0 && (
                                                <span className="c-cart-item__badge c-cart-item__badge--special">
                                                  Not selling
                                                </span>
                                              )}
                                            {tags.map((tag) => {
                                              return (
                                                <span
                                                  key={tag}
                                                  className="c-cart-item__badge c-cart-item__tag-promo"
                                                >
                                                  {tag}
                                                </span>
                                              );
                                            })}
                                            {item?.available_from &&
                                              item?.available_from?.length >
                                                0 && (
                                                <span className="c-cart-item__badge c-cart-item__available-from">
                                                  {item?.available_from}
                                                </span>
                                              )}
                                          </div>
                                          {!notEditable && fragranceDetails?.fragrance
                                            ?.show_shipping_multipliers && (
                                            <MultipleItems
                                              currentQty={item?.qty}
                                              shippingMethod={
                                                item?.shipping_method
                                              }
                                              qty={item?.shipping_multiplier}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  ) : (
                    <PanelBar key={index}>
                      <PanelBarItem title={article?.label}>
                        {article?.groups.length > 0 && (
                          <>
                            {article?.groups.map((group) => {
                              return (
                                <div
                                  className="c-cart-element"
                                  key={group.label}
                                >
                                  <strong>{group.label}</strong>
                                  {group?.articles?.length > 0 &&
                                    group?.articles.map((item) => {
                                      // Generate array for the select
                                      const range = (start, stop, step) =>
                                        Array.from(
                                          { length: (stop - start) / step + 1 },
                                          (_, i) => start + i * step
                                        );

                                      const tags = item?.tag_promo || [];

                                      return (
                                        <div
                                          className={`c-cart-item ${
                                            item?.available_from &&
                                            item?.available_from?.length > 0
                                              ? "c-cart-item--available-from"
                                              : ""
                                          }`}
                                          key={item?.article_id}
                                        >
                                          <div className="c-cart-item__main u-flex-between l-desktop-flex">
                                            <div className="c-cart-item__unit">
                                              <span className="c-cart-item__code">
                                                {item?.article_title}
                                              </span>
                                              {(item?.alert && item?.out_of_stock)?
                                                  <InfoArticle
                                                      item={item}
                                                      newLineInAlert={true}
                                                      classIcon="c-cart-item__alert icon icon-alert"
                                                      isAlert={true}
                                                  />
                                                  :
                                                  ( (item?.alert || item?.out_of_stock) && <InfoArticle
                                                      item={item?.alert ? item?.alert : item?.out_of_stock_message}
                                                      classIcon="c-cart-item__alert icon icon-alert"
                                                      isAlert={true}
                                                  />)
                                              }
                                              <InfoArticle
                                                item={item}
                                                classIcon="c-cart-item__info"
                                              />
                                            </div>
                                            <div className="l-mobile-flex u-flex-middle">
                                              <div className="c-cart-item__meta">
                                                {item?.volume && (
                                                  <span className="c-cart-item__badge">
                                                    {item?.volume}
                                                  </span>
                                                )}
                                              </div>
                                              {item?.price?.value !== null && (
                                                <div className="c-cart-item__price l-mobile-flex u-flex-middle">
                                                  <strong>
                                                    <span>{`${formatNumber(
                                                      item?.price?.value
                                                    )} ${currency}`}</span>
                                                  </strong>
                                                  <div className="l-mobile-flex">
                                                    <div>
                                                      {/* maxQty is used to discriminate if the field rendered is a select or a an input */}
                                                      {item?.maxQty ? (
                                                        <DropDownList
                                                          popupSettings={{
                                                            className:
                                                              "c-cart-price__select-box",
                                                          }}
                                                          className=""
                                                          disabled={
                                                            loadingSetOrderFragrance ||
                                                            notEditable ||
                                                            !article?.editable ||
                                                            item?.locked
                                                          }
                                                          name={
                                                            item?.article_code
                                                          }
                                                          data={range(
                                                            0,
                                                            item?.maxQty,
                                                            item?.multiply
                                                          )}
                                                          value={
                                                            item?.qty ||
                                                            range(
                                                              0,
                                                              item?.maxQty,
                                                              item?.multiply
                                                            )[0]
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeQty(
                                                              e,
                                                              item?.type,
                                                              item?.article_code
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <Input
                                                          disabled={
                                                            loadingSetOrderFragrance ||
                                                            notEditable ||
                                                            !article?.editable ||
                                                            item?.locked
                                                          }
                                                          name={
                                                            item?.article_code
                                                          }
                                                          value={
                                                            item?.qty || ""
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeQty(
                                                              e,
                                                              item?.type,
                                                              item?.article_code
                                                            )
                                                          }
                                                          onFocus={(e) => {
                                                            e.target.select();
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                    {item?.discount !==
                                                      null && (
                                                      <div className="c-cart-item__percentage">
                                                        <Input
                                                          disabled={
                                                            loadingSetOrderFragrance ||
                                                            notEditable ||
                                                            !article?.editable ||
                                                            item?.locked
                                                          }
                                                          name={
                                                            item?.article_code
                                                          }
                                                          value={item?.discount}
                                                          onChange={(e) =>
                                                            handleChangeDiscount(
                                                              e,
                                                              item?.type,
                                                              item?.article_code
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.select()
                                                          }
                                                        />
                                                        <span>%</span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>

                                          <div className="c-cart-item__extra">
                                            <div className="c-cart-item__badges">
                                              {item?.selling &&
                                                item?.selling?.length > 0 && (
                                                  <span className="c-cart-item__badge c-cart-item__badge--special">
                                                    Not selling
                                                  </span>
                                                )}
                                              {tags.map((tag) => {
                                                return (
                                                  <span
                                                    key={tag}
                                                    className="c-cart-item__badge c-cart-item__tag-promo"
                                                  >
                                                    {tag}
                                                  </span>
                                                );
                                              })}
                                              {item?.available_from &&
                                                item?.available_from?.length >
                                                  0 && (
                                                  <span className="c-cart-item__badge c-cart-item__available-from">
                                                    {item?.available_from}
                                                  </span>
                                                )}
                                              {item?.alert &&  (
                                                <InfoArticle
                                                  item={item?.alert}
                                                  classIcon="c-cart-item__alert icon icon-alert"
                                                  isAlert={true}
                                                />
                                              )}
                                            </div>
                                            {!notEditable && fragranceDetails?.fragrance
                                              ?.show_shipping_multipliers && (
                                              <MultipleItems
                                                currentQty={item?.qty}
                                                shippingMethod={
                                                  item?.shipping_method
                                                }
                                                qty={item?.shipping_multiplier}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </PanelBarItem>
                    </PanelBar>
                  );
                })}
              {(isCatalogueOrderPages || selectedOrder) && (
                <footer className="c-cart__footer l-tablet-flex u-flex-middle u-flex-end">
                  <div className="c-cart__total-order">
                    <span>{totalOrderLabel}</span>
                    <strong>{`${formatNumber(
                      localTotalOrder
                    )} ${currency}`}</strong>
                  </div>
                  <div className="c-cart__total">
                    <span>{orderSubTotal}</span>
                    <strong>{`${formatNumber(total)} ${currency}`}</strong>
                  </div>
                  {!notEditable && (
                    <Button
                      disabled={loadingSetOrderFragrance}
                      className="c-cart__button"
                      type="submit"
                    >
                      {loadingSetOrderFragrance ? adding : add}
                    </Button>
                  )}
                </footer>
              )}
            </form>
            {!isCatalogueOrderPages && articleCode && (
              <footer className="c-cart__footer l-tablet-flex u-flex-middle u-flex-end">
                {selectedCustomer?.code ? (
                  <Button className="c-cart__button" onClick={handleSearch}>
                    {"Apri o crea ordine"}
                  </Button>
                ) : (
                  <p>
                    Chiudi il cassetto e seleziona un cliente o un ordine per
                    poter aggiungere l'articolo
                  </p>
                )}
              </footer>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default Cart;
