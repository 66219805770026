import React from "react";
import PropTypes from "prop-types";
/* import { useInternationalization } from "@progress/kendo-react-intl"; */

const CellTotal = ({ data, isExpired }) => {

    /* const intl = useInternationalization(); */
    
    return (
        <td
            colSpan="1"
            className={`c-order-total ${isExpired ? "c-row-expired" : ""}`}
            role="gridcell"
            aria-colindex="2"
            aria-selected="false"
            data-grid-col-index="0"
        >
            {/* {intl.formatNumber(data, "c")} */}
            {data}
        </td>
    );
};

CellTotal.propTypes = {
  data: PropTypes.string,
  isExpired: PropTypes.bool,
};

export default CellTotal;
