import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerPayments } from "redux/features/customer/actions";
import { setPagerStatePayments as setPagerState } from "redux/features/customer";
import { toast } from 'react-toastify';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useCustomLocalization } from "utils";
import CellOperationNumber from "./cellOperationNumber";
import CellCode from "./cellCode";
import CellDate from "./cellDate";
import CellDownload from "./cellDownload";
import CellTotal from "./cellTotal";
import CellPaymentType from "./cellPaymentType";
import EmptySearch from "components/emptySearch";
import HeaderCustomerPayments from "./header";
import Loader from "components/loader";
import "./tab-payments.scss";
import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    labelEmptyListCustomerPayments: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabPayments.emptyListCustomerPayments.label',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.emptyListCustomerPayments.label),
    labelStrongEmptyListCustomerPayments: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabPayments.emptyListCustomerPayments.labelStrong',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.emptyListCustomerPayments.labelStrong),
    labelEmptySearch: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabPayments.emptySearch.label',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.emptySearch.label),
    labelStrongEmptySearch: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabPayments.emptySearch.labelStrong',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.emptySearch.labelStrong),          
    totalAmount: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabPayments.totalAmount',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.totalAmount),
  };
}

const TabPayments = ({id}) => {

  const {
    labelEmptyListCustomerPayments,
    labelStrongEmptyListCustomerPayments,
    labelEmptySearch,
    labelStrongEmptySearch,
    totalAmount,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /* const intl = useInternationalization(); */

  const authenticated = useSelector((state) => state.user.authenticated);
  const customerId = useSelector( (state) => state.user.customerId );

  const layoutColumn = useSelector((state) => state.customer.layoutColumnPayments);
  const loading= useSelector((state) => state.customer.loadingPayments);
  const pagerState = useSelector((state) => state.customer.pagerStatePayments);
  const pagination = useSelector((state) => state.customer.paginationPayments);
  const pageSizes = useSelector((state) => state.customer.pageSizesPayments);
  const payments = useSelector((state) => state.customer.payments);
  const paymentsAmountTotal = useSelector((state) => state.customer.paymentsAmountTotal);
  
  /**
   * Get the list of payments
   */
  useEffect(() => {
    if (authenticated) {
      const args = {
        id: id === undefined ? customerId : id,
        pager: pagerState,
        toast
      }
      dispatch(getCustomerPayments(args));
    }
    
  }, [authenticated, customerId, dispatch, id, pagerState]);

  /**
   * pageChange is the function to handle the change between pages
   * @param {object} e - It's an event object
   */
  const pageChange = (e) => {
    const { skip, take } = e.page;
    dispatch(
      setPagerState({
        page: skip / take + 1,
        perPage: take,
      })
    );
  };

  /**
   * sortChange is the function to handle the sort of customers by column
   * @param {object} e - It's an event object
   */
  const sortChange = (e) => {
    if (e.sort.length) {
      const { field, dir } = e.sort[0];
      dispatch(
        setPagerState({
          orderBy: field,
          orderByDirection: dir,
        })
      );
    } else {
      dispatch(
        setPagerState({
          orderBy: null,
          orderByDirection: null,
        })
      );
    }
  };

  /**
   * Render the cell in column operation's number
   * @param {object} data
   * @returns {CellOperationNumber} component
   */
    const cellOperationNumber = (data) => {     
      const { operation_expired, operation_number } = data.dataItem;
      
      return (
        <CellOperationNumber  
          isExpired={operation_expired}
          operationNumber={operation_number}
        />
      );
    };

  /**
   * Render the cell in column Code
   * @param {object} data
   * @returns {CellCode} component
   */
  const cellCode = (data) => {
    const states = layoutColumn.find(
      (column) => column.propId === "payment_code"
    ).editor.data.rows;
    const stateLabel = states.find(
      (state) => state.value === data?.dataItem?.payment_code
    );
    
    return (
      <CellCode 
        code={stateLabel?.label} 
        isExpired={data?.dataItem?.operation_expired} 
      />
    );
  };

  /**
   * Render the cell in column data creation
   * @param {object} data
   * @returns {CellDate} component
   */
  const CellDateCreation = (data) => {
    const { document_date, operation_expired } = data.dataItem;
    return <CellDate date={document_date} isExpired={operation_expired} />;
  };

  /**
   * Render the cell in column data expiration
   * @param {object} data
   * @returns {CellDate} component
   */
  const cellDateExpiration = (data) => {
    const { expiration_date, operation_expired } = data.dataItem;
    return <CellDate date={expiration_date} isExpired={operation_expired} />;
  };

  /**
   * Render the cell in column data expiration
   * @param {object} data
   * @returns {CellDate} component
   */
    const cellOperationDate = (data) => {
      const { operation_date, operation_expired } = data.dataItem;
      return <CellDate date={operation_date} isExpired={operation_expired} />;
    };

  /**
   * Render the cell in column download
   * @param {object} data
   * @returns {CellDownload} component
   */
  const cellDownload = (data) => {
    const { document_id } = data.dataItem;

    return <CellDownload  idDocs={document_id} />;
  };

  /**
   * Render the cell in column data amount
   * @param {object} data
   * @returns {CellTotal} component
   */
  const cellTotalResidual = (data) => {
    const { residual_amount, operation_expired } = data.dataItem;

    return <CellTotal data={residual_amount} isExpired={operation_expired} />;
  };

  /**
   * Render the cell in column data amount
   * @param {object} data
   * @returns {CellTotal} component
   */
  const cellTotalAmount = (data) => {
    const { total_amount, operation_expired } = data.dataItem;

    return <CellTotal data={total_amount} isExpired={operation_expired} />;
  };

  /**
   * Render the cell in column data amount
   * @param {object} data
   * @returns {CellTotal} component
   */
  const cellPaymentType = (data) => {
    const { payment_type, operation_expired } = data.dataItem;

    return <CellPaymentType data={payment_type} isExpired={operation_expired} />;
  };
  
  return (
    <>
      <HeaderCustomerPayments id={id} />
      {loading ? (
        <Loader className="c-loader--center c-loader--absolute" size="medium" />
      ) : payments && payments?.length === 0 ? (
        <EmptySearch 
          icon={pagerState.search ? "search" : null}
          strongText={
            pagerState.search
              ? labelStrongEmptySearch
              : labelStrongEmptyListCustomerPayments
          }
          text={pagerState.search ? labelEmptySearch : labelEmptyListCustomerPayments}
        />
      ) : (
        <>
          <section>
            <Grid
              data={payments}
              pageable={{
                buttonCount: 5,
                info: false,
                pageSizes,
              }}
              onPageChange={pageChange}
              skip={(pagerState?.page - 1) * pagerState?.perPage}
              sortable={{
                allowUnsort: true,
                mode: "single",
              }}
              sort={[
                {
                  field: pagerState.orderBy,
                  dir: pagerState.orderByDirection,
                },
              ]}
              onSortChange={sortChange}
              take={pagination?.size}
              total={pagination?.total}
            >
              {layoutColumn && layoutColumn.map( (column) => {              
                if ( !column.hidden || column.propId === 'id' ) {
                  let cell = null;
                  switch (column?.propId) {
                    case "operation_number":
                      cell = cellOperationNumber
                      break;
                    case "payment_code":
                      cell = cellCode
                      break;
                    case "operation_date":
                      cell = cellOperationDate
                      break;
                    case "document_date":
                      cell = CellDateCreation
                      break; 
                    case "expiration_date":
                      cell = cellDateExpiration
                      break;                                             
                    case "id":
                      cell = cellDownload
                      break;
                    case "residual_amount":
                      cell = cellTotalResidual
                      break;
                    case "total_amount":
                      cell = cellTotalAmount
                      break;
                    case "payment_type":
                      cell = cellPaymentType
                      break;
                    default:
                      cell = null
                      break;
                  }

                  return (
                    <Column
                      key={column?.propId}
                      field={column?.propId}
                      title={ column?.propId === 'id' ? ' ' : column?.label }
                      cell={cell}
                      sortable={ column?.propId === 'id' ? false : true }
                    />
                  )  
                }
                return null;
              })}
            </Grid>
          </section>
          <div className="c-total">
            {/* {totalAmount} <strong>{intl.formatNumber(paymentsAmountTotal, "c")}</strong> */}
            {totalAmount} <strong>{paymentsAmountTotal}</strong>
          </div>
        </>
      )}
    </>
  );
};

export default TabPayments;
