import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCustomers } from "redux/features/customer/actions";
import { setPagerState } from "redux/features/customer";
import { toast } from "react-toastify";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import CellAgent from "./cellAgent";
import CellCode from "./cellCode";
import CellDetails from "./cellDetails";
import HeaderCustomers from "./header";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import Notification from "components/notification";
import "./CustomersListing.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    labelEmptyListCustomer: toLanguageString(
      "metislab.frontend.components.customers.components.emptyListCustomer.label",
      defaultMessages.metislab.frontend.components.customers.components
        .emptyListCustomer.label
    ),
    labelStrongEmptyListCustomer: toLanguageString(
      "metislab.frontend.components.customers.components.emptyListCustomer.labelStrong",
      defaultMessages.metislab.frontend.components.customers.components
        .emptyListCustomer.labelStrong
    ),
    labelEmptySearch: toLanguageString(
      "metislab.frontend.components.customers.components.emptySearch.label",
      defaultMessages.metislab.frontend.components.customers.components.emptySearch
        .label
    ),
    labelStrongEmptySearch: toLanguageString(
      "metislab.frontend.components.customers.components.emptySearch.labelStrong",
      defaultMessages.metislab.frontend.components.customers.components.emptySearch
        .labelStrong
    ),
  };
};




const CustomersListing = () => {

  const {
    labelEmptyListCustomer,
    labelStrongEmptyListCustomer,
    labelEmptySearch,
    labelStrongEmptySearch,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  const customers = useSelector((state) => state.customer.customers);
  const layoutColumn = useSelector((state) => state.customer.layoutColumn);
  const error = useSelector((state) => state.customer.error);
  const loading = useSelector((state) => state.customer.loading);
  const pagerState = useSelector((state) => state.customer.pagerState);
  const pagination = useSelector((state) => state.customer.pagination);
  const pageSizes = useSelector((state) => state.customer.pageSizes);

  /**
   * getListOfCustomers is a callback's hook
   * used to get the list of customers
   */
  const getListOfCustomers = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      dispatch(getCustomers({ toast, pager: pagerState }));
    }
  }, [companyCustomers, selectedCompany, dispatch, pagerState]);

  useEffect(() => {
    getListOfCustomers();
  }, [getListOfCustomers]);

  /**
   * pageChange is the function to handle the change between pages
   * @param {object} e - It's an event object
   */
  const pageChange = (e) => {
    const { skip, take } = e.page;
    dispatch(
      setPagerState({
        page: skip / take + 1,
        perPage: take,
      })
    );
  };

  /**
   * searchChange is the function to handle the search of the customers
   * @param {object} data - It's an object that contains the search params
   */
  const searchChange = (data) => {
    const { search, searchBy } = data;
    dispatch(
      setPagerState({
        page: 1,
        search,
        searchBy,
      })
    );
  };

  /**
   * sortChange is the function to handle the sort of customers by column
   * @param {object} e - It's an event object
   */
  const sortChange = (e) => {
    if (e.sort.length) {
      const { field, dir } = e.sort[0];
      dispatch(
        setPagerState({
          orderBy: field,
          orderByDirection: dir,
        })
      );
    } else {
      dispatch(
        setPagerState({
          orderBy: null,
          orderByDirection: null,
        })
      );
    }
  };

  /**
   * Render the cell in column Agent
   * @param {object} data
   * @returns {CellAgent} component
   */
  const cellAgent = (data) => {
    const states = layoutColumn.find((column) => column.propId === "agente")
      .editor.data.rows;
    const agentName = states.find(
      (state) => state.value === data?.dataItem?.agente
    );
    return <CellAgent agentName={agentName?.label} />;
  };

  /**
   * Render the cell in column Code
   * @param {object} data
   * @returns {CellCode} component
   */
  const cellCode = (data) => {
    const { search_code, fido } = data.dataItem;
    return <CellCode code={search_code} fido={fido} />;
  };

  /**
   * Render the cell in column details
   * @param {object} data
   * @returns {CellDetails} component
   */
  const cellDetails = (data) => {
    const { id } = data.dataItem;
    return <CellDetails id={id} />;
  };

  if (error) {
    return <Notification message="Error occurred" type="error" />;
  }

  return (
    <>
      <HeaderCustomers
        filterByType={layoutColumn}
        loading={loading}
        onSearch={searchChange}
      />
      {loading || loadingIndexWebCustomer ? (
        <Loader className="c-loader--center" size="large" />
      ) : customers && customers?.length === 0 ? (
        <EmptySearch
          icon={pagerState.search ? "search" : null}
          strongText={
            pagerState.search
              ? labelStrongEmptySearch
              : labelStrongEmptyListCustomer
          }
          text={pagerState.search ? labelEmptySearch : labelEmptyListCustomer}
        />
      ) : (
        <Grid
          className="c-table-customers"
          data={customers}
          pageable={{
            buttonCount: 5,
            info: false,
            pageSizes,
          }}
          onPageChange={pageChange}
          skip={(pagerState?.page - 1) * pagerState?.perPage}
          sortable={{
            allowUnsort: true,
            mode: "single",
          }}
          sort={[
            {
              field: pagerState.orderBy,
              dir: pagerState.orderByDirection,
            },
          ]}
          onSortChange={sortChange}
          take={pagination?.size}
          total={pagination?.total}
        >
          {layoutColumn &&
            layoutColumn.map((column) => {
              if (!column.hidden || column.propId === "id") {
                let cell = null;
                switch (column?.propId) {
                  case "search_code":
                    cell = cellCode;
                    break;
                  case "agente":
                    cell = cellAgent;
                    break;
                  case "id":
                    cell = cellDetails;
                    break;
                  default:
                    cell = null;
                    break;
                }

                return (
                  <Column
                    key={column?.propId}
                    field={column?.propId}
                    title={column?.propId === "id" ? " " : column?.label}
                    cell={cell}
                    sortable={column?.propId === "id" ? false : true}
                    width={column?.propId === "search_code" ? 100 : "auto"}
                    locked={column?.propId === "id" ? true : false}
                  />
                );
              }
              return null;
            })}
        </Grid>
      )}
    </>
  );
};

export default React.memo(CustomersListing);
