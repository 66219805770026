import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Skeleton } from "@progress/kendo-react-indicators";
import TooltipTemplate from "./tooltipTemplate";
import { useCheckIsMobile, formatDateByLanguage } from "utils";
import { useInternationalization } from "@progress/kendo-react-intl";
import "./order-header-accordion.scss";

const OrderHeaderAccordion = ({ headerOrder }) => {
  const isMobile = useCheckIsMobile();

  const intl = useInternationalization();
  const languageDateFormat = formatDateByLanguage(intl.locale);

  const loading = useSelector((state) => state.order.loading);

  return (
    <div className="c-headerboard__content l-tablet-flex" style={{ gap: 128 }}>
      <ul className="u-col-2">
        {loading ? (
          <>
            {[192, 152, 168, 224, 192].map((width, index) => (
              <li key={index}>
                <Skeleton style={{ width }} />
              </li>
            ))}
          </>
        ) : (
          <>
            {headerOrder.length > 0 &&
              headerOrder.map((field) => {
                return field?.visible && field?.attribute !== "status_field" ? (
                  <li key={field?.attribute}>
                    <strong>{field?.name}</strong>
                    {field?.attribute === "delivery_date" ||
                    field?.attribute === "customer_order_date"
                      ? field?.value
                        ? intl.formatDate(
                            new Date(field?.value),
                            languageDateFormat
                          )
                        : "-"
                      : field?.value
                      ? field?.value
                      : "-"}
                    {field?.defaulValue?.length > 0 &&
                      field?.value !== field?.defaulValue && (
                        <Tooltip
                          className="c-tooltip-box"
                          anchorElement="target"
                          position={isMobile ? "left" : "right"}
                          content={(props) => (
                            <TooltipTemplate item={props.title} />
                          )}
                        >
                          <span
                            title={JSON.stringify(field?.defaulValue)}
                            className=" k-icon k-i-warning"
                          ></span>
                        </Tooltip>
                      )}
                  </li>
                ) : null;
              })}
          </>
        )}
      </ul>
    </div>
  );
};

OrderHeaderAccordion.propTypes = {
  headerOrder: PropTypes.array,
};

export default OrderHeaderAccordion;
