import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { resetImportExcelFileResult } from "redux/features/user";
import { Button } from "@progress/kendo-react-buttons";
import Loader from "components/loader";
import "./previewDocument.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    errorExtensionFileNotSupported: toLanguageString(
      "upload.errorExtensionFileNotSupported",
      defaultMessages.upload.errorExtensionFileNotSupported
    ),
  };
};

const PreviewDocument = (props) => {
  const { onRemove } = props;
  const { name: filename, uid, validationErrors } = props?.files[0];

  const { errorExtensionFileNotSupported } = useLocalizedMessages();

  const dispatch = useDispatch();

  const loadingImportExcelFile = useSelector(
    (state) => state.user.loadingImportExcelFile
  );
  const importExcelFileResult = useSelector(
    (state) => state.user.importExcelFileResult
  );

  const noteMaxFileSizeLabel = useSelector(
    (state) => state.customer.noteMaxFileSizeLabel
  );

  const handleRemove = () => {
    dispatch(resetImportExcelFileResult());
    onRemove(uid);
  };

  if (validationErrors) {
    return (
      <div>
        <span> {`${filename}:`}</span>
        {validationErrors?.map((error) => {
          // Invalid extension
          if (error === "invalidFileExtension") {
            return (
              <p key={error} className="j-error-import">
                {errorExtensionFileNotSupported}
              </p>
            );
          }
          // File max size exceded
          if (error === "invalidMaxFileSize") {
            if (noteMaxFileSizeLabel?.length > 0) {
              return (
                <p
                  key={error}
                  className="j-error-import"
                  dangerouslySetInnerHTML={{ __html: noteMaxFileSizeLabel }}
                />
              );
            }
          }

          return "";
        })}
      </div>
    );
  }

  return (
    <div className="c-upload__media c-upload__media--document l-mobile-flex u-flex-between u-flex-middle">
      <p className="c-upload__label">{filename}</p>
      {loadingImportExcelFile ? (
        <Loader />
      ) : importExcelFileResult?.bResult ? (
        <span className="k-icon k-i-tick"></span>
      ) : (
        <Button
          className="c-upload__remove"
          icon="close"
          onClick={handleRemove}
        />
      )}
    </div>
  );
};

PreviewDocument.propTypes = {
  props: PropTypes.object,
};

export default PreviewDocument;
