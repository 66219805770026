import React from "react";
import { useSelector } from "react-redux";

import "./company.scss";

const CompanySelected = () => {

  const company = useSelector((state) => state.user.companyChannel);

  return (
    <span className="c-company-selected">
      <strong> { company?.value || 'Nessuna company selezionata' }</strong>
    </span>
  );
};

export default CompanySelected;
