import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    buttonLabelGoToDetails: toLanguageString(
      'metislab.frontend.components.customers.components.table.buttonLabelGoToDetails',
      defaultMessages.metislab.frontend.components.customers.components.table.buttonLabelGoToDetails),
  };
};

const CellDetails = ({ id }) => {
  const { buttonLabelGoToDetails } = useLocalizedMessages();

  const navigate = useNavigate();

  return (
    <td
      colSpan="1"
      className="c-customers-grid__action k-grid-content-sticky"
      role="gridcell"
      aria-colindex="7"
      aria-selected="false"
      data-grid-col-index="6"
      style={{ textOverflow: "unset", left: 0, right: 0 }}
    >
      <Button
        icon="preview"
        fillMode="outline"
        dir="rtl"
        onClick={() => navigate(`${id}`)}
      >
        {buttonLabelGoToDetails}
      </Button>
    </td>
  );
};

CellDetails.propTypes = {
  id: PropTypes.number,
};

export default CellDetails;
