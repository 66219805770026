import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomerContacts,
  // editCustomerContacts,
} from "redux/features/customer/actions";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    dropDownListLabelDisabled: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.dropDownListLabelDisabled',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.dropDownListLabelDisabled),
    dropDownListLabelEnabled: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.dropDownListLabelEnabled',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.dropDownListLabelEnabled), 
      editAccessConfirmButton: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.editAccessConfirmButton',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.editAccessConfirmButton),      
    editAccessLabel: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.editAccessLabel',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.editAccessLabel),    
    inputUsernamePlaceholder: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.inputUsernamePlaceholder',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.inputUsernamePlaceholder),    
    portalAccessLabel: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabContacts.portalAccessLabel',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabContacts.portalAccessLabel),  

    editContactSuccess: toLanguageString(
      'metislab.frontend.components.notification.components.customer.editContactSuccess',
      defaultMessages.metislab.frontend.components.notification.components.customer.editContactSuccess),    
    newContactSuccess: toLanguageString(
      'metislab.frontend.components.notification.components.customer.newContactSuccess',
      defaultMessages.metislab.frontend.components.notification.components.customer.newContactSuccess),  
  
  };
}

const Contact = ({ contact }) => {

  const {
    dropDownListLabelDisabled,
    dropDownListLabelEnabled,
    editAccessConfirmButton,
    editAccessLabel,
    inputUsernamePlaceholder,
    portalAccessLabel,
    editContactSuccess,
    newContactSuccess
  } = useLocalizedMessages();

  const dropDownList = [
    {
      text: dropDownListLabelDisabled,
      value: false,
    },
    {
      text: dropDownListLabelEnabled,
      value: true,
    },
  ];

  /**
   * Customer states
   */
  const layoutColumnContacts = useSelector((state) => state.customer.layoutColumnContacts);
  const panels = useSelector((state) => state.customer.customerDetails);
  const loadingContact = useSelector((state) => state.customer.loadingContact);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const {
    access_enabled,
    address,
    email,
    enabled,
    IdSfa,
    mobile,
    name,
    phone,
    surname,
    title,
    type,
    user_id,
    username,
  } = contact;
  
  const INITIAL_VALUES = {
    enabled: !!enabled ? dropDownList[1] : dropDownList[0],
    username: username ? username : "",
  };

  const PERMISSION_VALIDATION_SCHEMA = Yup.object().shape({
    username: Yup.string().required("Required"),
  });

  const panelHiddenFields = panels.find( panel => panel.name === 'Hidden')?.fields;
  const canEditUsers = panelHiddenFields.find( field => field.name === 'canEditUsers');

  return (
    <>
      <GridLayoutItem className="c-tile">
        <h3 className="c-tile__title t-title t-title--3 k-mt-0">
          {`${name} ${surname || ""}`}
        </h3>
        {access_enabled && (
          <ul className="c-tile__list">
            <li>
              <strong>{portalAccessLabel}</strong>
              <span>{!!enabled 
                ? dropDownListLabelEnabled
                : dropDownListLabelDisabled
              }</span>
            </li>
            <li>
              <strong>
                {layoutColumnContacts && layoutColumnContacts[17].label}:
              </strong>
              <span>{username || "-"}</span>
            </li>
          </ul>
        )}

        <ul className="c-tile__list">
          <li>
            <strong>
              {layoutColumnContacts && layoutColumnContacts[6].label}:
            </strong>
            <span>{address || "-"}</span>
          </li>
          <li className="l-tablet-flex u-flex-start">
            <p className="u-flex-1">
              <strong>
                {layoutColumnContacts && layoutColumnContacts[11].label}:
              </strong>
              <span>{name || ""}</span>
            </p>
            <p className="u-flex-1">
              <strong className="">
                {layoutColumnContacts && layoutColumnContacts[12].label}:
              </strong>
              <span>{surname || ""}</span>
            </p>
          </li>
          <li>
            <strong>
              {layoutColumnContacts && layoutColumnContacts[13].label}:
            </strong>
            <span>{type || "-"}</span>
          </li>
          <li>
            <strong>
              {layoutColumnContacts && layoutColumnContacts[5].label}:
            </strong>
            <span>{title || "-"}</span>
          </li>
          <li>
            <strong>
              {layoutColumnContacts && layoutColumnContacts[8].label}:
            </strong>
            <span>{email || "-"}</span>
          </li>
          <li>
            <strong>
              {layoutColumnContacts && layoutColumnContacts[7].label}:
            </strong>
            <span>{phone || "-"}</span>
          </li>
          <li>
            <strong>
              {layoutColumnContacts && layoutColumnContacts[9].label}:
            </strong>
            <span>{mobile || "-"}</span>
          </li>
        </ul>
        { (canEditUsers?.value && access_enabled)&& (
          <footer className="c-tile__footer">
            <Button
              className="c-tile__button"
              icon="pencil"
              themeColor="inverse"
              dir="rtl"
              onClick={handleModal}
            >
              {editAccessLabel}
            </Button>
          </footer>
        )}
      </GridLayoutItem>
      {showModal && (
        <Dialog title={editAccessLabel} onClose={handleModal}>
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              const data = {
                enabled: values.enabled.value,
                username: values.username,
              };
              const idsfa = IdSfa;


              const args = { 
                data, 
                toast, 
                idsfa, 
                messageNotification: user_id 
                  ? editContactSuccess
                  : newContactSuccess
              };
              
              dispatch(createCustomerContacts(args))
                .then(() => handleModal());
            }}
            validationSchema={PERMISSION_VALIDATION_SCHEMA}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="c-form-field">
                    <label htmlFor="enabled">{portalAccessLabel}</label>
                    <DropDownList
                      className=""
                      name="enabled"
                      data={dropDownList}
                      textField="text"
                      dataItemKey="value"
                      defaultValue={values.enabled}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="c-form-field">
                    <label htmlFor="username">
                      {layoutColumnContacts && layoutColumnContacts[17].label}
                    </label>
                    <Input
                      disabled={true}
                      name="username"
                      placeholder={inputUsernamePlaceholder}
                      value={values.username}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={
                        errors.username && touched.username
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.username && touched.username && (
                      <div className="input-feedback">{errors.username}</div>
                    )}
                  </div>
                  <DialogActionsBar>
                    <Button type="submit" disabled={loadingContact}>
                      {editAccessConfirmButton}
                    </Button>
                  </DialogActionsBar>
                </form>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </>
  );
};

export default Contact;
