import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { resetSearch } from "redux/features/search";
import { Link } from "react-router-dom";

const ProductItemSearch = ({ item }) => {
  const dispatch = useDispatch();

  const selectedOrder = useSelector((state) => state.user.selectedOrder);

  const handleClick = () => {
    dispatch(resetSearch(item));
  };
  
  return (
    <li className="c-box-research__item k-py-3 k-px-2">
      <Link
        className="k-d-flex k-align-items-center"
        to={
          selectedOrder
            ? `/orders/${selectedOrder?.id}/edit`
            : `/catalogue/${item.fragrance_code}`
        }
        onClick={handleClick}
      >
        <img
          className="c-box-research__img u-tablet"
          src={item.preview_file_name || "/img/placeholder-medium.jpg"}
          width={48}
          height={48}
          alt=""
        />
        <div className="c-box-research__content k-ml-2">
          <div className="c-box-research__main k-m-0 l-mobile-flex u-flex-start">
            <div className="l-tablet-flex u-flex-middle">
              <h3 className="c-box-research__title k-pr-2">
                {item.fragrance_title}
              </h3>
              <strong className="c-box-research__cat u-tablet k-pr-2">
                {item.class_title}
              </strong>

              <span className="c-box-research__cod k-pr-3">
                <span className="u-tablet">Cod. </span>
                {item.article_code}
              </span>
            </div>
            {item?.available_from && item?.available_from?.length > 0 && (
              <span
                className="c-box-research__badge u-mr-6"
                style={{ background: "#FFD3AD" }}
              >
                {item?.available_from}
              </span>
            )}
            {item?.volume && (
              <span className="c-box-research__badge">{item.volume}</span>
            )}
          </div>
          {/* <p className="k-m-0 c-box-research__text u-tablet">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p> */}
        </div>
      </Link>
    </li>
  );
};

ProductItemSearch.propTypes = {
  item: PropTypes.object,
};

export default ProductItemSearch;
