import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplate from "./tooltipTemplate";
import "./cell-state.scss";

const CellState = ({ note, state, stateLabel }) => {
  return (
    <td
      colSpan="1"
      className="c-state-order"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="5"
    >
      {note ? (
        <Tooltip
          className="c-tooltip-box"
          anchorElement="target"
          position="bottom"
          content={(props) => <TooltipTemplate item={props.title} />}
        >
          <span className={`s-${state}`} title={note}>
            {stateLabel || "-"}
          </span>
        </Tooltip>
      ) : (
        <span className={`s-${state}`}>{stateLabel || "-"}</span>
      )}
    </td>
  );
};

CellState.propTypes = {
  state: PropTypes.number,
  stateLabel: PropTypes.string,
  note: PropTypes.any,
};

export default CellState;
