import React from "react";
import "./header.scss";

const Header = ({ children }) => {
  return (
    <header className="c-subheader l-tablet-flex u-flex-middle u-flex-between">
      {children}
    </header>
  );
};

export default Header;
