import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarousel } from "redux/features/home/actions";
import { toast } from "react-toastify";
import Carousel from "components/carousel";
import LastDocuments from "./documents";
import LastOrders from "./orders";
import "./home.scss";

const Home = () => {

  const dispatch = useDispatch();
  /**
   * IndexWebCustomer states
   */
  const loadingCompany = useSelector((state) => state.indexWebCustomer.loading);
  const selectedCompany = useSelector((state) => state.indexWebCustomer.selectedCompany);
  const companyCustomers = useSelector((state) => state.indexWebCustomer.indexCustomers);
  const loadingIndexWebCustomer = useSelector((state) => state.indexWebCustomer.loading);

  /**
   * Homepage states
   */
  const loadingCarousel = useSelector((state) => state.home.loadingCarousel);
  const carousel = useSelector((state) => state.home.carousel);
  const carouselStandTime = useSelector((state) => state.home.carouselStandTime);

  /**
   * User states
   */
  const loadingSetCustomer = useSelector((state) => state.user.loading);

  const getListCarousel = useCallback( () => {
    if (selectedCompany && companyCustomers) {
      dispatch(getCarousel({toast}));
    }
  }, [companyCustomers, dispatch, selectedCompany])

  useEffect(() => {
    getListCarousel();
  }, [getListCarousel])

  const carouselImages = carousel?.map( slide => slide.image);

  return (
    <div className="c-content-home">
      <Carousel
        automaticViewChange={true}
        automaticViewChangeInterval={carouselStandTime}
        images={carouselImages}
        pageable={true}
        slides={false}
        templates={carousel}
        width="100%"
        loader={ loadingCompany || loadingCarousel || loadingIndexWebCustomer || loadingSetCustomer === 'pendingSetCustomer' }
      />
      <LastDocuments />
      <LastOrders />
    </div>
  );
};

export default Home;
