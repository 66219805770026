import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ProspectDetails from './get-prospect';
import ProspectEdit from './edit-prospect';
import './prospects-details.scss';

import DATA_DUMMIES from '../data';

const ProspectsDetails = () => {

    const { id } = useParams();

    const [editView, setEditView] = useState(false);

    const handleEdit = () => {
        setEditView(!editView);
    }

    const prospect = DATA_DUMMIES.find( element => element.id === Number(id) );
    
    return editView 
        ? (
            <ProspectEdit 
                handleEdit={ handleEdit }  
                data={ prospect }
            />      
        )
        : (
            <ProspectDetails 
                handleEdit={ handleEdit }  
                data={ prospect }
            />
        )
}

export default ProspectsDetails;