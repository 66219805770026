import React, { useMemo } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import ContentModal from "./content-modal";
import "./modal.scss";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    createNoteModalTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.createNoteModalTitle",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.createNoteModalTitle
    ),
    editNoteModalTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.editNoteModalTitle",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.editNoteModalTitle
    ),
  };
};

const Modal = (props) => {
  const {
    content,
    customerId,
    files = [],
    isUpdate = false,
    noteId = null,
    text = null,
    onClose,
  } = props;

  const { createNoteModalTitle, editNoteModalTitle } = useLocalizedMessages();

  const titleModal = useMemo(() => {
    if (!text && files.length === 0) {
      return createNoteModalTitle;
    }

    return editNoteModalTitle;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, text]);

  return (
    <Dialog
      className="c-modal-edit-note"
      title={titleModal}
      onClose={onClose}
      width={566}
    >
      <ContentModal
        content={content}
        customerId={customerId}
        files={files}
        isUpdate={isUpdate}
        noteId={noteId}
        text={text}
        onClose={onClose}
      />
    </Dialog>
  );
};

export default Modal;
