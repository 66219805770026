import { createSlice } from "@reduxjs/toolkit";
import { 
    getCarousel,
    getLastOrders,
    getLastDocuments, 
} from "./actions";


const initialState =  { 
    carousel: [],
    carouselStandTime: 5000,
    loadingCarousel: false,

    lastOrders: [0, 1, 2, 3],
    loadingLastOrder: false,

    lastDocuments: [0, 1, 2, 3],
    loadingLastDocuments: false,

    documentPermissions: {
        documentDownload: true,
    },
    orderPermissions: {
        orderView: true,
    },
};

const homepageSlice = createSlice({
    name: "homepage",
    initialState,
    reducers: {},
    extraReducers: {
        /**
         * Get carousel
         */
        [getCarousel.pending]: (state) => {
            state.loadingCarousel = true;
        },
        [getCarousel.fulfilled]: (state, action) => {      
            const { carousel, carouselStandTime } = action.payload;         
            state.loadingCarousel = false;
            state.carousel = carousel;
            state.carouselStandTime = carouselStandTime && parseFloat( carouselStandTime * 1000);
        },
        [getCarousel.rejected]: (state, action) => {
            state.loadingCarousel = false;
        },
        /**
         * Get list of last orders
         */
        [getLastOrders.pending]: (state) => {
            state.loadingLastOrder = true;
        },
        [getLastOrders.fulfilled]: (state, action) => {      
            state.loadingLastOrder = false;
            const { table: { rows } } = action.payload;
            if(action.payload.orderPermissions){
                state.orderPermissions.orderView = action.payload.orderPermissions.orderView ?? true;
            }
            state.lastOrders = rows;
        },
        [getLastOrders.rejected]: (state, action) => {
            state.loadingLastOrder = false;
        },
        /**
         * Get list of last last documents
         */
        [getLastDocuments.pending]: (state) => {
            state.loadingLastDocuments = true;
        },
        [getLastDocuments.fulfilled]: (state, action) => {
            state.loadingLastDocuments = false;
            if(action.payload.documentPermissions) {
                state.documentPermissions.documentDownload = action.payload.documentPermissions.documentDownload ?? true;
            }
            const { table: { rows } } = action.payload;
            state.lastDocuments = rows;
        },
        [getLastDocuments.rejected]: (state, action) => {
            state.loadingLastDocuments = false;
        },    
    },
});

export default homepageSlice.reducer;