import React from "react";
import PropTypes from "prop-types";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    code: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.promo.tooltipTemplate.code',
      defaultMessages.metislab.frontend.components.order.components.catalogue.promo.tooltipTemplate.code),
    ean: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.promo.tooltipTemplate.ean',
      defaultMessages.metislab.frontend.components.order.components.catalogue.promo.tooltipTemplate.ean),
    description: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.promo.tooltipTemplate.description',
      defaultMessages.metislab.frontend.components.order.components.catalogue.promo.tooltipTemplate.description),
  
  };
}

const TooltipTemplate = ({ item }) => {
  const {
    code,
    description,
    ean,
  } = useLocalizedMessages();

  const itemParsed = JSON.parse(item);

  return typeof itemParsed === 'string'
    ? <p>{itemParsed}</p>
    : (
    <ul className="c-tooltip__content">
      <li>
        <strong>{code}</strong>
        <span>{itemParsed?.article_code}</span>
      </li>
      <li>
        <strong>{ean}</strong>
        <span>{itemParsed?.ean}</span>
      </li>
      <li>
        <strong>{description}</strong>
        <span>{`${itemParsed?.article_title} ${itemParsed?.description2 ? itemParsed?.description2 : ''}`}</span>
      </li>
    </ul>
  );
};

TooltipTemplate.propTypes = {
  item: PropTypes.string,
};

export default TooltipTemplate;
