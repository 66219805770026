import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArticleGallery } from "redux/features/catalogue/actions";
import { Dialog } from "@progress/kendo-react-dialogs";
import { toast } from "react-toastify";
import Carousel from "components/carousel";
import Loader from "components/loader";
import "./article-gallery.scss";

const ArticleGallery = ({ articleId, handleGallery }) => {
  const dispatch = useDispatch();

  const loadingGallery = useSelector((state) => state.fragrance.loadingGallery);
  const articleGallery = useSelector((state) => state.fragrance.articleGallery);

  const getDetails = useCallback(() => {
    const args = {
      articleId,
      toast,
    };

    dispatch(getArticleGallery(args));
  }, [articleId, dispatch]);

  useEffect(() => getDetails(), [getDetails]);

  return (
    <Dialog
      className="c-modal-gallery"
      title=" "
      onClose={handleGallery}
      width={566}
    >
      {loadingGallery ? (
        <Loader size="large" className="c-loader c-loader--center" />
      ) : (
        <Carousel images={articleGallery} />
      )}
    </Dialog>
  );
};

export default ArticleGallery;
