import { createSlice } from "@reduxjs/toolkit";
import { 
    getCustomers, 
    getCustomerAddresses,
    getCustomerContacts,
    getCustomerDetails,
    getCustomerPayments,
    createCustomerContacts,
    downloadCustomerPayment,
    getCustomerExportExcelFile,
    getCustomerNotes,
    getCustomerNotesMaxTextLength,
    getCustomerNotesMediaFileInfo,
    getCustomerNotesDocumentFileInfo,
    importCustomerTextNote,
    importCustomerMediaNote,
    importCustomerDocumentNote,
    deleteCustomerNote,
    updateCustomerNote,
} from "./actions";
import { keyBy, merge } from 'lodash';

const initialState =  {
    customers: null,
    customerAddresses: null,
    customerContacts: null,
    customerDetails: null,
    customerHeader: null,
    customerExportExcelFile: null,

    layoutColumn: null,
    layoutColumnAddresses: null,
    layoutColumnContacts: null,
    layoutColumnPayments: null,

    loading: false,
    loadingPayments: false,
    loadingDownloadDoc: false,
    loadingContact: false,
    loadingExportExcelFile: false,
    loadingCustomerNotes: false,
    loadingCustomerNotesMaxTextLength: false,
    loadingCustomerNotesMediaFileInfo: false,
    loadingCustomerNotesDocumentFileInfo: false,
    loadingImportCustomerTextNote: false,
    loadingImportCustomerMediaNote: false,
    loadingImportCustomerDocumentNote: false,
    loadingDeleteCustomerNote: false,

    notes: null,
    notesMediaAllowedExtensions: null,
    notesMediaAllowedMaxSize: null,
    notesDocumentAllowedExtensions: null,
    notesDocumentAllowedMaxSize: null,
    notesTextMaxLength: null,
    notesWarningMessage: null,
    noteMaxFileSizeLabel: '',

    pagerState: {
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
    },
    pagerStatePayments: {
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        search: null,
        searchBy: null,
    },

    pageSizes: null,
    pageSizesPayments: null,

    pagination: null,
    paginationPayments: null,

    payments: null,
    paymentsAmountTotal: null,

    error: false,
    errorCustomerDetails: false,
    errorCustomerDetailsStatus: null,
    errorExportExcelFile: null,
};

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        resetPaymentsList: (state, action) => {
            state.payments = null;
        },
        setPagerState: (state, action) => {
            state.pagerState = { ...state.pagerState, ...action.payload };
        },
        setPagerStatePayments: (state, action) => {
            state.pagerStatePayments = { ...state.pagerStatePayments, ...action.payload };
        }
    },
    extraReducers: {
        /**
         * Get paginated list of customers 
         */
        [getCustomers.pending]: (state, action) => {
            state.loading = true;
        },
        [getCustomers.fulfilled]: (state, action) => {
            const {
                grid: {
                    columns,
                    layout: { children },
                    pageSizes
                },
                table: {
                    page,
                    pages,
                    rows,
                    size,
                    total,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            })
            state.loading = false;
            state.customers = rows;
            state.pageSizes = pageSizes;
            state.layoutColumn = arrayMergeColumnsAndChildren;        
            state.pagination = {
                page,
                pages,
                size,
                total,
            };
        },
        [getCustomers.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
        }, 
         /**
         * Get addresses of a customer
         */
        [getCustomerAddresses.pending]: (state) => {
            state.loading = true;
        },
        [getCustomerAddresses.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                grid: { columns },
                table: { rows }
            } = action.payload;
            state.layoutColumnAddresses = columns;
            state.customerAddresses = rows;
        },
        [getCustomerAddresses.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Get details of a customer
         */
        [getCustomerDetails.pending]: (state) => {
            state.loading = 'pendingCustomerDetails';
            state.errorCustomerDetails = false;
            state.errorCustomerDetailsStatus = false;
        },
        [getCustomerDetails.fulfilled]: (state, action) => {      
            state.loading = false;
            const { panels, record } = action.payload;
            state.customerHeader = record;
            state.customerDetails = panels;
        },
        [getCustomerDetails.rejected]: (state, action) => {
            state.loading = false;
            state.errorCustomerDetails = action.payload?.sMessageText;
            state.errorCustomerDetailsStatus = action.payload?.status;
        }, 
        /**
         * Get contacts of a customer
         */
        [getCustomerContacts.pending]: (state) => {
            state.loading = true;
        },
        [getCustomerContacts.fulfilled]: (state, action) => {      
            state.loading = false;
            const { 
                grid: { columns },
                table: { rows }
            } = action.payload;
            state.layoutColumnContacts = columns;
            state.customerContacts = rows;
        },
        [getCustomerContacts.rejected]: (state, action) => {
            state.loading = false;
        }, 
        /**
         * Get payments of a customer
         */
        [getCustomerPayments.pending]: (state) => {
            state.loadingPayments = true;
        },
        [getCustomerPayments.fulfilled]: (state, action) => {   
            const {
                grid: {
                    columns,
                    layout: { children },
                    pageSizes
                },
                table: {
                    amount_total,
                    page,
                    pages,
                    rows,
                    size,
                    total,
                }
            } = action.payload;

            const mergeColumnsAndChildren = merge( keyBy(children, 'refId'), keyBy(columns, 'propId'));

            const arrayMergeColumnsAndChildren = Object.keys(mergeColumnsAndChildren).map(key => {
                return mergeColumnsAndChildren[key];
            });

            state.loadingPayments = false;
            state.payments = rows;
            state.paymentsAmountTotal = amount_total;
            state.pageSizesPayments = pageSizes;
            state.layoutColumnPayments = arrayMergeColumnsAndChildren;
            state.paginationPayments = {
                page,
                pages,
                size,
                total,
            };
        },
        [getCustomerPayments.rejected]: (state, action) => {
            state.loadingPayments = false;
        }, 
        /**
         * Create contact of a customer
         */
        [createCustomerContacts.pending]: (state) => {
            state.loadingContact = true;
        },
        [createCustomerContacts.fulfilled]: (state, action) => {      
            state.loadingContact = false;
            const { 
                grid: { columns },
                table: { rows }
            } = action.payload;
            state.layoutColumnContacts = columns;
            state.customerContacts = rows;
        },
        [createCustomerContacts.rejected]: (state, action) => {
            state.loadingContact = false;
        },
        /**
         * Download document relative to a payment of a customer
         */
        [downloadCustomerPayment.pending]: (state, action) => {
            state.loadingDownloadDoc = true;
        },
        [downloadCustomerPayment.fulfilled]: (state, action) => {
            state.loadingDownloadDoc = false;
        },
        [downloadCustomerPayment.rejected]: (state, action) => {
            state.loadingDownloadDoc = false;
        },  
        /**
         * Get export file of a customer account balance
         */
        [getCustomerExportExcelFile.pending]: (state, action) => {
            state.loadingExportExcelFile = true;
            state.customerExportExcelFile = null;
            state.errorExportExcelFile = null;
        },
        [getCustomerExportExcelFile.fulfilled]: (state, action) => {
            state.loadingExportExcelFile = false;
            const {
                bResult,
                name,
                url,
            } = action.payload;
            
            if (bResult) {
                state.customerExportExcelFile = {name, url};
            }
            
        },
        [getCustomerExportExcelFile.rejected]: (state, action) => {
            const { sMessageText } = action.payload;
            state.loadingExportExcelFile = false;
            state.errorExportExcelFile = sMessageText;
        },   
        /**
         * Get the notes of a customer
         */
        [getCustomerNotes.pending]: (state, action) => {
            state.loadingCustomerNotes = true;
        },
        [getCustomerNotes.fulfilled]: (state, action) => {
            state.loadingCustomerNotes = false;
            const { notes } = action.payload;
            state.notes = notes;
        },
        [getCustomerNotes.rejected]: (state, action) => {
            state.loadingCustomerNotes = false;
        },  
        /**
         * Get the max length of the text of notes
         */
        [getCustomerNotesMaxTextLength.pending]: (state, action) => {
            state.loadingCustomerNotesMaxTextLength = true;
        },
        [getCustomerNotesMaxTextLength.fulfilled]: (state, action) => {
            state.loadingCustomerNotesMaxTextLength = false;
            const { max_note_length } = action.payload;
            state.notesTextMaxLength = max_note_length;
        },
        [getCustomerNotesMaxTextLength.rejected]: (state, action) => {
            state.loadingCustomerNotesMaxTextLength = false;
        }, 
        /**
         * Get the max length and allowed extensions for the media's files of notes
         */
        [getCustomerNotesMediaFileInfo.pending]: (state, action) => {
            state.loadingCustomerNotesMediaFileInfo = true;
        },
        [getCustomerNotesMediaFileInfo.fulfilled]: (state, action) => {
            state.loadingCustomerNotesMediaFileInfo = false;
            const { 
                allowed_extensions, 
                maxFileSize,
                max_note_length,
                maxFileSizeLabel,
                modalMessage,
            } = action.payload;
            state.notesTextMaxLength = max_note_length;
            state.notesMediaAllowedExtensions = allowed_extensions;
            state.notesMediaAllowedMaxSize = maxFileSize;
            state.noteMaxFileSizeLabel = maxFileSizeLabel;
            state.notesWarningMessage = modalMessage;
        },
        [getCustomerNotesMediaFileInfo.rejected]: (state, action) => {
            state.loadingCustomerNotesMediaFileInfo = false;
        },
        /**
         * Get the max length and allowed extensions for the media's files of notes
         */
        [getCustomerNotesDocumentFileInfo.pending]: (state, action) => {
            state.loadingCustomerNotesDocumentFileInfo = true;
        },
        [getCustomerNotesDocumentFileInfo.fulfilled]: (state, action) => {
            state.loadingCustomerNotesDocumentFileInfo = false;
            const { 
                allowed_extensions, 
                maxFileSize,
                max_note_length,
                maxFileSizeLabel,
                modalMessage,
            } = action.payload;
            state.notesTextMaxLength = max_note_length;
            state.notesDocumentAllowedExtensions = allowed_extensions;
            state.notesDocumentAllowedMaxSize = maxFileSize;
            state.noteMaxFileSizeLabel = maxFileSizeLabel;
            state.notesWarningMessage = modalMessage;
        },
        [getCustomerNotesDocumentFileInfo.rejected]: (state, action) => {
            state.loadingCustomerNotesDocumentFileInfo = false;
        },  
        /**
         * Upload note with only text
         */
        [importCustomerTextNote.pending]: (state, action) => {
            state.loadingImportCustomerTextNote = true;
        },
        [importCustomerTextNote.fulfilled]: (state, action) => {
            state.loadingImportCustomerTextNote = false;
            const { notes } = action.payload;
            state.notes = notes;
        },
        [importCustomerTextNote.rejected]: (state, action) => {
            state.loadingImportCustomerTextNote = false;
        },  
        /**
         * Upload note with media files attachment
         */
        [importCustomerMediaNote.pending]: (state, action) => {
            state.loadingImportCustomerMediaNote = true;
        },
        [importCustomerMediaNote.fulfilled]: (state, action) => {
            state.loadingImportCustomerMediaNote = false;
            const { notes } = action.payload;
            state.notes = notes;
        },
        [importCustomerMediaNote.rejected]: (state, action) => {
            state.loadingImportCustomerMediaNote = false;
        },  
        /** 
         * Upload note with document files attachment
         */
        [importCustomerDocumentNote.pending]: (state, action) => {
            state.loadingImportCustomerDocumentNote = true;
        },
        [importCustomerDocumentNote.fulfilled]: (state, action) => {
            state.loadingImportCustomerDocumentNote = false;
            const { notes } = action.payload;
            state.notes = notes;
        },
        [importCustomerDocumentNote.rejected]: (state, action) => {
            state.loadingImportCustomerDocumentNote = false;
        },  
        /** 
         * Delete note of a customer
         */
        [deleteCustomerNote.pending]: (state, action) => {
            state.loadingDeleteCustomerNote = true;
        },
        [deleteCustomerNote.fulfilled]: (state, action) => {
            state.loadingDeleteCustomerNote = false;
            const { notes } = action.payload;
            state.notes = notes;
        },
        [deleteCustomerNote.rejected]: (state, action) => {
            state.loadingDeleteCustomerNote = false;
        },  
        /** 
         * Update note of a customer
         */
        [updateCustomerNote.pending]: (state, action) => {
            state.loadingImportCustomerTextNote = true;
        },
        [updateCustomerNote.fulfilled]: (state, action) => {
            state.loadingImportCustomerTextNote = false;
            const { notes } = action.payload;
            state.notes = notes;
        },
        [updateCustomerNote.rejected]: (state, action) => {
            state.loadingImportCustomerTextNote = false;
        },              
    },
});

export const { 
    resetPaymentsList, 
    setPagerState,
    setPagerStatePayments
} = customerSlice.actions;
export default customerSlice.reducer;