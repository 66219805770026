import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getCustomerExportExcelFile } from 'redux/features/customer/actions';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import Loader from "components/loader";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {  
      exportButtonLabel: toLanguageString(
        "metislab.frontend.components.customer.components.details.tabs.tabPayments.exportButtonLabel",
        defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.exportButtonLabel
      ),
      exportDownloadButtonLabel: toLanguageString(
        "metislab.frontend.components.customer.components.details.tabs.tabPayments.exportDownloadButtonLabel",
        defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.exportDownloadButtonLabel
      ),
      exportModalTitle: toLanguageString(
        "metislab.frontend.components.customer.components.details.tabs.tabPayments.exportModalTitle",
        defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabPayments.exportModalTitle
      ),
    };
  };
  

const ExportFileExcel = ({id}) => {

    const {
        exportButtonLabel,
        exportDownloadButtonLabel,
        exportModalTitle,
      } = useLocalizedMessages();

    const dispatch = useDispatch();

    /**
     * Customer states
     */
    const customerExportExcelFile = useSelector( (state) => state.customer.customerExportExcelFile );
    const errorExportExcelFile = useSelector( (state) => state.customer.errorExportExcelFile );
    const loadingExportExcelFile = useSelector( (state) => state.customer.loadingExportExcelFile );

    /**
     * User states
     */
    const customerId = useSelector( (state) => state.user.customerId );

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      if (showModal) {
        const args = {
            id: id === undefined ? customerId : id,
        }
        dispatch(getCustomerExportExcelFile(args));
      }
    
    }, [customerId, dispatch, id, showModal])
    

    const handleModal = useCallback( () => {
        setShowModal( (prevState) => !prevState);
    }, []);
    
    return (
        <>
            <Button
                className=""
                fillMode="outline"
                onClick={() => handleModal()}
            >
                {exportButtonLabel}
            </Button>
            {showModal && (
                <Dialog title={exportModalTitle} onClose={handleModal}>
                    {loadingExportExcelFile && <Loader />}
                    {(!loadingExportExcelFile && errorExportExcelFile) && (
                        <div 
                            className="error" 
                            dangerouslySetInnerHTML={{ __html: errorExportExcelFile}}
                        />
                    )}
                    {(!loadingExportExcelFile && !errorExportExcelFile && customerExportExcelFile) && (
                        <>
                            <p className="c-export-order-text">
                                <span className="icon icon-excel-table"></span>
                                <span>{customerExportExcelFile?.name}</span>
                            </p>
                            <DialogActionsBar>
                                <a
                                    className="k-button k-button-md"
                                    href={customerExportExcelFile?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => handleModal(false)}
                                >
                                    <span className="k-icon k-i-download"></span>
                                    <span>{exportDownloadButtonLabel}</span>
                                </a> 
                            </DialogActionsBar>
                        </>
                    )}               
                </Dialog>
            )}
        </>
    )
}

export default ExportFileExcel;