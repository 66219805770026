import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const getCustomers = createAsyncThunk(
    "customer/getCustomers",
    async ( { toast, pager }, { rejectWithValue } ) => {       
        try {
            let query = "/r/sfas/customers?";
            if ( pager ) {
                pager.page && (query += `page=${pager.page}`);
                pager.perPage && (query += `&perPage=${pager.perPage}`);
                pager.orderBy && (query += `&orderBy=${pager.orderBy}`);
                pager.orderByDirection && (query += `&orderByDirection=${pager.orderByDirection}`);
                pager.search && (query += `&search=${encodeURIComponent(pager.search)}`);
                pager.searchBy && (query += `&searchBy=${pager.searchBy.propId}`);    
            }
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(sMessageText);
        }
    }
);

export const getCustomerAddresses = createAsyncThunk(
    "customer/getCustomerAddresses",
    async ( { toast }, { getState, rejectWithValue } ) => {       
        try {
            const {
                customer: { customerHeader: { code } }
            } = getState();
            let query = `/r/sfas/dispatchAddress?searchBy=customer_code&search=${code.v}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);


export const getCustomerContacts = createAsyncThunk(
    "customer/getCustomerContacts",
    async ( { toast }, { getState, rejectWithValue } ) => {       
        try {
            const {
                customer: { customerHeader: { code } }
            } = getState();
            let query = `/r/xtr/customercontacts?search=${code.v}&searchBy=customer_code`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getCustomerDetails = createAsyncThunk(
    "customer/getCustomerDetails",
    async ( { toast, id }, { rejectWithValue } ) => {       
        try {
            let query = `/r/sfas/customers/${id}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            const { status } = err?.response;
            return rejectWithValue({sMessageText, status});
        }
    }
);

export const getCustomerPayments = createAsyncThunk(
    "customer/getCustomerPayments",
    async ( { id, toast, pager}, { rejectWithValue } ) => {       
        try {
            let query = `/r/xtr/customeraccountbalances/${id}?`;
            pager.page && (query += `page=${pager.page}`);
            pager.perPage && (query += `&perPage=${pager.perPage}`);
            pager.orderBy && (query += `&orderBy=${pager.orderBy}`);
            pager.orderByDirection && (query += `&orderByDirection=${pager.orderByDirection}`);
            pager.search && (query += `&search=${pager.search.value}`);
            pager.searchBy && (query += `&searchBy=${pager.searchBy}`);    
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const createCustomerContacts = createAsyncThunk(
    "customer/createCustomerContacts",
    async ({ data, toast, idsfa, messageNotification }, { rejectWithValue }) => {       
        try {
            const query = `/customer/contact/${idsfa}/user`;
            const response = await http.post(query, data);
            toast.success(
                <Notification 
                    message={messageNotification} 
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const downloadCustomerPayment = createAsyncThunk(
    "customer/downloadCustomerPayment",
    async ( { id, toast }, { rejectWithValue } ) => {       
        try {
            let query = `/web/document/download/${id}`;     
            const response = await http.get(query, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            console.log(response);
            const contentDispositionHeader = response.headers['content-disposition'];
            const filename = contentDispositionHeader
                ? contentDispositionHeader.split('filename=')[1]
                : 'file.pdf';
            console.log(filename);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            return response?.status;
        } catch (err) {
            const blob = err?.response?.data;
            const reader = new FileReader();
            reader.onload = () => {
                const jsonString = reader.result;
                const jsonData = JSON.parse(jsonString);
                
                const { sMessageText } = jsonData?.aMessage[0];
                toast.error(
                    <Notification 
                        message={sMessageText} 
                        type="error"
                    />
                );

            };
            reader.readAsText(blob);     
            return false;
        }
    }
);

export const getCustomerExportExcelFile = createAsyncThunk(
    "customer/getCustomerExportExcelFile",
    async ( { id }, { rejectWithValue } ) => {       
        try {
            let query = `/r/xtr/exportcustomeraccountbalances/${id}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            const { status } = err?.response;
            return rejectWithValue({sMessageText, status});
        }
    }
);

export const getCustomerNotes = createAsyncThunk(
    "customer/getCustomerNotes",
    async ( { id, toast }, { rejectWithValue } ) => {       
        try {
            let query = `/r/core/indexNotes/${id}`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getCustomerNotesMaxTextLength = createAsyncThunk(
    "customer/getCustomerNotesMaxTextLength",
    async ( { toast }, { rejectWithValue } ) => {       
        try {
            let query = `/customer/noteInfo/maxTextNoteSize`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getCustomerNotesMediaFileInfo = createAsyncThunk(
    "customer/getCustomerNotesMediaFileInfo",
    async ( { toast }, { rejectWithValue } ) => {       
        try {
            let query = `/customer/noteInfo/enabledMediaFileInfo`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const getCustomerNotesDocumentFileInfo = createAsyncThunk(
    "customer/getCustomerNotesDocumentFileInfo",
    async ( { toast }, { rejectWithValue } ) => {       
        try {
            let query = `/customer/noteInfo/enabledDocumentFileInfo`;
            const response = await http.get(query);
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const importCustomerTextNote = createAsyncThunk(
    "customer/importCustomerTextNote",
    async ( { data, customerId, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/customer/addNote/${customerId}`;
            const response = await http.post(query, data);       
            toast.success(
                <Notification 
                    message={'Nota aggiunta con successo'} 
                    type="success"
                />
            );  
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const importCustomerMediaNote = createAsyncThunk(
    "customer/importCustomerMediaNote",
    async ( { data, customerId, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/customer/addImageNote/${customerId}`;
            const response = await http.post(query, data);       
            toast.success(
                <Notification 
                    message={'Nota aggiunta con successo'} 
                    type="success"
                />
            );  
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const importCustomerDocumentNote = createAsyncThunk(
    "customer/importCustomerDocumentNote",
    async ( { data, customerId, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/customer/addDocumentNote/${customerId}`;
            const response = await http.post(query, data);       
            toast.success(
                <Notification 
                    message={'Nota aggiunta con successo'} 
                    type="success"
                />
            );  
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deleteCustomerNote = createAsyncThunk(
    "customer/deleteCustomerNote",
    async ( { customerId: customerid, noteId: noteid, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/customer/deleteNote/${customerid}/${noteid}`;
            const response = await http.delete(query);
            toast.info(
                <Notification 
                    message={'Nota cancellata con successo'}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateCustomerNote = createAsyncThunk(
    "customer/updateCustomerNote",
    async ( { data, customerId: customerid, noteId: noteid, toast }, { rejectWithValue } ) => {       
        try {    
            const query = `/customer/editNote/${customerid}/${noteid}`;
            const response = await http.post(query, data);
            toast.info(
                <Notification 
                    message={'Nota aggiornata con successo'}
                    type="success"
                />
            );
            return response.data;
        } catch (err) {
            const { sMessageText } = err?.response?.data?.aMessage[0];
            console.log(err);
            toast.error(
                <Notification 
                    message={sMessageText}
                    type="error"
                />
            );
            return rejectWithValue(err.message);
        }
    }
);
