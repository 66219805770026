import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyChannel } from "redux/features/user";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { dispatchBroadcastMessageChangeCompany } from "utils";
import Loader from "components/loader";
import "./select-company.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    buttonLabel: toLanguageString(
      'metislab.frontend.components.selectCompany.components.buttonLabel',
      defaultMessages.metislab.frontend.components.selectCompany.components.buttonLabel),
    title: toLanguageString(
      'metislab.frontend.components.selectCompany.components.title',
      defaultMessages.metislab.frontend.components.selectCompany.components.title),
  };
}


const FormSelectCompany = () => {
  const {
    buttonLabel,
    title,
  } = useLocalizedMessages();

  const company = useSelector((state) => state.user.companyChannel);
  const companies = useSelector((state) => state.user.companies);
  const loading = useSelector((state) => state.user.loading);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [companySelected, setCompanySelected] = useState(company || companies[0]);
  const [isDisabled, setIsDisabled] = useState(false);
  
  useEffect(() => {
    const companyChannelInSessionStorage = JSON.parse(sessionStorage.getItem('CHANNEL'));
    if (companyChannelInSessionStorage) {
      const companyObject = companies.find(company => company?.key === companyChannelInSessionStorage);
      setCompanySelected(companyObject);
      setIsDisabled(true);
    } 
    companies?.length === 1 && navigate("/dashboard", { replace: true });
  }, [companies, navigate]);

  const handleCompany = (e) => {
    setCompanySelected(e.value);
  };

  const handleSubmit = () => {
    dispatch(setCompanyChannel(companySelected));
    if (sessionStorage.getItem('URL_NOTES')) {
      const showNotes = JSON.parse(sessionStorage.getItem('SHOW_NOTES'));
      navigate(`${JSON.parse(sessionStorage.getItem('URL_NOTES'))}`, { state: {showNotes: showNotes}});
    } else {
      navigate("/dashboard");
    }
    
    dispatchBroadcastMessageChangeCompany();
  };

  return (
    <div className="c-form c-form--login">
      <div className="c-fieldset">
        <header className="c-fieldset__header">
          <h1 className="c-fieldset__title t-title t-title--1">
            {title}
          </h1>
        </header>
        <div className="c-form-field">
          <label htmlFor="">Company</label>
          <DropDownList
            className=""
            disabled={ loading || isDisabled }
            name="selectCompany"
            data={companies}
            textField="value"
            dataItemKey="key"
            value={companySelected}
            onChange={handleCompany}
          />
        </div>
        <footer className="c-fieldset__footer">
          <Button
            icon={ loading ? null : "arrow-chevron-right"}
            fillMode="solid"
            dir="rtl"
            onClick={handleSubmit}
            disabled={ loading }
          >
            { loading ? <Loader /> : buttonLabel}
          </Button>
        </footer>
      </div>
    </div>
  );
};

export default FormSelectCompany;
