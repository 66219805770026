import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFragrancesFilters,
  downloadExcelCatalogue,
} from "redux/features/catalogue/actions";
import { useSearchParams } from "react-router-dom";

import { toast } from "react-toastify";
import { setPagerState } from "redux/features/catalogue";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Skeleton } from "@progress/kendo-react-indicators";
import Loader from "components/loader";
import ResetFilter from "components/resetFilter";
import "./header.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    downloadExcel: toLanguageString(
      "metislab.frontend.components.catalogue.components.header.downloadExcel",
      defaultMessages.metislab.frontend.components.catalogue.components.header
        .downloadExcel
    ),

    downloadExcelButtonLabel: toLanguageString(
      "metislab.frontend.components.catalogue.components.header.downloadExcelButtonLabel",
      defaultMessages.metislab.frontend.components.catalogue.components.header
        .downloadExcelButtonLabel
    ),
    downloadExcelModalTitle: toLanguageString(
      "metislab.frontend.components.catalogue.components.header.downloadExcelModalTitle",
      defaultMessages.metislab.frontend.components.catalogue.components.header
        .downloadExcelModalTitle
    ),

    title: toLanguageString(
      "metislab.frontend.components.catalogue.components.header.title",
      defaultMessages.metislab.frontend.components.catalogue.components.header
        .title
    ),
  };
};

const HeaderProducts = () => {
  const {
    downloadExcel,
    downloadExcelButtonLabel,
    downloadExcelModalTitle,
    title,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);

  /**
   * indexWebCustomer states
   */
  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * catalogue states
   */
  const loading = useSelector((state) => state.fragrance.loading);
  const filters = useSelector((state) => state.fragrance.filters);
  const downloadExcelCatalogueFile = useSelector(
    (state) => state.fragrance.downloadExcelCatalogueFile
  );
  const loadingDownloadExcelCatalogue = useSelector(
    (state) => state.fragrance.loadingDownloadExcelCatalogue
  );
  const pagerState = useSelector((state) => state.fragrance.pagerState);

  const [showDownloadExcelOrder, setShowDownloadExcelOrder] = useState(false);

  /**
   * getListOfFilter is a callback's hook
   * used to get the list of fragrances
   */
  const getListOfFilter = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      const args = {
        toast,
        tag: searchParams.get("tag"),
      };
      dispatch(getFragrancesFilters(args));
    }
  }, [companyCustomers, dispatch, selectedCompany, searchParams]);

  useEffect(() => {
    getListOfFilter();
  }, [getListOfFilter]);

  /**
   * handleModalDownloadExcelOrder handles the modal to download the file excel for the catalogue
   */
  const handleModalDownloadExcelOrder = (isOpenAction = true) => {
    setShowDownloadExcelOrder((prevState) => !prevState);
    if (isOpenAction) {
      const args = {
        toast,
      };
      dispatch(downloadExcelCatalogue(args));
    }
  };

  /**
   * Convert the object of filters into array of filters
   * It remove the filter 'famiglie olfattive'
   */
  const arrayOfFilters =
    filters &&
    Object.keys(filters)
      .map((key) => filters[key])
      .filter((filt) => filt?.field !== "fragrance_title");

  const handleFilters = useCallback(
    (e) => {
      dispatch(
        setPagerState({
          page: 1,
          [e.target.name]: e.value.key
            ? { key: e.value.key, label: e.value.label }
            : null,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <header className="c-subheader l-tablet-flex k-align-items-center k-justify-content-between u-bg-beige">
        <h1 className="c-subheader__title t-title t-title--2 k-m-0">{title}</h1>

        {loading || loadingIndexWebCustomer || loadingUser ? (
          <Skeleton />
        ) : (
          <ul
            className="c-subheader__filter c-subheader__filter--catalog l-tablet-flex k-align-items-center"
            style={{ listStyle: "none" }}
          >
            {pagerState?.class_code && (
              <li>
                <ResetFilter
                  dispatchResetAction={() =>
                    dispatch(
                      setPagerState({
                        ...pagerState,
                        class_code: null,
                        fragrance_title: null,
                      })
                    )
                  }
                />
              </li>
            )}
            {arrayOfFilters &&
              arrayOfFilters.map((filter) => {
                const options = Object.entries(filter.options).map(
                  ([_key, _value]) => {
                    return { key: _key, label: _value };
                  }
                );

                const DEFAULT_VALUE = {
                  key: null,
                  label: filter.label,
                };

                return (
                  <li key={filter.field} className="c-filter">
                    {loading || loadingIndexWebCustomer || loadingUser ? (
                      <Skeleton />
                    ) : (
                      <DropDownList
                        className="c-filter-container"
                        name={filter.field}
                        data={options}
                        fillMode="outline"
                        textField="label"
                        dataItemKey="key"
                        onChange={handleFilters}
                        popupSettings={{
                          popupClass: "c-filter-content",
                          width: 180,
                        }}
                        defaultItem={DEFAULT_VALUE}
                        value={pagerState[filter.field]}
                      />
                    )}
                  </li>
                );
              })}
            <li>
              <Button
                className="c-subheader__button"
                icon="excel"
                dir="rtl"
                fillMode="outline"
                onClick={handleModalDownloadExcelOrder}
              >
                {downloadExcel}
              </Button>
            </li>
          </ul>
        )}
      </header>
      {/* Modal to download excel */}
      {showDownloadExcelOrder && (
        <Dialog
          className="c-modal-export-catalogue"
          title={downloadExcelModalTitle}
          onClose={() => handleModalDownloadExcelOrder(false)}
          width={520}
        >
          {loadingDownloadExcelCatalogue ? (
            <Loader />
          ) : (
            <>
              <p className="c-export-order-text">
                <span className="icon icon-excel-table"></span>
                <span>{downloadExcelCatalogueFile?.name}</span>
              </p>
              <DialogActionsBar>
                <a
                  className="k-button k-button-md"
                  href={downloadExcelCatalogueFile?.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleModalDownloadExcelOrder(false)}
                >
                  <span className="k-icon k-i-download"></span>
                  <span>{downloadExcelButtonLabel}</span>
                </a>
              </DialogActionsBar>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default React.memo(HeaderProducts);
