import React, { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFragrances } from "redux/features/catalogue/actions";
import { setPagerState } from "redux/features/catalogue";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { toast } from "react-toastify";
import CellProduct from "./cellProduct";
import HeaderProducts from "./header";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import "./catalog.scss";

const Catalog = () => {
  const dispatch = useDispatch();

  /**
   * isFirstRender is a ref's hook
   * used to run once the call to the endpoints
   */
  const isFirstRender = useRef(true);

  const authenticated = useSelector((state) => state.user.authenticated);

  const fragrances = useSelector((state) => state.fragrance.fragrances);
  const loading = useSelector((state) => state.fragrance.loading);
  const pagerState = useSelector((state) => state.fragrance.pagerState);
  const pagination = useSelector((state) => state.fragrance.pagination);
  const pageSizes = useSelector((state) => state.fragrance.pageSizes);

  /**
   * getListOfFragrances is a callback's hook
   * used to get the list of fragrances
   */
  const getListOfFragrances = useCallback(() => {
    if (authenticated && isFirstRender.current) {
      dispatch(getFragrances({ toast, pager: pagerState }));
      isFirstRender.current = false;
    }
  }, [authenticated, dispatch, pagerState]);

  useEffect(() => {
    getListOfFragrances();
  }, [getListOfFragrances]);

  /**
   * pageChange is the function to handle the change between pages
   * @param {object} e - It's an event object
   */
  const pageChange = (e) => {
    const { skip, take } = e.page;
    dispatch(
      setPagerState({
        page: skip / take + 1,
        perPage: take,
      })
    );
    isFirstRender.current = true;
  };

  const cellProduct = ({ dataItem: product }) => {
    return <CellProduct data={product} />;
  };

  return (
    <>
      <HeaderProducts />
      {loading ? (
        <Loader className="c-loader--center" size="large" />
      ) : fragrances && fragrances?.length === 0 ? (
        <EmptySearch text="La ricerca non ha portato ad alcun risultato. Prova a cambiare i filtri o i parametri di ricerca." />
      ) : (
        <Grid
          className="c-table-gragrances"
          data={fragrances}
          pageable={{ buttonCount: 5, info: false, pageSizes }}
          onPageChange={pageChange}
          skip={(pagerState?.page - 1) * pagerState?.perPage}
          take={pagination?.size}
          total={pagination?.total}
        >
          <Column cell={cellProduct} headerClassName="k-hidden" />
        </Grid>
      )}
    </>
  );
};

export default Catalog;
