import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { StackLayout } from "@progress/kendo-react-layout";
import Header from "../header";
import ProspectsNew from "pages/prospects/add";
import "./prospect-edit.scss";

const ProspectEdit = ({ handleEdit, data }) => {
  const { info } = data;

  const classNames = `c-prospect-state s-${data?.state.toLowerCase()}`;

  const saveProspect = () => {
    console.log("save prospect");
  };

  return (
    <StackLayout orientation="vertical" className="c-user-setting">
      <Link className="c-subheader__link" to="/prospects">
        <span className="k-icon k-i-arrow-left"></span>
        Torna all’elenco dei Prospect
      </Link>
      <Header className="c-subheader c-user-setting__subheader">
        <div className="l-mobile-flex u-flex-baseline">
          <h1 className="c-user-setting__title t-title t-title--2">
            {info?.description}
            <span className={classNames}>{data?.state}</span>
          </h1>
        </div>
        <div className="l-mobile-flex u-flex-middle" style={{ gap: 16 }}>
          <Button
            className="c-user-setting__button"
            fillMode="outline"
            icon="close"
            dir="rtl"
            onClick={handleEdit}
          >
            Annulla
          </Button>
          <Button
            className="c-user-setting__button"
            fillMode="solid"
            icon="tick"
            dir="rtl"
            type="submit"
            onClick={saveProspect}
          >
            Salva Modifiche
          </Button>
        </div>
      </Header>
      <ProspectsNew isEditView data={data} />
    </StackLayout>
  );
};

ProspectEdit.propTypes = {
  handleEdit: PropTypes.func,
  data: PropTypes.object,
};

export default ProspectEdit;
