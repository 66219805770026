import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

const CellProduct = ({ data: product }) => {
  
  const navigate = useNavigate();

  return (
    <td
      colSpan="1"
      className="c-product"
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
    >
      <img src={ product.preview_file_name || "img/placeholder-medium.jpg" } alt="" />
      { product.class_title && (
        <strong className="c-product__lead">
          { product.class_title }
        </strong>
      )}
      { product.fragrance_title && (
        <h3 className="c-product__title t-title t-title--3">
          { product.fragrance_title }
        </h3>
      )}
      { product.fragrance_short_description && (
        <p className="c-product__text">
          { product.fragrance_short_description }
        </p>
      )}
      <Button
        icon="arrow-right"
        dir="rtl"
        onClick={() => navigate(`${ product.fragrance_code }`)}
      >
        {"Tutti i formati"}
      </Button>
    </td>
  );
};

CellProduct.propTypes = {
  product: PropTypes.object,
};

export default CellProduct;
