import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { StackLayout } from "@progress/kendo-react-layout";
import Header from "../header";
import "./prospect-details.scss";

const ProspectDetails = ({ handleEdit, data }) => {
  const { info } = data;

  const classNames = `c-prospect-state s-${data?.state.toLowerCase()}`;

  return (
    <StackLayout
      orientation="vertical"
      className="c-user-setting c-user-setting--prospect"
    >
      <Link className="c-subheader__link" to="/prospects">
        <span className="k-icon k-i-arrow-left"></span>
        Torna all’elenco dei Prospect
      </Link>
      <Header className="c-user-setting__subheader">
        <div className="l-mobile-flex u-flex-middle">
          <h1 className="c-user-setting__title t-title t-title--2">
            {info?.description}
            <span className={classNames}>{data?.state}</span>
          </h1>
        </div>
        {data?.state.toLowerCase() === "da approvare" && (
          <Button
            className="c-user-setting__button"
            fillMode="solid"
            icon="pencil"
            dir="rtl"
            onClick={handleEdit}
          >
            Modifica prospect
          </Button>
        )}
      </Header>
      <section className="c-user-setting__content">
        {data?.state.toLowerCase() !== "da approvare" && (
          <p className="c-text-alert">
            <span className="k-icon k-i-warning"></span>
            <strong>Attenzione: </strong>
            <span>{`il prospect ${
              data?.state.toLowerCase() === "non approvato" ? "non" : ""
            } è stato approvato, non è più possibile modificarlo.`}</span>
          </p>
        )}
        <div className="c-user-setting__item">
          <h2 className="c-user-setting__heading t-title t-title--3">
            Principali
          </h2>
          <ul className="c-user-setting__list l-tablet-grid u-grid-3">
            <li>
              <strong>Ragione sociale:</strong>
              <span>{info?.description}</span>
            </li>
            <li>
              <strong>Email:</strong>
              <span>{info?.email}</span>
            </li>
          </ul>
        </div>
        <div className="c-user-setting__item">
          <h2 className="c-user-setting__heading t-title t-title--3">
            Generali
          </h2>
          <ul className="c-user-setting__list l-tablet-grid u-grid-3">
            <li>
              <strong>Indirizzo:</strong>
              <span>{info?.address}</span>
            </li>
            <li>
              <strong>Frazione:</strong>
              <span>{info?.city}</span>
            </li>
            <li>
              <strong>Comune:</strong>
              <span>{info?.comune}</span>
            </li>
            <li>
              <strong>CAP:</strong>
              <span>{info?.cap}</span>
            </li>
            <li>
              <strong>Provincia:</strong>
              <span>{info?.province}</span>
            </li>
            <li>
              <strong>Nazione:</strong>
              <span>{info?.country}</span>
            </li>
          </ul>
        </div>
        <div className="c-user-setting__item">
          <h2 className="c-user-setting__heading t-title t-title--3">
            Fatturazione
          </h2>
          <ul className="c-user-setting__list l-tablet-grid u-grid-3">
            <li>
              <strong>Codice fiscale:</strong>
              <span>{info?.codfiscale}</span>
            </li>
            <li>
              <strong>Partita IVA:</strong>
              <span>{info?.pIVA}</span>
            </li>
            <li>
              <strong>Modalità di consegna:</strong>
              <span>{info?.delivery_methods}</span>
            </li>
            <li>
              <strong>Tipologia:</strong>
              <span>{info?.typology}</span>
            </li>
            <li>
              <strong>Insegna:</strong>
              <span>{info?.signboard}</span>
            </li>
            <li>
              <strong>Sconto percentuale su listino:</strong>
              <span>{info?.discount}</span>
            </li>
          </ul>
        </div>
        <div className="c-user-setting__item">
          <h2 className="c-user-setting__heading t-title t-title--3">
            Altre informazioni
          </h2>
          <ul className="c-user-setting__list l-tablet-grid u-grid-3">
            <li style={{ gridArea: "1 / 1 / span 1 / span 2" }}>
              <div className="c-form-field">
                <label className="">
                  <strong>Informazioni aggiuntive:</strong>
                </label>
                <span>{info?.notes}</span>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </StackLayout>
  );
};

ProspectDetails.propTypes = {
  handleEdit: PropTypes.func,
  data: PropTypes.object,
};

export default ProspectDetails;
