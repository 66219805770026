import React from 'react';
import ProtectedRoutes from 'components/protectedRoutes';
import PageNotFound from 'components/404';

const NotFound = () => {
  const token = localStorage.getItem('usrtkn') 
  ? localStorage.getItem('usrtkn') 
  : null;

  if (!token) {
    return <ProtectedRoutes />
  } 

  return (
    <PageNotFound />
  );
  
}

export default NotFound;