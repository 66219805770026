import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetErrors } from "redux/features/order";
import { getOrder, getOrderNotEditable, getConcurrentOrderAccess } from "redux/features/order/actions";
import { resetArticleCode } from "redux/features/search";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { toast } from "react-toastify";
import HeaderOrderCatalogue from "./header";
import CellAction from "./cellAction";
import CellFragrance from "./cellFragrance";
import CellProducts from "./cellProducts";
import CellTotal from "./cellTotal";
import Promo from "./promo";
import Cart from "components/cart";
import Error from "components/Error";
import Loader from "components/loader";
import NotFound from "components/notFound";
import "./orders-catalogue.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    columnFragrancesAmount: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.columnFragrancesAmount',
      defaultMessages.metislab.frontend.components.order.components.catalogue.columnFragrancesAmount),
    columnFragrancesProducts: toLanguageString(
      'metislab.frontend.components.order.components.catalogue.columnFragrancesProducts',
      defaultMessages.metislab.frontend.components.order.components.catalogue.columnFragrancesProducts),  
  };
};

const OrdersCatalog = (props) => {
  const { columnFragrancesAmount, columnFragrancesProducts } =
    useLocalizedMessages();

  const { 
    notEditable = false, 
    isPendingOrder = false, 
    isProcessingOrder = false 
  } = props;

  const dispatch = useDispatch();

  const params = useParams();

  const scrollY = useRef(0);

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);
  const loadingCreateOrder = useSelector((state) => state.user.loadingCreateOrder);

  /**
   * IndexWebCustomer states
   */
  const loadingCompany = useSelector((state) => state.indexWebCustomer.loading);
  const selectedCompany = useSelector((state) => state.indexWebCustomer.selectedCompany);
  const companyCustomers = useSelector((state) => state.indexWebCustomer.indexCustomers);

  /**
   * Order states
   */
  const errorOrderInEditing = useSelector((state) => state.order.errorOrderInEditing);
  const errorOrderInEditingStatus = useSelector((state) => state.order.errorOrderInEditingStatus);
  const errorNotEditable = useSelector((state) => state.order.errorNotEditable);
  const errorNotEditableStatus = useSelector((state) => state.order.errorNotEditableStatus);
  const order = useSelector((state) => state.order.orderList);
  const orderHasPromoToSelect = useSelector((state) => state.order.orderHasPromoToSelect);
  const orderNotEditable = useSelector((state) => state.order.orderListNotEditable);
  const loading = useSelector((state) => state.order.loading);

  /**
   * searchProduct States
   */
  const articleCode = useSelector((state) => state.searchProduct.articleCode);

  /**
   * Internal States
   */
  const [showCart, setShowCart] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtersTag, setFiltersTag] = useState([]);
  const [code, setCode] = useState(null);
  const [startPolling, setStartPolling] = useState(false);

  /**
   * getListOrder is a callback's hook
   * used to get the list of the order selected
   */
  const getListOrder = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      const args = {
        id: params.id,
        toast,
      };
      dispatch(getOrder(args)).then(() => {
        window.scrollTo({
          top: scrollY.current,
          left: 0,
          behavior: "auto",
        });
      });
    }
  }, [companyCustomers, params, dispatch, selectedCompany]);

  /**
   * getListOrderNotEditable is a callback's hook
   * used to get the details of the order selected
   */
  const getListOrderNotEditable = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      const args = {
        toast,
        id: params.id,
      };
      dispatch(getOrderNotEditable(args)).then(() => {
        window.scrollTo({
          top: scrollY.current,
          left: 0,
          behavior: "auto",
        });
      });
    }
  }, [companyCustomers, dispatch, params, selectedCompany]);

  useEffect(() => {
    if (!notEditable) {
      getListOrder();
    }

    return () => {
      dispatch(resetErrors(notEditable));
    };
  }, [dispatch, getListOrder, notEditable]);

  useEffect(() => {
    if (notEditable) {
      getListOrderNotEditable();
    }
  }, [getListOrderNotEditable, notEditable]);

  useEffect(() => {
    if (articleCode && !showCart) {
      setCode(articleCode);
      dispatch(resetArticleCode());
    }
  }, [articleCode, code, dispatch, showCart]);

  useEffect(() => {
    const showNotes = JSON.parse(sessionStorage.getItem("SHOW_NOTES"));
    if (!showNotes) {
      sessionStorage.removeItem("SHOW_NOTES");
      sessionStorage.removeItem("URL_NOTES");
      sessionStorage.removeItem("CHANNEL");
    }
  }, []);

  useEffect(() => {
    const showOrderTag = JSON.parse(sessionStorage.getItem("SHOW_ORDER_TAG"));
    if (!showOrderTag) {
      sessionStorage.removeItem("SHOW_ORDER_TAG");
    }
  }, []);

  /**
   * getListOfConcurrentOrderAccess is a callback hook to handle
   * the polling to retrieve concurrent access on same order
   */
  const getListOfConcurrentOrderAccess = useCallback(() => {
    if (selectedCompany && companyCustomers && !isProcessingOrder) {
      const args = {
        idOrder: params?.id,
        data: notEditable ? 0 : 1 
      }    
      dispatch(getConcurrentOrderAccess(args))
        .then( () => {
          setStartPolling(true);
        });
    }
  }, [companyCustomers, dispatch, isProcessingOrder, notEditable, params, selectedCompany]);
  

  useEffect(() => {
    let interval;
    if (!startPolling) {
      getListOfConcurrentOrderAccess();
    } else {
      interval = setInterval(() => {
        getListOfConcurrentOrderAccess();
      }, 14000);
    }

    return () => {
      if (startPolling) {
        clearInterval(interval)};
      }
  }, [getListOfConcurrentOrderAccess, startPolling])

  /**
   * handleCart handles the view of the cart on the right side
   */
  const handleCart = () => {
    scrollY.current = window.scrollY;
    showCart && setCode(null);
    setShowCart((prevState) => !prevState);
  };

  /**
   * handleFilter handles the array of filter presents
   * in header component.
   *
   * @param {string} filterSelected
   */
  const handleFilter = (filterSelected) => {
    /**
     * If filters's state in not empty, check if a filter
     * is already presents and remove it. Otherwise, add it
     */
    filterSelected
      ? filters.length > 0 && filters.includes(filterSelected)
        ? setFilters((prevState) =>
            prevState.filter((filt) => filt !== filterSelected)
          )
        : setFilters((prevState) => [...prevState, filterSelected])
      : setFilters([]);
  };

  /**
   * handleFilterTag is a callback hook to handle
   * the behaviour of the filter's tags buttons clicked
   */
  const handleFilterTag = useCallback(
    (tag) => {
      if (tag) {
        if (filtersTag.length > 0 && filtersTag.includes(tag)) {
          setFiltersTag( (prevState) => 
            prevState.filter( filt => filt !== tag)
          )
        } else {
          setFiltersTag((prevState) => [...prevState, tag])
        }
        
      } else {
        setFiltersTag([]);
      }        
    },
    [filtersTag]
  );

  const cellFragrance = ({ dataItem }) => <CellFragrance data={dataItem} />;

  const cellProducts = ({ dataItem }) => <CellProducts data={dataItem} />;

  const cellTotal = ({ dataItem }) => <CellTotal data={dataItem} />;

  const cellAction = ({ dataItem }) => (
    <CellAction
      data={dataItem}
      handleCart={handleCart}
      idOrder={params.id}
      notEditable={notEditable}
    />
  );

  /**
   * filterOrder is the function used to filter the list
   * of the order (client-side).
   * @returns {array} orderFiltered
   */
  const filterOrder = () => {

    let orderFiltered = order;

    // This is the case with at least a filter selected
    if (filters.length > 0) {
      orderFiltered = orderFiltered.filter((category) =>
        filters.includes(category.label)
      );
    }
    
    // This is the case with at least a tag selected
    if (filtersTag.length > 0) {
      orderFiltered = orderFiltered.map( (category) => {
        return { 
          ...category, 
          rows: category.rows.filter( (row) => {
            const hasTagPromo = filtersTag.some( filt => {
              if (!row.tag_promo) {
                return false;
              }
              return row.tag_promo.includes(filt);
            });

            return hasTagPromo;
          })  
        }
      })
    }

    // If at least a filter is selected, remove the fragrances without rows 
    if (filters.length > 0 || filtersTag.length > 0) {
      const orderList = orderFiltered.filter( (fragrance) => fragrance.rows.length > 0)
      return orderList; 
    }

    return orderFiltered;
  };

  /**
   * orderList handles the list to pass to the data grid
   */
  const orderList = filterOrder();

  if (loadingUser || loadingCreateOrder || loadingCompany) {
    return <Loader className="c-loader--center" size="large" />;
  }

  if (errorNotEditable) {
    return errorNotEditableStatus === 404
      ? <NotFound/>
      : <Error errorText={errorNotEditable}/>;
  }

  if (errorOrderInEditing) {
    return errorOrderInEditingStatus === 404
      ? <NotFound/>
      : <Error errorText={errorOrderInEditing}/>;
  }
  
  return (
    <>
      <HeaderOrderCatalogue
        filter={handleFilter}
        filtersActive={filters}
        notEditable={notEditable}
        isPendingOrder={isPendingOrder}
        isProcessingOrder={isProcessingOrder}
        filterTag={handleFilterTag}
        filtersTagActive={filtersTag}
      />
      {loading || loadingUser ? (
        <Loader className="c-loader--center" size="large" />
      ) : (
        <Grid
          className="c-table-orders"
          data={notEditable ? orderNotEditable : orderList}
        >
          <Column field="class_title" title=" " cell={cellFragrance} />
          <Column
            field="fragrances"
            title={columnFragrancesProducts}
            cell={cellProducts}
          />
          <Column
            field="fragrances"
            title={columnFragrancesAmount}
            cell={cellTotal}
          />
          <Column
            field="fragrances"
            title=" "
            cell={cellAction}
            locked={true}
          />
        </Grid>
      )}
      {showCart && (
        <Cart
          articleCode={code}
          handleCart={handleCart}
          notEditable={notEditable}
        />
      )}
      {notEditable && orderHasPromoToSelect?.openCartPromo && <Promo />}
    </>
  );
};

OrdersCatalog.propTypes = {
  notEditable: PropTypes.bool,
};

export default OrdersCatalog;
