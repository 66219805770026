import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerNotesMaxTextLength,
  getCustomerNotesMediaFileInfo,
  getCustomerNotesDocumentFileInfo,
  importCustomerTextNote,
  importCustomerMediaNote,
  importCustomerDocumentNote,
  updateCustomerNote,
} from "redux/features/customer/actions";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import MediaUpload from "components/mediaUpload";
import { toast } from "react-toastify";
import Loader from "components/loader";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    insertNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.insertNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.insertNote
    ),
    uploadFromComputer: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.uploadFromComputer",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.uploadFromComputer
    ),
    addToNotes: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.addToNotes",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.addToNotes
    ),
    updateNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.updateNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.updateNote
    ),
    uploadingNote: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.uploadingNote",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.uploadingNote
    ),
    insertTextNotePlaceholder: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.insertTextNotePlaceholder",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.insertTextNotePlaceholder
    ),
  };
};

const ContentModal = (props) => {
  const { content, customerId, files, isUpdate, noteId, text, onClose } = props;

  const {
    insertNote,
    uploadFromComputer,
    addToNotes,
    updateNote,
    uploadingNote,
    insertTextNotePlaceholder,
  } = useLocalizedMessages();

  let title = "";

  content === "note" ? (title = insertNote) : (title = uploadFromComputer);

  const dispatch = useDispatch();

  // Loaders - GET requests
  const loadingCustomerNotesMaxTextLength = useSelector(
    (state) => state.customer.loadingCustomerNotesMaxTextLength
  );
  const loadingCustomerNotesDocumentFileInfo = useSelector(
    (state) => state.customer.loadingCustomerNotesDocumentFileInfo
  );
  const loadingCustomerNotesMediaFileInfo = useSelector(
    (state) => state.customer.loadingCustomerNotesMediaFileInfo
  );

  // Loaders upload - POST requests
  const loadingImportCustomerTextNote = useSelector(
    (state) => state.customer.loadingImportCustomerTextNote
  );
  const loadingImportCustomerMediaNote = useSelector(
    (state) => state.customer.loadingImportCustomerMediaNote
  );
  const loadingImportCustomerDocumentNote = useSelector(
    (state) => state.customer.loadingImportCustomerDocumentNote
  );

  const isLoading =
    loadingCustomerNotesMaxTextLength ||
    loadingCustomerNotesMediaFileInfo ||
    loadingCustomerNotesDocumentFileInfo;

  const isUploading =
    loadingImportCustomerMediaNote ||
    loadingImportCustomerDocumentNote ||
    loadingImportCustomerTextNote;

  // Customer Note states
  const notesTextMaxLength = useSelector(
    (state) => state.customer.notesTextMaxLength
  );
  const notesMediaAllowedExtensions = useSelector(
    (state) => state.customer.notesMediaAllowedExtensions
  );
  const notesMediaAllowedMaxSize = useSelector(
    (state) => state.customer.notesMediaAllowedMaxSize
  );
  const notesDocumentAllowedExtensions = useSelector(
    (state) => state.customer.notesDocumentAllowedExtensions
  );
  const notesDocumentAllowedMaxSize = useSelector(
    (state) => state.customer.notesDocumentAllowedMaxSize
  );
  const notesWarningMessage = useSelector(
    (state) => state.customer.notesWarningMessage
  );

  const [filesToImport, setFilesToImport] = useState([]);
  const [textArea, setTextArea] = useState(text ?? "");
  const [existingFiles, setExistingFiles] = useState(files);

  useEffect(() => {
    if (content === "note") {
      dispatch(getCustomerNotesMaxTextLength({ toast }));
    }

    if (content === "image") {
      dispatch(getCustomerNotesMediaFileInfo({ toast }));
    }

    if (content === "document") {
      dispatch(getCustomerNotesDocumentFileInfo({ toast }));
    }
  }, [content, dispatch]);

  /**
   * handleChange is used to handle the content of the textArea
   */
  const handleChange = useCallback(
    (e) => {
      if (e.value.length <= notesTextMaxLength) {
        setTextArea(e.value);
      }
    },
    [notesTextMaxLength]
  );

  const handleRemoveExistingFile = useCallback((fileIdToRemove) => {
    setExistingFiles((prevState) =>
      prevState.filter((file) => file.file_id !== fileIdToRemove)
    );
  }, []);

  /**
   * handleAddFile is used to add the files to add in upload's dropbox
   * if the file is valid.
   */
  const handleAddFile = (e) => {
    const { affectedFiles } = e;
    if (affectedFiles.length > 0) {
      affectedFiles.forEach((file) => {
        if (!file.hasOwnProperty("validationErrors")) {
          setFilesToImport((prevState) => [...prevState, file]);
        }
      });
    }
  };

  /**
   * handleRemoveFile is used to remove the files to upload's dropbox
   */
  const handleRemoveFile = (e) => {
    const fileToRemoveFromImport = e.affectedFiles[0].name;
    setFilesToImport((prevState) =>
      prevState.filter((file) => file.name !== fileToRemoveFromImport)
    );
  };

  /**
   * handleSubmit is used to handle the submit of the form
   */
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append("text", textArea);

      let args;

      // CASE: upload only text note
      if (content === "note") {
        // CASE: check if the request is an update
        if (isUpdate) {
          args = {
            data: formData,
            customerId,
            noteId,
            toast,
          };

          dispatch(updateCustomerNote(args)).then((res) => {
            if (res && res.payload && res.payload.bResult) {
              onClose();
            }
          });
        } else {
          args = {
            data: formData,
            customerId,
            toast,
          };

          dispatch(importCustomerTextNote(args)).then((res) => {
            if (res && res.payload && res.payload.bResult) {
              onClose();
            }
          });
        }
      }

      // CASE: upload with file media (images, videos)
      if (content === "image") {
        filesToImport.forEach((file) => {
          formData.append("files[]", file.getRawFile());
        });

        // CASE: check if the request is an update
        if (isUpdate) {
          const existingFilesIds = existingFiles.map((file) => file.file_id);
          existingFilesIds.forEach((existingFileId) => {
            formData.append("existingFiles[]", existingFileId);
          });

          args = {
            data: formData,
            customerId,
            noteId,
            toast,
          };

          dispatch(updateCustomerNote(args)).then((res) => {
            if (res && res.payload && res.payload.bResult) {
              onClose();
            }
          });
        } else {
          args = {
            data: formData,
            customerId,
            toast,
          };

          dispatch(importCustomerMediaNote(args)).then((res) => {
            if (res && res.payload && res.payload.bResult) {
              onClose();
            }
          });
        }
      }

      // CASE: upload with document files
      if (content === "document") {
        filesToImport.forEach((file) => {
          formData.append("files[]", file.getRawFile());
        });

        if (isUpdate) {
          const existingFilesIds = existingFiles.map((file) => file.file_id);
          existingFilesIds.forEach((existingFileId) => {
            formData.append("existingFiles[]", existingFileId);
          });

          args = {
            data: formData,
            customerId,
            noteId,
            toast,
          };

          dispatch(updateCustomerNote(args)).then((res) => {
            if (res && res.payload && res.payload.bResult) {
              onClose();
            }
          });
        } else {
          args = {
            data: formData,
            customerId,
            toast,
          };

          dispatch(importCustomerDocumentNote(args)).then((res) => {
            if (res && res.payload && res.payload.bResult) {
              onClose();
            }
          });
        }
      }
    },
    [
      content,
      customerId,
      dispatch,
      existingFiles,
      filesToImport,
      isUpdate,
      noteId,
      onClose,
      textArea,
    ]
  );

  /**
   * Gestione delle estensioni consentite
   */
  let allowedExtension;
  let maxFileSize;

  if (content !== "note") {
    if (content === "image") {
      allowedExtension =
        notesMediaAllowedExtensions &&
        notesMediaAllowedExtensions.map((ext) => ext.extension);
      maxFileSize = notesMediaAllowedMaxSize;
    } else {
      allowedExtension =
        notesDocumentAllowedExtensions &&
        notesDocumentAllowedExtensions.map((ext) => ext.extension);
      maxFileSize = notesDocumentAllowedMaxSize;
    }
  }

  if (isLoading) {
    return <Loader className="c-loader--center" size="medium" />;
  }

  return (
    <>
      <form
        className="c-form-field c-form-field--textarea"
        onSubmit={handleSubmit}
      >
        <TextArea
          name="newNote"
          placeholder={insertTextNotePlaceholder}
          rows={7}
          value={textArea}
          onChange={handleChange}
        />
        {content !== "note" && (
          <div className="c-form-field c-upload k-mt-4">
            <label htmlFor="">{title}</label>
            <MediaUpload
              autoUpload={false}
              batch={false}
              multiple={true}
              allowedExtensions={allowedExtension}
              maxFileSize={maxFileSize}
              showActionButtons={false}
              listItemUI="previewDocument"
              withCredentials={true}
              onAdd={handleAddFile}
              onRemove={handleRemoveFile}
            />
            {isUpdate && files.length > 0 && (
              <ul className="k-upload-files k-reset k-m-0">
                {existingFiles.map((file, index) => (
                  <li key={`${file.file_id}-${index}`} className="k-p-2">
                    <div className="c-upload__media l-mobile-flex u-flex-middle">
                      <p className="c-upload__label k-m-0">
                        {file.file_name}
                        <span className="k-icon k-i-check-circle"></span>
                      </p>
                      <Button
                        className="c-upload__remove"
                        icon="close"
                        type="button"
                        onClick={() => handleRemoveExistingFile(file.file_id)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        <DialogActionsBar>
          <Button disabled={isUploading} type="submit">
            {isUploading && uploadingNote}
            {!isUploading ? (text ? updateNote : addToNotes) : ""}
          </Button>
        </DialogActionsBar>
        {content !== "note" && notesWarningMessage && (
          <div className="c-modal-notes" dangerouslySetInnerHTML={{__html: notesWarningMessage}} />
        )}
      </form>
    </>
  );
};

export default ContentModal;
