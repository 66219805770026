import React from 'react';
import PropTypes from 'prop-types';

const CellCode = ({ code, isExpired }) => {

    return (
        <td 
            colSpan="1" 
            className={ isExpired && "c-row-expired"}
            role="gridcell" 
            aria-colindex="1" 
            aria-selected="false"
            data-grid-col-index="0"
        >
            { code } 
        </td>
    )
}

CellCode.propTypes = {
    code: PropTypes.string,
    isExpired: PropTypes.bool,
}

export default CellCode;