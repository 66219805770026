import React from "react";
import PropTypes from "prop-types";

const TooltipTemplateTwoLines = ({ item }) => {
    const itemParsed = item;

    return typeof itemParsed === 'string'
        ? <p>{itemParsed}</p>
        : (
            <ul className="c-tooltip__content">
                <li>
                    <span>{itemParsed?.alert}</span>
                </li>
                <li>
                    <span>{itemParsed?.out_of_stock_message}</span>
                </li>
            </ul>
        );
};

TooltipTemplateTwoLines.propTypes = {
    item: PropTypes.object,
};

export default TooltipTemplateTwoLines;
