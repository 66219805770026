import React from "react";
import PropTypes from "prop-types";
import "./cell-tag.scss";
import {useSelector} from "react-redux";

const CellTag = ({dataItem} ) => {
  const comboOrderTags = useSelector((state) => state.user.comboOrderTags);
  const tagObj = comboOrderTags.find(el=> el.key===dataItem.tag);
  const label = tagObj?.label ?? '';
  const type = tagObj?.type ?? '';
  return (
    <td
      colSpan="1"
      className={"c-tag-table-order "}
      role="gridcell"
      aria-colindex="1"
      aria-selected="false"
      data-grid-col-index="1"
    >
      <span className={"c-type-icon c-type-"+type+'__'+label}></span>
    </td>
  );
};

CellTag.propTypes = {

  state: PropTypes.number,
  stateLabel: PropTypes.string,
};

export default CellTag;
