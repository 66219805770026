import React from "react";
import PropTypes from "prop-types";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    tooltipConcurrentAccessOrderLabel: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.tooltipConcurrentAccessOrderLabel",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .tooltipConcurrentAccessOrderLabel
    ),
  };
}

const TooltipTemplate = ({ item }) => {
  const { tooltipConcurrentAccessOrderLabel } = useLocalizedMessages();

  const users = JSON.parse(item);
  
  return (
    <>
      <strong>{tooltipConcurrentAccessOrderLabel}</strong>
      <ul className="c-tooltip__content">
        {users.map( user => {
          return (
            <li key={user?.username}>
              <span>{`${user?.full_name} - ${user?.email}`}</span>
            </li>
          )
        })}
      </ul>
    </>
  );
};

TooltipTemplate.propTypes = {
  item: PropTypes.string,
};

export default TooltipTemplate;
