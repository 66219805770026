import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useCustomLocalization } from "utils";

import TooltipTemplate from "../tooltipTemplate";
import { useCheckIsMobile } from "utils";
import "./info-article.scss";
import defaultMessages from 'i18n/en.json';
import TooltipContentTemplate from "../../../pages/order/catalogue/cellProducts/tooltipContentTemplate";
import TooltipTemplateTwoLines from "../tooltipTemplateTwoLines";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    info: toLanguageString(
      'metislab.frontend.components.cart.infoArticle.info',
      defaultMessages.metislab.frontend.components.cart.infoArticle.info),
    };
}

const InfoArticle = ({ classIcon, isAlert = false, item ,newLineInAlert=false}) => {
  const { info } = useLocalizedMessages();

  const isMobile = useCheckIsMobile();

  return (
      <Tooltip
          className="c-tooltip-box"
          anchorElement="target"
          position={(isMobile) ? (isAlert ? "top" : "left") : "right"}
          content={(props) => (newLineInAlert) ? <TooltipTemplateTwoLines item={item} />: <TooltipTemplate item={props.title}/>}
      >
        {!isAlert && isMobile &&(
            <span
                title={JSON.stringify(item)}
                className={`u-mobile ${classIcon}`}
            >
         {" "} {/*{info}*/}
        </span>
        )}
        {!isAlert && !isMobile &&(
            <span
                title={JSON.stringify(item)}
                className={`u-desktop ${classIcon}`}
            >
         {" "} {/*{info}*/}
        </span>
        )}
        {isAlert && !newLineInAlert && <span
            title={JSON.stringify(item)}
            className={`${classIcon}`}
        ></span>}

        {isAlert && newLineInAlert && <span
            title={item}
            className={`${classIcon}`}
        ></span>}

      </Tooltip>
  );
};

InfoArticle.propTypes = {
  classIcon: PropTypes.string,
  isAlert: PropTypes.bool,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default InfoArticle;
