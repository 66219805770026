import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import { ScrollView } from "@progress/kendo-react-scrollview";
import "./carousel.scss";

const CarouselMedia = (props) => {
  const {
    automaticViewChange = false,
    automaticViewChangeInterval,
    arrows = false,
    height = 520,
    images = [],
    loader = false,
    pageable = false,
    slides = true,
    width = 520,
  } = props;

  const refCarousel = useRef();

  const [activeSlide, setactiveSlide] = useState(null);

  useEffect(() => {
    if (!loader && images?.length > 0) {
      if (activeSlide) {
        const scrollviewWrap = refCarousel.current.element?.children[0];
        scrollviewWrap &&
          scrollviewWrap.style.setProperty(
            "--kendo-scrollview-current",
            activeSlide
          );
      } else {
        setactiveSlide(1);
      }
    }
  }, [activeSlide, images?.length, loader]);

  /**
   * Slides create the sequence of images below the slider
   * @returns {Slides} component
   */
  const Slides = () => {
    return images.map((image, index) => (
      <li key={index}>
        <Button
          className={activeSlide === index + 1 && "j-active"}
          fillMode="flat"
          onClick={() => setactiveSlide(index + 1)}
        >
          <img src={image.media_preview_url} alt="slide" />
        </Button>
      </li>
    ));
  };

  return (
    <>
      <div className="c-slider-container c-slider-container--media u-flex-1">
        {images?.length > 0 ? (
          <>
            <ScrollView
              className="c-slider"
              ref={refCarousel}
              arrows={arrows}
              automaticViewChange={automaticViewChange}
              automaticViewChangeInterval={automaticViewChangeInterval}
              endless={automaticViewChange}
              pageable={pageable}
              style={{
                width,
                height,
              }}
            >
              {images.map((image, index) => (
                <div className="c-slider__slide" key={index}>
                  <div className="c-slider__button-gallery">
                    <figure className="c-slider__figure" key={index}>
                      {image.file_type_description.toLowerCase() === "image" ? (
                        <img
                          src={image.media_url}
                          alt="slide"
                          style={{ width, height }}
                        />
                      ) : (
                        <video src={image.media_url} controls></video>
                      )}
                    </figure>
                  </div>
                </div>
              ))}
            </ScrollView>
            {slides && (
              <ul className="c-slider__nav l-mobile-flex">
                <Slides />
              </ul>
            )}
          </>
        ) : (
          <figure className="c-placeholder">
            <img src="/img/placeholder-big.jpg" alt="slide" />
          </figure>
        )}
      </div>
    </>
  );
};

CarouselMedia.propTypes = {
  automaticViewChange: PropTypes.bool,
  automaticViewChangeInterval: PropTypes.number,
  arrows: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  images: PropTypes.array,
  pageable: PropTypes.bool,
  slides: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CarouselMedia;
