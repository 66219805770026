import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useCustomLocalization } from "utils";
import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
    const [toLanguageString] = useCustomLocalization();
    return {
        notice: toLanguageString(
            'metislab.frontend.components.BroadcastChannel.notice',
            defaultMessages.metislab.frontend.components.BroadcastChannel.notice),
        tooManyCompanies1: toLanguageString(
            'metislab.frontend.components.BroadcastChannel.tooManyCompanies1',
            defaultMessages.metislab.frontend.components.BroadcastChannel.tooManyCompanies1),
        tooManyCompanies2: toLanguageString(
            'metislab.frontend.components.BroadcastChannel.tooManyCompanies2',
            defaultMessages.metislab.frontend.components.BroadcastChannel.tooManyCompanies2),
        ok: toLanguageString(
            'metislab.frontend.components.BroadcastChannel.ok',
            defaultMessages.metislab.frontend.components.BroadcastChannel.ok),
    };
}


const BroadcastChannelComponent = ({ children }) => {
    const {
        notice,
        tooManyCompanies1,
        tooManyCompanies2,
        ok
    } = useLocalizedMessages();
    
    const [showModal, setShowModal] = useState(false);
      
    try {
        const bc = new BroadcastChannel('change_company');
        bc.addEventListener( "message", () => {
            if ( document.visibilityState !== "visible" ) {   
                setShowModal(true);
            }
        })
    } catch (error) {
        console.error(error);
    }

    /**
     * When modal is closed, it trigger the reload of the page
     */
    const handleModal = () => {
        window.location.replace("/dashboard");
        setTimeout(() => {
            window.location.reload();         
        }, 500);
    }
    
    return (
        <>
            { showModal && (
                <Dialog 
                    title={notice}
                    onClose={ handleModal } 
                    width={ 566 }
                >
                    {tooManyCompanies1}                    
                    <br />
                    {tooManyCompanies2}  
                    <DialogActionsBar>
                        <Button type="submit" onClick={ handleModal }>
                        {ok}  
                        </Button>
                  </DialogActionsBar>
                </Dialog>
            )}
            { children }
        </>
    );
}

export default BroadcastChannelComponent;