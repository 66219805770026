import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unsetOrderHeaderNotEditable } from "redux/features/order";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import FormNewOrder from "./newOrder";
import FormOpenOrder from "./openOrders";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    tabTitle: toLanguageString(
      'metislab.frontend.components.catalogue.components.modalOrder.tabTitle',
      defaultMessages.metislab.frontend.components.catalogue.components.modalOrder.tabTitle),      
    title: toLanguageString(
      'metislab.frontend.components.catalogue.components.modalOrder.title',
      defaultMessages.metislab.frontend.components.catalogue.components.modalOrder.title),      
    };
}

const ModalOrder = ({ handleOrder }) => {

  const {
    tabTitle,
    title
  } = useLocalizedMessages();

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  const openOrders = useSelector((state) => state.order.openOrders);

  const orderHeaderNotEditable = useSelector(
    (state) => state.order.orderHeaderNotEditable
  );

  /**
   * handleSelect handles the switch
   * between the TabStripTab of TabStrip
   * @param {object} e
   */
  const handleSelect = (e) => {
    orderHeaderNotEditable && dispatch(unsetOrderHeaderNotEditable());
    setSelected(e.selected);
  };

  return (
    <Dialog
      className="c-modal-scroll"
      title={title}
      onClose={handleOrder}
      width={566}
    >
      <div className="c-new-order-modal">
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={tabTitle}>
            <FormNewOrder handleOrder={(e) => handleOrder(e, true)} />
          </TabStripTab>
          {openOrders?.list?.length > 0 && (
            <TabStripTab title={openOrders?.label}>
              <FormOpenOrder handleOrder={(e) => handleOrder(e, true)} />
            </TabStripTab>
          )}
        </TabStrip>
      </div>
    </Dialog>
  );
};

export default ModalOrder;
