import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setKeyword } from "redux/features/search";
import { getProductsSearched } from "redux/features/search/actions";
import { toast } from "react-toastify";
import { DebounceInput } from "react-debounce-input";
import ProductsList from "./products";
import Loader from "components/loader";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    emptyResultsMessage: toLanguageString(
      'metislab.frontend.components.header.components.search.emptyResultsMessage',
      defaultMessages.metislab.frontend.components.header.components.search.emptyResultsMessage),   
    emptyResultsTitle: toLanguageString(
      'metislab.frontend.components.header.components.search.emptyResultsTitle',
      defaultMessages.metislab.frontend.components.header.components.search.emptyResultsTitle),     
    placeholder: toLanguageString(
      'metislab.frontend.components.header.components.search.placeholder',
      defaultMessages.metislab.frontend.components.header.components.search.placeholder),   
  };
}


const SearchProducts = () => {

  const {
    emptyResultsMessage,
    emptyResultsTitle,
    placeholder,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  /**
   * refInput is a ref's hook used to get a DOM
   * access on the element
   */
  const refInput = useRef();

  const keyword = useSelector((state) => state.searchProduct.keyword);
  const loading = useSelector((state) => state.searchProduct.loading);
  const productsSearched = useSelector((state) => state.searchProduct.results);

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (keyword.length > 0) {
      const args = {
        stringToSearch: keyword,
        toast,
      };
      dispatch(getProductsSearched(args));
    } else {
      setOpen(true);
    }
  }, [dispatch, keyword]);
  
  /**
   * handleClickOutside is a useCallback hook to close
   * the modal when an user clicks outside it
   */
  const handleClickOutside = useCallback(
    (e) => {
      const headerLeft = document.querySelector('.c-header__left');
      if (open && keyword.length > 0 && !refInput.current.contains(e.target) && e.target !== headerLeft) {
        setOpen((prevState) => !prevState);
      }
    },
    [open, keyword]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleChange = (e) => {
    dispatch(setKeyword(e.target.value));
  };

  const handleFocus = () => {
    if (!open && keyword.length > 0) {
      setOpen((prevState) => !prevState);
    }
  };

  const handleMouseDown = () => {
    if (!open && keyword.length > 0) {
      setOpen((prevState) => !prevState);
    }
  }

  return (
    <div ref={refInput} className="k-relative" style={{ width: "100%" }}>
      <DebounceInput
        className="c-header__search-input k-input"
        placeholder={placeholder}
        minLength={0}
        debounceTimeout={300}
        value={keyword}
        onChange={handleChange}
        onFocus={handleFocus}
        onMouseDown={handleMouseDown}
      />
      {open && keyword.length > 0 && (
        <div
          className="c-box-research k-absolute"
          style={{
            left: 0,
            width: "100%",
            maxHeight: 368,
            overflow: "auto",
            background: "#ffffff",
          }}
        >
          {loading ? (
            <Loader className="c-loader c-loader" />
          ) : productsSearched.length === 0 ? (
            <div className="c-box-research__no-results">
              <h2 className="c-empty-state__title l-mobile-flex">
                <span className="k-icon k-i-search"></span>
                <strong>{emptyResultsTitle}</strong>
              </h2>
              <p>{emptyResultsMessage}</p>
            </div>
          ) : (
            <ProductsList products={productsSearched} />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchProducts;
