import { configureStore, combineReducers } from "@reduxjs/toolkit";
import UserReducer from "./features/user";
import CustomerReducer from "./features/customer";
import FragranceReducer from "./features/catalogue";
import indexWebCustomerReducer from "./features/indexWeb";
import OrderReducer from "./features/order";
import DownloadReducer from './features/download';
import searchProductReducer from './features/search';
import homepageReducer from './features/home';
import printReducer from './features/print';

const appReducer = combineReducers({
  user: UserReducer,
  customer: CustomerReducer,
  fragrance: FragranceReducer,
  indexWebCustomer: indexWebCustomerReducer,
  order: OrderReducer,
  download: DownloadReducer,
  searchProduct: searchProductReducer,
  home: homepageReducer,
  print: printReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/logout/fulfilled') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}

export default configureStore({
  reducer: rootReducer
});