import React from "react";
import PropTypes from "prop-types";

const CellState = ({ state }) => {
  const classNames = `c-cell-state s-${state.toLowerCase()}`;

  return (
    <td
      colSpan="1"
      className="c-cell-state-prospect"
      role="gridcell"
      aria-colindex="15"
      aria-selected="false"
      data-grid-col-index="0"
    >
      <span className={classNames}>{state}</span>
    </td>
  );
};

CellState.propTypes = {
  state: PropTypes.string,
};

export default CellState;
