import React from 'react';
import PropTypes from 'prop-types';
import { useInternationalization } from "@progress/kendo-react-intl";
import { formatDateTimeByLanguage } from "utils";

const CellDateDelivery = ({ date }) => {

    const intl = useInternationalization();
    
    const dateConverted = date 
        ? formatDateTimeByLanguage(intl.locale, date, true)
        : '-';

    return (
        <td 
            colSpan="1" 
            className="" 
            role="gridcell" 
            aria-colindex="2"
            aria-selected="false"
            data-grid-col-index="0"
        >
            <strong>{ date ? dateConverted : '-' }</strong>
        </td>
    )
}

CellDateDelivery.propTypes = {
    date: PropTypes.string,
}

export default CellDateDelivery;