import { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { useLocalization } from "@progress/kendo-react-intl";

/**
 * useCurrentLocation is used to return the current url
 * @param {*} path 
 * @returns {String} location
 */
export const useCurrentLocation = (path) => {    
    const [location, setLocation] = useState(null);
    useEffect(() => setLocation(path), [path])
    
    return location;
};

/**
 * dispatchBroadcastMessageChangeCompany is used to dispatch
 * the message on broadcast channel and synchronize all the tabs open
 */
export const dispatchBroadcastMessageChangeCompany = () => {
    try {
        const bc = new BroadcastChannel('change_company');
        bc.postMessage('This is a company change');      
    } catch (error) {
        console.error(error);
    }
};

export const useCheckIsMobile = () => {
    const [windowWidth, setWindowWidth] = useState({
        width: undefined,
    });
    
    useEffect(() => {
        function handleResize() {
            setWindowWidth({
                width: window.innerWidth,
            });
        }
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth.width < 1024;
}

/**
 * useGetMatchesRoutes is a custom hook to check if the
 * current location matches with one of the routes present
 * in routes
 * @param {Array} routes 
 * @returns {Array} matches
 */
export const useGetMatchesRoutes = (routes) => {
    const location = useLocation();

    const matches = matchRoutes(routes, location);

    return matches;
}

export const formatDateByLanguage = (lang) => {
    return lang.includes('it')
        ? "dd/MM/y"
        : "dd/MM/y";
}

export const formatDateTimeByLanguage = (lang, date, onlyDate = false, onlyTime = false, onlyYear = false, letteralMonth = false) => {
    
    let options = { 
        timeZone: "UTC",  
        year: 'numeric',
        month:"2-digit", 
        day: "2-digit", 
        hour: "2-digit", 
        minute:"2-digit" 
    };
    
    if (onlyDate) {
        options = { 
            timeZone: "UTC",  
            year: 'numeric',
            month:"2-digit", 
            day: "2-digit", 
        }; 
    }

    if (onlyTime) {
        options = { 
            timeZone: "UTC",  
            hour: "2-digit", 
            minute:"2-digit" 
        }; 
    }

    if (letteralMonth) {
        options = { 
            timeZone: "UTC",  
            month:"long", 
        }; 
    }

    if (onlyYear) {
        options = { 
            timeZone: "UTC",  
            year: 'numeric',
        }; 
    }

    /* return new Date( Date.parse(date) ).toLocaleString(lang, options); */
    return new Date( Date.parse(date) ).toLocaleString('it-IT', options);
}

/**
 * useCustomLocalization is a function to expose the name of the labels used 
 * in the app
 * @returns 
 */
export const useCustomLocalization = () => {

    const localization = useLocalization();
    
    const toLanguageString = (message, defaultMessage) => {
        if (localization.language === 'it-IT' || localization.language === 'en-US') {
            return localization.toLanguageString(message, defaultMessage);
        } else {
            return message;
        }
    }

    return [toLanguageString];
}

export const formatNumber = (number) => {
    const numberFormatted = new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2 }).format(number);
    return numberFormatted;
} 
