import React from "react";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    label: toLanguageString(
      'metislab.frontend.components.resetFilter.label',
      defaultMessages.metislab.frontend.components.resetFilter.label),
    };
}

const ResetFilter = ({ dispatchResetAction }) => {

  const {
    label
  } = useLocalizedMessages();

  return (
    <Button
      className="c-reset-button"
      icon="close"
      fillMode="flat"
      style={{ color: "#610d11" }}
      onClick={dispatchResetAction}
    >
      {label}
    </Button>
  );
};

ResetFilter.propTypes = {
  dispatchResetAction: PropTypes.func,
};

export default ResetFilter;
