import { createSlice } from "@reduxjs/toolkit";
import { getOrderToPrint } from "./actions";


const initialState =  { 
    error: false,
    errorStatus: null,
    footer: null,
    header: null,
    loading: false,
    order: [],
    orderInfo: null,

};

const printSlice = createSlice({
    name: "print",
    initialState,
    reducers: {},
    extraReducers: {
        /**
         * Get order to print
         */
        [getOrderToPrint.pending]: (state) => {
            state.error = false;
            state.errorStatus = null;
            state.loading = true;
        },
        [getOrderToPrint.fulfilled]: (state, action) => {      
            state.loading = false;
            const { footer, header, order, orderInfo } = action.payload;
            state.footer = footer; 
            state.header = header; 
            state.order = order; 
            state.orderInfo = orderInfo; 
        },
        [getOrderToPrint.rejected]: (state, action) => {
            state.error = action.payload?.sMessageText;
            state.errorStatus = action.payload?.status;
            state.loading = false;
        },
    },
});

export default printSlice.reducer;