import React from "react";
import { useCustomLocalization } from "utils";
import defaultMessages from "i18n/en.json";
import "./order-footer.scss";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    discount: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.discount",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .discount
    ),
    total: toLanguageString(
      "metislab.frontend.components.footer.components.orderActive.total",
      defaultMessages.metislab.frontend.components.footer.components.orderActive
        .total
    ),
  };
};

const OrderFooter = (props) => {
  const { totalValue } = props;

  const { total } = useLocalizedMessages();

  return (
    <footer className="c-print__footer">
      <span>{total}</span>
      <strong>{totalValue}</strong>
    </footer>
  );
};

export default OrderFooter;
