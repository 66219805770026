const DATA_DUMMIES = [
  {
    id: 1,
    description: 'La bottega del profumo S.r.l.',
    email: 'bottega.del.profumo@gmail.com',
    phone: '+39 333 1234567',
    province: 'Bologna',
    state: 'Approvato',
    info: {
      description: 'La bottega del profumo S.r.l.',
      email: 'bottega.del.profumo@gmail.com',
      customer_code: '0014578',

      address: "Via del Borgo, 4",
      city: "San Lazzaro",
      comune: "Bologna",
      cap: "44125",
      province: "BO",
      country: "IT",

      codfiscale: "JDJ34IR5IO5MGR5JK",
      pIVA: "1234567890",
      delivery_methods: "Corriere Espresso",
      typology: "62783",
      signboard: "Insegna 1",
      discount: 7,

      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing bibendum est ultricies integer quis auctor. Id aliquet lectus proin nibh. Amet aliquam id diam maecenas ultricies mi eget. Id faucibus nisl tincidunt eget nullam non nisi. Magna sit amet purus gravida quis blandit turpis. In nibh mauris cursus mattis molestie. Ipsum dolor sit amet consectetur."
    }
  },
  {
    id: 2,
    description: 'Cose di Casa S.r.l.',
    email: 'Cose@DiCasa.it',
    phone: '+39 333 1234567',
    province: 'Milano',
    state: 'Da approvare',
    info: {
      description: 'Cose di Casa S.r.l.',
      email: 'Cose@DiCasa.it',
      customer_code: '123456',

      address: "Corso Buenos Aires, 35",
      city: "Milano",
      comune: "Milano",
      cap: "44125",
      province: "MI",
      country: "IT",

      codfiscale: "JDJ34IR5IO5MGR5JK",
      pIVA: "0123456789",
      delivery_methods: "Corriere Espresso",
      typology: "987654",
      signboard: "Insegna 2",
      discount: 15,

      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing bibendum est ultricies integer quis auctor. Id aliquet lectus proin nibh. Amet aliquam id diam maecenas ultricies mi eget. Id faucibus nisl tincidunt eget nullam non nisi. Magna sit amet purus gravida quis blandit turpis. In nibh mauris cursus mattis molestie. Ipsum dolor sit amet consectetur."
    }
  },
  {
    id: 3,
    description: 'Profumeria Lupi S.r.l.',
    email: 'Profumerialupi@gmail.com',
    phone: '+39 333 1234567',
    province: 'Firenze',
    state: 'Non approvato',
    info: {
      description: 'Profumeria Lupi S.r.l.',
      email: 'Profumerialupi@gmail.com',
      customer_code: '987654',

      address: "Corso Vinci, 54",
      city: "Empoli",
      comune: "Firenze",
      cap: "44698",
      province: "FI",
      country: "IT",

      codfiscale: "JDJ34IR5IO5MGR5JK",
      pIVA: "1478520369",
      delivery_methods: "Corriere Espresso",
      typology: "987654",
      signboard: "Insegna 4",
      discount: 3,

      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing bibendum est ultricies integer quis auctor. Id aliquet lectus proin nibh. Amet aliquam id diam maecenas ultricies mi eget. Id faucibus nisl tincidunt eget nullam non nisi. Magna sit amet purus gravida quis blandit turpis. In nibh mauris cursus mattis molestie. Ipsum dolor sit amet consectetur."
    }
  },
  {
    id: 4,
    description: 'Fiori di Fiori S.r.l.',
    email: 'Fiorifiorifiori@gmail.com',
    phone: '+39 333 1234567',
    province: 'Reggio Emilia',
    state: 'Approvato',
    info: {
      description: 'Fiori di Fiori S.r.l.',
      email: 'Fiorifiorifiori@gmail.com',
      customer_code: '258741',

      address: "Corso Parma, 124",
      city: "Gualtieri",
      comune: "Reggio Emilia",
      cap: "44698",
      province: "RE",
      country: "IT",

      codfiscale: "JDJ34IR5IO5MGR5JK",
      pIVA: "3698521470",
      delivery_methods: "Corriere Espresso",
      typology: "987654",
      signboard: "Insegna 2",
      discount: 54,

      notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing bibendum est ultricies integer quis auctor. Id aliquet lectus proin nibh. Amet aliquam id diam maecenas ultricies mi eget. Id faucibus nisl tincidunt eget nullam non nisi. Magna sit amet purus gravida quis blandit turpis. In nibh mauris cursus mattis molestie. Ipsum dolor sit amet consectetur."
    }
  },
];

export default DATA_DUMMIES;