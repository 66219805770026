import { createSlice } from "@reduxjs/toolkit";
import { 
    getDocAdministrative,
    downloadAdministrative,
    getDocMarketing,
    getDocMarketingTypes,
} from "./actions";
import { keyBy, merge } from "lodash";

const initialState = {
    error: null,
    errorMarketing: null,

    layoutColumn: null,

    loading: false,
    loadingMarketing: false,
    loadingMarketingTypes: false,
    loadingDownloadDoc: false,
    loadingDownloadMarketingDoc: false,

    administrativeList: null,
    marketingFilterList: null,
    marketingList: null,

    pagerState: {
        page: null,
        perPage: null,
        orderBy: null,
        orderByDirection: null,
        filterFields: [],
        search: null,
        searchBy: null,
    },
    pagerStateMarketing: {
        search: null,
        searchBy: null,
    },

    viewDocsAdministrative: false,
    pageSizes: null,
    pagination: null,
};

const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    setViewDocsAdministrative: (state, action) => {
      state.viewDocsAdministrative = action.payload;
    },
    setPagerState: (state, action) => {
      state.pagerState = { ...state.pagerState, ...action.payload };
    },
    setPagerStateMarketing: (state, action) => {
        state.pagerStateMarketing = { ...state.pagerStateMarketing, ...action.payload };
      },
  },
  extraReducers: {
    /**
     * Get paginated list of administrative docs
     */
    [getDocAdministrative.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getDocAdministrative.fulfilled]: (state, action) => {
      const {
        grid: {
          columns,
          layout: { children },
          pageSizes,
        },
        table: { page, pages, rows, size, total },
      } = action.payload;

      const mergeColumnsAndChildren = merge(
        keyBy(children, "refId"),
        keyBy(columns, "propId")
      );

      const arrayMergeColumnsAndChildren = Object.keys(
        mergeColumnsAndChildren
      ).map((key) => {
        return mergeColumnsAndChildren[key];
      });

      state.loading = false;
      state.administrativeList = rows;
      state.pageSizes = pageSizes;
      state.layoutColumn = arrayMergeColumnsAndChildren;
      state.pagination = {
        page,
        pages,
        size,
        total,
      };
    },
    [getDocAdministrative.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    /**
     * Download document administrative
     */
    [downloadAdministrative.pending]: (state, action) => {
      state.loadingDownloadDoc = true;
    },
    [downloadAdministrative.fulfilled]: (state, action) => {
      state.loadingDownloadDoc = false;
    },
    [downloadAdministrative.rejected]: (state, action) => {
      state.loadingDownloadDoc = false;
    },
    /**
     * Get list of marketing's docs
     */
    [getDocMarketing.pending]: (state, action) => {
        state.loadingMarketing = true;
        state.errorMarketing = null;
    },
    [getDocMarketing.fulfilled]: (state, action) => {
        state.loadingMarketing = false;
        const { document_types } = action.payload;
        state.marketingList = document_types;
    },
    [getDocMarketing.rejected]: (state, action) => {
        state.loadingMarketing = false;
        state.errorMarketing = action.payload;
    },      
    /**
     * Get types of marketing's docs
     */
    [getDocMarketingTypes.pending]: (state, action) => {
        state.loadingMarketingTypes = true;
    },
    [getDocMarketingTypes.fulfilled]: (state, action) => {
        state.loadingMarketingTypes = false;
        const { document_types } = action.payload;
        state.marketingFilterList = document_types;
    },
    [getDocMarketingTypes.rejected]: (state, action) => {
        state.loadingMarketingTypes = false;
    },
  },
});

export const { 
    setPagerState, 
    setViewDocsAdministrative,
    setPagerStateMarketing,
} = downloadSlice.actions;
export default downloadSlice.reducer;
