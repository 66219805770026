import React from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FormLogin from './components/formLogin';

const Login = () => {
    const authenticated = useSelector((state) => state.user.authenticated );
    const companies = useSelector((state) => state.user.companies );

    return authenticated
        ? companies.length > 1
            ? <Navigate to="/select-company" />
            : sessionStorage.getItem('URL_NOTES')
                ? <Navigate
                    to={JSON.parse(sessionStorage.getItem('URL_NOTES'))}
                    state={{
                        showNotes: JSON.parse(sessionStorage.getItem('SHOW_NOTES')),
                        showOrderTag: JSON.parse(sessionStorage.getItem('SHOW_ORDER_TAG'))
                    }}
                />
                : <Navigate to="/dashboard" />
        : <FormLogin />
}

export default Login;