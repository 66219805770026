import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TooltipTemplate from "../tooltipTemplate";
import { useCheckIsMobile } from "utils";
import "./info-article.scss";

const InfoArticle = ({ classIcon, isAlert = false, item }) => {
  const isMobile = useCheckIsMobile();
  return (
    <Tooltip
      className="c-tooltip-box"
      anchorElement="target"
      position={isMobile ? "left" : "right"}
      content={(props) => <TooltipTemplate item={props.title} />}
    >
      <span title={JSON.stringify(item)} className="u-mobile">
        Informazioni
      </span>
      <span
        title={JSON.stringify(item)}
        className={`${classIcon}`}
      ></span>
    </Tooltip>
  );
};

InfoArticle.propTypes = {
  classIcon: PropTypes.string,
  isAlert: PropTypes.bool,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default InfoArticle;
