import React from 'react';
import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from 'redux/features/user/actions';
import { Button } from "@progress/kendo-react-buttons";


const Logout = (props) => {

    const {
        fillmode, 
        icon, 
        label
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout({ navigate }));
    }

    return (
        <Button 
            fillMode={fillmode} 
            icon={icon || null}
            onClick={handleLogout}
        >
            {label}
        </Button>
    )
}

Logout.propTypes = {
    fillmode: PropTypes.string, 
    icon: PropTypes.any, 
    label: PropTypes.string.isRequired
}

export default Logout;

