import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import HeaderCustomer from "./header";
import TabAddresses from "./tabs/tab-addresses";
import TabContacts from "./tabs/tab-contacts";
import TabCustomer from "./tabs/tab-details";
import TabPayments from "./tabs/tab-payments";
import TabNotes from "./tabs/tab-notes";
import Modal from "./tabs/tab-notes/modal";
import Error from "components/Error";
import Loader from "components/loader";
import NotFound from "components/notFound";
import "./customerDetails.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    tabCustomerTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabCustomer.title",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabCustomer.title
    ),
    tabContactsTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabContacts.title",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabContacts.title
    ),
    tabAddressesTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabAddresses.title",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabAddresses.title
    ),
    tabPaymentsTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabPayments.title",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabPayments.title
    ),
    tabNotesTitle: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.title",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.title
    ),

    addNoteButtonHeader: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.addNoteButtonHeader",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.addNoteButtonHeader
    ),
    addMediaButtonHeader: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.addMediaButtonHeader",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.addMediaButtonHeader
    ),
    addDocumentButtonHeader: toLanguageString(
      "metislab.frontend.components.customer.components.details.tabs.tabNotes.addDocumentButtonHeader",
      defaultMessages.metislab.frontend.components.customer.components.details
        .tabs.tabNotes.addDocumentButtonHeader
    ),
  };
};

const INITIAL_MODAL = {
  open: false,
  content: null,
};

const CustomerDetails = ({ id }) => {
  const {
    tabCustomerTitle,
    tabContactsTitle,
    tabAddressesTitle,
    tabPaymentsTitle,
    tabNotesTitle,
    addNoteButtonHeader,
    addMediaButtonHeader,
    addDocumentButtonHeader,
  } = useLocalizedMessages();

  const errorCustomerDetails = useSelector((state) => state.customer.errorCustomerDetails);
  const errorCustomerDetailsStatus = useSelector((state) => state.customer.errorCustomerDetailsStatus);
  const loading = useSelector((state) => state.customer.loading);
  const panels = useSelector((state) => state.customer.customerDetails);

  const panelHiddenFields = panels?.find(
    (panel) => panel.name === "Hidden"
  )?.fields;
  const canViewNotes = panelHiddenFields?.find(
    (field) => field.name === "canViewNotes"
  );

  // TODO: qua ci sono le label per i tasti in header
  const BUTTONS = [
    {
      icon: "file",
      text: addNoteButtonHeader,
      type: "note",
    },
    {
      icon: "image",
      text: addMediaButtonHeader,
      type: "image",
    },
    {
      icon: "document-manager",
      text: addDocumentButtonHeader,
      type: "document",
    },
  ];

  const [selected, setSelected] = useState(0);
  const [showModal, setShowModal] = useState(INITIAL_MODAL);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const handleModal = (modal) => {
    setShowModal({
      open: !showModal.open,
      content: modal,
    });
  };

  if (errorCustomerDetails) {
    return errorCustomerDetailsStatus === 404
      ? <NotFound/>
      : <Error errorText={errorCustomerDetails}/>;
  }
  return (
    <>
      <HeaderCustomer
        selected={selected}
        buttons={BUTTONS}
        handleModal={handleModal}
      />
      {loading ? (
        <Loader
          className="c-loader c-loader--absolute c-loader--center"
          size="large"
        />
      ) : (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={tabCustomerTitle}>
            <TabCustomer id={id} />
          </TabStripTab>
          <TabStripTab title={tabContactsTitle}>
            <TabContacts />
          </TabStripTab>
          <TabStripTab title={tabAddressesTitle}>
            <TabAddresses />
          </TabStripTab>
          <TabStripTab title={tabPaymentsTitle}>
            <TabPayments id={id} />
          </TabStripTab>
          {canViewNotes?.value && (
            <TabStripTab title={tabNotesTitle}>
              <TabNotes id={id} />
            </TabStripTab>
          )}
        </TabStrip>
      )}
      {showModal.open && (
        <Modal
          customerId={id}
          content={showModal.content}
          onClose={() => setShowModal(INITIAL_MODAL)}
        />
      )}
    </>
  );
};

CustomerDetails.propTypes = {
  id: PropTypes.string,
};

export default CustomerDetails;
