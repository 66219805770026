import React from "react";
import { useLocation } from "react-router-dom";
import { useCurrentLocation } from "utils";
import { useLoadingRecoveryData } from "./hooks";
import Header from "../header";
import Footer from "../footer";
import Loader from "components/loader";
import NavSidebar from "../navSidebar";
import "./layout.scss";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const currentLocation = useCurrentLocation(pathname);

  const isPageSelectCompany = currentLocation?.includes("select-company");
  const isPrintPages = currentLocation?.includes("print");
  
  const [authenticated, loadingRecoveryData] = useLoadingRecoveryData(isPageSelectCompany);

  let classNames = [];
  classNames.push("c-layout");

  // Case: User is logged and the current page is not select-company
  if (authenticated && !isPageSelectCompany) {
    classNames.push("c-layout--logged");
  }

  if (isPrintPages) {
    classNames.push("c-layout--print");
  }



  if (loadingRecoveryData) {
    return <Loader size="large" className="c-loader--full-page" />;
  }

  return (
    <>
      <Header />
      <NavSidebar />
      <main className={classNames.join(" ")}>
        { children }
      </main>
      <Footer />
    </>
  );
};

export default Layout;
