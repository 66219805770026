import React from "react";
import PropTypes from "prop-types";
import "./cell-customer-description.scss";
import { useCustomLocalization } from "utils";

import defaultMessages from 'i18n/en.json';

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    code: toLanguageString(
      'metislab.frontend.components.orders.components.listing.cellCustomerDescription.code',
      defaultMessages.metislab.frontend.components.orders.components.listing.cellCustomerDescription.code),
  };
}

const CellCustomerDescription = ({ customerDescription, customerCode, fido }) => {
  const { code } = useLocalizedMessages();

  return (
    <td
      colSpan="1"
      className="c-list-orders__name"
      role="gridcell"
      aria-colindex="0"
      aria-selected="false"
      data-grid-col-index="0"
    >
      <strong>
        {fido === '1' && <span className="k-icon k-i-lock"></span> }
        {customerDescription}
      </strong>
      <span>{`${code} ${customerCode}`}</span>
    </td>
  );
};

CellCustomerDescription.propTypes = {
  customerCode: PropTypes.string,
  customerDescription: PropTypes.string,
  fido: PropTypes.string,
};

export default CellCustomerDescription;
