import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "redux/api/config";
import Notification from "components/notification";

export const getDocAdministrative = createAsyncThunk(
  "download/getDocAdministrative",
  async ({ pager, toast }, { rejectWithValue }) => {
    try {
      let query = "/r/xtr/administrativeDocuments/?";
      if (pager) {
        pager.page && (query += `page=${pager.page}`);
        pager.perPage && (query += `&perPage=${pager.perPage}`);
        pager.orderBy && (query += `&orderBy=${pager.orderBy}`);
        pager.orderByDirection &&
          (query += `&orderByDirection=${pager.orderByDirection}`);
        pager.search && (query += `&search=${pager.search}`);
        pager.searchBy && (query += `&searchBy=${pager.searchBy.propId}`);
        if (pager.filterFields.length > 0) {
          const encodedFields = encodeURIComponent(
            JSON.stringify(pager.filterFields)
          );
          query += `&filterFields=${encodedFields}`;
        }
      }
      const response = await http.get(query);
      return response.data;
    } catch (err) {
      const { sMessageText } = err?.response?.data?.aMessage[0];
      toast.error(<Notification message={sMessageText} type="error" />);
      return rejectWithValue(sMessageText);
    }
  }
);

export const downloadAdministrative = createAsyncThunk(
  "download/downloadAdministrative",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      let query = `/web/document/download/${id}`;
      const response = await http.get(query, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(response);
      const contentDispositionHeader = response.headers["content-disposition"];
      const filename = contentDispositionHeader
        ? contentDispositionHeader.split("filename=")[1]
        : "file.pdf";
      console.log(filename);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return response?.status;
    } catch (err) {
      const blob = err?.response?.data;
      const reader = new FileReader();
      reader.onload = () => {
        const jsonString = reader.result;
        const jsonData = JSON.parse(jsonString);

        const { sMessageText } = jsonData?.aMessage[0];
        toast.error(<Notification message={sMessageText} type="error" />);
      };
      reader.readAsText(blob);
      return false;
    }
  }
);

export const getDocMarketing = createAsyncThunk(
  "download/getDocMarketing",
  async ({ id, pager, toast }, { rejectWithValue }) => {
    try {
      let query = "/r/xtr/marketingDocuments?";
      if (id) {
        query += `id=${id}`;
      }
      if (pager) {
        pager.search && (query += `&search=${pager.search}`);
      }
      const response = await http.get(query);
      return response.data;
    } catch (err) {
      const { sMessageText } = err?.response?.data?.aMessage[0];
      toast.error(<Notification message={sMessageText} type="error" />);
      return rejectWithValue(sMessageText);
    }
  }
);

export const getDocMarketingTypes = createAsyncThunk(
  "download/getDocMarketingTypes",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      let query = `/web/marketing/documentTypes?id=${id}`;
      const response = await http.get(query);
      return response.data;
    } catch (err) {
      const { sMessageText } = err?.response?.data?.aMessage[0];
      toast.error(<Notification message={sMessageText} type="error" />);
      return rejectWithValue(sMessageText);
    }
  }
);