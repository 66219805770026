import React from "react";
import PropTypes from "prop-types";
import RowProducts from "./row";
import "./cell-products.scss";

const CellProducts = ({ data: fragrances }) => {
  return (
    <td
      colSpan="1"
      className="c-order-product"
      role="gridcell"
      aria-colindex="2"
      aria-selected="false"
      data-grid-col-index="0"
    >
      {fragrances && fragrances?.rows.length > 0 && (
        <ul className="c-order-product__list" style={{ marginTop: 39 }}>
          {fragrances?.rows.map((fragrance, index) => (
            <RowProducts 
              key={index} 
              hasAlert={fragrance?.has_alert} 
              items={fragrance?.order} 
            />
          ))}
        </ul>
      )}
    </td>
  );
};

CellProducts.propTypes = {
  data: PropTypes.object,
};

export default CellProducts;
