import React from 'react'

const Template = () => {
  return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 336 88"
            style={{
                enableBackground: "new 0 0 336 88",
            }}
            xmlSpace="preserve"
        >
            <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
            <g>
                <path
                    className="st0"
                    d="M64.8,71c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.4-0.1-0.6-0.1-0.7c-0.1-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.6,0-1.1,0.1 C62.4,69,61.8,69,61,69h-5.2c-0.4,0-0.9,0-1.4,0c-0.6,0-1.2-0.1-1.9-0.1c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2 c0,0.2,0.1,0.4,0.2,0.5c0.4,0.1,0.7,0.1,0.9,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4l0,12.6 c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7,0.2-1.3,0.4c-0.2,0-0.2,0.2-0.2,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0.2,0,0.5,0,0.8,0 c0.3,0,0.6,0,0.9,0s0.6,0,0.9,0c0.3,0,0.5,0,0.8,0c0.5,0,1,0,1.5,0c0.5,0,1,0,1.4,0.1c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0 c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.2c0-0.3-0.1-0.4-0.3-0.4c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.7-0.2 c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.2-0.2-0.2-0.4V78h3.5c0.3,0,0.5,0.1,0.6,0.4c0.1,0.3,0.2,0.7,0.3,1.2 c0.1,0.2,0.2,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1c-0.1-0.5-0.1-1-0.1-1.4c0-0.4,0-0.8,0-1.1c0-0.3,0-0.8,0-1.3 c0-0.5,0.1-1,0.1-1.3c-0.1,0-0.1,0-0.2,0s-0.2,0-0.2,0c-0.3,0-0.4,0.1-0.5,0.3c-0.1,0.6-0.2,1-0.3,1.2c-0.1,0.3-0.3,0.4-0.6,0.4 h-3.5v-6.4h3.3c0.5,0,0.9,0,1.2,0.1c0.3,0.1,0.6,0.2,0.9,0.4s0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.7,0.6,1.1c0.1,0.2,0.2,0.2,0.4,0.2 c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.4-0.2-0.7-0.3-1C64.9,71.5,64.8,71.3,64.8,71z"
                />
                <path
                    className="st0"
                    d="M93.1,69.1c-0.6,0-1.2,0-1.6,0c-0.5,0-1,0-1.6,0c-0.6,0-1.2-0.1-1.7-0.1c0,0.1-0.1,0.2-0.1,0.3s0,0.2,0,0.2 c0,0.2,0.1,0.4,0.2,0.4c0.3,0.1,0.6,0.1,0.8,0.2s0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.4l0,12.6 c0,0.2,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.5,0.2s-0.5,0.1-0.8,0.2c-0.2,0-0.2,0.2-0.2,0.4c0,0.1,0,0.1,0,0.2 c0,0.1,0,0.2,0.1,0.2c0.5,0,1.1-0.1,1.7-0.1c0.6,0,1.2,0,1.6,0c0.5,0,1,0,1.6,0c0.6,0,1.2,0.1,1.7,0.1c0-0.1,0-0.2,0-0.2 c0-0.1,0-0.2,0-0.2c0-0.2-0.1-0.4-0.2-0.4c-0.3-0.1-0.6-0.1-0.8-0.2s-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0-0.4 l0-12.6c0-0.2,0-0.3,0-0.4c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.5-0.1,0.8-0.2c0.2-0.1,0.2-0.2,0.2-0.4 c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3C94.3,69,93.8,69,93.1,69.1z"
                />
                <path
                    className="st0"
                    d="M134.2,84.8c-0.3-0.1-0.7-0.2-1.1-0.4c-0.4-0.2-0.7-0.5-1.1-0.9l-4-5.8c1-0.4,1.7-0.9,2.3-1.6 c0.6-0.7,0.8-1.6,0.8-2.4c0-0.7-0.1-1.3-0.4-1.8c-0.2-0.6-0.7-1-1.1-1.4c-0.5-0.4-1.1-0.7-1.8-0.9c-0.7-0.2-1.5-0.3-2.4-0.3h-3.8 c-0.5,0-1,0-1.6,0c-0.6,0-1.2-0.1-1.6-0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2 c0.7,0.1,1.2,0.2,1.4,0.3c0.2,0.2,0.4,0.4,0.4,0.7l0,12.6c0,0.2,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.5,0.2 c-0.2,0-0.5,0.1-0.9,0.2c-0.2,0-0.3,0.2-0.3,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2c0.5,0,1-0.1,1.6-0.1c0.6,0,1.2,0,1.7,0 c0.9,0,1.6,0,2.4,0c0.7,0,1.3,0.1,1.8,0.1c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.5-0.3-0.5c-0.8-0.1-1.3-0.2-1.7-0.4 c-0.3-0.2-0.5-0.4-0.5-0.8v-5.3h1.1l3.9,5.8c0.3,0.5,0.7,0.9,1,1.2c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.5,0,0.9,0s0.7,0,1.1,0 c0.4,0,0.7,0,1.1,0s0.6,0,0.8,0c0-0.1,0.1-0.2,0.1-0.2s0-0.2,0-0.2c0-0.2,0-0.2-0.1-0.3S134.3,84.9,134.2,84.8z M127.5,75.3 c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.9,0.2-1.4,0.2h-0.7v-6.6h0.7c1.3,0,2.2,0.3,2.7,0.9 c0.5,0.6,0.7,1.4,0.7,2.5C127.8,74.4,127.7,74.9,127.5,75.3z"
                />
                <path
                    className="st0"
                    d="M170.7,81.4c-0.2,0-0.3,0.1-0.3,0.2c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0.3-0.4,0.6-0.7,0.9 c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.1-0.8,0.2-1.3,0.2h-1.1c-1.1,0-1.8-0.1-2.2-0.2c-0.4-0.1-0.7-0.3-0.7-0.7v-5.7h3.5 c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.7,0.3,1.3c0,0.2,0.2,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1 c-0.1-0.5-0.1-1-0.1-1.4c0-0.4,0-0.8,0-1.1c0-0.3,0-0.8,0-1.3c0-0.5,0.1-1,0.1-1.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0 c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.3c-0.1,0.6-0.2,1-0.3,1.3c-0.1,0.2-0.3,0.4-0.7,0.4h-3.5v-6.1h3.4c0.5,0,0.8,0,1.2,0.1 c0.3,0.1,0.6,0.2,0.9,0.4c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.7,0.6,1.1c0.1,0.2,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1 c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.4-0.2-0.7-0.3-1c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.4-0.1-0.6-0.1-0.7 c-0.1-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.6,0-1.1,0.1c-0.6,0.1-1.2,0.1-2,0.1H161c-0.4,0-0.9,0-1.4,0c-0.6,0-1.2-0.1-1.9-0.1 c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.4,0.2,0.5c0.3,0.1,0.7,0.1,0.9,0.2c0.2,0.1,0.4,0.1,0.6,0.2 c0.2,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.4l0,12.6c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.2-0.7,0.2-1.4,0.4c-0.2,0-0.2,0.2-0.2,0.4 c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2c0.5,0,1.1-0.1,1.7-0.1c0.6,0,1.2,0,1.7,0h5.7c0.2,0,0.5,0,0.8,0c0.3,0,0.6,0,0.9,0 c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.6,0c0.2,0,0.3-0.1,0.4-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6 c0-0.2,0.1-0.4,0.1-0.6c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4 c-0.1-0.1-0.2-0.1-0.3-0.2C170.9,81.5,170.8,81.4,170.7,81.4z"
                />
                <path
                    className="st0"
                    d="M209.4,69.2c-0.4,0-0.8,0-1.4,0c-0.5,0-1,0-1.4,0c-0.4,0-0.8-0.1-1.2-0.1c0,0.1-0.1,0.2-0.1,0.2 c0,0.1,0,0.2,0,0.2c0,0.3,0.1,0.4,0.3,0.5c0.7,0.1,1.1,0.2,1.3,0.3c0.2,0.2,0.4,0.4,0.4,0.8v9L199,69.5c-0.1-0.1-0.2-0.2-0.4-0.2 c-0.2,0-0.5-0.1-1-0.1c-0.2,0-0.5,0-0.9,0c-0.4,0-0.7,0-1.1,0c-0.2,0-0.4,0-0.7,0s-0.4,0-0.7,0l0,0.2c0,0.1,0,0.1,0,0.1 c0,0,0,0.1,0,0.1c0,0.3,0.1,0.4,0.3,0.5c0.6,0.1,1,0.2,1.2,0.3c0.2,0.2,0.2,0.3,0.2,0.6l0,12.8c0,0.3-0.1,0.6-0.3,0.7 c-0.2,0.2-0.6,0.3-1.2,0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2 c0.4,0,0.8-0.1,1.2-0.1s0.9,0,1.4,0c0.5,0,0.9,0,1.3,0c0.4,0,0.8,0.1,1.2,0.1c0-0.2,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.4 s-0.1-0.1-0.2-0.1c-0.3-0.1-0.6-0.1-0.8-0.2s-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0-0.4l0-10.9l9.9,12.6 c0.2,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.3,0l0-14.8c0-0.3,0.1-0.6,0.3-0.7 c0.2-0.1,0.6-0.3,1.1-0.3c0.2,0,0.3-0.2,0.3-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3C210.2,69.2,209.8,69.2,209.4,69.2z"
                />
                <path
                    className="st0"
                    d="M246.6,81.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.5-0.3,0.9-0.5,1.2c-0.2,0.3-0.4,0.7-0.6,0.9 c-0.2,0.2-0.5,0.4-0.9,0.5s-0.8,0.2-1.3,0.2H238l9-14.6c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3h-9.8c-0.5,0-1,0-1.4-0.1 c-0.4,0-0.7-0.1-1.1-0.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.4-0.1,0.9-0.2,1.6c-0.1,0.7-0.2,1.3-0.3,2.1c0.1,0.1,0.2,0.1,0.2,0.1 c0.1,0,0.2,0,0.2,0c0.3,0,0.5-0.1,0.6-0.4c0.1-0.6,0.2-1,0.4-1.3c0.2-0.3,0.3-0.6,0.6-0.8c0.2-0.2,0.5-0.3,0.9-0.4s0.8-0.1,1.3-0.1 h4.7l-9,14.7c-0.1,0.1-0.1,0.3-0.1,0.4h10c0.5,0,1,0,1.5,0.1c0.5,0.1,0.8,0.1,1.2,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.4 c0-0.4,0.1-1,0.2-1.7c0.1-0.7,0.2-1.5,0.3-2.2c-0.2-0.1-0.4-0.2-0.6-0.2C246.8,81.2,246.7,81.3,246.6,81.3z"
                />
                <path
                    className="st0"
                    d="M283.5,81.6c-0.2,0-0.3,0.1-0.3,0.2c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0.3-0.4,0.6-0.7,0.9 c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.1-0.8,0.2-1.3,0.2h-1.1c-1.1,0-1.8-0.1-2.2-0.2c-0.4-0.1-0.7-0.3-0.7-0.7V78h3.5 c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.7,0.3,1.3c0,0.2,0.2,0.3,0.5,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1 c-0.1-0.5-0.1-1-0.1-1.4c0-0.4,0-0.8,0-1.1c0-0.3,0-0.8,0-1.3c0-0.5,0.1-1,0.1-1.3c-0.1,0-0.1,0-0.2,0s-0.2,0-0.2,0 c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.3c-0.1,0.6-0.2,1-0.3,1.3c-0.1,0.2-0.3,0.4-0.7,0.4h-3.5v-6.1h3.4c0.5,0,0.8,0,1.2,0.1 c0.3,0.1,0.6,0.2,0.9,0.4c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.7,0.6,1.1c0.1,0.2,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1 c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.4-0.2-0.7-0.3-1c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.4-0.1-0.6-0.1-0.7 c-0.1-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.6,0-1.1,0.1c-0.6,0.1-1.2,0.1-2,0.1h-5.2c-0.4,0-0.9,0-1.4,0c-0.6,0-1.2-0.1-1.9-0.1 c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.4,0.2,0.5c0.3,0.1,0.7,0.1,0.9,0.2s0.4,0.1,0.6,0.2c0.2,0.1,0.2,0.2,0.3,0.3 c0.1,0.1,0.1,0.2,0.1,0.4l0,12.6c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.1-0.7,0.2-1.4,0.4c-0.2,0-0.2,0.2-0.2,0.4c0,0.1,0,0.1,0,0.2 c0,0.1,0,0.2,0.1,0.2c0.5,0,1.1-0.1,1.7-0.1c0.6,0,1.2,0,1.7,0h5.7c0.2,0,0.5,0,0.8,0c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,0.8,0 c0.2,0,0.4,0,0.6,0c0.2,0,0.3-0.1,0.4-0.2c0-0.1,0.1-0.2,0.1-0.3s0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.6 c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.2 C283.7,81.7,283.6,81.6,283.5,81.6z"
                />
                <path
                    className="st0"
                    d="M20.6,76.6c-3.3,0-6.6,0.1-9.8,0.2s-6.6,0.3-9.8,0.7c3.3,0.4,6.6,0.6,9.8,0.7c3.3,0.2,6.6,0.2,9.8,0.2 s6.6-0.1,9.8-0.2c3.3-0.2,6.6-0.3,9.8-0.7c-3.3-0.4-6.6-0.6-9.8-0.7S23.9,76.6,20.6,76.6z"
                />
                <path
                    className="st0"
                    d="M35.6,23.4c0-17.1-11.5-20.6-23.1-20.6H0v0.7c2.6,0,4,2.5,4,6.1v27.2c0,4-1.5,6.2-4,6.2v0.7h12.5 C24.1,43.8,35.6,41,35.6,23.4z M10,3.6h2.6c6.4,0,16.1,1.1,16.1,19.8c0,19.3-9.8,19.6-16.1,19.6H10V3.6z"
                />
                <path
                    className="st0"
                    d="M69.9,37.9c-3-12.7-7-13.1-10.9-13.3c6.3-0.4,10.8-2.7,10.8-10.6c0-9.7-6.4-11.1-18-11.1H40.8v0.7 c2.6,0,3.6,0.8,3.6,4.4v29c0,4.9-1.1,6-3.6,6v0.7h13V43c-2.4,0-3.7-1.3-3.7-5.3V25h2.7c1.5,0,7.4,0,9,6.1 c3.3,12.6,2.4,12.7,9.9,12.7h4.4V43C74.6,42.9,71,42.3,69.9,37.9z M51.9,24.2h-1.8V3.6h1.8c6.2,0,11.6-0.2,11.6,10.4 C63.5,24.3,58.5,24.2,51.9,24.2z"
                />
                <path
                    className="st0"
                    d="M83.4,39.4c-1.9,0-3.4,1.6-3.4,3.5c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4 C86.8,40.9,85.3,39.4,83.4,39.4z"
                />
                <path
                    className="st0"
                    d="M129.3,3.4V2.6h-14.2v0.7c8.2,0,4.9,8.2,3.1,13.6l-7.1,18.9L100.8,8.5c-0.1-0.4-1.3-5.1,3.5-5.1V2.6H88.8v0.7 c2,0,3.8,0.1,5.4,4.2c0,0,6.8,18.6,13.9,35.5h0.8l9.9-25.9C124.1,3.4,125.5,3.4,129.3,3.4z"
                />
                <path
                    className="st0"
                    d="M166.1,43v-0.7c-1.5-0.1-5.1-0.7-6.1-5c-3-12.6-6.9-13-10.7-13.1c6.2-0.4,10.7-2.7,10.7-10.5 c0-9.6-6.3-11-17.7-11h-11v0.7c2.5,0,3.5,0.8,3.5,4.4v28.6c0,4.8-1.1,6-3.5,6v0.7H144v-0.7c-2.4,0-3.7-1.3-3.7-5.2V24.5h2.7 c1.5,0,7.3,0,8.9,6c3.3,12.5,2.4,12.5,9.7,12.5L166.1,43z M142.1,23.8h-1.8V3.4h1.8c6.1,0,11.4-0.2,11.4,10.2 C153.5,23.8,148.6,23.8,142.1,23.8z"
                />
                <path
                    className="st0"
                    d="M191.9,42.3V43H205v-0.7c-1.6-0.1-2.8-0.1-4.7-4.6c0,0-7.1-18.1-14.1-35h-0.6L176,28.7 c-5,13.3-6.4,13.5-8.6,13.5V43h12.8v-0.7c-8,0.2-5.4-6.9-3.9-11.5h14.8l2,5C194.4,39,195,42.3,191.9,42.3z M176.5,30.1l6.9-19 l7.5,19H176.5z"
                />
                <path
                    className="st0"
                    d="M216.7,28.7V11.6L241,43h1.8V16.9c0-5.3-0.2-13.6,8.2-13.6V2.6h-16.4v0.7c7.6,0,7.5,8.2,7.5,13.6v17.7 l-24.8-32h-10.6v0.7c1.5,0.1,3.1-0.2,6.8,4.4l2.4,3.1v18c0,5.2,0,13.6-8.3,13.6v0.7h16.6v-0.7C216.7,42.3,216.7,33.7,216.7,28.7z"
                />
                <path
                    className="st0"
                    d="M255.5,3.3L255.5,3.3c4.3,0,4.3,1.4,4.3,12.3v17.7c0,12.5-4.7,16.8-9.6,18.6l0.2,0.7c9.3-1.4,15.3-6.8,15.3-20 V8.6c0-3.7,2-5.3,4.6-5.3V2.6h-14.8V3.3z"
                />
                <path
                    className="st0"
                    d="M303.4,31.1h-0.7c-1.2,11.5-11.4,11.2-16.5,11.2h-2.6V23h3.7c1.4,0,7.6,0.1,7.9,5.8h0.7V16.5h-0.7 c-0.3,5.8-6.4,5.8-7.9,5.8h-3.7V3.4h3.1c4.6,0,14.6,0.1,15.3,9.6h0.7V2.7h-28.1v0.7c2.5,0,3.1,1.8,3.1,5.4v27.5c0,3.9-0.7,6-3.1,6 v0.7h28.9V31.1z"
                />
                <path
                    className="st0"
                    d="M316.8,22.2l9.5,6.1c6.7,4.8,5.6,14.4-3.1,14.4c-9.7,0-12.3-6.5-13-13.5h-0.7v11c0,0,4.3,3.2,13.7,3.2 c8.9,0,11.4-4.7,11.4-4.7c3-4.7,1.7-11.1-6-16.1l-9.5-6.3c-8.7-5.6-4-13.6,2.2-13.6c9.6,0,11.8,6,12.3,11.6h0.8V4.8 c0,0-4.8-2.7-13.1-2.7c-8,0-10.8,4.5-10.8,4.5C306.1,13.4,311.3,18.6,316.8,22.2z"
                />
                <path
                    className="st0"
                    d="M316.7,76.4c-3.2,0-6.4,0.1-9.6,0.2s-6.4,0.3-9.6,0.7c3.2,0.4,6.4,0.6,9.6,0.7c3.2,0.2,6.4,0.2,9.6,0.2 s6.4-0.1,9.6-0.2c3.2-0.2,6.4-0.3,9.6-0.7c-3.2-0.4-6.4-0.6-9.6-0.7S319.9,76.4,316.7,76.4z"
                />
            </g>
        </svg>
)
}

export default Template
