import React, { useState } from 'react';
import UserSettingsDetails from './user-settings-details';
import UserSettingsEdit from './user-settings-edit';

const initialValues = { 
    firstName: 'Luigi', 
    lastName: 'Bianchi',
    phone: '333 333 333 3330', 
    email: 'luigi.bianchi@gmail.com',
    password: 3,
    newPassword: null,
    language: { id: 'it', name: 'italiano'},
    notes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et butdolore sic et magna aliqua. Adipiscing bibendum est ultricies integer quis auctor. Id aliquet lectus proin sic.Amet aliquam id diam maecenas ultricies mi eget. Id faucibus nisl tincidunt eget nullam non nisi.Magna sit amet purus gravida quis blandit turpis. In nibh mauris cursus mattis molestie. Ipsum dolor sit.",
};

const UserSettings = () => {

    const [editView, setEditView] = useState(false);

    const handleEdit = () => {
        setEditView((prevState) => !prevState);
    }

    return (
        <>         
            { editView ?
                <UserSettingsEdit 
                    handleEdit={handleEdit} 
                    initialValues={initialValues}
                /> :
                <UserSettingsDetails 
                    handleEdit={handleEdit}  
                    initialValues={initialValues}
                />
            }
        </>
    )
}

export default UserSettings;