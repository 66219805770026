import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadAdministrative } from "redux/features/download/actions";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import EmptySearch from "components/emptySearch";
import Loader from "components/loader";
import { toast } from "react-toastify";
import { useCustomLocalization } from "utils";

import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    downloadButtonLabel: toLanguageString(
      "metislab.frontend.components.orders.components.listing.modalDocumentsOrder.downloadButtonLabel",
      defaultMessages.metislab.frontend.components.orders.components.listing
        .modalDocumentsOrder.downloadButtonLabel
    ),
    noDocuments: toLanguageString(
      "metislab.frontend.components.orders.components.listing.modalDocumentsOrder.noDocuments",
      defaultMessages.metislab.frontend.components.orders.components.listing
        .modalDocumentsOrder.noDocuments
    ),
    title: toLanguageString(
      "metislab.frontend.components.orders.components.listing.modalDocumentsOrder.title",
      defaultMessages.metislab.frontend.components.orders.components.listing
        .modalDocumentsOrder.title
    ),
  };
};

const ModalDocumentsOrder = ({ handleModal }) => {
  const { downloadButtonLabel, noDocuments, title } = useLocalizedMessages();

  const dispatch = useDispatch();

  const loadingDocumentsOrder = useSelector(
    (state) => state.order.loadingDocumentsOrder
  );
  const documentsOrder = useSelector((state) => state.order.documentsOrder);
  const loadingDownloadDoc = useSelector(
    (state) => state.download.loadingDownloadDoc
  );

  const [isDownloading, setIsDownloading] = useState(false);
  const [activeDownload, setActiveDownload] = useState(null);

  const handleClick = (id) => {
    const args = {
      id,
      toast,
    };
    setIsDownloading(true);
    setActiveDownload(id);
    dispatch(downloadAdministrative(args)).then(() => {
      setIsDownloading(false);
      setActiveDownload(null);
    });
  };

  return (
    <Dialog
      className="c-modal-document-order"
      title={title}
      onClose={handleModal}
      width={566}
    >
      {loadingDocumentsOrder ? (
        <Loader className="c-loader c-loader--center" />
      ) : documentsOrder.length === 0 ? (
        <EmptySearch strongText={noDocuments} text={null} />
      ) : (
        <ul className="c-document-list">
          {documentsOrder.map((doc) => {
            return (
              <li key={doc.id}>
                <span>{`${doc.order_id} - ${doc.document_type_description}`}</span>
                <Button
                  disabled={loadingDownloadDoc && isDownloading}
                  className="c-button-download"
                  fillMode="outline"
                  onClick={() => handleClick(doc.document_id)}
                >
                  {downloadButtonLabel}
                  {loadingDownloadDoc &&
                  isDownloading &&
                  activeDownload === doc.document_id ? (
                    <Loader size="small" className="k-m-0" />
                  ) : (
                    <span
                      role="presentation"
                      className="k-icon k-i-download"
                    ></span>
                  )}
                </Button>
              </li>
            );
          })}
        </ul>
      )}
    </Dialog>
  );
};

export default ModalDocumentsOrder;
