import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { getDocAdministrative } from "redux/features/download/actions";
import { setPagerState } from "redux/features/download";
import CellCustomer from "./cellCustomer";
import CellDate from "./cellDate";
import CellDetails from "./cellDetails";
import CellState from "./cellState";
import EmptySearch from "components/emptySearch";
import HeaderDocsAdministrative from "./header";
import Loader from "components/loader";
import { toast } from "react-toastify";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCustomLocalization } from "utils";

import { 
  formatDateByLanguage,
  formatDateTimeByLanguage
} from "utils";
import "./administrative.scss";
import defaultMessages from "i18n/en.json";

const useLocalizedMessages = () => {
  const [toLanguageString] = useCustomLocalization();
  return {
    labelEmptyListOrder: toLanguageString(
      'metislab.frontend.components.download.components.emptyListOrder.label',
      defaultMessages.metislab.frontend.components.download.components.emptyListOrder.label),
    labelStrongEmptyListOrder: toLanguageString(
      'metislab.frontend.components.download.components.emptyListOrder.labelStrong',
      defaultMessages.metislab.frontend.components.download.components.emptyListOrder.labelStrong),
    downloadButtonLabel: toLanguageString(
      'metislab.frontend.components.download.components.table.downloadButtonLabel',
      defaultMessages.metislab.frontend.components.download.components.table.downloadButtonLabel),
    tabCustomerTitle: toLanguageString(
      'metislab.frontend.components.customer.components.details.tabs.tabCustomer.title',
      defaultMessages.metislab.frontend.components.customer.components.details.tabs.tabCustomer.title),
  };
};

const Administrative = () => {
  const {
    labelEmptyListOrder,
    labelStrongEmptyListOrder,
    downloadButtonLabel,
    tabCustomerTitle,
  } = useLocalizedMessages();

  const dispatch = useDispatch();

  const intl = useInternationalization();

  const languageDateFormat = formatDateByLanguage(intl.locale);

  /**
   * Download states
   */
  const docsAdministrative = useSelector((state) => state.download.administrativeList);
  const layoutColumn = useSelector((state) => state.download.layoutColumn);
  const loading = useSelector((state) => state.download.loading);
  const pagerState = useSelector((state) => state.download.pagerState);
  const pagination = useSelector((state) => state.download.pagination);
  const pageSizes = useSelector((state) => state.download.pageSizes);
  const viewDocsAdministrative = useSelector((state) => state.download.viewDocsAdministrative);

  /**
   * IndexWebCustomer states
   */
  const companyCustomers = useSelector(
    (state) => state.indexWebCustomer.indexCustomers
  );
  const loadingIndexWebCustomer = useSelector(
    (state) => state.indexWebCustomer.loading
  );
  const selectedCompany = useSelector(
    (state) => state.indexWebCustomer.selectedCompany
  );

  /**
   * User states
   */
  const loadingUser = useSelector((state) => state.user.loading);

  /**
   * getListOfOrders is a callback's hook
   * used to get the list of orders
   */
  const getListDocsAdministrative = useCallback(() => {
    if (selectedCompany && companyCustomers) {
      dispatch(getDocAdministrative({ pager: pagerState, toast }));
    }
  }, [companyCustomers, dispatch, pagerState, selectedCompany]);

  useEffect(() => {
    getListDocsAdministrative();
  }, [getListDocsAdministrative]);

  /**
   * pageChange is the function to handle the change between pages
   * @param {object} e - It's an event object
   */
  const pageChange = (e) => {
    const { skip, take } = e.page;
    dispatch(
      setPagerState({
        page: skip / take + 1,
        perPage: take,
      })
    );
  };

  /**
   * sortChange is the function to handle the sort of customers by column
   * @param {object} e - It's an event object
   */
  const sortChange = (e) => {
    if (e.sort.length) {
      const { field, dir } = e.sort[0];
      dispatch(
        setPagerState({
          orderBy: field,
          orderByDirection: dir,
        })
      );
    } else {
      dispatch(
        setPagerState({
          orderBy: null,
          orderByDirection: null,
        })
      );
    }
  };

  /**
   * Render the cell in column Agent
   * @param {object} data
   * @returns {CellAgent} component
   */
  const cellCustomer = (data) => {
    const customers = layoutColumn.find(
      (column) => column.propId === "customer_code"
    ).editor.data.rows;
    const customer = customers.find(
      (customer) => customer.value === data?.dataItem?.customer_code
    );
    return <CellCustomer name={customer?.label} />;
  };

  /**
   * Render the cell in column date
   * @param {object} data
   * @returns {cellDate} component
   */
  const cellDate = (data) => {
    const { document_date } = data.dataItem;
    return <CellDate date={document_date} />;
  };

  /**
   * Render the cell in column state
   * @param {object} data
   * @returns {CellAgent} component
   */
  const cellState = (data) => {
    const states = layoutColumn.find(
      (column) => column.propId === "document_state"
    ).editor.data.rows;
    const stateLabel = states.find(
      (state) => Number(state.value) === data?.dataItem?.document_state
    );

    return (
      <CellState
        stateLabel={stateLabel?.label}
        state={data?.dataItem?.document_state}
      />
    );
  };

  /**
   * Render the cell in column state
   * @param {object} data
   */
  const cellTypeCode = (data) => {
    const states = layoutColumn.find(
      (column) => column.propId === "document_type_code"
    ).editor.data.rows;
    const stateLabel = states.find(
      (state) => Number(state.value) === data?.dataItem?.document_type_code
    );

    return (
      <CellState
        stateLabel={stateLabel?.label}
        state={data?.dataItem?.document_type_code}
      />
    );
  };

  /**
   * Render the cell in column details
   * @param {object} data
   * @returns {CellDetails} component
   */
  const cellDetails = (data) => {
    const { document_id } = data.dataItem;

    return <CellDetails idDocs={document_id} />;
  };

  return (
    <div className="">
      <HeaderDocsAdministrative filterByType={layoutColumn} />
      {loading || loadingUser || loadingIndexWebCustomer ? (
        <Loader className="c-loader--center" size="large" />
      ) : docsAdministrative?.length === 0 ? (
        <div className="c-empty-state-orders">
          <EmptySearch
            icon={pagerState.search ? "search" : null}
            strongText={labelStrongEmptyListOrder}
            text={labelEmptyListOrder}
          />
        </div>
      ) : viewDocsAdministrative ? (
        <section className="c-downloads-view-grid l-tablet-grid u-grid-3 u-gap-16">
          {docsAdministrative?.map((doc) => {
            return (
              <div key={doc.id} className="c-tile c-tile--download">
                <header className="c-tile__header l-mobile-flex u-flex-between u-flex-middle">
                  <h2 className="c-tile__title t-title t-title--4">
                    <span className="icon icon-shipping"></span>
                    {`Ordine ${doc?.document_number}`}
                  </h2>
                  <p>
                    <strong>Data:</strong>
                    <span>
                      {intl.formatDate(
                        new Date(doc?.document_date),
                        languageDateFormat
                      )}
                    </span>
                  </p>
                </header>
                <ul className="c-tile__list">
                  <li className="l-mobile-flex u-flex-between">
                    <span>
                      <strong>{`${tabCustomerTitle}:`}</strong>
                      {doc?.customer_name}
                    </span>
                    {/* <span>
                      <strong>Importo:</strong>
                      3.456,90 €
                    </span> */}
                  </li>
                </ul>
                <p>{`Ordine_N.${
                  doc?.document_number
                }_${formatDateTimeByLanguage(
                  intl.locale,
                  doc?.document_date,
                  false,
                  false,
                  false,
                  true
                )}_${formatDateTimeByLanguage(
                  intl.locale,
                  doc?.document_date,
                  false,
                  false,
                  true
                )}.PDF`}</p>
                <footer className="c-tile__actions u-align-right u-border">
                  <button
                    dir="rtl"
                    className="k-button k-button-md k-button-link k-button-link-base k-rounded-md k-rtl c-tile__action"
                  >
                    <span
                      role="presentation"
                      className="k-button-icon k-icon k-i-download"
                    ></span>
                    <Link
                      className="k-button-text"
                      to={`/api/web/document/download/${doc?.document_id}`}
                      target="_blank"
                    >
                      {downloadButtonLabel}
                    </Link>
                  </button>
                </footer>
              </div>
            );
          })}
        </section>
      ) : (
        <Grid
          className="c-download-grid"
          data={docsAdministrative}
          pageable={{ buttonCount: 5, info: false, pageSizes }}
          onPageChange={pageChange}
          skip={(pagerState?.page - 1) * pagerState?.perPage}
          sortable={{
            allowUnsort: true,
            mode: "single",
          }}
          sort={[
            {
              field: pagerState.orderBy,
              dir: pagerState.orderByDirection,
            },
          ]}
          onSortChange={sortChange}
          take={pagination?.size}
          total={pagination?.total}
        >
          {layoutColumn &&
            layoutColumn.map((column) => {
              if (!column.hidden || column.propId === "id") {
                let cell = null;
                switch (column?.propId) {
                  case "document_date":
                    cell = cellDate;
                    break;
                  case "document_state":
                    cell = cellState;
                    break;
                  case "document_type_code":
                    cell = cellTypeCode;
                    break;
                  case "id":
                    cell = cellDetails;
                    break;
                  case "customer_code":
                    cell = cellCustomer;
                    break;
                  default:
                    cell = null;
                    break;
                }

                return (
                  <Column
                    key={column?.propId}
                    field={column?.propId}
                    title={column?.propId === "id" ? " " : column?.label}
                    cell={cell}
                    sortable={column?.propId === "id" ? false : true}
                    locked={column?.propId === "id" ? true : false}
                  />
                );
              }
              return null;
            })}
        </Grid>
      )}
    </div>
  );
};

export default Administrative;
